import React, { Component } from "react";
import { connect } from "react-redux";
import pageloadloader from "../asset/images/page-load-loader.gif";
class Loader extends Component {
  render() {
    
    const { loading = false } = this.props.loading;
    return (
      loading&&  
      <div id="loading">
      <div id="loading-center">
      {/* <img src={pageloadloader} className="loader-img" /> */}
      </div>
   </div>
    );
  }
}



const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading
  };
};

export default connect(mapStateToProps)(Loader);
