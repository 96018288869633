import React from "react";
import { connect } from "react-redux";
import { LogFEvent } from '../firebase'
import {
   BasicInfoAPI, BasicCountAPI, PostListAPI, CommentListAPI, PostAPI, SearchPostAPI, SavePostAPI, RemoveFromSaveAPI,
   UPVoteAPI, DownVoteAPI, CommentAPI, LogoutAPI, UPVoteCmtAPI, DownVoteCmtAPI, SearchUsersAPI,DeletePostAPI
} from "../actions/globalactions";
import { dispatchLoadingStatus, encryptStorage, mentionUser } from '../utils/utils';
import { basepath, devicetype, Policy_URL, Help_URL } from "../constants";
import Loader from "./Loader";
import logo from "../asset/images/logo.svg";
import user1 from "../asset/images/user1.svg";
import pageloadloader from "../asset/images/page-load-loader.gif";
import sort_asc from "../asset/images/sort_asc.svg";
import sort_desc from "../asset/images/sort_desc.svg";
import sorting from "../asset/images/sorting.svg";
import swal from 'sweetalert';
import PostCard from './Postcard';
import arrowtutorial from "../asset/images/arrowtutorial.png";
import Modal from "react-responsive-modal";
import { Slider } from 'material-ui-slider';
import Tooltip from '@material-ui/core/Tooltip';
import Sidebar from './Sidebar';
import { Img } from 'react-image';
import TextareaAutosize from 'react-textarea-autosize';
import Mentiontextarea from './Mentionuser'
import moment from 'moment';
// import InputEmoji from 'react-input-emoji';

var lat, long;

class Home extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         Pbar: 10,
         Emotion: "",
         Content: "",
         isAnonymous: false,
         isPrivate: false,
         isopenid: "",
         searchTerm: "",
         Pdata: [],
         loading: true,
         start: 0,
         startsearch: 0,
         searchemotion: "",
         sort: 0,
         cardview: 'list',
         textcreate: "",
         selection: true,
         // openmentionarea: false, //cizzr 1.5 (NiraliBThummar)
         // mentionUserlist:[],//cizzr 1.5 (NiraliBThummar)
         collectmuserlist: [],
         postbyfrnd: false,
         Askfortour: localStorage.getItem('askfortourflag') == "true" ? true : false,
         Mark1: localStorage.getItem('askfortourflag') == "true" ? false : localStorage.getItem('guideflag') == "true" && localStorage.getItem('guidehomeflag') == "true" ? true : false,
         Mark2: false,
         Mark3: false,
         Mark4: false,
         Mark5: false,
         Mark6: false,
         Mark7: false
      }
      this.handleScroll = this.handleScroll.bind(this);
   }





   onEmojiClick = (event, emojiObject) => {

      this.setState({ Content: this.state.Content + emojiObject.emoji })
   };

   changes(value) {

      this.setState({ Pbar: value });
      if (lat && long) {
         var data = {
            "text": this.state.searchTerm,
            "emotion": this.state.searchemotion,
            "start": 0,
            "limit": 10,
            "lattitude": lat ? lat : "",
            "lognitude": long ? long : "",
            "range": value ? parseInt(value) : 10,
            "post_by_friends": this.state.postbyfrnd ? "1" : "0",
            "device_type": devicetype
         }

         this.props.SearchPostAPI(data)
         this.setState({ startsearch: 0, loading: true, Pdata: [] })
         //   console.log("Near by api call")
      } else {

         if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'geolocation' })
               .then(function (permissionStatus) {
                  // console.log('geolocation permission state is ', permissionStatus.state);

                  if (permissionStatus.state == 'granted') {
                     navigator.geolocation.getCurrentPosition(function (position) {
                        // console.log("Latitude is :", position.coords.latitude);
                        // console.log("Longitude is :", position.coords.longitude);

                        lat = position.coords.latitude;
                        long = position.coords.longitude;


                     });

                  } else {
                     swal({ text: "Please allow location access from your browser settings to use filter by location.", icon: "warning" });
                     // swal({ text: "Please allow location access to use filter by location.", icon: "warning" });
                  }
                  permissionStatus.onchange = function () {
                     // console.log('geolocation permission state has changed to ', this.state);
                     if (this.state == 'granted') {

                        navigator.geolocation.getCurrentPosition(function (position) {
                           // console.log("Latitude is :", position.coords.latitude);
                           // console.log("Longitude is :", position.coords.longitude);
                           lat = position.coords.latitude;
                           long = position.coords.longitude;

                        });


                     }
                  };
               });
         } else {
            swal({ text: "Please allow location access to use filter by location.", icon: "warning" });
         }
      }
   }


   componentDidMount() {
      window.addEventListener("scroll", this.handleScroll);
      navigator.geolocation.getCurrentPosition(position => {

         // console.log("position...", position)
         lat = position.coords.latitude;
         long = position.coords.longitude;

      })
      LogFEvent("home_pageopen")
      //    if ( navigator.permissions && navigator.permissions.query) {
      //    navigator.permissions.query({name:'geolocation'})
      //    .then(function(permissionStatus) {
      //      console.log('geolocation permission state is ', permissionStatus.state);

      //  if(permissionStatus.state=='granted'){
      //    navigator.geolocation.getCurrentPosition(function(position) {
      //        console.log("Latitude is :", position.coords.latitude);
      //        console.log("Longitude is :", position.coords.longitude);
      //        
      //        lat = position.coords.latitude;
      //         long = position.coords.longitude;
      //      });

      //  }
      //      permissionStatus.onchange = function() {
      //        console.log('geolocation permission state has changed to ', this.state);
      //        if(this.state=='granted'){

      //          navigator.geolocation.getCurrentPosition(function(position) {
      //              console.log("Latitude is :", position.coords.latitude);
      //              console.log("Longitude is :", position.coords.longitude);
      //              lat = position.coords.latitude;
      //         long = position.coords.longitude;
      //            });


      //        }
      //      };
      //    });
      // }

      // var token = encryptStorage.getItem("cizzr_token")
      var token = encryptStorage.getItem("cizzr_token")

      if (!token) {
         // swal({text: tokenexpire, icon: "error",className:"error-modal"});
         this.props.history.push(`/signin`);
      } else {
         // console.log('guideflag..', localStorage.getItem('guideflag'))
         // this.props.isLoading(true);
         var Token = encryptStorage.getItem('FCM_Token');
         const postdata = {
            "fcm_token": Token ? Token : "",
            "device_type": devicetype
         }
         this.props.BasicCountAPI(postdata);
         // if (!encryptStorage.getItem('basicInfoRes')) {
         this.props.BasicInfoAPI(postdata);
         // } else {
         //    this.setState({ basicinfo: encryptStorage.getItem('basicInfoRes') })
         // }
         //  if (this.state.start != 0){
         //     this.state.start +=10
         //  }
         const pdata = {
            "sortby": this.state.sort,
            "start": this.state.start,
            "limit": 10,
            "device_type": devicetype,
            "post_by_friends": this.state.postbyfrnd ? "1" : "0"
         }
         this.setState({ Pdata: encryptStorage.getItem('PostData') && encryptStorage.getItem('PostData') != 'undefined' ? encryptStorage.getItem('PostData') : [] })
         this.props.PostListAPI(pdata);


         if (window.navigator && localStorage.getItem('askfortourflag') != "true") {
            if (window.navigator.userAgent.toLowerCase().includes("mobi"))
            document.body.classList.add('sidebar-main');
               // document.querySelector('.wrapper-menu').click();
         }

         if(localStorage.getItem('guideflag')== "true"){
            document.body.classList.add('guidecss');
         }else{
            document.body.classList.remove('guidecss');
         }

      }

   }
   componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
   }
   sort(e) {

      const image = e;
      // console.log(e)
      if (image == "default") {
         this.setState({ sort: 1 })
         // console.log(this.state.sort);
      }
      else if (image == "ascending") {
         this.setState({ sort: 2 })
      }
      else if (image == "descending") { this.setState({ sort: 0 }) }
      if (this.state.searchTerm.trim() == "" && this.state.searchemotion == "") {
         const pdata = {
            "sortby": image == "default" ? 1 : image == "ascending" ? 2 : image == "descending" ? 0 : this.state.sort,
            "start": 0,
            "limit": 10,
            "device_type": devicetype,
            "post_by_friends": this.state.postbyfrnd ? "1" : "0"
         }
         this.props.PostListAPI(pdata);
         this.setState({ start: 0, loading: true, Pdata: [] })
      } else if (image == "friend") {
         this.setState({ loading: true, Pdata: [] })
         var data = {
            "text": this.state.searchTerm,
            "emotion": this.state.searchemotion,
            "start": 0,
            "limit": 10,
            "lattitude": lat ? lat : "",
            "lognitude": long ? long : "",
            "range": this.state.Pbar ? parseInt(this.state.Pbar) : 10,
            "post_by_friends": this.state.postbyfrnd ? "1" : "0",
            "device_type": devicetype
         }
         this.props.SearchPostAPI(data);
      }

   }
   handleScroll() {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
         if (this.state.Pdata) {
            if (this.state.Pdata.length > 9) {
               this.searching()
            }
         }
      }
   }


   searching = () => {

      if (!this.state.loading) {
         if (this.state.searchemotion || this.state.searchTerm.trim() != "") {

            if (this.state.STotal_COUNT > this.state.startsearch + 10) {
               var data = {
                  "text": this.state.searchTerm,
                  "emotion": this.state.searchemotion,
                  "start": this.state.startsearch + 10,
                  "limit": 10,
                  "lattitude": lat ? lat : "",
                  "lognitude": long ? long : "",
                  "range": this.state.Pbar ? parseInt(this.state.Pbar) : 10,
                  "post_by_friends": this.state.postbyfrnd ? "1" : "0",
                  "device_type": devicetype
               }
               // console.log("search call..",data)
               this.props.SearchPostAPI(data)
               this.setState({ startsearch: this.state.startsearch + 10, loading: true })

            }
         }
         else {

            if (this.state.Total_COUNT > this.state.start + 10) {
               const pdata = {
                  "sortby": this.state.sort,
                  "start": this.state.start + 10,
                  "limit": 10,
                  "device_type": devicetype,
                  "post_by_friends": this.state.postbyfrnd ? "1" : "0"
               }
               // console.log("Post call..",pdata)
               this.props.PostListAPI(pdata);
               this.setState({ start: this.state.start + 10, loading: true })
            }
         }


      }

   }

   logout = () => {
      swal({
         title: "",
         text: "Are you sure you want to logout?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               this.finallogout()
            } else {
            }
         });
   }

   finallogout = () => {
      this.props.isLoading(true);
      var Token = encryptStorage.getItem('FCM_Token');
      this.props.LogoutAPI({
         "fcm_token": Token ? Token : "",
         "device_type": devicetype
      })
   }




   searchCondition(event) {

      this.setState({ loading: true })
      this.setState({ searchTerm: event.target.value, startsearch: 0 });
      if (event.target.value == "" && this.state.searchemotion == "") {
         this.setState({ start: 0, loading: true, Pdata: [] })
         const pdata = {
            "sortby": this.state.sort,
            "start": 0,
            "limit": 10,
            "device_type": devicetype,
            "post_by_friends": this.state.postbyfrnd ? "1" : "0"
         }
         this.props.PostListAPI(pdata);
      } else if (event.target.value.length >= 1) {
         this.setState({ loading: true, Pdata: [] })
         var data = {
            "text": event.target.value,
            "emotion": this.state.searchemotion,
            "start": 0,
            "limit": 10,
            "lattitude": lat ? lat : "",
            "lognitude": long ? long : "",
            "range": this.state.Pbar ? parseInt(this.state.Pbar) : 10,
            "post_by_friends": this.state.postbyfrnd ? "1" : "0",
            "device_type": devicetype
         }
         this.props.SearchPostAPI(data);
      }
   }


   emotionsearch = (id) => {

      this.setState({ loading: true, Pdata: [], searchemotion: this.state.searchemotion == id ? "" : id, startsearch: 0 })
      var data = {
         "text": this.state.searchTerm,
         "emotion": this.state.searchemotion == id ? "" : id,
         "start": 0,
         "limit": 10,
         "lattitude": lat ? lat : "",
         "lognitude": long ? long : "",
         "range": this.state.Pbar ? parseInt(this.state.Pbar) : 10,
         "post_by_friends": this.state.postbyfrnd ? "1" : "0",
         "device_type": devicetype
      }
      this.props.SearchPostAPI(data);
   }

  

   componentWillReceiveProps(nextprops) {

      if (nextprops.basicinfo) {
         if (nextprops.basicinfo != this.props.basicinfo) {
            this.setState({ basicinfo: nextprops.basicinfo })
         }
      }
      if (nextprops.postlist) {
         if (nextprops.postlist != this.props.postlist) {
            // debugger
            this.setState({ loading: false })
            if (this.state.Pdata.length == 0 || this.state.start == 0) {
               this.setState({ Pdata: nextprops.postlist.data, Total_COUNT: nextprops.postlist.count, nodata: nextprops.postlist.count > 0 ? false : true })
               encryptStorage.setItem('PostData', nextprops.postlist && nextprops.postlist.data)
            }
            else {

               var new_data = this.state.Pdata.concat(nextprops.postlist.data)
               this.setState({ Pdata: new_data, Total_COUNT: nextprops.postlist.count, nodata: false })
            }
         }
      }

      if (nextprops.searchlist) {
         if (nextprops.searchlist != this.props.searchlist) {

            this.setState({ loading: false })
            if (this.state.Pdata.length == 0 || this.state.startsearch == 0) {
               this.setState({ Pdata: nextprops.searchlist.data, STotal_COUNT: nextprops.searchlist.count, nodata: nextprops.searchlist.data.length > 0 ? false : true })
            }
            else {

               var search_new_data = this.state.Pdata.concat(nextprops.searchlist.data)
               this.setState({ Pdata: search_new_data, STotal_COUNT: nextprops.searchlist.count, nodata: false })
            }
         }
      }

      if (nextprops.commentlist) {
         if (nextprops.commentlist != this.props.commentlist) {

            if (this.state.CID != undefined) {
               var list = this.state.Pdata
               list[this.state.CID]["commentlist"] = nextprops.commentlist
               // list[this.state.CID]["is_open"] = true
               this.setState({ Pdata: list })
            }
         }
      }

      if (nextprops.savePost) {
         if (nextprops.savePost != this.props.savePost) {

            if (this.state.BID != undefined) {
               // var list = this.state.Pdata
               // list[this.state.BID]["is_save"] = !list[this.state.BID]["is_save"]
               // this.setState({ Pdata: list })
            }
         }
      }

      // if (nextprops.votePost) {
      //    if (nextprops.votePost != this.props.votePost) {

      //       if (this.state.LID != undefined) {
      //          var list = this.state.Pdata
      //          list[this.state.LID]["total_up_votes"] = nextprops.votePost.data
      //          list[this.state.LID]["is_up_vote"] = !list[this.state.LID]["is_up_vote"]
      //          this.setState({ Pdata: list })
      //       }
      //    }
      // }

      if (nextprops.postres) {
         if (nextprops.postres != this.props.postres) {

            // var list = this.state.Pdata;
            // list.unshift(nextprops.postres);
            // this.setState({ Pdata: list});
            this.setState({ openpost: false, Error: "", Emotion: "", Content: "", isAnonymous: false });
            LogFEvent("post_created_web")
         }
      }

      //       if (nextprops.searchUser) {
      //          if (nextprops.searchUser != this.props.searchUser) {
      // var final = nextprops.searchUser?nextprops.searchUser.filter(i=>!this.state.collectmuserlist.toString().includes(i)):[]
      //                   this.setState({ mentionUserlist:final, openmentionarea: true })
      //          }
      //       }


      if (nextprops.deletePostRes) {
         if (nextprops.deletePostRes != this.props.deletePostRes) {

            if (this.state.DID != undefined) {
               var list = this.state.Pdata
               if (this.state.DID != undefined) {
                  if (this.state.DID > -1) {
                     list.splice(this.state.DID, 1);
                  }
               }
               this.setState({ Pdata: list })
            }
         }
      }

   }


   postcontent() {

      // if (!this.state.Emotion) {
      //    this.setState({ Error: "Please select emotion." })
      // } else 
      if (!this.state.Content) {
         this.setState({ Error: "Please provide content." })
      } else if (this.state.Content.trim() == "") {
         this.setState({ Error: "Please provide content." })
      } else {
         this.props.isLoading(true);
         var final = this.state.collectmuserlist.filter(i => this.state.Content.includes(i.username))
         const postdata = {
            "emotion_id": this.state.Emotion,
            "content": this.state.Content,
            "is_anonymous": this.state.isAnonymous ? "1" : "0",
            "is_private": this.state.isPrivate ? "1" : "0",
            "latitude": lat ? lat : "",
            "longitude": long ? long : "",
            "device_type": devicetype,
            // "collectmuserlist":final   //cizzr 1.5 (NiraliBThummar)
         }
         // debugger
         this.props.PostAPI(postdata);
      }
   }


   comments = (index, i) => {
      if (this.state.cardview != '2post') {
         this.setState({ CID: index })
         const data = {
            "post_id": i._id,
            "device_type": devicetype
         }
         this.props.CommentListAPI(data)

      }
   }

   bookmarkcall = (index, i) => {


      this.setState({ BID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      if (i.is_save) {
         this.props.RemoveFromSaveAPI(data)
      } else {
         this.props.SavePostAPI(data)
      }
      // var list = this.state.Pdata
      // list[index]["is_save"] = !list[index]["is_save"]
      // this.setState({ Pdata: list })

      var list = this.state.Pdata
      list[index]["is_save"] = !list[index]["is_save"]
      this.setState({ Pdata: list })
   }

   likeunlikecall = (index, i) => {

      var list = this.state.Pdata
      let count = list[index]["total_up_votes"]
      list[index]["total_up_votes"] = list[index]["is_up_vote"] == true ? count - 1 : count + 1
      list[index]["is_up_vote"] = !list[index]["is_up_vote"]
      if (list[index]["is_down_vote"] == true) {
         list[index]["total_down_votes"] = list[index]["total_down_votes"] - 1
         list[index]["is_down_vote"] = !list[index]["is_down_vote"]
      }
      this.setState({ Pdata: list })
      this.setState({ LID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.UPVoteAPI(data)
      //   if(i.is_up_vote){
      //    this.props.DownVoteAPI(data)
      //   }else{

      //   }
      // var list = this.state.Pdata
      // list[index]["is_save"] = !list[index]["is_save"]
      // this.setState({ Pdata: list })

   }


   //cizzr 1.5 (NiraliBThummar 9Nov2021)
   downvotecall = (index, i) => {

      var list = this.state.Pdata
      let count = list[index]["total_down_votes"]
      list[index]["total_down_votes"] = list[index]["is_down_vote"] == true ? count - 1 : count + 1
      list[index]["is_down_vote"] = !list[index]["is_down_vote"]
      if (list[index]["is_up_vote"] == true) {
         list[index]["total_up_votes"] = list[index]["total_up_votes"] - 1
         list[index]["is_up_vote"] = !list[index]["is_up_vote"]
      }
      this.setState({ Pdata: list })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.DownVoteAPI(data)

   }

   // cizzr 1.5 (NiraliBThummar 10Nov2021)
   cmntupvotecall = (cmtid, index, i) => {
      // debugger
      var data = this.state.Pdata
      let postobj = data[index]
      var list = postobj.commentlist
      var cindex = list.map(item => item._id).indexOf(cmtid);
      let count = list[cindex]["total_up_votes"] ? list[cindex]["total_up_votes"] : 0
      list[cindex]["total_up_votes"] = list[cindex]["is_up_vote"] == true ? count - 1 : count + 1
      list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
      if (list[cindex]["is_down_vote"] == true) {
         list[cindex]["total_down_votes"] = list[cindex]["total_down_votes"] - 1
         list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
      }
      data[index]["commentlist"] = list
      this.setState({ Pdata: data })
      const req = {
         "comment_id": cmtid,
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.UPVoteCmtAPI(req)

   }

   //cizzr 1.5 (NiraliBThummar 10Nov2021)
   cmntdownvotecall = (cmtid, index, i) => {

      var data = this.state.Pdata
      let postobj = data[index]
      var list = postobj.commentlist
      var cindex = list.map(item => item._id).indexOf(cmtid);
      let count = list[cindex]["total_down_votes"] ? list[cindex]["total_down_votes"] : 0
      list[cindex]["total_down_votes"] = list[cindex]["is_down_vote"] == true ? count - 1 : count + 1
      list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
      if (list[cindex]["is_up_vote"] == true) {
         list[cindex]["total_up_votes"] = list[cindex]["total_up_votes"] - 1
         list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
      }
      data[index]["commentlist"] = list
      this.setState({ Pdata: data })
      const req = {
         "comment_id": cmtid,
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.DownVoteCmtAPI(req)

   }

   Addcomment = (comnt, isAnonymous, index, i) => {

      this.setState({ CID: index })
      const data = {
         "post_id": i._id,
         "message": comnt,
         "device_type": devicetype,
         "is_anonymous": isAnonymous ? "1" : "0"
      }
      this.props.CommentAPI(data)
   }

   Opencomment = (index) => {

      var list = this.state.Pdata
      if (this.state.cardview == '2post') {
         this.gotopost(list[index]._id)
      } else {
         list[index]["is_open"] = true
         list[index]["Clistcount"] = 3
         this.setState({ Pdata: list })

      }
   }

   // Hidepost= (index) => {     
   //    debugger 
   //    var list = this.state.Pdata
   //    list[index]["is_hide"] = true
   //    this.setState({ Pdata: list })
   // }

   Collapsecomment = (index) => {
      // debugger
      var list = this.state.Pdata
      if (this.state.cardview == '2post') {
         this.gotopost(list[index]._id)
      } else {
         list[index]["is_open"] = list[index]["is_open"] ? !list[index]["is_open"] : true
         list[index]["Clistcount"] = 3
         this.setState({ Pdata: list })
      }

   }

   Viewmore = (index) => {

      var list = this.state.Pdata
      list[index]["Clistcount"] = list[index]["Clistcount"] + 3
      this.setState({ Pdata: list })

   }
   gotopost = (post_id) => {

      this.props.history.push({
         pathname: `/post/` + post_id,
         state: { 'postid': post_id }
      });
   }

   // //cizzr 1.5 (NiraliBThummar)
   // mentioncall = (str) => {

   //    // this.setState({ Content: str, Error: "", warning: "" }); 

   //    if(this.state.Content.length>str.length){
   //       var z = str?str.split(' '):''
   //       if(z){
   //          if(z.length>0){
   //             if(z[z.length-1]){
   //                if(z[z.length-1].includes('@')){
   //                   var check = z[z.length-1].replace('@','')
   //                   if(this.state.collectmuserlist){
   //                      if(this.state.collectmuserlist.length>0){
   //                         var newcollection = []
   //                         debugger
   //                         this.state.collectmuserlist.map(i=>
   //                            (i.username==check || z.slice(0,z.length-1).toString().includes(i.username))
   //                            ?newcollection.push(i):null)
   //                         this.setState({collectmuserlist:newcollection})
   //                      }                        
   //                   }                     
   //                }
   //             }               
   //          }            
   //       }                  
   //    }

   //       if (str.toString().includes('@')) {
   //          var res = mentionUser(str)
   //          if(res){
   //             if(res.status){
   //                debugger
   //                if(res.searchtext){
   //                   this.props.SearchUsersAPI({"search_query":res.searchtext})
   //                }


   //                // var x = res.searchtext!=''?allmentionUserlist.filter(i=>i.name.substring(0,res.searchtext.length).toLowerCase()==res.searchtext.toLowerCase() || i.username.substring(0,res.searchtext.length).toLowerCase()==res.searchtext.toLowerCase()):[]
   //                // var final = x?x.filter(i=>!this.state.collectmuserlist.toString().includes(i)):[]
   //                // this.setState({ mentionUserlist:final, openmentionarea: true })
   //             }else{
   //                this.setState({ openmentionarea: false })
   //             }
   //          }
   //          // this.setState({ openmentionarea: true })
   //       } else {
   //          this.setState({ openmentionarea: false })
   //       }
   //       this.setState({ Content: str, Error: "", warning: "" });

   // }

   // collectmuser=(i)=>{
   //    if(i){    
   //       debugger
   //       this.state.collectmuserlist.push({id:i.user_id,username:i.username})
   //       var username = i.username+' '
   //       var x = this.state.Content
   //       var y = x.split('@')
   //       y[y.length-1] = username
   //       var res = y.join('@') 
   //       if(res.length>500){
   //          y.pop()
   //          res = y.join('@') 
   //          this.setState({warning : 'Sorry, you have reached the maximum limit.'})
   //       }

   //       var result = this.state.collectmuserlist.reduce((unique, o) => {
   //          if(!unique.some(obj => obj.user_id === o.user_id && obj.username === o.username)) {
   //            unique.push(o);
   //          }
   //          return unique;
   //       },[]);

   //       this.setState({Content:res,collectmuserlist:result,openmentionarea:false})
   //       this.nameInput.focus();
   //    }
   // }
   Privatepost = () => {
      if (!this.state.isPrivate) {
         swal({
            title: "",
            text: "Private post will only be visible to your Friends",
            icon: "warning",
            buttons: true,
            dangerMode: true,
         })
            .then((willDelete) => {
               if (willDelete) {
                  this.setState({ isPrivate: true })
               } else {
               }
            });
      } else {
         this.setState({ isPrivate: false })
      }
   }

   Setvalue = (val) => {
      this.setState({ Content: val, Error: '' }, () => {
         // console.log(this.state.Content)
      })
   }


//cizzr 3 : NiraliBThummar : 12May2022
Deletepost = (index, i) => {

   swal({
      title: "",
      text: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
   })
      .then((willDelete) => {
         if (willDelete) {
            this.props.isLoading(true);
            this.setState({ DID: index })
            const postdata = {
               "post_id": i._id
            }
            this.props.DeletePostAPI(postdata);
         } else {
         }
      });
}


   render() {

      return (
         <>
            <Loader />
            <div className="wrapper">

               <Sidebar active="home" />
               <div className="iq-top-navbar">
                  <div className="iq-navbar-custom">
                     <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="iq-navbar-logo d-flex justify-content-between">
                           <a href={`/home`}>
                              <img src={logo} className="img-fluid" />
                           </a>
                           <div className="iq-menu-bt align-self-center">
                              <div className="wrapper-menu">
                                 <div className="main-circle"><i className="ri-menu-line"></i></div>
                              </div>
                           </div>
                        </div>

                        <div className="iq-search-bar">
                           <div action="#" className="searchbox">
                              <input type="text" className="text search-input" placeholder="tell us what you are looking for" value={this.state.searchTerm} onChange={(e) => { this.searchCondition(e) }} />
                              <a className="search-link" ><i className="ri-search-line"></i></a>
                           </div>
                        </div>

                        <div className="iq-search-bar-mobile">
                           <div className="searchbar">
                              <i className="fa fa-search" aria-hidden="true"></i>
                              <div className="togglesearch">
                                 <input type="text" placeholder="tell us what you are looking for" value={this.state.searchTerm} onChange={(e) => { this.searchCondition(e) }} />

                              </div>
                           </div>
                        </div>

                        <div className="navbar right-profile" id="navbarSupportedContent">

                           <ul className="navbar-list">

                              <li className="profile-li d-flex">

                                 <a className="user-name-div iq-waves-effect d-flex align-items-center " onClick={() => this.props.history.push(`/profile`)}>
                                    {this.state.basicinfo &&
                                       <Img className="img-fluid rounded-circle mr-3" src={encryptStorage.getItem("userpic") ? encryptStorage.getItem("userpic") : this.state.basicinfo.userData.photo}
                                          loader={<img src={user1} className="img-fluid rounded-circle mr-3" />} unloader={<img src={user1} className="img-fluid rounded-circle mr-3" />} />
                                       // this.state.basicinfo.userData ?
                                       //    <img src={this.state.basicinfo.userData.photo} className="img-fluid rounded-circle mr-3"  />
                                       //    // {encryptStorage.getItem("username")}
                                       //    : <img src={user1} className="img-fluid rounded-circle mr-3" />
                                    }
                                    <div className="caption">
                                       {
                                          encryptStorage.getItem("myname") ?
                                             <h6 className="mb-0 line-height">{encryptStorage.getItem("myname")}</h6>
                                             : null
                                       }
                                       {
                                          encryptStorage.getItem("username") ?
                                             <span className=" font-size-12" style={{ color: '#9a9a9a' }}>{encryptStorage.getItem("username")}</span>
                                             : null
                                       }
                                    </div>

                                 </a>
                                 <a className="search-toggle search-div"> <i className="ri-arrow-down-s-fill ml-2 profile-arrow"></i></a>
                                 <div className="iq-sub-dropdown iq-user-dropdown">
                                    <div className="iq-card shadow-none m-0">
                                       <div className="iq-card-body pl-0 pb-0 ">
                                          <div className=" p-3 line-height d-flex profile-drop-div align-items-center" onClick={() => this.props.history.push(`/profile`)}>
                                             <div className="profile-edit-div">
                                                {this.state.basicinfo &&
                                                   <Img className="img-fluid rounded-circle mr-3" src={encryptStorage.getItem("userpic") ? encryptStorage.getItem("userpic") : this.state.basicinfo.userData.photo}
                                                      loader={<img src={user1} className="img-fluid rounded-circle mr-3" />} unloader={<img src={user1} className="img-fluid rounded-circle mr-3" />} />
                                                   // this.state.basicinfo.userData ?
                                                   //    <img src={this.state.basicinfo.userData.photo} className="img-fluid rounded-circle mr-3" />
                                                   //    // {encryptStorage.getItem("username")}
                                                   //    : <img src={user1} className="img-fluid rounded-circle mr-3" />
                                                }
                                             </div>
                                             <div className="profile-content-div">
                                                <h5 className="mb-0  line-height">{encryptStorage.getItem("myname")}</h5>
                                                <span className=" font-size-12">{encryptStorage.getItem("username")}</span>
                                             </div>
                                          </div>

                                          <a className="iq-sub-card iq-bg-danger-hover" href={`/editprofile`}>
                                             <div className="media align-items-center">
                                                <div className="rounded iq-card-icon ">
                                                   <i className="ri-edit-fill"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                   <h6 className="mb-0 ">Edit Profile</h6>

                                                </div>
                                             </div>
                                          </a>
                                          <a className="iq-sub-card iq-bg-danger-hover" href={`/setting`}>
                                             <div className="media align-items-center">
                                                <div className="rounded iq-card-icon ">
                                                   <i className="ri-settings-2-fill"></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                   <h6 className="mb-0 ">Settings</h6>

                                                </div>
                                             </div>
                                          </a>
                                          <a onClick={() => this.logout()} className="iq-sub-card iq-bg-danger-hover">
                                             <div className="media align-items-center">
                                                <div className="rounded iq-card-icon">
                                                   <i className="ri-login-box-line "></i>
                                                </div>
                                                <div className="media-body ml-3">
                                                   <h6 className="mb-0 " >Log out</h6>

                                                </div>
                                             </div>
                                          </a>

                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </nav>
                  </div>
               </div>
               <div id="content-page" className="content-page">
                  <div className="container-fluid" >
                     <div className="row">
                        <div className="col-lg-1 order-lg-2">

                           <div className="grid-btn-sec">

                              <div className="shop_toolbar_btn">
                                 <button data-role="grid_4" type="button" className={this.state.cardview == '2post' ? "active btn-grid-4" : "btn-grid-4"} data-toggle="tooltip" onClick={() => this.setState({ cardview: '2post' })}></button>
                                 {/* title="2 Post" */}
                                 <button data-role="grid_3" type="button" className={this.state.cardview == 'list' ? "active btn-grid-3" : "btn-grid-3"} data-toggle="tooltip" onClick={() => this.setState({ cardview: 'list' })}></button>
                              </div>

                              <div className="emoji-sec">
                                 <div className="">
                                    {this.state.basicinfo ? this.state.basicinfo.emotionList ?
                                       <ul className="user-chat-list sidebar-emoji">
                                          {this.state.basicinfo.emotionList.length > 0 ?
                                             this.state.basicinfo.emotionList.map(i => <li key={i._id} className={i._id == this.state.searchemotion ? "chat-item active" : "chat-item "} >
                                                <a className="author-img  sidebar-emoji-a" data-toggle="tooltip" title={i.name} onClick={() => this.emotionsearch(i._id)}>
                                                   {/* {i._id==this.state.searchemotion?<img src={i.icon} />: />} */}
                                                   <img className="sidebar-emoji-img" src={i.icon} />
                                                </a>
                                             </li>)

                                             : null}
                                       </ul>
                                       : null : null}
                                 </div>
                              </div>
                           </div>



                        </div>

                        <div className="col-lg-11  m-0 p-0">

                           <div className="row">
                              <div className="col-sm-12">
                                 <div className="modal-my">
                                    <div className="row">
                                       <div className="header-main-area">
                                          <div className="iq-header-title ">
                                             <div className="col-md-6 col-sm-12 col-12"><h4 className="card-title">Create Post</h4></div>
                                             <div className="col-md-6 col-sm-12 col-12">

                                                <a className="sort-img-sec">
                                                   <div className="filter-div mr-3" onClick={() => this.setState({ postbyfrnd: !this.state.postbyfrnd }, () => { this.sort("friend") })}>
                                                      <p className=" sorting-p">Filter By Friends </p>
                                                      {this.state.postbyfrnd ? <i className="ri-filter-line active"></i> : <i className="ri-filter-line "></i>}
                                                   </div>
                                                   {this.state.searchTerm.trim() == "" && this.state.searchemotion == "" &&
                                                      this.state.sort == 0 && <div name="default" className="filter-div " onClick={(e) => this.sort("default")}><p className="sorting-p">Sort By Likes  </p><img src={sorting} style={{ width: 30, height: 30 }} /></div>}
                                                   {this.state.searchTerm.trim() == "" && this.state.searchemotion == "" &&
                                                      this.state.sort == 1 && <div name="ascending" className="filter-div " onClick={(e) => this.sort("ascending")} ><p className="sorting-p">Sort By Likes  </p><img src={sort_asc} style={{ width: 25, height: 25 }} /></div>}
                                                   {this.state.searchTerm.trim() == "" && this.state.searchemotion == "" &&
                                                      this.state.sort == 2 && <div name="descending" className="filter-div " onClick={(e) => this.sort("descending")}><p className="sorting-p">Sort By Likes  </p><img src={sort_desc} style={{ width: 25, height: 25 }} /></div>
                                                   }</a>
                                             </div>
                                          </div>

                                       </div>

                                    </div>
                                    <div className="tutorial-main">
                                       <div id="post-modal-data" className=" iq-card iq-card-block iq-card-stretch iq-card-height home-card ">

                                          {/* <div className="iq-card-body" data-toggle="modal" data-target="#post-modal"> */}
                                          <div className="iq-card-body" onClick={() => this.setState({ openpost: true })}>
                                             <div className="user-img">
                                                <a className="search-toggle iq-waves-effect d-flex align-items-center">
                                                   {this.state.basicinfo &&
                                                      <Img className="img-fluid rounded-circle mr-3" src={encryptStorage.getItem("userpic") ? encryptStorage.getItem("userpic") : this.state.basicinfo.userData.photo}
                                                         loader={<img src={user1} className="img-fluid rounded-circle mr-3" />} unloader={<img src={user1} className="img-fluid rounded-circle mr-3" />} />
                                                      // this.state.basicinfo.userData ?
                                                      //    <img src={this.state.basicinfo.userData.photo} className="img-fluid rounded-circle mr-3" />
                                                      //    // {encryptStorage.getItem("username")}
                                                      //    : <img src={user1} className="img-fluid rounded-circle mr-3" />
                                                   }
                                                   <div className="caption">
                                                      <h6 className="mb-0 line-height">
                                                         {/* {this.state.basicinfo && this.state.basicinfo.userData ?
                                                         this.state.basicinfo.userData.name
                                                         // {encryptStorage.getItem("username")}
                                                         : null} */}
                                                         {encryptStorage.getItem("username") ? encryptStorage.getItem("username") : encryptStorage.getItem("myname")}
                                                      </h6>


                                                   </div>

                                                </a>
                                             </div>
                                             <div className="post-text  w-100" >
                                                <input type="text" onChange={() => this.setState({ textcreate: "" })} value={this.state.textcreate} className="form-control rounded tell-us-feel" placeholder="tell us how you feel..." style={{ border: "none" }} />
                                             </div>





                                          </div>

                                          <Modal
                                             classNames={{
                                                overlay: "customOverlay",
                                                modal: "customModal"
                                             }}
                                             open={this.state.openpost}
                                             center
                                             onClose={() => { }}
                                             showCloseIcon={false}
                                          >

                                             {this.state.openpost ?
                                                <div className={"modal fade show create-post"} id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                                   <div className="modal-dialog" role="document">
                                                      <div className="modal-content">
                                                         <div className="modal-header">
                                                            <h5 className="modal-title" id="post-modalLabel">Create Post</h5>
                                                            <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ openpost: false, Error: "", Emotion: "", Content: "", openemotion: false, isAnonymous: false, selection: true })}><i className="ri-close-fill"></i></button>
                                                         </div>
                                                         <div className="modal-body">

                                                            <div className="d-flex align-items-center mb-2">
                                                               <div className="user-img modal-image">
                                                                  {this.state.basicinfo &&
                                                                     <Img className="avatar-30 rounded-circle img-fluid" src={!this.state.isAnonymous ? encryptStorage.getItem("userpic") ? encryptStorage.getItem("userpic") : this.state.basicinfo.userData.photo : user1}
                                                                        loader={<img src={user1} className="avatar-30 rounded-circle img-fluid" />} unloader={<img src={user1} className="avatar-30 rounded-circle img-fluid" />} />
                                                                     // this.state.basicinfo.userData ?
                                                                     //    <img src={this.state.basicinfo.userData.photo}  className="avatar-60 rounded-circle img-fluid" />
                                                                     //    // {encryptStorage.getItem("username")}
                                                                     //    : <img src={user1}  className="avatar-60 rounded-circle img-fluid" />
                                                                  }

                                                               </div>
                                                               <div className="user-img">
                                                                  <div className=" modal-emotion-section"> <h4 className="mb-0 d-inline-block cursor-pointer">
                                                                     {!this.state.isAnonymous ? encryptStorage.getItem("username") ? encryptStorage.getItem("username") : encryptStorage.getItem("myname") : "Anonymous"}
                                                                  </h4>{this.state.Emotion && <p className="feeling-name">is feeling {this.state.basicinfo.emotionList.map(i => this.state.Emotion == i._id && <a className="post-emotion-name" onClick={() => this.setState({ openemotion: true })}><img src={i.icon} className="modal-emoji-upper" />{i.name}</a>)} </p>}
                                                                  </div>
                                                                  <div>
                                                                     {!this.state.Emotion &&
                                                                        <div onClick={() => this.setState({ openemotion: true })}>
                                                                           {this.state.basicinfo && this.state.basicinfo.emotionList ?
                                                                              this.state.basicinfo.emotionList.length > 0 ?
                                                                                 <button className="opt-sel-emote emotions-select" value={this.state.Emotion}>
                                                                                    Select Emotion
                                                                                    {/* {this.state.Emotion?
                                                                           this.state.basicinfo.emotionList.map(i =>this.state.Emotion==i._id&&i.name)
                                                                           :"Select Emotions"} */}
                                                                                    {/* <i className="ri-arrow-down-s-line"></i> */}
                                                                                    {/*{this.state.basicinfo.emotionList.map(i => <option key={i._id} value={i._id}>{i.name}</option>)} */}
                                                                                 </button>
                                                                                 : null : null}
                                                                        </div>}



                                                                  </div>
                                                               </div>

                                                            </div>
                                                            {this.state.openemotion &&
                                                               <div className="modal-emoji-slider">

                                                                  {this.state.basicinfo && this.state.basicinfo.emotionList ?
                                                                     this.state.basicinfo.emotionList.length > 0 ?
                                                                        // <select className="emotions-select" value={this.state.Emotion} onChange={(e) => this.setState({ Emotion: e.target.value, Error: "" })}>
                                                                        //    <option value="">Emotions</option>
                                                                        //    {this.state.basicinfo.emotionList.map(i => <option key={i._id} value={i._id}>{i.name}</option>)}
                                                                        // </select>

                                                                        <ul className="emoji-slider-ul d-flex flex-wrap align-items-center list-inline ">
                                                                           {this.state.basicinfo.emotionList.map(i =>
                                                                              <li className={i._id == this.state.Emotion ? "active emoji-slider-li  mb-2" : "emoji-slider-li  mb-2"}>
                                                                                 <div className="emoji-grid-list p-1 pointer mr-0 cursor-pointer" onClick={() => this.setState({ Emotion: this.state.Emotion == i._id ? "" : i._id, Error: "", openemotion: false, selection: false })}>
                                                                                    <a className="slider-emoji-a">
                                                                                       <img src={i.icon} /><p>{i.name} </p></a>
                                                                                 </div>

                                                                              </li>)}
                                                                        </ul>
                                                                        : null : null}

                                                               </div>
                                                            }  <div className="" id="contact-form mt-2" >
                                                               {/* <InputEmoji
                                                              className="create-post"
                                                                     maxLength={500} 
                                                                     value={this.state.Content} 
                                                                     onChange={(val) => this.setState({ Content: val, Error: "" })}
                                                                     placeholder="tell us how you feel..."
                                                                  /> */}

                                                               <Mentiontextarea intialval={''} setvalue={(val) => this.Setvalue(val)} />
                                                               {/* <TextareaAutosize maxLength={500} placeholder="tell us how you feel..." className="form-control rounded text-area" style={{ border: "none" }} value={this.state.Content} onChange={(e) => {this.mentioncall(e.target.value); }} ref={(input) => { this.nameInput = input; }} /> */}

                                                               {/* this.mentioncall(e.target.value)  */}
                                                               {/* <textarea id="message1" type="text"  className="form-control rounded" placeholder="tell us how you feel..." style={{ border: "none" }}  maxLength={500} value={this.state.Content} onChange={(e) => this.setState({ Content: e.target.value, Error: "" })}></textarea> */}
                                                               {/* <a onClick={() => this.setState({ emojipick: !this.state.emojipick})}>Emoji</a>
                                                           {this.state.emojipick && <Picker disableSkinTonePicker onEmojiClick={(e,emojiObject)=>this.onEmojiClick(e,emojiObject)} />} */}
                                                            </div>
                                                            {this.state.Error && <p style={{ color: 'red', fontSize: 14 }}>{this.state.Error}</p>}

                                                            {this.state.warning && <p style={{ color: 'gold', fontSize: 14 }}>{this.state.warning}</p>}

                                                            {/* //cizzr 1.5 (NiraliBThummar) start*/}
                                                            {/* {this.state.openmentionarea ? <div className="modal-emoji-slider">
                                                            {this.state.mentionUserlist ?
                                                               this.state.mentionUserlist.length > 0 ?
                                                                  <ul className="emoji-slider-ul d-flex flex-wrap align-items-center list-inline ">
                                                                     {this.state.mentionUserlist.map(i =>
                                                                        <li className={"emoji-slider-li  mb-2"}>
                                                                           <div className="emoji-grid-list p-1 pointer mr-0 cursor-pointer" onClick={()=>this.collectmuser(i)}>
                                                                              <a className="slider-emoji-a">
                                                                                 <p>{i.name} </p><br/>
                                                                                 <p>{i.username} </p>
                                                                                 </a>
                                                                           </div>
                                                                        </li>)}                                                                        
                                                                  </ul>
                                                                  : null : null}
                                                         </div> : null} 
                                                          {this.state.collectmuserlist?this.state.collectmuserlist.length>0?this.state.collectmuserlist.map(i=><p>{i.username + '-' + i.id}</p>):null:null}  */}

                                                            {/* //cizzr 1.5 (NiraliBThummar) end*/}

                                                            <hr />

                                                            <div className="modal-send-div">
                                                               <div className="switch-main">
                                                                  <div className="switch-div"> <label htmlFor="checkbox">Post as private</label>
                                                                     <input className="switch" type="checkbox" checked={this.state.isPrivate} onClick={() => this.Privatepost()} />  </div>
                                                                  <div className="switch-div"> <label htmlFor="checkbox">Post as anonymous</label>
                                                                     <input className="switch" type="checkbox" defaultChecked={this.state.isAnonymous} onClick={() => this.setState({ isAnonymous: !this.state.isAnonymous })} />  </div>
                                                               </div>

                                                               <div className="post-btn"><button type="submit" className="btn btn-primary " onClick={() => this.postcontent()}>Post</button></div></div>
                                                         </div>
                                                      </div>
                                                   </div>

                                                </div>
                                                : null}

                                          </Modal>
                                          {/* {this.state.openpost &&
                                       <div className="modal fade show" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                          <div className="modal-dialog" role="document">
                                             <div className="modal-content">
                                                <div className="modal-header">
                                                   <h5 className="modal-title" id="post-modalLabel">Create Post</h5>
                                                   <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ openpost: false, Error: "", Emotion: "", Content: "", isAnonymous: true })}><i className="ri-close-fill"></i></button>
                                                </div>
                                                <div className="modal-body">
                                                   <div className="d-flex align-items-center">
                                                      <div className="user-img modal-image">
                                                         {this.state.basicinfo && this.state.basicinfo.userData ?
                                                            <img src={this.state.basicinfo.userData.photo} className="avatar-60 rounded-circle img-fluid" />
                                                            // {encryptStorage.getItem("username")}
                                                            : <img src={user1}  className="avatar-60 rounded-circle img-fluid" />}

                                                      </div>
                                                      <div className="user-img ml-3">
                                                         <h4 className="mb-0 line-height">
                                                            {this.state.basicinfo && this.state.basicinfo.userData ?
                                                               this.state.basicinfo.userData.name
                                                               // {encryptStorage.getItem("username")}
                                                               : null}
                                                         </h4>
                                                         <div>
                                                            {this.state.basicinfo && this.state.basicinfo.emotionList ?
                                                               this.state.basicinfo.emotionList.length > 0 ?
                                                                  <select className="emotions-select" value={this.state.Emotion} onChange={(e) => this.setState({ Emotion: e.target.value, Error: "" })}>
                                                                     <option value="">Emotions</option>
                                                                     {this.state.basicinfo.emotionList.map(i => <option key={i._id} value={i._id}>{i.name}</option>)}
                                                                  </select>
                                                                  : null : null}
                                                         </div>
                                                      </div>

                                                   </div>
                                                   <div className="post-text ml-3 w-100 create-post" >
                                                      <textarea type="text" className="form-control rounded" placeholder="tell us how you feel..." style={{ border: "none" }} rows="5" maxLength={500} value={this.state.Content} onChange={(e) => this.setState({ Content: e.target.value, Error: "" })}></textarea>

                                                   </div>
                                                   <hr />


                                                   {this.state.Error && <p style={{ color: 'red', fontSize: 14 }}>{this.state.Error}</p>}

                                                   <div className="modal-send-div"><div className="switch-div"> <label htmlFor="checkbox">Post as anonymous</label>
                                                      <input className="switch" type="checkbox" defaultChecked={this.state.isAnonymous} onClick={() => this.setState({ isAnonymous: !this.state.isAnonymous })} />  </div>

                                                      <div className="post-btn"><button type="submit" className="btn btn-primary " onClick={() => this.postcontent()}>Post</button></div></div>
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    } */}


                                       </div>


                                       {localStorage.getItem('guideflag') == "true" && this.state.Askfortour &&
                                          <Modal
                                             classNames={{
                                                overlay: "customOverlay",
                                                modal: "customModal"
                                             }}
                                             open={true}
                                             center
                                             onClose={() => { }}
                                             showCloseIcon={false}
                                          >
                                             <div className="modal fade show create-post tour-modal" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                                <div className="modal-dialog" role="document">
                                                   <div className="modal-content">

                                                      <div className="modal-body">
                                                         <h4>Would you like a quick tour of the app? if not now it can be found in our settings.</h4>
                                                         <div className="tutorial-btn">
                                                            <div className="btn btn-primary ml-2" onClick={() => { this.setState({ Askfortour: false }); localStorage.setItem('askfortourflag', false); localStorage.setItem('guideflag', false);document.body.classList.remove('guidecss');}}>No</div>
                                                            <div className="btn btn-primary ml-2" onClick={() => { this.setState({ Askfortour: false, Mark1: true }); localStorage.setItem('askfortourflag', false); 
                                                          if (window.navigator) {
                                                            if (window.navigator.userAgent.toLowerCase().includes("mobi"))
                                                            document.body.classList.add('sidebar-main');
                                                         }}}>Yes</div>
                                                         </div>
                                                      </div>
                                                   </div>

                                                </div>

                                             </div>

                                          </Modal>}
                                       {localStorage.getItem('guideflag') == "true" && this.state.Mark1 &&
                                          <div className="tutorial-box tutorial-box6">

                                             <div className="tutorial-box-inner1 ">
                                                {/* <h4>Click here to add a <span>new post</span> to your feed.</h4> */}
                                                <h4>This is the home page, you can view the most popular posts from your friends and from people all over the world!</h4>
                                                <div className="tutorial-btn">
                                                   {/* <div className="btn btn-primary " onClick={() => { this.setState({ Mark1: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                                   <div className="btn btn-primary ml-2" onClick={() => {this.setState({ Mark2: true, Mark1: false });
                                                   if (window.navigator) {
                                                      if (window.navigator.userAgent.toLowerCase().includes("mobi"))
                                                         document.querySelector('.wrapper-menu').click();
                                                   }
                                                   }}>Next</div></div>

                                                {/* <div className="arrow-top"><img src={arrowtutorial} /></div> */}
                                             </div>
                                             <div className="arrow-top"></div>

                                          </div>}
                                       {localStorage.getItem('guideflag') == "true" && this.state.Mark2 &&
                                          <div className="tutorial-box  tutorial-box2 ">

                                             <div className="tutorial-box-inner1 ">
                                                {/* <h4>You can <span>select an emotion</span> to connect to your posts and use @ to <span>mention people</span>. </h4> */}
                                                <h4>This slider lets you filter your feed based on location.</h4>
                                                <div className="tutorial-btn">  <div className="btn btn-primary " onClick={() => {this.setState({ Mark1: true, Mark2: false });
                                                  if (window.navigator) {
                                                   if (window.navigator.userAgent.toLowerCase().includes("mobi"))
                                                      document.querySelector('.wrapper-menu').click();
                                                }}}>Back</div>
                                                   {/* <div onClick={() => { this.setState({ Mark2: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                                   <div className="btn btn-primary ml-2" onClick={() => this.setState({ Mark3: true, Mark2: false })}>Next</div>
                                                </div>

                                             </div>
                                             <div className="arrow-top"></div>
                                          </div>}
                                       {localStorage.getItem('guideflag') == "true" && this.state.Mark3 &&
                                          <div className="tutorial-box  tutorial-box3 ">

                                             <div className="tutorial-box-inner1 ">
                                                {/* <h4>You have the option to <span> be anonymous or do private post</span>.</h4> */}
                                                <h4>Click here you can search for posts!</h4>
                                                <div className="tutorial-btn">  <div className="btn btn-primary " onClick={() => this.setState({ Mark2: true, Mark3: false })}>Back</div>
                                                   {/* <div onClick={() => { this.setState({ Mark3: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                                   <div className="btn btn-primary ml-2" onClick={() => this.setState({ Mark3: false, Mark4: true })}>Next</div>
                                                </div>
                                             </div>
                                             <div className="arrow-top"></div>
                                          </div>}
                                       {localStorage.getItem('guideflag') == "true" && this.state.Mark4 &&
                                          <div className="tutorial-box  tutorial-box5 ">

                                             <div className="tutorial-box-inner1 ">
                                                {/* <h4>Click post and you’re ready to <span> connect to the rest of the world!</span></h4> */}
                                                <h4>Here, you can search posts by different emotions!</h4>
                                                <div className="tutorial-btn">  <div className="btn btn-primary " onClick={() => this.setState({ Mark3: true, Mark4: false })}>Back</div>
                                                   {/* <div onClick={() => { this.setState({ Mark3: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                                   <div className="btn btn-primary ml-2" onClick={() => this.setState({ Mark4: false, Mark5: true })}>Next</div>
                                                </div>
                                             </div>
                                             <div className="arrow-top"></div>
                                          </div>}
                                       {localStorage.getItem('guideflag') == "true" && this.state.Mark5 &&
                                          <div className="tutorial-box  tutorial-box1 ">

                                             <div className="tutorial-box-inner1 ">
                                                {/* <h4>Here, you can search posts <span>by different emotions</span>. You can filter posts by their location too! Connect with people near you or far away.</h4> */}
                                                <h4>Click here to add a new post to your feed. </h4>
                                                <div className="tutorial-btn">  <div className="btn btn-primary " onClick={() => this.setState({ Mark4: true, Mark5: false })}>Back</div>
                                                   {/* <div onClick={() => { this.setState({ Mark3: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                                   <div className="btn btn-primary ml-2" onClick={() => {
                                                      this.setState({ Mark5: false, Mark6: true });
                                                   }}>Next</div>
                                                </div>
                                             </div>
                                             <div className="arrow-top"></div>
                                          </div>}
                                       {localStorage.getItem('guideflag') == "true" && this.state.Mark6 &&
                                          <div className="tutorial-box  tutorial-box4 ">

                                             <div className="tutorial-box-inner1 ">
                                                <h4>When creating a post, you have the option to select any emotion also you can post anonymously or in private to just your friends.</h4>
                                                <div className="tutorial-btn">  <div className="btn btn-primary " onClick={() => {
                                                   this.setState({ Mark5: true, Mark6: false })
                                                   // if (window.navigator) {
                                                   //    if (window.navigator.userAgent.toLowerCase().includes("mobi"))
                                                   //       document.querySelector('.wrapper-menu').click();
                                                   // }
                                                }
                                                }>Back</div>
                                                   {/* <div onClick={() => { this.setState({ Mark3: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                                   <div className="btn btn-primary ml-2" onClick={() => this.setState({ Mark6: false, Mark7: true })}>Next</div>
                                                </div>
                                             </div>
                                             <div className="arrow-top"></div>
                                          </div>}
                                          {localStorage.getItem('guideflag') == "true" && this.state.Mark7 &&
                                          <Modal
                                             classNames={{
                                                overlay: "customOverlay",
                                                modal: "customModal"
                                             }}
                                             open={true}
                                             center
                                             onClose={() => { }}
                                             showCloseIcon={false}
                                          >
                                             <div className="modal fade show create-post tour-modal" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                                <div className="modal-dialog" role="document">
                                                   <div className="modal-content">

                                                      <div className="modal-body">
                                                         <h4>We hope you enjoy using this app, and if you have any feedback we’d love to hear it!</h4>
                                                         <div className="tutorial-btn"> 
                               {/* <div className="btn btn-primary " onClick={() => this.setState({ Mark6: true, Mark7: false })}>Back</div> */}
                                {/* <div className="btn btn-primary " onClick={() => { this.setState({ Mark1: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                 <div className="btn btn-primary ml-2" onClick={() => {this.setState({ Mark7: false }); localStorage.setItem('guideflag', false); localStorage.setItem('guideprofileflag',false); document.body.classList.remove('guidecss');}}>Finish</div></div>
                                 
                                 {/* <div className="arrow-top"><img src={arrowtutorial} /></div> */}
                              </div>
                                                   </div>

                                                </div>

                                             </div>

                                          </Modal>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="row shop_wrapper grid_3">
                              {this.state.Pdata &&
                                 this.state.Pdata.length > 0 ?
                                 this.state.Pdata.map((i, index) =>
                                    <PostCard cardview={this.state.cardview} data={i} key={i._id} index={index} 
                                       applycolor={this.state.searchemotion?true:false}
                                       bookmark={() => this.bookmarkcall(index, i)}
                                       getcomments={() => this.comments(index, i)}
                                       likeunlike={() => this.likeunlikecall(index, i)}
                                       addcomment={(val, isAnonymous) => this.Addcomment(val, isAnonymous, index, i)}
                                       opencomment={() => this.Opencomment(index)}
                                       hidepost={() => this.Hidepost(index)}
                                       viewmore={() => this.Viewmore(index)}
                                       collapsecomment={() => this.Collapsecomment(index)}
                                       downvote={() => this.downvotecall(index, i)} //cizzr 1.5 (NiraliBThummar 9Nov2021)
                                       cmntupvote={(cmtid) => this.cmntupvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 10Nov2021)
                                       cmntdownvote={(cmtid) => this.cmntdownvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 10Nov2021)
                                       deletepost={() => this.Deletepost(index, i)}//cizzr 3 (NiraliBThummar 11May2022)
                                    />
                                 ) : null}
                           </div>

                           {!this.state.loading && this.state.nodata && <p className="no-data-found" style={{ marginTop: 30 }}>No Posts Yet</p>}
                           {this.state.loading && <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                              <img src={pageloadloader} style={{ height: 80 }} />
                           </div>}


                        </div>


                     </div>

                  </div>
               </div>
            </div>
            <footer className=" iq-footer">
               <div className="container-fluid">
                  <div className="row align-items-center">


                     <div className="col-lg-3">
                        <ul className="list-inline mb-0 privacy-poicy">
                           <li className="list-inline-item"><a target="_blank" href={"/privacy-policy"}>Privacy Policy</a></li>
                           <li className="list-inline-item">|<i className=""></i></li>
                           <li className="list-inline-item"><a target="_blank" href={"/help"}>Help</a></li>
                           <li className="list-inline-item">|<i className=""></i></li>
                           <li className="list-inline-item"><a target="_blank" href={"/about-us"}>About Us</a></li>

                        </ul>
                     </div>

                     <div className="col-lg-6">
                        <div className="filter-location">
                           <label htmlFor="price">Filter By Location <span style={{ marginLeft: 4 }}>({this.state.Pbar == 100 ? "100+" : this.state.Pbar} {this.state.Pbar == 1 ? "Mile" : "Miles"})</span></label>
                           <output htmlFor="price">1 Mile</output>
                           {/* <Tooltip className="tooltip" enterTouchDelay={0} placement="top" title={this.state.Pbar}></Tooltip> */}
                           <Slider data-tooltip="This link goes nowhere"
                              data-tooltip-location="right" color="#2c8ff2" className="location-filter cursor-pointer" defaultValue={this.state.Pbar} min={1} max={100} onChangeComplete={(value) => this.changes(value)}>

                           </Slider>
                           {/* <input type="range" name="price" min="1" max="100" defaultValue={this.state.Pbar} onchange={(e) => this.changes(e)} /> */}
                           <output htmlFor="price">100+ Miles</output>
                        </div>
                     </div>

                     <div className="col-lg-3 text-right">
                        <p className="copyright-sec">  Copyright © {moment().year()} <a href={`/home`} className="cizzrlink">Cizzr.    </a> All Rights Reserved.</p>
                     </div>
                  </div>
               </div>
            </footer>

            {((localStorage.getItem('guideflag') == "true" && localStorage.getItem('guidehomeflag') == "true") || this.state.openpost) && <div className="modal-backdrop fade show"></div>}
         </>
      );
   }
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => {
         dispatch(dispatchLoadingStatus(loading));
      },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
      PostAPI: (postdata) => { dispatch(PostAPI(postdata)) },
      PostListAPI: (postdata) => { dispatch(PostListAPI(postdata)) },
      CommentListAPI: (postdata) => { dispatch(CommentListAPI(postdata)) },
      SearchPostAPI: (postdata) => { dispatch(SearchPostAPI(postdata)) },
      SavePostAPI: (postdata) => { dispatch(SavePostAPI(postdata)) },
      RemoveFromSaveAPI: (postdata) => { dispatch(RemoveFromSaveAPI(postdata)) },
      UPVoteAPI: (postdata) => { dispatch(UPVoteAPI(postdata)) },
      DownVoteAPI: (postdata) => { dispatch(DownVoteAPI(postdata)) },
      CommentAPI: (postdata) => { dispatch(CommentAPI(postdata)) },
      LogoutAPI: (postdata) => { dispatch(LogoutAPI(postdata)) },
      UPVoteCmtAPI: (postdata) => { dispatch(UPVoteCmtAPI(postdata)) },
      DownVoteCmtAPI: (postdata) => { dispatch(DownVoteCmtAPI(postdata)) },
      SearchUsersAPI: (postdata) => { dispatch(SearchUsersAPI(postdata)) },
      DeletePostAPI: (postdata) => { dispatch(DeletePostAPI(postdata)) },
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      basicinfo: state.globalReducer.BasicInfoRes,
      postres: state.globalReducer.PostRes,
      postlist: state.globalReducer.PostListRes,
      commentlist: state.globalReducer.CommentListRes,
      searchlist: state.globalReducer.SearchPostRes,
      savePost: state.globalReducer.SavePostRes,
      searchUser: state.globalReducer.SearchUsersRes,
      deletePostRes: state.globalReducer.DeletePostRes,
      // votePost: state.globalReducer.VoteRes
   }
}
export default connect(mapStateToProps, mapDispatchToProps)((Home))
