import React from "react";
import flogo from "../asset/images/logo.svg";
import img1 from "../asset/images/1.png";
import img2 from "../asset/images/2.png";
import img3 from "../asset/images/3.png";
export default class Slider extends React.Component {
    render(){
        return(
            <div className="col-lg-5 order-lg-1 col-md-12 text-center ">
            <div className="sign-in-detail text-white">
              <div className="mobile-logo-div"><img src={flogo}></img></div>
                <a className="sign-in-logo mb-5" ><img src={flogo} className="img-fluid" /></a>
                <div className="owl-carousel" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="0">
                    <div className="item">
                        <img src={img1} className="img-fluid mb-4" />
                        <h4 className="mb-1 text-white">Lorem Ipsum</h4>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                    </div>
                    <div className="item">
                        <img src={img2} className="img-fluid mb-4" />
                        <h4 className="mb-1 text-white">Lorem Ipsum</h4>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                    </div>
                    <div className="item">
                        <img src={img3} className="img-fluid mb-4" />
                        <h4 className="mb-1 text-white">Lorem Ipsum</h4>
                        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                    </div>
                </div>
            </div>
        </div>
              
        )
    }
}
