import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProfilePicAPI, RemoveProfileAPI, GetProfileAPI, UpdateProfileAPI, BasicInfoAPI, BasicCountAPI } from "../actions/globalactions";
import { dispatchLoadingStatus, isValidNumber, isValidName,encryptStorage } from '../utils/utils';
import { basepath, devicetype } from "../constants";
import Loader from './Loader';
import DatePicker from "react-datepicker";
import moment from "moment";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import user1 from "../asset/images/user1.svg";
import validator from 'validator';
import Modal from "react-responsive-modal";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import swal from 'sweetalert';
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Img } from 'react-image';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Editprofile = () => {
   const dispatch = useDispatch();
   const history = useHistory();
   const inputRef = useRef();
   const GpRes = useSelector(state => state.globalReducer.GetProfileRes);
   const UpRes = useSelector(state => state.globalReducer.UpdateProfileRes);
   const [name, setName] = useState("");
   const [website, setWebsite] = useState("");
   const [bio, setBio] = useState("");
   const [dob, setDob] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");
   const [PhoneNo, setPhoneNo] = useState("");
   const [Photo, setPhoto] = useState("");
   const [Ccode, setCode] = useState("");
   const [NameError, setNameError] = useState("");
   const [WError, setWError] = useState("");
   const [BError, setBError] = useState("");
   const [DobError, setDobError] = useState("");
   const [imageError, setimgError] = useState("");
   const [PhoneError, setPhoneError] = useState("");
   const [opencrop, setopencrop] = useState(false);
   const [openintrest, setopenintrest] = useState(false);
   const [selfile, setfile] = useState(null);
   const [placeholderflag, setplaceholderflag] = useState("true");

   const [upImg, setUpImg] = useState();
   const imgRef = useRef(null);
   const previewCanvasRef = useRef(null);
   const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
   const [completedCrop, setCompletedCrop] = useState(null);

   //cizzr 1.5 (NiraliBThummar 9Nov2021)
   const [username, setuserName] = useState("");
   const [UsernameError, setUsernameError] = useState("");
   //cizzr 1.5 (NiraliBThummar 31Dec2021)
   const [Intrest, setIntrest] = useState(null);
   const [finalIntrest, setfinalIntrest] = useState(null);
   // const [saveIntrest, setsaveIntrest] = useState(false);

   const onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
         const reader = new FileReader();
         reader.addEventListener('load', () => setUpImg(reader.result));
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const onLoad = useCallback((img) => {
      imgRef.current = img;
   }, []);

   useEffect(() => {

      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
         return;
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;

      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
         image,
         crop.x * scaleX,
         crop.y * scaleY,
         crop.width * scaleX,
         crop.height * scaleY,
         0,
         0,
         crop.width,
         crop.height
      );
   }, [completedCrop]);

   function uploadprofilepic(canvas, crop) {

      if (!crop || !canvas) {
         return;
      }

      canvas.toBlob(
         (blob) => {


            let form_data = new FormData();
            if (blob) {
               form_data.append("photo", blob);
            }
            else { form_data.append("photo", selfile); }
            dispatch(dispatchLoadingStatus(true));
            dispatch(ProfilePicAPI(form_data));
            setopencrop(false);
            //  const previewUrl = window.URL.createObjectURL(blob);

            //  const anchor = document.createElement('a');
            //  anchor.download = 'cropPreview.png';
            //  anchor.href = URL.createObjectURL(blob);
            //  anchor.click();

            //  window.URL.revokeObjectURL(previewUrl);
         },
         'image/png',
         1
      );


   }

   useEffect(async () => {
      if (GpRes != null) {
         // console.log(GpRes);
         setPhoto(await GpRes.data.photo);
         setName(await GpRes.data.name);
         setWebsite(await GpRes.data.website);
         setBio(await GpRes.data.bio);
         setEmail(await GpRes.data.email);
         setplaceholderflag(await GpRes.data.placeholder)
         setIntrest(await GpRes.data.interests)
         setfinalIntrest(await GpRes.data.interests)
         //cizzr 1.5 (NiraliBThummar 9Nov2021)
         if (GpRes.data.username) {
            setuserName(await GpRes.data.username.toLowerCase());
         }


         if (GpRes.data.phone) {
            if (GpRes.data.country_code) {
               var code = GpRes.data.country_code
               setCode(await code.replace("+", ""));
               setPhone(await code.replace("+", "") + GpRes.data.phone);
               setPhoneNo(await code.replace("+", "") + GpRes.data.phone);
            } else {
               // document.getElementById("phonenum").disabled = true;
               setPhone(await GpRes.data.phone);
               setPhoneNo(await GpRes.data.phone);
            }

         }

         // const Dob = moment().format("DD/MM/yyyy");
         const selected = moment(GpRes.data.dob).toDate();

         setDob(selected);
      }
   }, [GpRes])
   useEffect(() => {
      // console.log(name);
   }, [name])
   useEffect(() => {
      GpRes && setPhoto(GpRes.data.photo)
   }, [Photo])
   useEffect(() => {
      // console.log(dob);
   }, [dob])
   useEffect(() => {
      // console.log(bio);
   }, [bio])
   useEffect(() => {
      // console.log(website);
   }, [website])
   useEffect(() => {
      // console.log(email);
   }, [email])
   useEffect(() => {
      // console.log(phone);
   }, [phone])
   useEffect(() => {
      // console.log(placeholderflag);
   }, [placeholderflag])

   //cizzr 1.5 (NiraliBThummar 9Nov2021)
   useEffect(() => {
      // console.log(username);
   }, [username])

   useEffect(() => {
   }, [Intrest])
   useEffect(() => {
   }, [finalIntrest])
   // useEffect(() => {
   // }, [saveIntrest])

   useEffect(() => {

      var token = encryptStorage.getItem("cizzr_token")

      if (!token) {
         history.push(`/signin`);
      } else {
         dispatch(dispatchLoadingStatus(true))
         dispatch(GetProfileAPI())
         var Token = encryptStorage.getItem('FCM_Token');
         const postdata = {
            "fcm_token": Token ? Token : "",
            "device_type": devicetype
         }
         dispatch(BasicCountAPI(postdata));
         if (!encryptStorage.getItem('basicInfoRes')) {
            dispatch(BasicInfoAPI(postdata));
         }
      }
   }, [])

   function handleDateChangeRaw(e) {
      e.preventDefault();
   }

   function handleChange(e) {
      setimgError("");
      // console.log(e.target.files);
      if (e.target.files) {
         var file = e.target.files
         if (file && file.length > 0) {
            if (file[0].name.toLowerCase().includes("jpg") ||
               file[0].name.toLowerCase().includes("png") ||
               file[0].name.toLowerCase().includes("jpeg")) {
               setopencrop(true)
               if (e.target.files && e.target.files.length > 0) {
                  setfile(e.target.files[0])
                  const reader = new FileReader();
                  reader.addEventListener('load', () => setUpImg(reader.result));
                  reader.readAsDataURL(e.target.files[0]);
               }
               // let form_data = new FormData();
               // form_data.append("photo", file[0]);
               // dispatch(dispatchLoadingStatus(true));
               // dispatch(ProfilePicAPI(form_data));

            } else {
               setimgError("The photo must be a file of type: jpg, png, jpeg.")
            }
         }
      }
   }


   function EnterPressCall(e) {
      if (e.charCode == 13 || e.which == 13) {
         updateProfile()
      }
   }

   function updateProfile() {
      // var isvalidnum =true;
      // if(phone != ''){
      //   isvalidnum = Ccode!=phone&&Validationnum(phone)
      // }
      var isValidphone = PhoneNo ? Validationnum("+" + PhoneNo) : true;
      var todayDate = moment().subtract(18, 'years');
      var userregex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9_.]+$/;
      var userregex2 = /^[0-9]+$/;
      // let isnum = /^\d+$/.test(phone);
      if (!name) {
         setNameError("Please enter your Name.");
      } else if (name.trim() == "") {
         setNameError("Please provide a valid Name.");
      } else if (!dob) {
         setDobError("Please enter your Date of Birth.");
      } else if (!todayDate.isAfter(dob)) {
         setDobError("Please enter a valid Date of Birth.");
      }
      else if (PhoneNo && PhoneNo != '' && !isValidphone) {
         setPhoneError("Please provide a valid Phone number.");
      }
      // else if (phone && phone!=''&& !isvalidnum && Ccode!=phone) {
      //    if(Ccode){
      //       setPhoneError("Please provide a valid Phone number." );
      //    }else{
      //       setPhoneError("Please select Country." );
      //    }         
      //  }
      //  else if (phone && phone!=''&& phone.length!=10) {
      //    setPhoneError("Please provide 10 digit Phone number." );
      //  }

      //cizzr 1.5 (NiraliBThummar 9Nov2021)
      else if (!username) {
         setUsernameError("Please enter your Username.");// Phase 3
      }else if (username && UsernameError != "") {
      } else if (username && (username.length < 6 || username.length > 30)) {
         setUsernameError("Username must be between 6 to 30 characters.");
      } else if (username && userregex2.test(username)) {
         setUsernameError("Username cannot contain only numbers.");
      } else if (username && !userregex.test(username)) {
         setUsernameError("Username can only use letters, numbers, underscores and dots.");
      }

      else if (website && !validator.isURL(website)) {
         setWError("Please enter a valid Website url.");
      } else if (bio && bio.trim() == "") {
         setBError("Please provide a valid Bio.");
      } else {
         debugger
         dispatch(dispatchLoadingStatus(true))
         const postdata = {
            "name": name,
            "website": website,
            "bio": bio,
            "interests": finalIntrest,
            "dob": moment(dob).format("yyyy-MM-DD"),
            "country_code": PhoneNo ? Ccode ? Ccode.includes("+") ? Ccode : "+" + Ccode : "+1" : "",
            "phone": PhoneNo ? PhoneNo.substring(Ccode.length, PhoneNo.length) : "",
            "username": username //cizzr 1.5 (NiraliBThummar 9Nov2021)
         }
         if (localStorage.getItem("updateprocess") == "true") { } else {
            dispatch(UpdateProfileAPI(postdata));
            localStorage.setItem("updateprocess", true)
            // setsaveIntrest(false)
         }
      }
   }
   function previousPage() {
      history.goBack()
   }
   // function changephone(e){

   //    setPhone(e);setPhoneError("");
   //    if(e){
   //       if(e.length>5 &&e.includes("+")){
   //          var phone = e&&getValidNumber(e)
   //          var code = phone.split(" ");
   //          setCode(code[0])
   //       }
   //    }

   //    document.getElementById("phonenum").disabled = false;
   // }

   // function selectcountry(e){

   //    document.getElementById("phonenum").disabled = false;
   //    if(e.target.value){
   //       if(e.target.value.includes("+")){
   //          var code = e.target.value.split(" ");
   //          setCode(code[0])
   //       }
   //    }
   //  }
   function Validationnum(phoneNumber) {

      let valid = false;
      try {
         const phoneUtil = PhoneNumberUtil.getInstance();
         valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
      } catch (e) {
         valid = false;
      }
      if (valid) {
         return true;
         // this.setState({
         //   message:'Phone number '+this.getValidNumber(phoneNumber)+' is valid',
         //   color:'green'
         // });
      } else {
         return false;
         // this.setState({
         //   message:'Phone number '+phoneNumber+' is not valid',
         //   color:'red'
         // });
      }
   }
   function getValidNumber(phoneNumber) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const parsedNumber = phoneUtil.parse(phoneNumber);
      return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL)
   }

   function removeprofile() {
      swal({
         title: "",
         text: "Remove profile photo?",
         icon: "warning",
         buttons: [true, "Remove"],
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               dispatch(RemoveProfileAPI())
            }
         });

   }

   function Checkusername(e) {
      debugger
      var uname = e.target.value.toString().toLowerCase();
      var userregex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9_.]+$/;
      var moredot = /^(?!.*?\.\.)/;
      if (uname) {
         if (uname.trim() == "") {
            setuserName(uname.trim())
         } else {
            setuserName(uname)
            setUsernameError("")
            if (!userregex.test(uname)) {
               if (uname.charAt(0) == ".") {
                  setUsernameError("You can't start your username with a dot.")
               } else if (uname.charAt(uname.length - 1) == ".") {
                  setUsernameError("You can't end your username with a dot.")
               } else if (!moredot.test(uname)) {
                  setUsernameError("You can't use your username with consecutive dots.")
               } else {
                  setUsernameError("Username can only use letters, numbers, underscores and dots.");
               }
            }
         }
      } else {
         setuserName("");
         setUsernameError("")
      }
   }


   function handleIntrestchange(catval) {
      debugger
      var newIntrest = Intrest ? Intrest.split(",") : []
      var indx = newIntrest.findIndex(i => i == catval)
      if (indx > -1) {
         newIntrest.splice(indx, 1);
      } else {
         newIntrest.push(catval)
      }
      setIntrest(newIntrest.join(','))
   }

   return (
      <>
         <Loader />
         <div className="wrapper">
            <Sidebar />
            <Navbar profile={GpRes && GpRes.data} />
            <div id="content-page" className="content-page">
               <div className="container-fluid">
                  <div className="row">

                     <div className="col-lg-12">
                        <div className="iq-edit-list-data">

                           <div className="iq-card">
                              <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                    <h4 className="card-title">Edit Profile</h4>
                                 </div>
                              </div>
                              <div className="iq-card-body">
                                 <div className="edit-profile-form">
                                    <div className="form-group row align-items-center">
                                       <div className="col-md-12">
                                          <div className="profile-img-edit">
                                             {/* {encryptStorage.getItem('userpic') ?
                                             <img className="profile-pic" src={encryptStorage.getItem('userpic')}  /> :
                                             <img className="profile-pic" src={user1}  />} */}
                                             {/* {placeholderflag!="true"&&<a onClick={()=>removeprofile()}>X</a>} */}
                                             <img className="profile-pic" src={Photo ? Photo : user1} />
                                             {/* {Photo&& <Img className="profile-pic" src={Photo}  
                                               loader={<img src={user1} className="profile-pic"/>}
                                                unloader={<img src={user1} className="profile-pic"/>} 
                                               />} */}
                                             <div className="p-image">
                                                <label className="cursor-pointer dropdown dropdown-ul toggle"><i className="fa fa-pencil upload-button"></i>

                                                   <input id="t1" type="checkbox" />

                                                   <ul >
                                                      <li><label htmlFor="imagepic">Upload Photo</label></li>
                                                      {placeholderflag != "true" && <li><label onClick={() => removeprofile()}>Remove Photo</label></li>}

                                                   </ul></label>
                                                <input id="imagepic" type="file" name="Image" onClick={(event) => {
                                                   event.target.value = null
                                                }} onChange={(e) => handleChange(e)} style={{ display: 'none' }} />

                                             </div>

                                          </div>{imageError && <p style={{ color: 'red', fontSize: 14, textAlign: 'center' }}>{imageError}</p>}

                                       </div></div>
                                    <div className=" row ">
                                       <div className="form-group col-sm-6">
                                          <label htmlFor="fname"> Name :</label>
                                          <input type="text" className="form-control" id="fname" placeholder="enter full name" value={name} onChange={(e) => { e.target.value ? e.target.value.trim() == "" ? setName(e.target.value.trim()) : isValidName(e.target.value) ? setName(e.target.value) : setName(name) : setName(""); setNameError("") }} maxLength={30} onKeyPress={(e) => EnterPressCall(e)} />
                                          {NameError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{NameError}</p>}
                                          {/* <p style={{ color: "red", fontSize: "14", float: "left" }}>Please provide a valid Email.</p> */}
                                       </div>

                                       <div className="col-sm-6">
                                          <div className="form-group addon-input">
                                             <label >Date of Birth :</label>
                                             <DatePicker
                                                className=" form-control"
                                                placeholderText={"mm/dd/yyyy"}
                                                dateFormat="MM/dd/yyyy"
                                                onChangeRaw={(e) => handleDateChangeRaw(e)}
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={moment().year() - 1900}
                                                selected={dob ? dob : null}
                                                onChange={(date) => setDob(date)}
                                                maxDate={moment().subtract(18, 'years').toDate()}
                                                minDate={moment("01/01/1900").toDate()}
                                                ref={inputRef}
                                             />
                                             <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={() => { inputRef && inputRef.current && inputRef.current.input && inputRef.current.input.focus() }}> <i className="fa fa-calendar-o"></i> </span>

                                             {DobError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{DobError}</p>}
                                          </div>  </div>

                                       <div className="form-group col-sm-6">
                                          <label htmlFor="uname" className="disabled">Email :</label>
                                          <input type="text" className="form-control " disabled id="uname" value={email} placeholder="enter email " />

                                       </div>

                                       <div className="form-group col-sm-6">
                                          <label htmlFor="phone" > Phone Number (Optional) :</label>
                                          {/* <input type="text" className="form-control" id="uname" placeholder="enter phone number " value={phone} onChange={(e) => { e.target.value?setPhone(isValidNumber(e.target.value)?e.target.value:phone):setPhone(e.target.value); setPhoneError("");}} maxLength={10}/> */}
                                          <div className="country-drop-list">
                                             {/* <PhoneInput
                                       id="phonenum"
                          className="form-control mb-0 country-dropdown" placeholder="enter phone number "
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry={"US"}
                          onSelect={(e)=>selectcountry(e)}
                          value={phone} onChange={(e) =>changephone(e)} maxLength={20} />
                          this.setState({phone:phone,PNError:"", PhoneNo:country.dialCode!=phone?phone:"" ,Ccode:country.dialCode}) */}
                                             <PhoneInput
                                                countryCodeEditable={false}
                                                country={'us'}
                                                value={phone}
                                                onChange={(phone, country) => { setPhone(phone); setPhoneNo(country.dialCode != phone ? phone : ""); setPhoneError(""); setCode(country.dialCode); }}
                                             />
                                          </div>
                                          {PhoneError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{PhoneError}</p>}

                                       </div>

                                       {/* //cizzr 1.5 (NiraliBThummar 9Nov2021) */}
                                       <div className="form-group col-sm-6">
                                          <label htmlFor="urname">Username :</label>
                                          <input type="text" className="form-control" id="urname" placeholder="enter username" value={username} onChange={(e) => Checkusername(e)} maxLength={30} onKeyPress={(e) => EnterPressCall(e)} />
                                          {UsernameError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{UsernameError}</p>}
                                       </div>

                                       <div className="form-group col-sm-6">
                                          <label htmlFor="webs"> Website :</label>
                                          <input type="text" className="form-control" id="webs" placeholder="https://example.com" value={website} onChange={(e) => { setWebsite(e.target.value); setWError("") }} maxLength={100} onKeyPress={(e) => EnterPressCall(e)} />
                                          {WError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{WError}</p>}
                                       </div>

                                    </div>
                                    <div className="row">
                                       <div className="form-group col-sm-6">
                                          <label htmlFor="bio"> Bio :</label>
                                          <TextareaAutosize rows="1" className="form-control bio-textarea" style={{ height: 45, lineHeight: 2 }} placeholder="enter bio" value={bio} onChange={(e) => { setBio(e.target.value); setBError("") }} maxLength={160} />
                                          {/* <input type="text" className="form-control" id="fname" placeholder="enter bio" value={bio} onChange={(e) => {setBio(e.target.value); setBError("")}} maxLength={160}/> */}
                                          {BError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{BError}</p>}
                                       </div>

                                       <div className="form-group col-sm-6">
                                       <label htmlFor="bio"> Add Interests :</label>
                                       <TextareaAutosize rows="1" className="form-control bio-textarea" style={{ height: 45, lineHeight: 2 }} placeholder="tell me about what you like.." value={finalIntrest?finalIntrest.replaceAll(',',',  '):''} onClick={() =>{setopenintrest(true); setIntrest(finalIntrest)}} />
                                       {/* <input type="text" className="form-control" id="addin"  placeholder="tell me about what you like.." value={saveIntrest ? Intrest : finalIntrest} onClick={() => setopenintrest(true)} /> */}
                                          {/* <button type="submit" className="btn    btn-primary " onClick={() => setopenintrest(true)} >Add Intrest </button> */}
                                       </div>
                                       <div className="col-md-12 m-auto d-block text-center">
                                          <div className="edit-button-sec">
                                             <button type="reset" className="btn   cancel-btn mr-2" onClick={previousPage}>Cancel</button>
                                             <button type="submit" className="btn   submit-btn btn-primary " onClick={updateProfile} >Update </button>
                                          </div>


                                       </div>
                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
               <Modal
                  classNames={{
                     overlay: "customOverlay",
                     modal: "customModal"
                  }}
                  open={opencrop}
                  center
                  onClose={() => { }}
                  showCloseIcon={false}
               >
                  {opencrop &&
                     <div className="modal fade show create-post" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                        <div className="modal-dialog" role="document">
                           <div className="modal-content">
                              <div className="modal-body" style={{ textAlign: 'center' }}>

                                 <ReactCrop
                                    src={upImg}
                                    onImageLoaded={onLoad}
                                    crop={crop}
                                    onChange={(c) => setCrop(c)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                 />
                                 <div style={{ display: 'none' }}>
                                    <canvas
                                       ref={previewCanvasRef}
                                       // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                       style={{
                                          width: Math.round(completedCrop?.width ?? 0),
                                          height: Math.round(completedCrop?.height ?? 0)
                                       }}
                                    />
                                 </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                 <button type="reset" className="btn  cancel-btn mr-2 mb-2" onClick={() => setopencrop(false)}>Cancel</button>
                                 <button type="submit" className="btn  submit-btn btn-primary mb-2" onClick={() =>
                                    uploadprofilepic(previewCanvasRef.current, completedCrop)
                                 } >Update</button>
                              </div>
                           </div>
                        </div>

                     </div>
                  }
               </Modal>
               <Modal
                  classNames={{
                     overlay: "customOverlay",
                     modal: "customModal"
                  }}
                  open={openintrest}
                  center
                  onClose={() => {}}
                  showCloseIcon={false}
               >
                  {openintrest &&
                     <div className="modal fade show interest-modal" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                        <div className="modal-dialog  " role="document">
                           <div className="modal-content">
                              <div className="modal-header">
                                 <h5 className="modal-title" id="post-modalLabel">Tell me about what you like..</h5>
                                 {/* <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ openpost: false, Error: "", Emotion: "", Content: "", openemotion: false, isAnonymous: false, selection: true })}><i className="ri-close-fill"></i></button> */}
                              </div>
                              <div className="modal-body" >

                                 <ul class="ks-cboxtags">
                                    <li className="">Sports : </li>
                                    <li><input type="checkbox" id="Indoor" checked={Intrest ? Intrest.includes('Sports-Indoor') ? true : false : false} onClick={() => handleIntrestchange('Sports-Indoor')} /><label for="Indoor" >Indoor</label></li>
                                    <li><input type="checkbox" id="Outdoor" checked={Intrest ? Intrest.includes('Sports-Outdoor') ? true : false : false} onClick={() => handleIntrestchange('Sports-Outdoor')} /><label for="Outdoor" >Outdoor</label></li>
                                    </ul>
                                    <ul class="ks-cboxtags">
                                    <li className="">Traveling : </li>
                                    <li><input type="checkbox" id="Mountain" checked={Intrest ? Intrest.includes('Traveling-Mountain') ? true : false : false} onClick={() => handleIntrestchange('Traveling-Mountain')} /><label for="Mountain" >Mountain</label></li>
                                    <li><input type="checkbox" id="Beach" checked={Intrest ? Intrest.includes('Traveling-Beach') ? true : false : false} onClick={() => handleIntrestchange('Traveling-Beach')} /><label for="Beach" >Beach</label></li>
                                    <li><input type="checkbox" id="TOther" checked={Intrest ? Intrest.includes('Traveling-Other') ? true : false : false} onClick={() => handleIntrestchange('Traveling-Other')} /><label for="TOther" >Other</label></li>
                                </ul>
                                <ul class="ks-cboxtags">
                                    <li className="">Reading : </li>
                                    <li><input type="checkbox" id="Fiction" checked={Intrest ? Intrest.includes('Reading-Fiction') ? true : false : false} onClick={() => handleIntrestchange('Reading-Fiction')} /><label for="Fiction" >Fiction</label></li>
                                    <li><input type="checkbox" id="NonFiction" checked={Intrest ? Intrest.includes('Reading-NonFiction') ? true : false : false} onClick={() => handleIntrestchange('Reading-NonFiction')} /><label for="NonFiction" >Non Fiction</label></li>
                                    <li><input type="checkbox" id="ROther" checked={Intrest ? Intrest.includes('Reading-Other') ? true : false : false} onClick={() => handleIntrestchange('Reading-Other')} /><label for="ROther" >Other</label></li>
                                </ul>
                                <ul class="ks-cboxtags">
                                    <li className="">Writing : </li>
                                    <li><input type="checkbox" id="Poem" checked={Intrest ? Intrest.includes('Writing-Poem') ? true : false : false} onClick={() => handleIntrestchange('Writing-Poem')} /><label for="Poem" >Poem</label></li>
                                    <li><input type="checkbox" id="Narrative" checked={Intrest ? Intrest.includes('Writing-Narrative') ? true : false : false} onClick={() => handleIntrestchange('Writing-Narrative')} /><label for="Narrative" >Narrative</label></li>
                                    {/* <li><input type="checkbox" id="Journal" checked={Intrest ? Intrest.includes('Writing-Journal') ? true : false : false} onClick={() => handleIntrestchange('Writing-Journal')} /><label for="Journal" > Journal</label></li> */}
                                    <li><input type="checkbox" id="Story" checked={Intrest ? Intrest.includes('Writing-Story') ? true : false : false} onClick={() => handleIntrestchange('Writing-Story')} /><label for="Story">Story</label></li>
                                    <li><input type="checkbox" id="WOther" checked={Intrest ? Intrest.includes('Writing-Other') ? true : false : false} onClick={() => handleIntrestchange('Writing-Other')} /><label for="WOther" >Other</label></li>
                                    </ul>
                                    <ul class="ks-cboxtags">
                                    <li className="">Music : </li>
                                    <li><input type="checkbox" id="Soft" checked={Intrest ? Intrest.includes('Music-Soft') ? true : false : false} onClick={() => handleIntrestchange('Music-Soft')} /><label for="Soft" >Soft</label></li>
                                    <li><input type="checkbox" id="Loud" checked={Intrest ? Intrest.includes('Music-Loud') ? true : false : false} onClick={() => handleIntrestchange('Music-Loud')} /><label for="Loud" >Loud</label></li>
                                 </ul>
                                 <ul class="ks-cboxtags">
                                    <li className="">Movies : </li>
                                    <li><input type="checkbox" id="Action" checked={Intrest ? Intrest.includes('Movies-Action') ? true : false : false} onClick={() => handleIntrestchange('Movies-Action')} /><label for="Action" >Action</label></li>
                                    <li><input type="checkbox" id="Horror" checked={Intrest ? Intrest.includes('Movies-Horror') ? true : false : false} onClick={() => handleIntrestchange('Movies-Horror')} /><label for="Horror" >Horror</label></li>
                                    <li><input type="checkbox" id="Comedy" checked={Intrest ? Intrest.includes('Movies-Comedy') ? true : false : false} onClick={() => handleIntrestchange('Movies-Comedy')} /><label for="Comedy" > Comedy</label></li>
                                    <li><input type="checkbox" id="MOther" checked={Intrest ? Intrest.includes('Movies-Other') ? true : false : false} onClick={() => handleIntrestchange('Movies-Other')} /><label for="MOther">Other</label></li>
                                 </ul>
                                 
                                 {/* <div className="checkbox-group-sec">
                                    <label className="label">Sports : </label>
                                    <div className="form-inline"><div className='' ><input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Sports-Indoor') ? true : false : false} onClick={() => handleIntrestchange('Sports-Indoor')} /><label> Indoor</label></div>
                                       <div className='' > <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Sports-Outdoor') ? true : false : false} onClick={() => handleIntrestchange('Sports-Outdoor')} /><label> Outdoor</label></div>
                                       <input type="checkbox" defaultChecked={Intrest?Intrest.includes('Sports-None')?true:false:false} onClick={() => handleIntrestchange('Sports-None')} /> None 
                                    </div>
                                 </div>
                                 <div className="checkbox-group-sec">
                                    <label className="label">Traveling : </label>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Traveling-Mountain') ? true : false : false} onClick={() => handleIntrestchange('Traveling-Mountain')} /><label> Mountain</label>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Traveling-Beach') ? true : false : false} onClick={() => handleIntrestchange('Traveling-Beach')} /> <label>Beach</label>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Traveling-Other') ? true : false : false} onClick={() => handleIntrestchange('Traveling-Other')} /><label> Other</label>
                                   <input type="checkbox" defaultChecked={Intrest?Intrest.includes('Traveling-None')?true:false:false} onClick={() => handleIntrestchange('Traveling-None')} /> None 
                                 </div>
                                 <div className="checkbox-group-sec">
                                    <span>Reading : </span>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Reading-Fiction') ? true : false : false} onClick={() => handleIntrestchange('Reading-Fiction')} /> Fiction
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Reading-NonFiction') ? true : false : false} onClick={() => handleIntrestchange('Reading-NonFiction')} /> Non Fiction
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Reading-Other') ? true : false : false} onClick={() => handleIntrestchange('Reading-Other')} /> Other
                                     <input type="checkbox" defaultChecked={Intrest?Intrest.includes('Reading-None')?true:false:false} onClick={() => handleIntrestchange('Reading-None')} /> None 
                                 </div>
                                 <div className="checkbox-group-sec">
                                    <span>Writing : </span>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Writing-Poem') ? true : false : false} onClick={() => handleIntrestchange('Writing-Poem')} /> Poem
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Writing-Narrative') ? true : false : false} onClick={() => handleIntrestchange('Writing-Narrative')} /> Narrative
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Writing-Journal') ? true : false : false} onClick={() => handleIntrestchange('Writing-Journal')} /> Journal
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Writing-Story') ? true : false : false} onClick={() => handleIntrestchange('Writing-Story')} /> Story
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Writing-Other') ? true : false : false} onClick={() => handleIntrestchange('Writing-Other')} /> Other
                                     <input type="checkbox" defaultChecked={Intrest?Intrest.includes('Writing-None')?true:false:false} onClick={() => handleIntrestchange('Writing-None')} /> None 
                                 </div>
                                 <div className="checkbox-group-sec">
                                    <span>Music : </span>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Music-Soft') ? true : false : false} onClick={() => handleIntrestchange('Music-Soft')} /> Soft
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Music-loud') ? true : false : false} onClick={() => handleIntrestchange('Music-loud')} /> loud
                                     <input type="checkbox" defaultChecked={Intrest?Intrest.includes('Music-None')?true:false:false} onClick={() => handleIntrestchange('Music-None')} /> None 
                                 </div>
                                 <div className="checkbox-group-sec">
                                    <span>Movies : </span>
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Movies-Action') ? true : false : false} onClick={() => handleIntrestchange('Movies-Action')} /> Action
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Movies-Horror') ? true : false : false} onClick={() => handleIntrestchange('Movies-Horror')} /> Horror
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Movies-Other') ? true : false : false} onClick={() => handleIntrestchange('Movies-Other')} /> Other
                                    <input type="checkbox" defaultChecked={Intrest ? Intrest.includes('Movies-Comedy') ? true : false : false} onClick={() => handleIntrestchange('Movies-Comedy')} /> Comedy
                                     <input type="checkbox" defaultChecked={Intrest?Intrest.includes('Movies-None')?true:false:false} onClick={() => handleIntrestchange('Movies-None')} /> None
                                 </div> */}
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                 <button type="reset" className="btn  cancel-btn mr-2 mb-2" onClick={() => setopenintrest(false)}>Skip</button>
                                 <button type="submit" className="btn  submit-btn btn-primary mb-2" onClick={() => { setopenintrest(false); setfinalIntrest(Intrest);}} >Save</button>
                              </div>
                           </div>
                        </div>

                     </div>
                  }
               </Modal>
               {opencrop || openintrest && <div className="modal-backdrop fade show"></div>}
            </div>
         </div>
      </>)
}

export default Editprofile