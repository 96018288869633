import React, { useEffect, useState } from 'react'
import {LogFEvent} from '../firebase'
import {
   GetProfileAPI, MyPostAPI, SavedPostAPI, CommentListAPI, SavePostAPI, RemoveFromSaveAPI, CommentAPI,
   UPVoteAPI, DownVoteAPI, PostAPI, DeletePostAPI, BasicInfoAPI, BasicCountAPI, UPVoteCmtAPI, DownVoteCmtAPI,
   FriendReqListAPI, FriendListAPI, AllUserAPI, AddFriendAPI, AcceptRejectFriendAPI, FriendSuggestedAPI, MutualFriendAPI
} from "../actions/globalactions";
import { dispatchLoadingStatus, encryptStorage } from '../utils/utils';
import { basepath, devicetype } from '../constants';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import user1 from "../asset/images/user1.svg";
import bgimg from "../asset/images/profile-bg1.jpg";
import PostCard from './Postcard';
import pageloadloader from "../asset/images/page-load-loader.gif";
import Loader from './Loader';
import Modal from "react-responsive-modal";
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { Img } from 'react-image';
import TextareaAutosize from 'react-textarea-autosize';
import ShowMoreText from "react-show-more-text";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
   EmailShareButton,
   FacebookShareButton,
   TelegramShareButton,
   TwitterShareButton, WhatsappShareButton
} from "react-share";
import Mentiontextarea from './Mentionuser'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

var latparam, longparam;

class Profile extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         Pdata: [],
         GpRes: [],
         loading: true,
         start: 0,
         savestart: 0,
         startsearch: 0,
         opendiv: true,
         Emotion: "",
         Content: "",
         isAnonymous: false,
         isPrivate: false,
         frienddiv: false,
         FriendsList: null,
         FriendsReqList: null,
         searchtext: '',
         SuggestedFriendsList: null,
         process: false,
         suggestedprocess: false,
         openMutual: false,
         mutualloader: false
      }
      this.handleScroll = this.handleScroll.bind(this);
   }
   executeOnClick = (isExpanded) => {
      // console.log(isExpanded);
   }
   componentDidMount() {
      LogFEvent("profile_pageopen")
      var token = encryptStorage.getItem("cizzr_token")
      if (!token) {
         this.props.history.push(`/signin`);
      } else {
         window.addEventListener("scroll", this.handleScroll);
         var Basicinfo = encryptStorage.getItem('basicInfoRes');
         var ProfileRes = encryptStorage.getItem('ProfileRes');
         debugger
         var profiledata = this.state.GpRes
         profiledata.data = ProfileRes ? ProfileRes.data : Basicinfo ? Basicinfo.userData : null
         this.setState({ basicinfo: Basicinfo, GpRes: profiledata })
         var Token = encryptStorage.getItem('FCM_Token');
         const data = {
            "fcm_token": Token ? Token : "",
            "device_type": devicetype
         }
         this.props.BasicCountAPI(data);
         if (!encryptStorage.getItem('basicInfoRes')) {
            this.props.BasicInfoAPI(data);
         }
         const postdata = {
            "start": this.state.start,
            "limit": 5,
            "device_type": devicetype
         }
         // console.log("Post call..",pdata)
         this.getlatlong()
         if (localStorage.getItem("Fromprofile") == "true") {
            this.myFriends()
         } else {
            this.props.GetProfileAPI();
            this.props.MyPostAPI(postdata);
         }
         this.setState({
            Pdata: encryptStorage.getItem('MyPostData') && encryptStorage.getItem('MyPostData') != 'undefined' ? encryptStorage.getItem('MyPostData') : [],
            FriendsList: encryptStorage.getItem('FriendsList') && encryptStorage.getItem('FriendsList') != 'undefined' ? encryptStorage.getItem('FriendsList') : null,
            // SuggestedFriendsList: encryptStorage.getItem('SuggestedFriendsList') && encryptStorage.getItem('SuggestedFriendsList') != 'undefined' ? encryptStorage.getItem('SuggestedFriendsList') : null,
            FriendsReqList: encryptStorage.getItem('FriendsReqList') && encryptStorage.getItem('FriendsReqList') != 'undefined' ? encryptStorage.getItem('FriendsReqList') : []
            ,Tfriends: localStorage.getItem("Tfriends") ? localStorage.getItem("Tfriends") : 0
         })
      }
   }

   scrolltoTop = () => {

      window.scrollTo(0, 0)
   }
   componentWillReceiveProps(nextprops) {

      if (nextprops.GpRes) {
         if (nextprops.GpRes != this.props.GpRes) {
            this.scrolltoTop();
            this.setState({ GpRes: nextprops.GpRes })
         }
      }
      if (nextprops.MPdata) {
         if (nextprops.MPdata != this.props.MPdata) {

            this.setState({ loading: false })
            if (this.state.Pdata.length == 0 || this.state.start == 0) {
               if (this.state.opendiv && !this.state.frienddiv) {
                  this.scrolltoTop();
               }
               this.setState({ Pdata: nextprops.MPdata.data, Total_COUNT: nextprops.MPdata.count, isnodata: nextprops.MPdata.data.length > 0 ? '' : "You don't have any post! Add a post." })
               encryptStorage.setItem('MyPostData', nextprops.MPdata && nextprops.MPdata.data)
            }
            else {

               var new_data = this.state.Pdata.concat(nextprops.MPdata.data)
               this.setState({ Pdata: new_data, Total_COUNT: nextprops.MPdata.count, isnodata: '' })
            }
         }
      }

      if (nextprops.SPdata) {
         if (nextprops.SPdata != this.props.SPdata) {

            this.setState({ loading: false })
            if (this.state.Pdata.length == 0 || this.state.savestart == 0) {
               if (!this.state.opendiv && !this.state.frienddiv) {
                  this.scrolltoTop();
               }
               this.setState({ Pdata: nextprops.SPdata.data, STotal_COUNT: nextprops.SPdata.count, isnodata: nextprops.SPdata.data.length > 0 ? '' : "You don't have any saved post." })
            }
            else {

               var search_new_data = this.state.Pdata.concat(nextprops.SPdata.data)
               this.setState({ Pdata: search_new_data, STotal_COUNT: nextprops.SPdata.count, isnodata: '' })
            }
         }
      }


      if (nextprops.commentlist) {
         if (nextprops.commentlist != this.props.commentlist) {

            if (this.state.CID != undefined) {
               var list = this.state.Pdata
               list[this.state.CID]["commentlist"] = nextprops.commentlist
               // list[this.state.CID]["is_open"] = true
               this.setState({ Pdata: list })
            }
         }
      }

      if (nextprops.savePost) {
         if (nextprops.savePost != this.props.savePost) {

            if (this.state.BID != undefined) {
               // var list = this.state.Pdata
               // if(!this.state.opendiv && this.state.BID != undefined){
               //    if (this.state.BID > -1) {
               //       list.splice(this.state.BID, 1);
               //     }
               // }else{
               //    list[this.state.BID]["is_save"] = !list[this.state.BID]["is_save"]
               // }
               // this.setState({ Pdata: list })
            }
         }
      }

      // if (nextprops.votePost) {
      //    if (nextprops.votePost != this.props.votePost) {

      //       if (this.state.LID != undefined) {
      //          var list = this.state.Pdata
      //          list[this.state.LID]["total_up_votes"] = nextprops.votePost.data
      //          list[this.state.LID]["is_up_vote"] = !list[this.state.LID]["is_up_vote"]
      //          this.setState({ Pdata: list })
      //       }
      //    }
      // }

      if (nextprops.editpostres) {
         if (nextprops.editpostres != this.props.editpostres) {

            if (this.state.EID != undefined) {
               var list = this.state.Pdata
               list[this.state.EID] = nextprops.editpostres
               this.setState({ Pdata: list, openpost: false, Error: "", Emotion: "", Content: "", openemotion: false, isAnonymous: false })
            }
         }
      }
      if (nextprops.deletePostRes) {
         if (nextprops.deletePostRes != this.props.deletePostRes) {

            if (this.state.DID != undefined) {
               var list = this.state.Pdata
               if (this.state.DID != undefined) {
                  if (this.state.DID > -1) {
                     list.splice(this.state.DID, 1);
                  }
               }
               this.setState({ Pdata: list })
            }
         }
      }

      if (nextprops.friendListRes) {
         if (nextprops.friendListRes != this.props.friendListRes) {
            debugger
            this.setState({ FriendsList: nextprops.friendListRes , Tfriends:localStorage.getItem("Tfriends")?parseInt(localStorage.getItem("Tfriends")):0})
            encryptStorage.setItem('FriendsList', nextprops.friendListRes)
            
         }
      }

      if (nextprops.suggestedFriendRes) {
         if (nextprops.suggestedFriendRes != this.props.suggestedFriendRes) {
            debugger
            this.setState({ SuggestedFriendsList: nextprops.suggestedFriendRes })
            encryptStorage.setItem('SuggestedFriendsList', nextprops.suggestedFriendRes)
         }
      }

      if (nextprops.friendReqListRes) {
         if (nextprops.friendReqListRes != this.props.friendReqListRes) {
            debugger
            this.setState({ FriendsReqList: nextprops.friendReqListRes })
            encryptStorage.setItem('FriendsReqList', nextprops.friendReqListRes)
         }
      }

      if (nextprops.alluserRes) {
         if (nextprops.alluserRes != this.props.alluserRes) {
            debugger
            if (this.state.searchtext) {
               if (this.state.searchtext.length > 2) {
                  this.setState({ FriendsList: nextprops.alluserRes })
                  encryptStorage.setItem('FriendsList', nextprops.alluserRes)
               }
            }
         }
      }
      if (nextprops.addFriendRes) {
         if (nextprops.addFriendRes != this.props.addFriendRes) {
            debugger
            if (this.state.suggestedprocess) {
               var obj = this.state.SuggestedFriendsList
               if (obj) {
                  if (this.state.frndindex > -1) {
                     obj.splice(this.state.frndindex, 1);
                  }
               }
               this.setState({ SuggestedFriendsList: obj, suggestedprocess: false })
               encryptStorage.setItem('SuggestedFriendsList', obj)
            } else if (this.state.mutualprocess) {
               var obj = this.state.MutualfriendRes
               if (obj) {
                  if (obj[this.state.frndindex]) {
                     obj[this.state.frndindex]["friend_status"] = nextprops.addFriendRes.friend_status
                     obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.addFriendRes.friend_request_sent_by
                     obj[this.state.frndindex]["friend_id"] = nextprops.addFriendRes.friend_id
                  }
               }
               var objfrnd = this.state.FriendsList
               if (objfrnd) {
                  var findx = objfrnd.findIndex(i => i._id ? i._id == nextprops.addFriendRes.other_user_id : i.user_id == nextprops.addFriendRes.other_user_id)
                  if (objfrnd[findx]) {
                     objfrnd[findx]["friend_status"] = nextprops.addFriendRes.friend_status
                     objfrnd[findx]["friend_request_sent_by"] = nextprops.addFriendRes.friend_request_sent_by
                     objfrnd[findx]["friend_id"] = nextprops.addFriendRes.friend_id
                  }
               }
               this.setState({ MutualfriendRes: obj, FriendsList: objfrnd, mutualprocess: false })
               encryptStorage.setItem('FriendsList', objfrnd)

            } else {
               var obj = this.state.FriendsList
               if (obj) {
                  if (obj[this.state.frndindex]) {
                     obj[this.state.frndindex]["friend_status"] = nextprops.addFriendRes.friend_status
                     obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.addFriendRes.friend_request_sent_by
                     obj[this.state.frndindex]["friend_id"] = nextprops.addFriendRes.friend_id
                  }
               }

               this.setState({ FriendsList: obj, process: false })
               encryptStorage.setItem('FriendsList', obj)
            }

         }
      }

      if (nextprops.accrejFriendRes) {
         if (nextprops.accrejFriendRes != this.props.accrejFriendRes) {
            debugger
            if (this.state.mutualprocess) {
               var obj = this.state.MutualfriendRes
               if (obj) {
                  if (obj[this.state.frndindex]) {
                     obj[this.state.frndindex]["friend_status"] = nextprops.accrejFriendRes.friend_status
                     obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.accrejFriendRes.friend_request_sent_by
                     obj[this.state.frndindex]["friend_id"] = nextprops.accrejFriendRes.friend_id
                  }
               }
               var objfrnd = this.state.FriendsList
               if (objfrnd) {
                  var findx = objfrnd.findIndex(i => i._id ? i._id == nextprops.accrejFriendRes.other_user_id : i.user_id == nextprops.accrejFriendRes.other_user_id)
                  if (objfrnd[findx]) {
                     if (nextprops.accrejFriendRes.friend_status == 2) {
                        if (findx > -1) {
                           objfrnd.splice(findx, 1);
                        }
                        this.setState({Tfriends:this.state.Tfriends-1},()=> localStorage.setItem("Tfriends",this.state.Tfriends))
                       
                     } else {
                        objfrnd[findx]["friend_status"] = nextprops.accrejFriendRes.friend_status
                        objfrnd[findx]["friend_request_sent_by"] = nextprops.accrejFriendRes.friend_request_sent_by
                        objfrnd[findx]["friend_id"] = nextprops.accrejFriendRes.friend_id
                     }
                  }
               }
               this.setState({ MutualfriendRes: obj, FriendsList: objfrnd, mutualprocess: false })
               encryptStorage.setItem('FriendsList', objfrnd)

               debugger
               var objm = this.state.currentlist == "SuggestedMutual" ? this.state.SuggestedFriendsList : this.state.currentlist == "FriendMutual" ? this.state.FriendsList : null
               if (objm) {
                  var mindx = objm.findIndex(i => i._id ? i._id == nextprops.accrejFriendRes.mutual_friend_id : i.user_id == nextprops.accrejFriendRes.mutual_friend_id)
                  if (objm[mindx]) {
                     objm[mindx]["mutual_friends"] = nextprops.accrejFriendRes.mutual_friends
                     objm[mindx]["mutual_friends_count"] = nextprops.accrejFriendRes.mutual_friends_count
                     if (this.state.currentlist == "SuggestedMutual") {
                        this.setState({ SuggestedFriendsList: objm, currentlist: '' })
                        encryptStorage.setItem('SuggestedFriendsList', objm)
                     } else if (this.state.currentlist == "FriendMutual") {
                        this.setState({ FriendsList: objm, currentlist: '' })
                        encryptStorage.setItem('FriendsList', objm)
                     }
                  }
               }
            } else {
               var obj = this.state.FriendsList
               if (obj) {
                  if (obj[this.state.frndindex]) {
                     if (nextprops.accrejFriendRes.friend_status == 2) {
                        if (this.state.frndindex > -1) {
                           obj.splice(this.state.frndindex, 1);
                        }
                        this.setState({Tfriends:this.state.Tfriends-1},()=> localStorage.setItem("Tfriends",this.state.Tfriends))
                     } else {
                        obj[this.state.frndindex]["friend_status"] = nextprops.accrejFriendRes.friend_status
                        obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.accrejFriendRes.friend_request_sent_by
                        obj[this.state.frndindex]["friend_id"] = nextprops.accrejFriendRes.friend_id
                     }

                  }
               }
               this.setState({ FriendsList: obj, process: false })
               encryptStorage.setItem('FriendsList', obj)
            }
         }
      }

      if (nextprops.mutualfriendRes) {
         if (nextprops.mutualfriendRes != this.props.mutualfriendRes) {
            this.setState({ MutualfriendRes: nextprops.mutualfriendRes.data, MFcount: nextprops.mutualfriendRes.count, mutualloader: false })
            if (nextprops.mutualfriendRes.data) {
               var firstfive = nextprops.mutualfriendRes.data.slice(0, 5)
               var newmutual = []
               firstfive.map(i => newmutual.push({ "user_id": i.user_id, "photo": i.photo }))
            }
            var objm = this.state.currentlist == "SuggestedMutual" ? this.state.SuggestedFriendsList : this.state.currentlist == "FriendMutual" ? this.state.FriendsList : null
            if (objm) {
               var mindx = objm.findIndex(i => i._id ? i._id == this.state.currentmutualid : i.user_id == this.state.currentmutualid)
               if (objm[mindx]) {
                  objm[mindx]["mutual_friends"] = newmutual
                  objm[mindx]["mutual_friends_count"] = nextprops.mutualfriendRes.count
                  if (this.state.currentlist == "SuggestedMutual") {
                     this.setState({ SuggestedFriendsList: objm })
                     encryptStorage.setItem('SuggestedFriendsList', objm)
                  } else if (this.state.currentlist == "FriendMutual") {
                     this.setState({ FriendsList: objm })
                     encryptStorage.setItem('FriendsList', objm)
                  }
               }
            }
         }
      }


   }

   componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
   }

   handleScroll() {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
         if (this.state.Pdata) {
            if (this.state.Pdata.length > 1) {
               this.searching()
            }
         }
      }
   }

   searching = () => {
      if (!this.state.frienddiv) {
         if (!this.state.loading) {
            if (this.state.opendiv) {
               if (this.state.Total_COUNT > this.state.start + 5) {
                  const postdata = {
                     "start": this.state.start + 5,
                     "limit": 5,
                     "device_type": devicetype
                  }
                  // console.log("Post call..",pdata)
                  this.props.MyPostAPI(postdata);
                  this.setState({ start: this.state.start + 5, loading: true });
               }
            }
            else {
               if (this.state.STotal_COUNT > this.state.savestart + 5) {
                  const postdata = {
                     "start": this.state.savestart + 5,
                     "limit": 5,
                     "device_type": devicetype
                  }
                  this.props.SavedPostAPI(postdata);
                  this.setState({ savestart: this.state.savestart + 5, loading: true });
               }
            }
         }
      }
   }

   MyPost() {

      const myPd = {
         "start": 0,
         "limit": 5,
         "device_type": devicetype
      }

      this.setState({ opendiv: true, frienddiv: false, Pdata: [], loading: true, isnodata: "", searchtext: "" })
      this.props.MyPostAPI(myPd)
      localStorage.removeItem("searchtxt")
   }

   Savedpost() {

      const savedPd = {
         "start": 0,
         "limit": 5,
         "device_type": devicetype
      }

      this.setState({ opendiv: false, frienddiv: false, Pdata: [], loading: true, isnodata: "", searchtext: "" })
      this.props.SavedPostAPI(savedPd)
      localStorage.removeItem("searchtxt")
   }

   getlatlong = () => {
      if (navigator.permissions && navigator.permissions.query) {
         navigator.permissions.query({ name: 'geolocation' })
            .then(function (permissionStatus) {
               if (permissionStatus.state == 'granted') {
                  navigator.geolocation.getCurrentPosition(function (position) {
                     latparam = position.coords.latitude;
                     longparam = position.coords.longitude;
                  });
               }
               permissionStatus.onchange = function () {
                  // console.log('geolocation permission state has changed to ', this.state);
                  if (this.state == 'granted') {
                     navigator.geolocation.getCurrentPosition(function (position) {
                        latparam = position.coords.latitude;
                        longparam = position.coords.longitude;
                     });

                  }
               };
            });
      }
   }

   FriendSuggestedcall = () => {
      debugger
      var postdata = {
         "latitude": latparam ? latparam : "",
         "longitude": longparam ? longparam : "",
         "km": 10
      }
      this.props.FriendSuggestedAPI(postdata)
   }

   myFriends() {
      var srchtxt = localStorage.getItem("searchtxt")
      this.setState({ frienddiv: true })
      this.props.FriendReqListAPI()
      this.FriendSuggestedcall(latparam, longparam)
      if (srchtxt) {
         this.callalluser(srchtxt)
         this.setState({ searchtext: srchtxt })
      } else {
         this.props.FriendListAPI({
            "start": 1,
            "limit": 10
         })
      }
      this.setState({ frienddiv: true, loading: false }, () => {
         localStorage.removeItem("Fromprofile")
         localStorage.removeItem("searchtxt")
      }
      )
   }

   callalluser = (val) => {
      setTimeout(() => {
         if (val.length > 2) {
            this.props.AllUserAPI({
               "text": val
            })
         }
      }, 1000);
   }
   searchuser = (e) => {
      this.setState({ searchtext: e.target.value.trim() == "" ? "" : e.target.value })
      if (e.target.value.trim() != "" && e.target.value.length == 3) {
         this.setState({ FriendsList: null })
      }
      if (e.target.value.trim() != "" && e.target.value.length > 2) {
         this.callalluser(e.target.value)
      } else if (e.target.value.trim() == "" || e.target.value.length < 3) {
         debugger
         if (e.target.value.length == 2 || e.target.value.trim() == "") {
            this.props.FriendListAPI({
               "start": 1,
               "limit": 10
            })
         }
      }

   }

   gotofriend = (data, title) => {
      this.props.history.push(`/friend`);
      // this.props.history.push({
      //    pathname: `/friend`,
      //    state: { 'frnddata': data, 'title': title }
      // });
      encryptStorage.setItem('friendtitle', title)
      localStorage.setItem("Fromprofile", true)
      localStorage.setItem("searchtxt", this.state.searchtext)
      localStorage.setItem("Tfriends",this.state.Tfriends)
      if (title == "Friends") {
         encryptStorage.setItem('FriendsList', data)
      } else if (title == "Suggested Friends") {
         encryptStorage.setItem('SuggestedFriendsList', data)
      } else {
         encryptStorage.setItem('FriendsReqList', data)
      }
   }


   comments = (index, i) => {
      this.setState({ CID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.CommentListAPI(data)

   }

   bookmarkcall = (index, i) => {


      this.setState({ BID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      if (i.is_save) {
         this.props.RemoveFromSaveAPI(data)
      } else {
         this.props.SavePostAPI(data)
      }

      var list = this.state.Pdata
      if (!this.state.opendiv && index != undefined) {
         if (index > -1) {
            list.splice(index, 1);
         }
      } else {
         list[index]["is_save"] = !list[index]["is_save"]
      }
      this.setState({ Pdata: list })
   }

   likeunlikecall = (index, i) => {

      var list = this.state.Pdata
      let count = list[index]["total_up_votes"]
      list[index]["total_up_votes"] = list[index]["is_up_vote"] == true ? count - 1 : count + 1
      list[index]["is_up_vote"] = !list[index]["is_up_vote"]
      if (list[index]["is_down_vote"] == true) {
         list[index]["total_down_votes"] = list[index]["total_down_votes"] - 1
         list[index]["is_down_vote"] = !list[index]["is_down_vote"]
      }
      this.setState({ Pdata: list })
      this.setState({ LID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.UPVoteAPI(data)
      //   if(i.is_up_vote){
      //    this.props.DownVoteAPI(data)
      //   }else{

      //   }
      // var list = this.state.Pdata
      // list[index]["is_save"] = !list[index]["is_save"]
      // this.setState({ Pdata: list })

   }

   //cizzr 1.5 (NiraliBThummar 10Nov2021)
   downvotecall = (index, i) => {

      var list = this.state.Pdata
      let count = list[index]["total_down_votes"]
      list[index]["total_down_votes"] = list[index]["is_down_vote"] == true ? count - 1 : count + 1
      list[index]["is_down_vote"] = !list[index]["is_down_vote"]
      if (list[index]["is_up_vote"] == true) {
         list[index]["total_up_votes"] = list[index]["total_up_votes"] - 1
         list[index]["is_up_vote"] = !list[index]["is_up_vote"]
      }
      this.setState({ Pdata: list })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.DownVoteAPI(data)

   }

   // cizzr 1.5 (NiraliBThummar 10Nov2021)
   cmntupvotecall = (cmtid, index, i) => {
      debugger
      var data = this.state.Pdata
      let postobj = data[index]
      var list = postobj.commentlist
      var cindex = list.map(item => item._id).indexOf(cmtid);
      let count = list[cindex]["total_up_votes"] ? list[cindex]["total_up_votes"] : 0
      list[cindex]["total_up_votes"] = list[cindex]["is_up_vote"] == true ? count - 1 : count + 1
      list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
      if (list[cindex]["is_down_vote"] == true) {
         list[cindex]["total_down_votes"] = list[cindex]["total_down_votes"] - 1
         list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
      }
      data[index]["commentlist"] = list
      this.setState({ Pdata: data })
      const req = {
         "comment_id": cmtid,
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.UPVoteCmtAPI(req)

   }

   //cizzr 1.5 (NiraliBThummar 10Nov2021)
   cmntdownvotecall = (cmtid, index, i) => {

      var data = this.state.Pdata
      let postobj = data[index]
      var list = postobj.commentlist
      var cindex = list.map(item => item._id).indexOf(cmtid);
      let count = list[cindex]["total_down_votes"] ? list[cindex]["total_down_votes"] : 0
      list[cindex]["total_down_votes"] = list[cindex]["is_down_vote"] == true ? count - 1 : count + 1
      list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
      if (list[cindex]["is_up_vote"] == true) {
         list[cindex]["total_up_votes"] = list[cindex]["total_up_votes"] - 1
         list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
      }
      data[index]["commentlist"] = list
      this.setState({ Pdata: data })
      const req = {
         "comment_id": cmtid,
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.DownVoteCmtAPI(req)

   }

   Addcomment = (comnt, isAnonymous, index, i) => {

      this.setState({ CID: index })
      const data = {
         "post_id": i._id,
         "message": comnt,
         "device_type": devicetype,
         "is_anonymous": isAnonymous ? "1" : "0"
      }
      this.props.CommentAPI(data)
   }

   Opencomment = (index) => {

      var list = this.state.Pdata
      list[index]["is_open"] = true
      list[index]["Clistcount"] = 3
      this.setState({ Pdata: list })

   }

   Collapsecomment = (index) => {

      var list = this.state.Pdata
      list[index]["is_open"] = list[index]["is_open"] ? !list[index]["is_open"] : true
      list[index]["Clistcount"] = 3
      this.setState({ Pdata: list })

   }

   Viewmore = (index) => {

      var list = this.state.Pdata
      list[index]["Clistcount"] = list[index]["Clistcount"] + 3
      this.setState({ Pdata: list })

   }

   Editpost = (index, i) => {
      this.setState({
         EID: index, editobj: i, openpost: true, Emotion: i.emotion_id ? i.emotion_id : "",
         selection: i.emotion_id ? false : true, Content: i.original_content,
         isAnonymous: i.is_anonymous == "1" ? true : false,
         isPrivate: i.is_private == "1" ? true : false,
      })
   }
   Deletepost = (index, i) => {

      swal({
         title: "",
         text: "Are you sure you want to delete?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               this.props.isLoading(true);
               this.setState({ DID: index })
               const postdata = {
                  "post_id": i._id
               }
               this.props.DeletePostAPI(postdata);
            } else {
            }
         });
   }

   postcontent() {
      // if (!this.state.Emotion) {
      //    this.setState({ Error: "Please select emotion." })
      // } else 
      if (!this.state.Content) {
         this.setState({ Error: "Please provide content." })
      } else if (this.state.Content.trim() == "") {
         this.setState({ Error: "Please provide content." })
      } else {
         this.props.isLoading(true);
         const postdata = {
            "post_id": this.state.editobj && this.state.editobj._id,
            "emotion_id": this.state.Emotion,
            "content": this.state.Content,
            "is_anonymous": this.state.isAnonymous ? "1" : "0",
            "is_private": this.state.isPrivate ? "1" : "0",
            "latitude": this.state.editobj && this.state.editobj.latitude,
            "longitude": this.state.editobj && this.state.editobj.longitude,
            "device_type": devicetype
         }
         this.props.PostAPI(postdata);
      }
   }

   sendfriendrequest = (id, idx, from) => {
      debugger
      if (id) {
         if (from == "suggested") {
            this.setState({ suggestedprocess: true })
         } else if (from == "mutual") {
            this.setState({ mutualprocess: true })
         } else {
            this.setState({ process: true })
         }
         this.setState({ frndindex: idx })
         var sendreq = {
            to_user_id: id,
            device_type: devicetype,
            mutual_friend_id: this.state.currentmutualid
         }
         this.props.AddFriendAPI(sendreq)
      }
   }

   acceptrejectfriendrequest = (freqid, statusreq, idx, status, from) => {
      debugger
      if (freqid) {
         if (from == "mutual") {
            this.setState({ mutualprocess: true })
         } else {
            this.setState({ process: true })
         }
         this.setState({ frndindex: idx })
         var sendreq = {
            request_id: freqid,
            status: statusreq,
            cancel_request: statusreq == 2 ? status == 1 ? "0" : "1" : "0",
            mutual_friend_id: this.state.currentmutualid
         }
         this.props.AcceptRejectFriendAPI(sendreq)
      }
   }

   gotoprofile = (otheruserid) => {
      localStorage.setItem("Fromprofile", true)
      localStorage.setItem("searchtxt", this.state.searchtext)
      encryptStorage.setItem('FriendsList', this.state.FriendsList)
      this.props.history.push({
         pathname: `/otherprofile/`+otheruserid,
         state: otheruserid
      });
   }

   Privatepost = () => {
      if (!this.state.isPrivate) {
         swal({
            title: "",
            text: "Private post will only be visible to your Friends",
            icon: "warning",
            buttons: true,
            dangerMode: true,
         })
            .then((willDelete) => {
               if (willDelete) {
                  this.setState({ isPrivate: true })
               } else {
               }
            });
      } else {
         this.setState({ isPrivate: false })
      }
   }

   removeele = (index) => {
      var array = this.state.SuggestedFriendsList
      if (index > -1) {
         array.splice(index, 1);
      }
      this.setState({ SuggestedFriendsList: array })
      encryptStorage.setItem('SuggestedFriendsList', array)
   }

   openMutual = (id, updatelist) => {
      debugger
      this.setState({ openMutual: true, mutualloader: true, currentmutualid: id, currentlist: updatelist })
      this.props.MutualFriendAPI({
         "other_user_id": id
      })
   }
   Setvalue=(val)=>{
      this.setState({Content:val,Error:''},()=>{
         // console.log(this.state.Content)
      })     
   }
   render() {
      return (
         <>
            <Loader />
            <div className="wrapper"  >
               <Sidebar active="profile" />
               <Navbar />
               <div id="content-page" className="content-page">
                  <div className="container-fluid">
                     <div className="row" id="profilecontent">
                        <div className="col-sm-12">
                           <div className="iq-card profile-card">
                              <div className="iq-card-body profile-page p-0">
                                 <div className="profile-header">
                                    <div className="cover-container">
                                       <img src={bgimg} className=" img-fluid" />
                                    </div>
                                    <div className="profile-data "> 
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="user-detail ">
                                                <div className="profile-img">
                                                   {this.state.GpRes && this.state.GpRes.data &&
                                                      // <img src={this.state.GpRes.data.photo!=""?this.state.GpRes.data.photo:user1}  className="avatar-100 img-fluid" />
                                                      <Img className="avatar-100 img-fluid" src={encryptStorage.getItem("userpic")}
                                                         loader={<img src={user1} className="avatar-100 img-fluid" />} unloader={<img src={user1} className="avatar-100 img-fluid" />} />
                                                   }
                                                </div>
                                                <div className="profile-detail">
                                                   {this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.name && <h4 className="">{this.state.GpRes.data.name}</h4>}
                                                   {this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.bio && <div className="bio-details">
                                                      <ShowMoreText lines={3} more="Read more" less="Read less" className="content-css" anchorclassName="my-anchor-css-class" onClick={(isExpanded) => this.executeOnClick(isExpanded)} expanded={false} truncatedEndingComponent={"... "}>
                                                         {this.state.GpRes.data.bio.split("\n").map(function (item, idx) { return (<p key={idx}>{item} <br /></p>) })}
                                                      </ShowMoreText>
                                                   </div>}
                                                   {this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.website && <p className="website-details"><a target='_blank' href={this.state.GpRes.data.website.includes('http') ? this.state.GpRes.data.website : '//' + this.state.GpRes.data.website}>{this.state.GpRes.data.website}</a></p>}
                                                </div>
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="profile-info p-4 ">
                                                <div className="social-info profile-btns">
                                                   <button type="reset" className="btn   cancel-btn mr-2" onClick={() => this.props.history.push(`/setting`)}>Settings</button>
                                                   <button type="submit" className="btn   submit-btn btn-primary " onClick={() => this.props.history.push(`/editprofile`)}>Edit Profile</button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    
                                    <div className="user-tabing">
                                       <ul className="nav nav-pills profile-tabs  d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                          <li className="col-lg-2 col-sm-6 col-md-6 col-6 p-0   ">
                                             <a className={this.state.opendiv && !this.state.frienddiv ? "nav-link  active" : "nav-link"} onClick={() => this.MyPost()} >My Posts</a>
                                          </li>
                                          <li className="col-lg-2 col-sm-6 col-md-6 col-6 p-0 ">
                                             <a className={this.state.frienddiv ? "nav-link  active" : "nav-link"} onClick={() => this.myFriends()} >My Friends</a>
                                          </li>
                                          <li className="col-lg-2 col-sm-6 col-md-6 col-6 p-0 ">
                                             <a className={!this.state.opendiv && !this.state.frienddiv ? "nav-link  active" : "nav-link"} onClick={() => this.Savedpost()} >Saved Posts</a>
                                          </li>
                                       </ul>
                                      
                                    {localStorage.getItem('guideflag') == "true" && this.state.Mark9 &&
                              <div className="tutorial-box   tutorial-box9">
                                 
                              <div className="tutorial-box-inner1 ">
                                 <h4>Click here to <span>view your posts</span>.</h4>
                               <div className="tutorial-btn"> 
                                {/* <div className="btn btn-primary " onClick={() => { this.setState({ Mark1: false }); localStorage.setItem('guideflag', false) }}>Skip</div> */}
                                 <div className="btn btn-primary ml-2" onClick={() => this.setState({ Mark10: true, Mark9: false })}>Next</div></div>
                                 
                                 {/* <div className="arrow-top"><img src={arrowtutorial} /></div> */}
                              </div>
                              <div className="arrow-top"></div>
                              
                              </div>}
                              
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="tab-content">
                              <div className="tab-pane fade active show" id="timeline" role="tabpanel">
                                 {this.state.opendiv && !this.state.frienddiv && this.state.Pdata &&
                                    this.state.Pdata.length > 0 ?
                                    this.state.Pdata.map((i, index) =>
                                       <PostCard data={i} key={i._id} index={index} isEdit={true}
                                          bookmark={() => this.bookmarkcall(index, i)}
                                          getcomments={() => this.comments(index, i)}
                                          likeunlike={() => this.likeunlikecall(index, i)}
                                          addcomment={(val, isAnonymous) => this.Addcomment(val, isAnonymous, index, i)}
                                          opencomment={() => this.Opencomment(index)}
                                          viewmore={() => this.Viewmore(index)}
                                          editpost={() => this.Editpost(index, i)}
                                          deletepost={() => this.Deletepost(index, i)}
                                          collapsecomment={() => this.Collapsecomment(index)}
                                          downvote={() => this.downvotecall(index, i)} //cizzr 1.5 (NiraliBThummar 10Nov2021)
                                          cmntupvote={(cmtid) => this.cmntupvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                          cmntdownvote={(cmtid) => this.cmntdownvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                       />
                                    ) : null}
                              </div>
                              <div className="tab-pane fade active show" id="about" role="tabpanel">
                                 {!this.state.opendiv && !this.state.frienddiv && this.state.Pdata &&
                                    this.state.Pdata.length > 0 ?
                                    this.state.Pdata.map((i, index) =>
                                       <PostCard data={i} key={i._id} index={index}
                                          bookmark={() => this.bookmarkcall(index, i)}
                                          getcomments={() => this.comments(index, i)}
                                          likeunlike={() => this.likeunlikecall(index, i)}
                                          addcomment={(val, isAnonymous) => this.Addcomment(val, isAnonymous, index, i)}
                                          opencomment={() => this.Opencomment(index)}
                                          viewmore={() => this.Viewmore(index)}
                                          collapsecomment={() => this.Collapsecomment(index)}
                                          downvote={() => this.downvotecall(index, i)} //cizzr 1.5 (NiraliBThummar 10Nov2021)
                                          cmntupvote={(cmtid) => this.cmntupvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                          cmntdownvote={(cmtid) => this.cmntdownvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                          deletepost={() => this.Deletepost(index, i)}//cizzr 3 (NiraliBThummar 11May2022)
                                          />
                                    ) : null}
                              </div>
                              {this.state.frienddiv &&
                                 <div className="tab-pane fade active show" id="friends" role="tabpanel">

                                    <div className="mt-1 iq-card iq-card-block iq-card-stretch iq-card-height home-card">
                                       <div className="iq-card-header friends-card-header d-flex justify-content-between border-0">
                                          <div className="iq-header-title">
                                             <h4 className="card-title">Friends</h4>
                                          </div>
                                          <div className="iq-search-bar members-search p-0 iq-friends-search">
                                             <div className="searchbox">
                                                <input type="text" className="text search-input bg-grey" maxLength={30} placeholder="Search Friends" value={this.state.searchtext}
                                                   onChange={(e) => this.searchuser(e)} />
                                                <a className="search-link" href="#"><i className="ri-search-line"></i></a>
                                             </div>
                                          </div>
                                       </div>
                                       {/* {this.state.FriendsReqList ?
                                          this.state.FriendsReqList.length > 0 ? */}
                                       <div className="iq-card-body ">
                                          <div className="iq-card noti-card shadow-none border" id="1">
                                             <div className="iq-card-body ">
                                                <ul className="notification-list friend-req-list m-0 p-0">
                                                   <li className="d-flex align-items-center friend-req-card justify-content-between" >
                                                      <div className="d-flex">
                                                         <a className=" noti-icon img-fluid friend-reqs" onClick={() => this.state.FriendsReqList ? this.state.FriendsReqList.length > 0 ? this.gotofriend(this.state.FriendsReqList, "Friend Requests") : {} : {}}>
                                                            {this.state.GpRes && this.state.GpRes.data &&
                                                               // <img src={this.state.GpRes.data.photo!=""?this.state.GpRes.data.photo:user1}  className="avatar-100 img-fluid" />
                                                               <Img className="rounded-circle avatar-45" src={encryptStorage.getItem("userpic")}
                                                                  loader={<img src={user1} className="rounded-circle avatar-45" />} unloader={<img src={user1} className="rounded-circle avatar-45" />} />
                                                            }
                                                            {this.state.FriendsReqList ? this.state.FriendsReqList.length > 0 ? <span className="req-count">{this.state.FriendsReqList.length}</span> : null : null}
                                                         </a>
                                                         <a className="media-support-info ml-3" onClick={() => this.state.FriendsReqList ? this.state.FriendsReqList.length > 0 ? this.gotofriend(this.state.FriendsReqList, "Friend Requests") : {} : {}}><h4>Friend Requests</h4>
                                                            <p className="mb-0 noti-time">Accept Or Reject The Requests</p></a>
                                                      </div>
                                                      <div className="d-flex align-items-center">
                                                         <div className="mr-3 "><a className="total-reqs" onClick={() => this.state.FriendsReqList ? this.state.FriendsReqList.length > 0 ? this.gotofriend(this.state.FriendsReqList, "Friend Requests") : {} : {}}>Total Requests ({this.state.FriendsReqList ? this.state.FriendsReqList.length : 0})<i className="ri-arrow-right-s-line"></i>
                                                         </a>
                                                         </div></div></li></ul></div>
                                          </div>
                                       </div>
                                       {/* : null : null} */}
                                    </div>

                                    {!this.state.searchtext || (this.state.searchtext && this.state.searchtext.length < 3) ?
                                       this.state.SuggestedFriendsList ?
                                          <div className="iq-card " id="1">
                                             <div className="iq-card-header d-flex justify-content-between border-0">
                                                <div className="iq-header-title">
                                                   <h4 className="card-title">Suggestions for you</h4>
                                                </div>

                                             </div>
                                             {this.state.SuggestedFriendsList.length > 0 ?
                                                <div className="iq-card-body ">
                                                   <ul className="request-list req-list list-inline m-0 p-0">
                                                      {this.state.SuggestedFriendsList.map((i, index) =>
                                                         i.friend_status == 2 && index < 3 &&
                                                         <li className="d-flex align-items-center " key={'sf' + index}>
                                                            <div className="user-img img-fluid cursor-pointer" onClick={() => this.gotoprofile(i._id)}>
                                                               <Img className="rounded-circle avatar-45" src={i.photo}
                                                                  loader={<img src={user1} className="rounded-circle avatar-45" />} unloader={<img src={user1} className="rounded-circle avatar-45" />} />
                                                            </div>
                                                            <div className="media-support-info suggestion-name ml-1">
                                                               <h5 className="cursor-pointer" onClick={() => this.gotoprofile(i._id)}>{i.name}</h5>
                                                               {i.username && <p className="mb-0">{i.username}</p>}
                                                               {i.mutual_friends_count > 0 && <p className='mb-0'><span className="cursor-pointer" onClick={() => this.openMutual(i._id, "SuggestedMutual")}>{i.mutual_friends_count}{i.mutual_friends_count > 1 ? ' Mutual Friends' : ' Mutual Friend'} </span></p>}
                                                            </div>
                                                            {this.state.suggestedprocess && index == this.state.frndindex ? null : <div className="d-flex align-items-center">
                                                               <button type="reset" className="btn add-btn submit-btn btn-primary ml-2" onClick={() => this.sendfriendrequest(i._id ? i._id : i.user_id, index, 'suggested')}>Add Friend</button>
                                                               <a className=" btn text-dark  rounded" onClick={() => this.removeele(index)}><i className='las la-times'></i></a>
                                                            </div>}
                                                         </li>)}
                                                   </ul>
                                                   {this.state.SuggestedFriendsList.length > 3 &&
                                                      <div className="col-lg-12">
                                                         <div className="d-block text-center mt-2 mb-3">
                                                            <button type="submit" className="mr-3 btn btn-primary" onClick={() => this.gotofriend(this.state.SuggestedFriendsList, "Suggested Friends")}>See All</button>
                                                         </div>
                                                      </div>
                                                   }
                                                </div> : <p className="no-data-found">No Suggestions</p>}
                                          </div>
                                          : null : null}


                                    {this.state.FriendsList ?
                                       <div className="mt-1 iq-card iq-card-block iq-card-stretch iq-card-height home-card">

                                          <div className="iq-card-header d-flex justify-content-between border-0 align-items-center">
                                             <div className="iq-header-title">
                                                {!this.state.searchtext || (this.state.searchtext && this.state.searchtext.length < 3) ?
                                                   <h4 className="card-title">My Friends {this.state.Tfriends>0 ? '(' + this.state.Tfriends + ')' : null}</h4>
                                                   : null}

                                             </div>
                                             <div className="dropdown">
                                                <ul className="share-drop m-0">
                                                   <li className="post-share">
                                                      <a className="share-btn ">
                                                         <span>Invite friends </span><i className="ri-share-line"></i>
                                                      </a>
                                                      <ul className="share-list share-list-new">
                                                         {/* {this.state.GpRes && this.state.GpRes.data && <span> {this.state.GpRes.data.web_invitation_link}</span>} */}
                                                         <li>
                                                            <CopyToClipboard onCopy={() => toast.success('Copied to clipboard', {
                                                               style: { fontSize: 14 },
                                                               autoClose: 3000
                                                            })} text={this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.web_invitation_link}>
                                                               <a>     <i className="ri-file-copy-line"></i></a>
                                                            </CopyToClipboard>
                                                         </li>
                                                         <EmailShareButton url={this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.web_invitation_link} subject="" body={""}>
                                                            <li><a className="color-messenger"><i className="fa fa-envelope-o"></i></a></li>
                                                         </EmailShareButton>
                                                         <FacebookShareButton url={this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.web_invitation_link} >
                                                            <li><a className="color-fb"><i className="fa fa-facebook"></i></a></li>
                                                         </FacebookShareButton>
                                                         <WhatsappShareButton url={this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.web_invitation_link} >
                                                            <li><a className="color-whatsapp"><i className="fa fa-whatsapp"></i></a></li>
                                                         </WhatsappShareButton>
                                                         <TwitterShareButton url={this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.web_invitation_link} >
                                                            <li><a className="color-twitter"><i className="fa fa-twitter"></i></a></li>
                                                         </TwitterShareButton>
                                                         <TelegramShareButton url={this.state.GpRes && this.state.GpRes.data && this.state.GpRes.data.web_invitation_link} >
                                                            <li><a className="color-telegram"><i className="fa fa-telegram"></i></a></li>
                                                         </TelegramShareButton>
                                                      </ul>
                                                   </li>
                                                </ul>
                                             </div>
                                          </div>
                                          {this.state.FriendsList.length > 0 ?
                                             <div className="iq-card-body row">
                                                {this.state.FriendsList.map((i, index) =>
                                                   index < 4 ?
                                                      <div className="col-md-6" key={'myfrnd' + index}>
                                                         <div className="iq-card iq-card-block iq-card-stretch iq-card-height home-card your-friend-card">
                                                            <div className="iq-card-body">
                                                               <div className="user-post-data">
                                                                  <div className="d-flex  ">
                                                                     <div className="media-support-user-img mr-3">
                                                                        <a onClick={() => this.gotoprofile(i._id ? i._id : i.user_id)}>
                                                                           <Img className="rounded-circle avatar-70" src={i.photo}
                                                                              loader={<img src={user1} className="rounded-circle avatar-70" />} unloader={<img src={user1} className="rounded-circle avatar-70" />} />
                                                                        </a>
                                                                     </div>
                                                                     <div className="media-support-info mt-2">
                                                                        <h5 className="mb-0 d-inline-block"><a onClick={() => this.gotoprofile(i._id ? i._id : i.user_id)}>{i.name}</a></h5>
                                                                        {i.username && <p>{i.username}</p>}
                                                                        {i.mutual_friends_count > 0 && <p className='mb-0'><span className="cursor-pointer" onClick={() => this.openMutual(i._id ? i._id : i.user_id, "FriendMutual")}>{i.mutual_friends_count}{i.mutual_friends_count > 1 ? ' Mutual Friends' : ' Mutual Friend'} </span></p>}
                                                                        {i.mutual_friends_count > 0 &&
                                                                           i.mutual_friends && i.mutual_friends.length > 0 ?
                                                                           <div className="group-member ">
                                                                              <div className="iq-media-group">
                                                                                 {i.mutual_friends.map(i =>
                                                                                    <a className="iq-media" onClick={() => this.gotoprofile(i.user_id)}>
                                                                                       <img className="img-fluid avatar-40 rounded-circle" src={i.photo} />
                                                                                    </a>)}
                                                                              </div>
                                                                           </div> : null}

                                                                     </div>

                                                                     <div className="iq-card-post-toolbar">
                                                                        <div className="dropdown" onClick={() => {
                                                                           this.props.history.push({ pathname: `/chat` });
                                                                           localStorage.setItem("chatobj", JSON.stringify(i)); localStorage.setItem("Fromprofiletochat", true); localStorage.setItem("Fromprofile", true); localStorage.setItem("searchtxt", this.state.searchtext)
                                                                        }}>
                                                                           <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                                                              <i className="ri-message-line"></i>
                                                                           </span>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div className="my-friend-btn">
                                                                     <div className="float-right">
                                                                        {this.state.process && index == this.state.frndindex ? null :
                                                                           i.friend_status == 2 ?
                                                                              <button type="reset" className="btn submit-btn btn-primary ml-2" onClick={() => this.sendfriendrequest(i._id ? i._id : i.user_id, index)}>Add Friend</button>
                                                                              : i.friend_status == 1 || (i.friend_status == 0 && i.friend_request_sent_by == 0) ?
                                                                                 <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status)}>{i.friend_status == 1 ? 'Remove' : 'Cancel Request'}</button>
                                                                                 : i.friend_status == 0 && i.friend_request_sent_by == 1 ?
                                                                                    <><button type="reset" className="btn add-btn submit-btn btn-primary mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 1, index, i.friend_status)}>Accept</button>
                                                                                       <button type="reset" className="btn add-btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status)}>Reject</button></>
                                                                                    : null}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      : null)}
                                                {this.state.FriendsList.length > 4 && <div className="col-lg-12">
                                                   <div className="d-block text-center mt-2 mb-3">
                                                      <button type="submit" className="mr-3 btn btn-primary" onClick={() => this.gotofriend(this.state.FriendsList, "Friends")}>See All</button>
                                                   </div>
                                                </div>}
                                             </div> :
                                             !this.state.searchtext || (this.state.searchtext && this.state.searchtext.length < 3) ?
                                                <p className="no-data-found">No Friends</p> : <p className="no-data-found">No Results</p>
                                          }
                                       </div>
                                       : <div className="col-sm-12 text-center" style={{ marginBottom: 10 }}>
                                          <img src={pageloadloader} style={{ height: 80 }} />
                                       </div>}

                                 </div>
                              }
                              {this.state.loading && <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                 <img src={pageloadloader} style={{ height: 80 }} />
                              </div>}
                              {!this.state.frienddiv && this.state.isnodata && <p className="no-data-found">{this.state.isnodata}</p>}
                           </div>
                        </div>
                        <Modal
                           classNames={{
                              overlay: "customOverlay",
                              modal: "customModal"
                           }}
                           open={this.state.openpost}
                           center
                           onClose={() => { }}
                           showCloseIcon={false}
                        >
                           {this.state.openpost ?
                              <div className="modal fade show create-post" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                 <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                       <div className="modal-header">
                                          <h5 className="modal-title" id="post-modalLabel">Edit Post</h5>
                                          <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ openpost: false, Error: "", Emotion: "", Content: "", openemotion: false, isAnonymous: false, selection: true })}><i className="ri-close-fill"></i></button>
                                       </div>
                                       <div className="modal-body">
                                          <div className="d-flex mb-2 ">
                                             <div className="user-img modal-image">
                                                {this.state.basicinfo && this.state.basicinfo.userData &&
                                                   // <img src={this.state.basicinfo.userData.photo}  className="avatar-60 rounded-circle img-fluid" />
                                                   // // {encryptStorage.getItem("username")}
                                                   // : <img src={user1}  className="avatar-60 rounded-circle img-fluid" />
                                                   <Img className="avatar-30 rounded-circle img-fluid" src={!this.state.isAnonymous ? this.state.basicinfo.userData.photo : user1}
                                                      loader={<img src={user1} className="avatar-30 rounded-circle img-fluid" />} unloader={<img src={user1} className="avatar-30 rounded-circle img-fluid" />} />
                                                }

                                             </div>
                                             <div className="user-img">
                                                <div className=" modal-emotion-section">
                                                   <h4 className="mb-0 line-height">
                                                      {!this.state.isAnonymous ? this.state.basicinfo && this.state.basicinfo.userData ?
                                                         this.state.basicinfo.userData.name
                                                         // {encryptStorage.getItem("username")}
                                                         : null : "Anonymous"}
                                                   </h4>
                                                   {this.state.Emotion && <p className="feeling-name">is feeling {this.state.basicinfo.emotionList.map(i => this.state.Emotion == i._id && <a onClick={() => this.setState({ openemotion: true })}><img src={i.icon} className="modal-emoji-upper" /> {i.name}</a>)} </p>}
                                                </div>
                                                <div>
                                                   {!this.state.Emotion && <div onClick={() => this.setState({ openemotion: true })}>
                                                      {this.state.basicinfo && this.state.basicinfo.emotionList ?
                                                         this.state.basicinfo.emotionList.length > 0 ?
                                                            <button className="opt-sel-emote emotions-select" value={this.state.Emotion}>
                                                               Select Emotion
                                                               {/* {this.state.Emotion ?
                                                                  this.state.basicinfo.emotionList.map(i => this.state.Emotion == i._id && i.name)
                                                                  : "Select Emotions"} */}
                                                               {/* <i className="ri-arrow-down-s-line"></i> */}
                                                            </button>
                                                            : null : null}
                                                   </div>}
                                                </div>
                                             </div>

                                          </div>
                                          {this.state.openemotion &&
                                             <div className="modal-emoji-slider">

                                                {this.state.basicinfo && this.state.basicinfo.emotionList ?
                                                   this.state.basicinfo.emotionList.length > 0 ?
                                                      // <select className="emotions-select" value={this.state.Emotion} onChange={(e) => this.setState({ Emotion: e.target.value, Error: "" })}>
                                                      //    <option value="">Emotions</option>
                                                      //    {this.state.basicinfo.emotionList.map(i => <option key={i._id} value={i._id}>{i.name}</option>)}
                                                      // </select>
                                                      <ul className="emoji-slider-ul d-flex flex-wrap align-items-center list-inline ">
                                                         {this.state.basicinfo.emotionList.map(i =>
                                                            <li className={i._id == this.state.Emotion ? "active emoji-slider-li  mb-2" : "emoji-slider-li  mb-2"}>
                                                               <div className="emoji-grid-list p-1 pointer mr-0 cursor-pointer" onClick={() => this.setState({ Emotion: this.state.Emotion == i._id ? "" : i._id, Error: "", openemotion: false, selection: false })}>
                                                                  <a className="slider-emoji-a">
                                                                     <img src={i.icon} /><p>{i.name} </p></a>
                                                               </div>

                                                            </li>)}
                                                      </ul>
                                                      : null : null}
                                             </div>
                                          }  <div className="" id="contact-form mt-2"  >
                                              <Mentiontextarea intialval={this.state.Content} setvalue={(val) => this.Setvalue(val)}/>
                                             {/* <TextareaAutosize maxLength={500} placeholder="tell us how you feel..." className="form-control rounded text-area" style={{ border: "none" }} value={this.state.Content} onChange={(e) => this.setState({ Content: e.target.value, Error: "" })} /> */}
                                             {/* <textarea type="text" className="form-control rounded" placeholder="tell us how you feel..." style={{ border: "none" }} rows="3" maxLength={500} value={this.state.Content} onChange={(e) => this.setState({ Content: e.target.value, Error: "" })}></textarea> */}
                                          </div>
                                          {this.state.Error && <p style={{ color: 'red', fontSize: 14 }}>{this.state.Error}</p>}<hr />
                                          <div className="modal-send-div">
                                             <div className="switch-main">
                                                <div className="switch-div"> <label htmlFor="checkbox">Post as private</label>
                                                   <input className="switch" type="checkbox" checked={this.state.isPrivate} onClick={() => this.Privatepost()} />  </div>
                                                <div className="switch-div"> <label htmlFor="checkbox">Post as anonymous</label>
                                                   <input className="switch" type="checkbox" defaultChecked={this.state.isAnonymous} onClick={() => this.setState({ isAnonymous: !this.state.isAnonymous })} />  </div>
                                             </div>
                                             <div className="post-btn"><button type="submit" className="btn btn-primary " onClick={() => this.postcontent()}>Post</button></div></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              : null}
                        </Modal>
                        <Modal
                           classNames={{
                              overlay: "customOverlay",
                              modal: "customModal"
                           }}
                           open={this.state.openMutual}
                           center
                           onClose={() => { }}
                           showCloseIcon={false}
                        >
                           {this.state.openMutual ?
                              <div className="modal fade show create-post" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                 <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                       <div className="modal-header">
                                          <h5 className="modal-title" id="post-modalLabel">Mutual Friends</h5>
                                          <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ openMutual: false, MutualfriendRes: null })}><i className="ri-close-fill"></i></button>
                                       </div>
                                       <div className="modal-body">
                                          {this.state.MutualfriendRes ?
                                             this.state.MutualfriendRes.length > 0 ?
                                                this.state.MutualfriendRes.map((i, index) =>
                                                   <div className="who-has-liked" key={'mf' + i._id}>
                                                      <div className="d-flex">
                                                         <Img
                                                            className="cursor-pointer rounded-circle img-fluid user-like-img"
                                                            src={i.photo}
                                                            loader={<img src={user1} className="rounded-circle img-fluid user-like-img cursor-pointer" />}
                                                            unloader={<img src={user1} className="rounded-circle img-fluid user-like-img cursor-pointer" />}
                                                            onClick={() => this.gotoprofile(i.user_id)}
                                                         />
                                                         {/* {i.photo!=""?<img className="rounded-circle img-fluid user-like-img" src={i.photo}  />:<img className="rounded-circle img-fluid user-like-img" src={user1}  />} */}
                                                         <div> <p className="user-like-name cursor-pointer" onClick={() => this.gotoprofile(i.user_id)}>{i.name}</p>
                                                            <p className="user-like-name  font-size-12">{i.username}</p>
                                                         </div>
                                                      </div>
                                                      <div className="my-friend-btn">
                                                         <div className="float-right">
                                                            {this.state.mutualprocess && index == this.state.frndindex ? null :
                                                               i.friend_status == 2 ?
                                                                  <button type="reset" className="btn add-btn submit-btn btn-primary ml-2" onClick={() => this.sendfriendrequest(i._id ? i._id : i.user_id, index, "mutual")}>Add Friend</button>
                                                                  : i.friend_status == 1 || (i.friend_status == 0 && i.friend_request_sent_by == 0) ?
                                                                     <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status, "mutual")}>{i.friend_status == 1 ? 'Remove' : 'Cancel Request'}</button>
                                                                     : i.friend_status == 0 && i.friend_request_sent_by == 1 ?
                                                                        <><button type="reset" className="btn submit-btn btn-primary mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 1, index, i.friend_status, "mutual")}>Accept</button>
                                                                           <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status, "mutual")}>Reject</button></>
                                                                        : null}
                                                         </div>
                                                      </div>
                                                   </div>)
                                                : <p className="no-data-found">No Mutual friends</p>
                                             : this.state.mutualloader ?
                                                <div className="col-sm-12 text-center">
                                                   <img src={pageloadloader} style={{ height: 50 }} />
                                                </div> : null}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              : null}
                        </Modal>
                     </div>
                  </div>
               </div>
               {((localStorage.getItem('guideflag') == "true" && localStorage.getItem('guideprofileflag')=="true") ||this.state.openpost || this.state.openMutual) && <div className="modal-backdrop fade show"></div>}
               
            </div>
         </>)
   }
}
const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => { dispatch(dispatchLoadingStatus(loading)); },
      MyPostAPI: (postdata) => { dispatch(MyPostAPI(postdata)) },
      SavedPostAPI: (postdata) => { dispatch(SavedPostAPI(postdata)) },
      GetProfileAPI: () => { dispatch(GetProfileAPI()) },
      CommentListAPI: (postdata) => { dispatch(CommentListAPI(postdata)) },
      SavePostAPI: (postdata) => { dispatch(SavePostAPI(postdata)) },
      RemoveFromSaveAPI: (postdata) => { dispatch(RemoveFromSaveAPI(postdata)) },
      UPVoteAPI: (postdata) => { dispatch(UPVoteAPI(postdata)) },
      DownVoteAPI: (postdata) => { dispatch(DownVoteAPI(postdata)) },
      CommentAPI: (postdata) => { dispatch(CommentAPI(postdata)) },
      PostAPI: (postdata) => { dispatch(PostAPI(postdata)) },
      DeletePostAPI: (postdata) => { dispatch(DeletePostAPI(postdata)) },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
      UPVoteCmtAPI: (postdata) => { dispatch(UPVoteCmtAPI(postdata)) },
      DownVoteCmtAPI: (postdata) => { dispatch(DownVoteCmtAPI(postdata)) },
      FriendListAPI: (postdata) => { dispatch(FriendListAPI(postdata)) },
      FriendReqListAPI: (postdata) => { dispatch(FriendReqListAPI(postdata)) },
      AllUserAPI: (data) => { dispatch(AllUserAPI(data)) },
      AddFriendAPI: (postdata) => { dispatch(AddFriendAPI(postdata)) },
      AcceptRejectFriendAPI: (postdata) => { dispatch(AcceptRejectFriendAPI(postdata)) },
      FriendSuggestedAPI: (postdata) => { dispatch(FriendSuggestedAPI(postdata)) },
      MutualFriendAPI: (postdata) => { dispatch(MutualFriendAPI(postdata)) },
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      SPdata: state.globalReducer.SavedPostRes,
      MPdata: state.globalReducer.MyPostRes,
      GpRes: state.globalReducer.GetProfileRes,
      commentlist: state.globalReducer.CommentListRes,
      savePost: state.globalReducer.SavePostRes,
      // votePost: state.globalReducer.VoteRes,
      editpostres: state.globalReducer.PostRes,
      deletePostRes: state.globalReducer.DeletePostRes,
      friendListRes: state.globalReducer.FriendListRes,
      friendReqListRes: state.globalReducer.FriendReqListRes,
      alluserRes: state.globalReducer.AllUserRes,
      addFriendRes: state.globalReducer.AddFriendRes,
      accrejFriendRes: state.globalReducer.AcceptRejectFriendRes,
      suggestedFriendRes: state.globalReducer.FriendSuggestedRes,
      mutualfriendRes: state.globalReducer.MutualFriendRes
   }
}
export default connect(mapStateToProps, mapDispatchToProps)((Profile))

