import React from "react";
import { connect } from "react-redux";
import logo from "../asset/images/logo.svg";
import user1 from "../asset/images/user1.svg";
import swal from 'sweetalert';
import { basepath, devicetype } from "../constants";
import { dispatchLoadingStatus, encryptStorage } from '../utils/utils';
import { useHistory } from 'react-router-dom';
import { LogoutAPI } from "../actions/globalactions";
import { useDispatch } from "react-redux";
import { Img } from 'react-image';

const Navbar = (props) => {
   const history = useHistory();

   const dispatch = useDispatch();


   function logout() {

      swal({
         title: "",
         text: "Are you sure you want to logout?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               finallogout()
            } else {
            }
         });

   }

   function finallogout() {
      dispatch(dispatchLoadingStatus(true));
      var Token = encryptStorage.getItem('FCM_Token');
      dispatch(LogoutAPI({
         "fcm_token": Token ? Token : "",
         "device_type": devicetype
      }));
   }


   return (
      <div className="iq-top-navbar">
         <div className="iq-navbar-custom">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
               <div className="iq-navbar-logo d-flex justify-content-between">
                  <a href={`/home`}>
                     <img src={logo} className="img-fluid" />
                  </a>
                  <div className="iq-menu-bt align-self-center">
                     <div className="wrapper-menu">
                        <div className="main-circle"><i className="ri-menu-line"></i></div>
                     </div>
                  </div>
               </div>

               {/* <div className="iq-search-bar">

                           <div action="#" className="searchbox">
                              <input type="text" className="text search-input" placeholder="tell us what you are looking for" />
                              <a className="search-link" ><i className="ri-search-line"></i></a>
                           </div>
                        </div> */}

               <div className="navbar right-profile" id="navbarSupportedContent">

                  <ul className="navbar-list">

                     <li className="profile-li">
                        <a className="search-toggle iq-waves-effect d-flex align-items-center"  onClick={()=>history.push(`/profile`)}>
                           {/* {props.profile&&props.profile.photo?<img src={props.profile.photo} className="img-fluid rounded-circle mr-3" />
                                    :encryptStorage.getItem("userpic")?
                                       <img src={encryptStorage.getItem("userpic")} className="img-fluid rounded-circle mr-3" />
                                       // {encryptStorage.getItem("encryptStorage.getItem("username")")}
                                       : <img src={user1} className="img-fluid rounded-circle mr-3" />} */}

                           {props.profile && props.profile.photo ?
                              <img className="img-fluid rounded-circle mr-3" src={props.profile.photo ? props.profile.photo : user1} />
                              : <img className="img-fluid rounded-circle mr-3" src={encryptStorage.getItem("userpic") ? encryptStorage.getItem("userpic") : user1} />}


                           <div className="caption">

                              {props.profile ?
                                 props.profile.name ?
                                    <h6 className="mb-0 line-height">{props.profile.name}</h6>
                                    : <h6 className="mb-0 line-height">{encryptStorage.getItem("myname")}</h6>
                                 : <h6 className="mb-0 line-height">{encryptStorage.getItem("myname")}</h6>
                              }


                              {props.profile ?
                                 props.profile.username ?
                                    <span className=" font-size-12" style={{ color: '#9a9a9a' }}>{props.profile.username}</span>
                                    : <span className=" font-size-12" style={{ color: '#9a9a9a' }}>{encryptStorage.getItem("username")}</span>
                                 : <span className=" font-size-12" style={{ color: '#9a9a9a' }}>{encryptStorage.getItem("username")}</span>
                              }

                           </div>
                           <i className="ri-arrow-down-s-fill ml-2 profile-arrow"></i>
                        </a>
                        <div className="iq-sub-dropdown iq-user-dropdown">
                           <div className="iq-card shadow-none m-0">
                              <div className="iq-card-body pl-0 pb-0 ">
                                 <div className=" p-3 line-height d-flex profile-drop-div align-items-center" onClick={()=>history.push(`/profile`)}>
                                    <div className="profile-edit-div">
                                       {/* {props.profile&&props.profile.photo?<img src={props.profile.photo} className="img-fluid rounded-circle mr-3" />
                                    :encryptStorage.getItem("userpic")?
                                       <img src={encryptStorage.getItem("userpic")} className="img-fluid rounded-circle mr-3"  />
                                       // {encryptStorage.getItem("encryptStorage.getItem("username")")}
                                                   : <img src={user1} className="img-fluid rounded-circle mr-3"  />} */}
                                       {props.profile && props.profile.photo ?
                                          <img className="img-fluid rounded-circle mr-3" src={props.profile.photo ? props.profile.photo : user1} />
                                          : <img className="img-fluid rounded-circle mr-3" src={encryptStorage.getItem("userpic") ? encryptStorage.getItem("userpic") : user1} />
                                          //    <Img
                                          //    className="img-fluid rounded-circle mr-3"
                                          //           src={props.profile.photo}  
                                          //           loader={<img className="img-fluid rounded-circle mr-3" src={user1}/>}  
                                          //   unloader={<img className="img-fluid rounded-circle mr-3" src={user1}/>}
                                          //         />:
                                          //         <Img
                                          //         className="img-fluid rounded-circle mr-3"
                                          //         src={encryptStorage.getItem("userpic")}
                                          //         loader={<img className="img-fluid rounded-circle mr-3" src={user1}/>}  
                                          // unloader={<img className="img-fluid rounded-circle mr-3" src={user1}/>}
                                          //       />
                                       }
                                    </div>
                                    <div className="profile-content-div">
                                       <h5 className="mb-0  line-height">{props.profile && props.profile.name ?
                                          props.profile.name
                                          : encryptStorage.getItem("myname")}</h5>

                                       {props.profile && props.profile.username ?
                                          <span className=" font-size-12">{props.profile.username} </span>
                                          : encryptStorage.getItem("username") ?
                                             <span className=" font-size-12">{encryptStorage.getItem("username")} </span>
                                             : null}

                                    </div>
                                 </div>

                                 <a className="iq-sub-card iq-bg-danger-hover" href={`/editprofile`}>
                                    <div className="media align-items-center">
                                       <div className="rounded iq-card-icon ">
                                          <i className="ri-edit-fill"></i>
                                       </div>
                                       <div className="media-body ml-3">
                                          <h6 className="mb-0 ">Edit Profile</h6>

                                       </div>
                                    </div>
                                 </a>
                                 <a className="iq-sub-card iq-bg-danger-hover" href={`/setting`}>
                                    <div className="media align-items-center">
                                       <div className="rounded iq-card-icon ">
                                          <i className="ri-settings-2-fill"></i>
                                       </div>
                                       <div className="media-body ml-3">
                                          <h6 className="mb-0 ">Settings</h6>

                                       </div>
                                    </div>
                                 </a>
                                 <a onClick={() => logout()} className="iq-sub-card iq-bg-danger-hover">
                                    <div className="media align-items-center">
                                       <div className="rounded iq-card-icon">
                                          <i className="ri-login-box-line "></i>
                                       </div>
                                       <div className="media-body ml-3">
                                          <h6 className="mb-0 " >Log out</h6>

                                       </div>
                                    </div>
                                 </a>

                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   )
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      // LogoutAPI: (postdata) => { dispatch(LogoutAPI(postdata)) },
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      basicinfo: state.globalReducer.BasicInfoRes,
   }
}
export default connect(mapStateToProps, mapDispatchToProps)((Navbar))