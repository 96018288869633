import React from "react";
import { connect } from "react-redux";
import flogo from "../asset/images/logo.svg";
import { basepath, devicetype, errormsg } from "../constants";
import { dispatchLoadingStatus, SecureEmail, isValidNumber, encryptStorage } from '../utils/utils';
import { RegistrationVerifyAPI, ForgotPassVerifyAPI, RegistrationAPI, ForgotPassAPI } from "../actions/globalactions";
import swal from 'sweetalert';
import Loader from "../Component/Loader";
import img2 from "../asset/images/sign-bg.png";
import Slider from "./Slider"
import Footer from "./Footer";

class OTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      one: '',
      two: '',
      three: '',
      four: ''
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (encryptStorage.getItem("ISOTP") == "true") {
      window.location.reload();
      encryptStorage.removeItem("ISOTP");
    }
    var token = encryptStorage.getItem("cizzr_token")

    if (!token) {
      // swal({text: tokenexpire, icon: "error",className:"error-modal"});
      // this.props.history.push(`/signin`);
      if (this.props.regReq) {
        console.log(this.props.regReq)
        encryptStorage.setItem('reginfo', this.props.regReq)
        encryptStorage.setItem('regemail', this.props.regReq.email)
      }
    } else {
      this.props.history.push(`/home`);
    }
  }


  EnterPressCall = (e) => {
    if (e.charCode == 13 || e.which == 13) {
      this.Validation()
    }
  }


  handleChange(event) {
    if(localStorage.getItem("Registrationprocess")=="true" || localStorage.getItem("Forgotpassprocess")=="true"
    || localStorage.getItem("ForgotPassVerifyprocess")=="true" || localStorage.getItem("RegistrationVerifyprocess")=="true"){
    }else{
    if (isValidNumber(event.target.value)) {
      this.setState({
        [event.target.id]: event.target.value,
        Error: ""
      });
      if (event.target.id == 'one') {
        this.refs.two.focus()
      } else if (event.target.id == 'two') {
        this.refs.three.focus()
      } else if (event.target.id == 'three') {
        this.refs.four.focus()
      }
    } else {
      this.setState({
        [event.target.id]: ""
      });
    }

    if (event.target.value == "") {
      if (event.target.id == 'two') {
        this.refs.one.focus()
      } else if (event.target.id == 'three') {
        this.refs.two.focus()
      } else if (event.target.id == 'four') {
        this.refs.three.focus()
      }
    }
  }
  }
  componentWillReceiveProps(nextprops) {

    if (nextprops.otpRes != null) {
      if (nextprops.otpRes !== this.props.otpRes) {

        // this.props.history.push(`/home`);
      }
    }
    if (nextprops.otpFRes != null) {
      if (nextprops.otpFRes !== this.props.otpFRes) {

        this.props.history.push(`/resetpassword`);
      }
    }

  }

  resendCode(remaining) {

    var forgot_pass = encryptStorage.getItem('forgot_pass');
    if (!forgot_pass) {
      var Token = encryptStorage.getItem('FCM_Token');
      if (this.props.regReq) {
        this.props.isLoading(true);
        const postdata = {
          "name": this.props.regReq.name,
          "email": this.props.regReq.email,
          "country_code": this.props.regReq.phone ? this.props.regReq.country_code : "",
          "phone": this.props.regReq.phone,
          "dob": this.props.regReq.dob,
          "password": this.props.regReq.password,
          "fcm_token": Token ? Token : "",
          "device_type": devicetype,
          "username":this.props.regReq.username,
        }
        if(localStorage.getItem("Registrationprocess")=="true"){
        }else{
        this.props.RegistrationAPI(postdata)
        localStorage.setItem("Registrationprocess",true)
        }
      }
    } else {
      this.props.isLoading(true);
      var femail = encryptStorage.getItem("Femail")
      const postdata = {
        "email": femail,
        "device_type": devicetype
      }
      if(localStorage.getItem("Forgotpassprocess")=="true"){
      }else{
      this.props.ForgotPassAPI(postdata)
      localStorage.setItem("Forgotpassprocess",true)
      }
    }
    this.Countdown(remaining)
  }
  Countdown(remaining) {
    let timerOn = true;
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    //document.getElementById('timer').innerHTML = m + ':' + s;
    if (m == 0 && s == 0) {
      this.setState({ timer: '' })
    } else {
      this.setState({ timer: m + ':' + s })
    }

    remaining -= 1;

    if (remaining >= 0 && timerOn) {
      setTimeout(() => {
        this.Countdown(remaining);
      }, 1000);
      return;
    }

    if (!timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    //alert('Timeout for otp');
  }

  Validation() {

    if (this.state.one && this.state.two && this.state.three && this.state.four) {
      let otp = this.state.one + this.state.two + this.state.three + this.state.four
      // alert("Done   "+otp);
      var forgot_pass = encryptStorage.getItem('forgot_pass');
      if (!forgot_pass) {
        var Token = encryptStorage.getItem('FCM_Token');

        if (this.props.regReq) {
          this.props.isLoading(true);
          const postdata = {
            "name": this.props.regReq.name,
            "email": this.props.regReq.email,
            // "username":this.props.regReq.username,
            "phone": this.props.regReq.phone,
            "dob": this.props.regReq.dob,
            "password": this.props.regReq.password,
            "fcm_token": Token ? Token : "",
            "device_type": devicetype,
            "otp": otp,
            "country_code": this.props.regReq.phone ? this.props.regReq.country_code ? this.props.regReq.country_code : "" : "",
        "reference_id":this.props.regReq.reference_id?this.props.regReq.reference_id:""
          } 
          if(localStorage.getItem("RegistrationVerifyprocess")=="true"){
          }else{
          this.props.RegistrationVerifyAPI(postdata)
          localStorage.setItem("RegistrationVerifyprocess",true)
          }
        } else {
          var regReq = encryptStorage.getItem('reginfo');
          if(regReq){
            this.props.isLoading(true);
            const postdata = {
              "name": regReq.name,
              "email": regReq.email,
              // "username":regReq.username,
              "phone": regReq.phone,
              "dob": regReq.dob,
              "password": regReq.password,
              "fcm_token": Token ? Token : "",
              "device_type": devicetype,
              "otp": otp,
              "country_code": regReq.phone ? regReq.country_code ? regReq.country_code : "" : "",
              "reference_id":regReq.reference_id?regReq.reference_id:""
            }
            if(localStorage.getItem("RegistrationVerifyprocess")=="true"){
            }else{
            this.props.RegistrationVerifyAPI(postdata)
            localStorage.setItem("RegistrationVerifyprocess",true)
            }
          }          
          // swal({text: errormsg, icon: "error",className:"error-modal"});
        }
      }
      else {
        debugger
        if(localStorage.getItem("ForgotPassVerifyprocess")=="true"){
        }else{
        var mail = encryptStorage.getItem("Femail");
        if (mail) {
          this.props.isLoading(true);
          const postdata = {
            // "email":this.props.regReq.email,
            "email": encryptStorage.getItem("Femail"),
            "device_type": devicetype,
            "otp": otp
          }
          // alert("ForgotPassVerifyAPI success");
         
          this.props.ForgotPassVerifyAPI(postdata);
          localStorage.setItem("ForgotPassVerifyprocess",true)
          
        }
        else {
          // swal({text: errormsg, icon: "error",className:"error-modal"});
        }
      }
      }
    } else {
      this.setState({ Error: "Please enter OTP" })
    }
  }


  render() {

    let regemail = encryptStorage.getItem('regemail');
    let forgot_pass = encryptStorage.getItem('forgot_pass');
    let fmail = encryptStorage.getItem('Femail');
    return (
      <>
        <Loader />
        <section className="sign-in-page new-sign-in  overlay-bg">

          <div className="container-fluid p-0">
            <div className="row no-gutters">
            <div className="col-lg-7  col-md-12 sm-displaynone">
                <div className="image-signin overlay-bg">
                <div className="overlay-homeslider"></div>
                  <div className="slider-text">
        <h3>A new twist on social media.<br /> Cutting the stigma around mental health. </h3>
                  </div>
              
                  <img src={img2} className="img-fluid mb-2" />
                </div>
              </div>
              <div className="col-lg-5 col-md-12  align-self-center ">
                <div className="sign-in-main">
                  <div className="sign-in-from">
                    <div className="sigin-logo-div"><img src={flogo}></img>
                      {/* <h1>Let's Cut The Stigma</h1> */}
                    </div>
                    <h1 className="signin-h1 mb-0">Enter OTP</h1>
                    <p>Enter the verification code sent to
                      {!forgot_pass ? regemail ? regemail ? " " + SecureEmail(regemail) : " your email " : " your email " :
                        " " + SecureEmail(fmail)}
                      {/* {this.props.forgetPReq ? this.props.forgetPReq.email ? SecureEmail(this.props.forgetPReq.email) : " your email " : " your email "} */}
                    </p>
                    <div className="mt-4 row ">
                      <div className="col-md-12">
                        <div className="form-group row ">

                          <div className="col-md-2 col-2">
                            <input type="text" ref='one' className="form-control mb-0 text-center" maxLength="1" id="one" value={this.state.one} onChange={this.handleChange} onKeyPress={(e) => this.EnterPressCall(e)} />
                          </div>
                          <div className="col-md-1 col-1">
                            <div className="opt-label"><label>-</label></div>
                          </div>
                          <div className="col-md-2 col-2">
                            <input type="text" ref='two' className="form-control mb-0 text-center" maxLength="1" id="two" value={this.state.two} onChange={this.handleChange} onKeyPress={(e) => this.EnterPressCall(e)} />
                          </div>
                          <div className="col-md-1 col-1">
                            <div className="opt-label"><label>-</label></div>
                          </div>
                          <div className="col-md-2 col-2">
                            <input type="text" ref='three' className="form-control mb-0 text-center" maxLength="1" id="three" value={this.state.three} onChange={this.handleChange} onKeyPress={(e) => this.EnterPressCall(e)} />
                          </div>
                          <div className="col-md-1 col-1">
                            <div className="opt-label"><label>-</label></div>
                          </div>
                          <div className="col-md-2 col-2">
                            <input type="text" ref='four' className="form-control mb-0 text-center" maxLength="1" id="four" value={this.state.four} onChange={this.handleChange} onKeyPress={(e) => this.EnterPressCall(e)} />
                          </div>


                        </div>
                      </div>
                      {this.state.Error && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.Error}</p>}

                      <div className="col-md-11 col-11">
                        <div className="d-inline-block w-100">
                          <div className=" d-inline-block mt-2 pt-1">
                            {/* resendCode */}
                            {this.state.timer ? <div className="forget-link"> <a className="forget">Time left : {this.state.timer}</a></div>
                              :
                              <a onClick={() => this.resendCode(120)} className="float-left">Resend Code</a>}
                          </div>
                          <button type="submit" className="btn btn-primary float-right" onClick={() => this.Validation()}>Verify</button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="sign-info border-0">
                          <span className="dark-color d-inline-block line-height-2">Don't have an account? <a href={`/signup`}>Sign up</a></span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer show={true}/>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
    },
    RegistrationAPI: (postdata) => { dispatch(RegistrationAPI(postdata)) },
    ForgotPassAPI: (postdata) => { dispatch(ForgotPassAPI(postdata)) },
    RegistrationVerifyAPI: (postdata) => { dispatch(RegistrationVerifyAPI(postdata)) },
    ForgotPassVerifyAPI: (postdata) => { dispatch(ForgotPassVerifyAPI(postdata)) }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading,
    regReq: state.globalReducer.RegistrationReq,
    regRes: state.globalReducer.RegistrationRes,
    otpRes: state.globalReducer.OTPRegistrationRes,
    otpFRes: state.globalReducer.OTPForgotPassRes,
    fres: state.globalReducer.ForgotPassRes
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OTP);