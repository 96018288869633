import React from "react";
import { connect } from "react-redux";
import Header from './Header';
import Footer from './Footer';
import parse from "html-react-parser";
import { PageDetailsAPI } from "../actions/globalactions";
import pageloadloader from "../asset/images/page-load-loader.gif";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

class Help extends React.Component {

  componentDidMount() {
    this.props.PageDetailsAPI({
      "slug": "help"
    });
  }

  render() {
    return (
      <>
        <Header />
        <section className="main-section privacy-policy-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="privacy-policy-title" style={{cursor:'pointer'}} onClick={()=>this.setState({contact:false})}> Help</h4>
                <span style={{marginLeft:20}}></span>
                <h4  style={{cursor:'pointer'}} onClick={()=>this.props.history.push(`/contact`)}> Contact us</h4>
              </div>
              <div className="col-lg-12">
                {this.props.pageres ?
                  this.props.pageres.data &&
                    this.props.pageres.data.body ? parse(this.props.pageres.data.body) :
                    <p className="no-data-found">No Data Found</p> :
                  <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                    <img src={pageloadloader} style={{ height: 80 }} />
                  </div>}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    PageDetailsAPI: (data) => { dispatch(PageDetailsAPI(data)) }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    pageres: state.globalReducer.PageDetailsRes
  }
}
export default connect(mapStateToProps, mapDispatchToProps)((Help))