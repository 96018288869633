import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { connect } from "react-redux";
import { LogFEvent } from '../firebase'
import Loader from "./Loader";
import img2 from "../asset/images/sign-bg.png";
import { devicetype, TC_URL } from "../constants";
import { dispatchLoadingStatus, isValidNumber, isValidName, Validatepass, encryptStorage } from '../utils/utils';
import { RegistrationAPI, ProfilePicAPI, RemoveProfileAPI , BasicCountAPI} from "../actions/globalactions";
import moment from "moment";
import flogo from "../asset/images/logo.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import swal from 'sweetalert';
import Slider from "./Slider"
import Footer from "./Footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import user1 from "../asset/images/user1.svg";
import Modal from "react-responsive-modal";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useHistory } from 'react-router-dom';
import Navbar from './Navbar';

toast.configure()

function SignupDetails() {

  const [Photo, setPhoto] = useState("");
  const [placeholderflag, setplaceholderflag] = useState("true");
  const [upImg, setUpImg] = useState();
  const [imageError, setimgError] = useState("");
  const [opencrop, setopencrop] = useState(false);
  const [selfile, setfile] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const previewCanvasRef = useRef(null);
  const [username, setuserName] = useState("");
  const [UsernameError, setUsernameError] = useState("");

  const [finalimg, setfinalimg] = useState();


  const history = useHistory();
  const dispatch = useDispatch();
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);


  useEffect(() => {

    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);



  function Checkusername(e) {
    // debugger
    var uname = e.target.value.toString().toLowerCase();
    var userregex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9_.]+$/;
    var moredot = /^(?!.*?\.\.)/;
    if (uname) {
      if (uname.trim() == "") {
        setuserName(uname.trim())
      } else {
        setuserName(uname)
        setUsernameError("")
        if (!userregex.test(uname)) {
          if (uname.charAt(0) == ".") {
            setUsernameError("You can't start your username with a dot.")
          } else if (uname.charAt(uname.length - 1) == ".") {
            setUsernameError("You can't end your username with a dot.")
          } else if (!moredot.test(uname)) {
            setUsernameError("You can't use your username with consecutive dots.")
          } else {
            setUsernameError("Username can only use letters, numbers, underscores and dots.");
          }
        }
      }
    } else {
      setuserName("");
      setUsernameError("")
    }
  }


  function updateProfile() {
    // var isvalidnum =true;
    // if(phone != ''){
    //   isvalidnum = Ccode!=phone&&Validationnum(phone)
    // }


    var userregex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9_.]+$/;
    var userregex2 = /^[0-9]+$/;


    if (!username) {
      setUsernameError("Please enter your Username.");// Phase 3
    } else if (username && UsernameError != "") {
    } else if (username && (username.length < 6 || username.length > 30)) {
      setUsernameError("Username must be between 6 to 30 characters.");
    } else if (username && userregex2.test(username)) {
      setUsernameError("Username cannot contain only numbers.");
    } else if (username && !userregex.test(username)) {
      setUsernameError("Username can only use letters, numbers, underscores and dots.");
    }


    else {

      //  debugger
       dispatch(dispatchLoadingStatus(true))

      // let form_data = new FormData();


      //  blob11.append("photo", blob11);

      let form_data = new FormData();
      if (finalimg) {
        form_data.append("photo", finalimg);
      }
      form_data.append("username", username);

      if (localStorage.getItem("updateprocess") == "true") { } else {
        dispatch(ProfilePicAPI(form_data));



        // setsaveIntrest(false)
      }

    }
  }

  function previousPage() {
    history.goBack()
  }


  const GpRes = useSelector(state => state.globalReducer.GetProfileRes);

  useEffect(() => {
    GpRes && setPhoto(GpRes.data.photo)
  }, [Photo])


  useEffect(async () => {
    if (GpRes != null) {
      setPhoto(await GpRes.data.photo);

      setplaceholderflag(await GpRes.data.placeholder)

      if (GpRes.data.username) {
        setuserName(await GpRes.data.username.toLowerCase());
      }

    }
  }, [GpRes])


  // useEffect(() => {
  //   if (GpRes) {
  //     history.push('/home')
  //   }

  // }, [GpRes])

  useEffect(() => {
    debugger
    var token = encryptStorage.getItem("username_required")
    if (!token && localStorage.getItem('askfortourflag')!="true") {
      history.push(`/home`);
   }
  },[])




  useEffect(() => {
    // console.log(placeholderflag);
  }, [placeholderflag])

  function uploadprofilepic(canvas, crop) {

    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {


        if (blob) {
          setfinalimg(blob)

        }
        else {

          setfinalimg(selfile)

        }



        setPhoto(URL.createObjectURL(selfile));
        setPhoto(URL.createObjectURL(blob));
        setplaceholderflag(true)

        setopencrop(false);

      },
      'image/png',
      1
    );


  }


  function removeprofile() {
    swal({
      title: "",
      text: "Remove profile photo?",
      icon: "warning",
      buttons: [true, "Remove"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          //  dispatch(RemoveProfileAPI())
          setPhoto(null)
          setplaceholderflag("true")

        }
      });

  }

  function handleChange(e) {
    setimgError("");
    // console.log(e.target.files);
    if (e.target.files) {
      var file = e.target.files
      if (file && file.length > 0) {
        if (file[0].name.toLowerCase().includes("jpg") ||
          file[0].name.toLowerCase().includes("png") ||
          file[0].name.toLowerCase().includes("jpeg")) {
          setopencrop(true)
          if (e.target.files && e.target.files.length > 0) {
            setfile(e.target.files[0])
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);

          }
          // let form_data = new FormData();
          // form_data.append("photo", file[0]);
          // dispatch(dispatchLoadingStatus(true));
          // dispatch(ProfilePicAPI(form_data));



        } else {
          setimgError("The photo must be a file of type: jpg, png, jpeg.")
        }
      }
    }
  }


  return (
    <>
      <Loader />

      <section className="sign-in-page new-sign-in  overlay-bg">

        <div className="container-fluid p-0">
          <div className="row no-gutters align-items-center">
            <div className="col-lg-7  col-md-12 sm-displaynone">
              <div className="image-signin  overlay-bg">
                <div className="overlay-homeslider"></div>
                <div className="slider-text">
                  <h3>A new twist on social media.<br /> Cutting the stigma around mental health. </h3>
                </div>

                <img src={img2} className="img-fluid mb-2" />
              </div>
            </div>
            <div className="col-lg-5 col-md-12  align-self-center ">

              <div className="sign-in-main">

                <div className="sign-in-from ">
                  <div className="sigin-logo-div"><img src={flogo}></img>
                    {/* <h1>Let's Cut The Stigma</h1> */}
                  </div>
                  {/* <h1 class="signin-h1 mb-0"> Signup Details</h1> */}
                  <h1 class="signin-h1 mb-0 text-center">Add Username</h1>

                  <div className="pt-3 row">
                    <div className="col-md-12">
                      <div className="profile-img-edit"  >

                        <img className="profile-pic" src={Photo ? Photo : user1} />

                        <div className="p-image">
                          <label className="cursor-pointer dropdown dropdown-ul toggle"><i className="fa fa-pencil upload-button"></i>

                            <input id="t1" type="checkbox" />

                            <ul >

                              <li><label htmlFor="imagepic">Upload Photo</label></li>
                              {placeholderflag != "true" && <li><label onClick={() => removeprofile()}>Remove Photo</label></li>}



                            </ul></label>
                          <input id="imagepic" type="file" name="Image" onClick={(event) => { event.target.value = null }} onChange={(e) => handleChange(e)} style={{ display: 'none' }} />

                        </div>
                      </div>{imageError && <p style={{ color: 'red', fontSize: 14, textAlign: 'center' }}>{imageError}</p>}
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputun">Username </label>
                        <input type="text" className="form-control mb-0" placeholder="enter username " value={username} onChange={(e) => Checkusername(e)} id="exampleInputun" />
                        {UsernameError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{UsernameError}</p>}

                      </div>
                    </div>

                    <div className="col-md-12 m-auto d-block text-center">
                      <div className="edit-button-sec mt-2">
                        {/* <button type="reset" className="btn   cancel-btn mr-2" onClick={previousPage}>Cancel</button> */}
                        <button type="submit" className="btn   submit-btn btn-primary " onClick={updateProfile} > Submit </button>
                      </div>


                    </div>



                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer show={true} />


      <Modal
        classNames={{
          overlay: "customOverlay",
          modal: "customModal"
        }}
        open={opencrop}
        center
        onClose={() => { }}
        showCloseIcon={false}
      >


        {opencrop &&
          <div className="modal fade show create-post" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body" style={{ textAlign: 'center' }}>

                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                  <div style={{ display: 'none' }}>
                    <canvas
                      ref={previewCanvasRef}
                      // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                      style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0)
                      }}
                    />
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button type="reset" className="btn  cancel-btn mr-2 mb-2" onClick={() => setopencrop(false)}>Cancel</button>
                  <button type="submit" className="btn  submit-btn btn-primary mb-2" onClick={() =>
                    uploadprofilepic(previewCanvasRef.current, completedCrop)
                  } >Update</button>
                </div>
              </div>
            </div>

          </div>
        }
      </Modal>


      <div className="col-md-12 m-auto d-block text-center">
        <div className="edit-button-sec">
          <button type="reset" className="btn   cancel-btn mr-2" onClick={previousPage}>Cancel</button>
          <button type="submit" className="btn   submit-btn btn-primary " onClick={updateProfile} >Update </button>
        </div>


      </div>
    </>

  )

}


export default SignupDetails;