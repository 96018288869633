// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics ,logEvent} from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging, getToken,onMessage} from "firebase/messaging";
import { encryptStorage } from './utils/utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDcGJTQPmf-i8TAfLgkj11QA2bggQgX1Q",
  authDomain: "cizzr-app-and-web.firebaseapp.com",
  projectId: "cizzr-app-and-web",
  storageBucket: "cizzr-app-and-web.appspot.com",
  messagingSenderId: "237777351694",
  appId: "1:237777351694:web:491245bbf1650d8702b85f",
  measurementId: "G-Z4HJ9KDE8J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Analytics Firebase
const analytics = getAnalytics(app);
export const LogFEvent = (text)=>{
  logEvent(analytics, text)
}

// Performance Firebase
const perf = getPerformance(app);


const messaging = getMessaging(app);
const appserverkey = "BHAQ9hXfFWlMiW45ZVQZvjrp4rh1WnGxS1XAj0GpCuFnZMa0rw0072zlvYPp5-aWo6H5hMNJLUa1tmFOQrfDogw"
function isIOS() {
  //For Device which are not supported Firebase : Added by NiraliCB : 23 June 2022
  const browserInfo = navigator.userAgent.toLowerCase();
  console.log('browserInfo..',browserInfo)
  console.log('navigator...',navigator.platform);
  if (browserInfo.match('iphone') || browserInfo.match('ipad')|| browserInfo.match('macintosh')) {
    console.log('browserInfo..true')
    return true;
  }  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacIntel'].includes(navigator.platform)) {
    console.log('navigator..true')
    return true;
  }   return false;
}
export const getFCMToken = () => {
  if (messaging) {
    //For Device which are not supported Firebase : Added by NiraliCB : 23 June 2022
    if (isIOS()){
      console.log('Firebase not supported this browser');
    }else{
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          return getToken(messaging, { vapidKey: appserverkey }).then((currentToken) => {
            if (currentToken) {
              encryptStorage.setItem("FCM_Token", currentToken)
              console.log('current token for client: ', currentToken);
              // Send the token to your server and update the UI if necessary
              // ...
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
          });
        } else {
          console.log("Unable to get permission to notify.")
        }
      })
    }
  }
}


if (messaging) {
  onMessage(messaging, (payload) => {
    console.log('Received foreground message : ', payload);
    toast.info(payload.data.body, {
      style: { fontSize: 14, top: 40 },
      autoClose: 3000
    });
  });
}