import React from "react";
import { connect } from "react-redux";
import {LogFEvent} from '../firebase'
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Loader from "./Loader";
import { devicetype } from '../constants';
import { encryptStorage } from '../utils/utils';
import { Img } from 'react-image';
import user1 from "../asset/images/user1.svg";
import { FriendListAPI, AddFriendAPI, AcceptRejectFriendAPI,MutualFriendAPI } from "../actions/globalactions";
import pageloadloader from "../asset/images/page-load-loader.gif";
import Modal from "react-responsive-modal";

class Friends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Friendlist: null,
            loading: false
        }
        this.handleScroll = this.handleScroll.bind(this);
    }
    componentDidMount() {
        debugger
        LogFEvent("friends_pageopen")
        var token = encryptStorage.getItem("cizzr_token")
      if (!token) {
         this.props.history.push(`/signin`);
      } else {
        var friendtitle = encryptStorage.getItem('friendtitle')
        if (friendtitle) {
            window.addEventListener("scroll", this.handleScroll);
            if (friendtitle == "Suggested Friends") {
                this.setState({
                    Friendlist: encryptStorage.getItem('SuggestedFriendsList') && encryptStorage.getItem('SuggestedFriendsList') != 'undefined' ? encryptStorage.getItem('SuggestedFriendsList') : [],
                    title: friendtitle
                })
            } else if (friendtitle == "Friend Requests") {
                this.setState({
                    Friendlist: encryptStorage.getItem('FriendsReqList') && encryptStorage.getItem('FriendsReqList') != 'undefined' ? encryptStorage.getItem('FriendsReqList') : [],
                    title: friendtitle
                })
            } else {
                this.setState({
                    Friendlist: encryptStorage.getItem('FriendsList') && encryptStorage.getItem('FriendsList') != 'undefined' ? encryptStorage.getItem('FriendsList') : [],
                    title: friendtitle,
                    Tfriends: localStorage.getItem("Tfriends") ? localStorage.getItem("Tfriends") : 0
                })
                localStorage.setItem("callfriend", false)
            }
            window.scrollTo(0, 0);
        }
    }
    }


    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        // localStorage.removeItem("Tfriends");
    }

    handleScroll() {
        debugger
        if (this.state.title == "Friends") {
            if(localStorage.getItem("searchtxt") && localStorage.getItem("searchtxt").length > 2){}else{
                const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
                const body = document.body;
                const html = document.documentElement;
                const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
                const windowBottom = windowHeight + window.pageYOffset;
                if (windowBottom >= docHeight) {
                    if (this.state.Friendlist) {
                        if (this.state.Friendlist.length < this.state.Tfriends) {
                            if (this.state.Tfriends > this.state.Friendlist.length+1) {
                                if (localStorage.getItem("callfriend") == "true") { } else {
                                    this.props.FriendListAPI({
                                        "start": this.state.Friendlist.length+1,
                                        "limit": 10
                                    })
                                    this.setState({ loading: true });
                                    localStorage.setItem("callfriend", true)
                                }
                            }
                        }
                    }
                }
            }            
        }
    }

    sendfriendrequest = (id, idx, from) => {
        debugger
        if (id) {
            if(from == "mutual"){
                this.setState({ mutualprocess: true })
             } else{
                this.setState({ process: true })
             }
            this.setState({ frndindex: idx})
            var sendreq = {
                to_user_id: id,
                device_type: devicetype,
                mutual_friend_id:this.state.currentmutualid
            }
            this.props.AddFriendAPI(sendreq)
        }

    }

    acceptrejectfriendrequest = (freqid, statusreq, idx, status,from) => {
        debugger
        if (freqid) {
            if(from == "mutual"){
                this.setState({ mutualprocess: true })
             } else{
                this.setState({ process: true })
             }
            this.setState({ frndindex: idx})
            var sendreq = {
                request_id: freqid,
                status: statusreq,
                cancel_request: statusreq == 2 ? status == 1 ? "0" : "1" : "0",
                mutual_friend_id:this.state.currentmutualid
            }
            this.props.AcceptRejectFriendAPI(sendreq)
        }

    }

    removeele = (index) => {
        var array = this.state.Friendlist
        if (index > -1) {
            array.splice(index, 1);
        }
        this.setState({ Friendlist: array , process: false})
        if (this.state.title == "Suggested Friends") {
            encryptStorage.setItem('SuggestedFriendsList', array)
        } else if (this.state.title == "Friend Requests") {
            debugger
            if(array.length>0){
                encryptStorage.setItem('FriendsReqList', array)
            }else{
                encryptStorage.setItem('FriendsReqList', array)
                this.props.history.push(`/profile`);
            }
            
        }
    }

    componentWillReceiveProps(nextprops) {
        if (nextprops.addFriendRes) {
            if (nextprops.addFriendRes != this.props.addFriendRes) {
                debugger
                if(this.state.mutualprocess){
                    var obj = this.state.MutualfriendRes
               if (obj) {
                  if (obj[this.state.frndindex]) {
                     obj[this.state.frndindex]["friend_status"] = nextprops.addFriendRes.friend_status
                     obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.addFriendRes.friend_request_sent_by
                     obj[this.state.frndindex]["friend_id"] = nextprops.addFriendRes.friend_id
                  }
               }
               this.setState({ MutualfriendRes: obj, mutualprocess: false })
                }else{
                var obj = this.state.Friendlist
                if (obj) {
                    if (obj[this.state.frndindex]) {
                        obj[this.state.frndindex]["friend_status"] = nextprops.addFriendRes.friend_status
                        obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.addFriendRes.friend_request_sent_by
                        obj[this.state.frndindex]["friend_id"] = nextprops.addFriendRes.friend_id
                    }
                }
                this.setState({ Friendlist: obj, process: false })
                if (this.state.title == "Suggested Friends") {
                    encryptStorage.setItem('SuggestedFriendsList', obj)
                } else if (this.state.title == "Friends") {
                    encryptStorage.setItem('FriendsList', obj)
                }
            }
            }
        }

        if (nextprops.accrejFriendRes) {
            if (nextprops.accrejFriendRes != this.props.accrejFriendRes) {
                debugger
                if(this.state.mutualprocess){
                    var obj = this.state.MutualfriendRes
               if (obj) {
                  if (obj[this.state.frndindex]) {
                     obj[this.state.frndindex]["friend_status"] = nextprops.accrejFriendRes.friend_status
                     obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.accrejFriendRes.friend_request_sent_by
                     obj[this.state.frndindex]["friend_id"] = nextprops.accrejFriendRes.friend_id

                     if (this.state.title == "Friends" && nextprops.accrejFriendRes.friend_status==2) {
                        var objm = this.state.Friendlist
                        var fid = obj[this.state.frndindex]._id?obj[this.state.frndindex]._id:obj[this.state.frndindex].user_id
                        var mindx = objm.findIndex(i=>i._id ? i._id==fid : i.user_id==fid)
                        if (mindx > -1) {
                            objm.splice(mindx, 1);
                         }
                         this.setState({Tfriends:this.state.Tfriends-1},()=> localStorage.setItem("Tfriends",this.state.Tfriends))
                        //  localStorage.setItem('Tfriends',localStorage.getItem('Tfriends')?parseInt(localStorage.getItem('Tfriends'))-1:localStorage.getItem('Tfriends'))
                         this.setState({ Friendlist: objm })
                        encryptStorage.setItem('FriendsList', objm)
                    }
                  }
               }
               
               this.setState({ MutualfriendRes: obj, mutualprocess: false })
                }else{
                if (this.state.title == "Friends" || this.state.title == "Suggested Friends") {
                    var obj = this.state.Friendlist
                    if (obj) {
                        if (obj[this.state.frndindex]) {
                            obj[this.state.frndindex]["friend_status"] = nextprops.accrejFriendRes.friend_status
                            obj[this.state.frndindex]["friend_request_sent_by"] = nextprops.accrejFriendRes.friend_request_sent_by
                            obj[this.state.frndindex]["friend_id"] = nextprops.accrejFriendRes.friend_id
                        }
                        if (this.state.title == "Suggested Friends") {
                            this.setState({ Friendlist: obj, process: false })
                            encryptStorage.setItem('SuggestedFriendsList', obj)
                        } else if (this.state.title == "Friends") {
                            if(nextprops.accrejFriendRes.friend_status==2){
                                if (this.state.frndindex > -1) {
                                    obj.splice(this.state.frndindex, 1);
                                 } 
                                 this.setState({Tfriends:this.state.Tfriends-1},()=> localStorage.setItem("Tfriends",this.state.Tfriends))
                                //  localStorage.setItem('Tfriends',localStorage.getItem('Tfriends')?parseInt(localStorage.getItem('Tfriends'))-1:localStorage.getItem('Tfriends'))
                            }
                            this.setState({ Friendlist: obj, process: false })
                            encryptStorage.setItem('FriendsList', obj)
                        }

                    }
                }
                else {
                    this.removeele(this.state.frndindex)
                }
                }
                var objm = this.state.Friendlist
                    if(objm){
                     var mindx = objm.findIndex(i=>i._id ? i._id==nextprops.accrejFriendRes.mutual_friend_id : i.user_id==nextprops.accrejFriendRes.mutual_friend_id)
                     if(objm[mindx]){
                        objm[mindx]["mutual_friends"]=nextprops.accrejFriendRes.mutual_friends
                        objm[mindx]["mutual_friends_count"]=nextprops.accrejFriendRes.mutual_friends_count
                        this.setState({Friendlist:objm})
                     }
                    } 
            }
        }


        if (nextprops.friendListRes) {
            if (nextprops.friendListRes != this.props.friendListRes) {
                debugger
                if(this.state.Friendlist){
                if(this.state.Friendlist.length>0 && nextprops.friendListRes.length>0){
                    if(this.state.Friendlist[0]._id == nextprops.friendListRes[0]._id){
                    }else if(this.state.title == "Friends"){
                        this.setState({ Friendlist: this.state.Friendlist.concat(nextprops.friendListRes), loading: false })
                    }                   
                }                
                }                
            }
        }

        if (nextprops.mutualfriendRes) {
            if (nextprops.mutualfriendRes != this.props.mutualfriendRes) {
               this.setState({MutualfriendRes:nextprops.mutualfriendRes.data,MFcount:nextprops.mutualfriendRes.count,mutualloader:false})
               if(nextprops.mutualfriendRes.data){
                  var firstfive = nextprops.mutualfriendRes.data.slice(0, 5)
                  var newmutual = []
                  firstfive.map(i=>newmutual.push({"user_id": i.user_id,"photo": i.photo}))
               }
               var objm = this.state.Friendlist
                       if(objm){
                        var mindx = objm.findIndex(i=>i._id ? i._id==this.state.currentmutualid : i.user_id==this.state.currentmutualid)
                        if(objm[mindx]){
                           objm[mindx]["mutual_friends"]=newmutual
                           objm[mindx]["mutual_friends_count"]=nextprops.mutualfriendRes.count
                           this.setState({Friendlist:objm})
                        }
              } 
            }
         }

    }

    gotoprofile = (otheruserid) => {
        this.props.history.push({
            pathname: `/otherprofile/`+otheruserid,
            state: otheruserid
        });
    }

    openMutual = (id) =>{
        debugger
        this.setState({openMutual:true,mutualloader:true,currentmutualid:id})
        this.props.MutualFriendAPI({
           "other_user_id":id
       })
     }

    render() {
        return (
            <>
                <Loader />
                <div className="wrapper">
                    <Sidebar />
                    <Navbar />
                    <div id="content-page" className="content-page">
                        <div className="tab-pane fade active show" id="friends" role="tabpanel">
                            {this.state.Friendlist &&
                                <div className="iq-card " id="1">
                                    
                                    <div className="iq-card-header d-flex justify-content-between border-0">
                                        <div className="iq-header-title">
                                            {localStorage.getItem("searchtxt") && localStorage.getItem("searchtxt").length > 2 && this.state.title == "Friends" ?
                                                <h4 className="card-title">Search Results for "{localStorage.getItem("searchtxt")}"</h4> :
                                                this.state.title == "Friends" ?
                                                <h4 className="card-title">{this.state.title} {this.state.Tfriends>0 ? '(' + this.state.Tfriends + ')' : null}</h4>:
                                                <h4 className="card-title">{this.state.title}</h4>
                                            } </div>

                                    </div>
                                    <div className="iq-card-body ">
                                        {this.state.Friendlist.length > 0 ?
                                            <ul className="request-list req-list list-inline m-0 p-0">
                                                {this.state.Friendlist.map((i, index) =>
                                                    <li className="d-flex align-items-center " key={'f' + index}>
                                                        <div className="user-img img-fluid">
                                                            <a onClick={() => this.gotoprofile(i._id ? i._id : i.user_id)}>
                                                                <Img className="rounded-circle avatar-45" src={i.photo}
                                                                    loader={<img src={user1} className="rounded-circle avatar-45" />} unloader={<img src={user1} className="rounded-circle avatar-45" />} />
                                                            </a>

                                                        </div>
                                                        <div className="media-support-info suggestion-name ml-2">
                                                            <div><h5 className="cursor-pointer" onClick={() => this.gotoprofile(i._id ? i._id : i.user_id)}>{i.name}</h5>
                                                                {i.username && <p className="mb-0">{i.username}</p>}</div>
                                                            {i.mutual_friends_count > 0 && <p className='mb-0'><span className="cursor-pointer" onClick={()=>this.openMutual(i._id ? i._id : i.user_id)}>{i.mutual_friends_count}{i.mutual_friends_count > 1 ? ' Mutual Friends' : ' Mutual Friend'} </span></p>}

                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            {this.state.process && index == this.state.frndindex ? null :
                                                                i.friend_status == 2 ?
                                                                    <button type="reset" className="btn add-btn submit-btn btn-primary mr-2" onClick={() => this.sendfriendrequest(i._id ? i._id : i.user_id, index)}>Add Friend</button>
                                                                    : i.friend_status == 1 || (i.friend_status == 0 && i.friend_request_sent_by == 0) ?
                                                                        <button type="reset" className="btn add-btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status)}>{i.friend_status == 1 ? 'Remove' : 'Cancel Request'}</button>
                                                                        : i.friend_status == 0 && i.friend_request_sent_by == 1 ?
                                                                            <><button type="reset" className="btn add-btn submit-btn btn-primary mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 1, index, i.friend_status)}>Accept</button>
                                                                                <button type="reset" className="btn add-btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status)}>Reject</button></>
                                                                            : null}
                                                            {(this.state.process || this.state.mutualprocess) && index == this.state.frndindex ? null : this.state.title == "Suggested Friends" && <a className=" btn text-dark  rounded" onClick={() => this.removeele(index)}><i className='las la-times'></i></a>}
                                                        </div>
                                                    </li>)}
                                            </ul>
                                            :
                                            this.state.title == "Suggested Friends" ?
                                                <p className="no-data-found">No Suggestions</p> :
                                                this.state.title == "Friend Requests" ?
                                                    <p className="no-data-found">No Friend Requests</p>
                                                    : <p className="no-data-found">No Friends</p>
                                        }
                                    </div>

                                </div>}

                        </div>
                        <Modal
                           classNames={{
                              overlay: "customOverlay",
                              modal: "customModal"
                           }}
                           open={this.state.openMutual}
                           center
                           onClose={() => { }}
                           showCloseIcon={false}
                        >
                           {this.state.openMutual ?
                              <div className="modal fade show create-post" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                 <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                       <div className="modal-header">
                                          <h5 className="modal-title" id="post-modalLabel">Mutual Friends</h5>
                                          <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => this.setState({ openMutual: false,MutualfriendRes:null})}><i className="ri-close-fill"></i></button>
                                       </div>
                                       <div className="modal-body">
                                          {this.state.MutualfriendRes?
                                          this.state.MutualfriendRes.length>0?
                                          this.state.MutualfriendRes.map((i, index) =>
                                          <div className="who-has-liked" key={'mf'+i._id}>
                                              <div className="d-flex">
                                              <Img
                                                  className="rounded-circle img-fluid user-like-img cursor-pointer"
                                                  src={i.photo}
                                                  loader={<img src={user1} className="rounded-circle img-fluid user-like-img cursor-pointer" />}
                                                  unloader={<img src={user1} className="rounded-circle img-fluid user-like-img cursor-pointer" />}
                                                  onClick={() => this.gotoprofile(i.user_id)}
                                             />
                                              {/* {i.photo!=""?<img className="rounded-circle img-fluid user-like-img" src={i.photo}  />:<img className="rounded-circle img-fluid user-like-img" src={user1}  />} */}
                                             <div> <p className="user-like-name cursor-pointer" onClick={() => this.gotoprofile(i.user_id)}>{i.name}</p>
                                              <p className="user-like-name  font-size-12">{i.username}</p>
                                              </div>
                                              </div>
                                              <div className="my-friend-btn">
                                                                     <div className="float-right">
                                                                        {this.state.mutualprocess && index == this.state.frndindex ? null :
 i.friend_status == 2 ?
 <button type="reset" className="btn add-btn submit-btn btn-primary ml-2" onClick={() => this.sendfriendrequest(i._id ? i._id : i.user_id, index,"mutual")}>Add Friend</button>
 : i.friend_status == 1 || (i.friend_status == 0 && i.friend_request_sent_by == 0) ?
    <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status,"mutual")}>{i.friend_status == 1 ? 'Remove' : 'Cancel Request'}</button>
    : i.friend_status == 0 && i.friend_request_sent_by == 1 ?
       <><button type="reset" className="btn submit-btn btn-primary mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 1, index, i.friend_status,"mutual")}>Accept</button>
          <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(i.friend_id, 2, index, i.friend_status,"mutual")}>Reject</button></>
       : null}
                                                                     </div>
                                                                  </div>
                                          </div>)
                                          :<p className="no-data-found">No Mutual friends</p>
                                          :this.state.mutualloader?
                                          <div className="col-sm-12 text-center">
                                          <img src={pageloadloader} style={{ height: 50 }} />
                                      </div>: null}
                                        </div>
                                    </div>
                                 </div>
                              </div>
                              : null}
                        </Modal>
                        {this.state.openMutual &&  <div className="modal-backdrop fade show"></div>}
                        {this.state.title == "Friends" && this.state.loading &&
                            <div className="col-sm-12 text-center">
                                <img src={pageloadloader} style={{ height: 80 }} />
                            </div>}
                    </div>
                </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        AddFriendAPI: (postdata) => { dispatch(AddFriendAPI(postdata)) },
        AcceptRejectFriendAPI: (postdata) => { dispatch(AcceptRejectFriendAPI(postdata)) },
        FriendListAPI: (postdata) => { dispatch(FriendListAPI(postdata)) },
        MutualFriendAPI: (postdata) => { dispatch(MutualFriendAPI(postdata)) },
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        addFriendRes: state.globalReducer.AddFriendRes,
        accrejFriendRes: state.globalReducer.AcceptRejectFriendRes,
        friendListRes: state.globalReducer.FriendListRes,
        mutualfriendRes: state.globalReducer.MutualFriendRes
    }
}
export default connect(mapStateToProps, mapDispatchToProps)((Friends))