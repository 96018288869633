import React from 'react';
import { getAsios, dispatchLoadingStatus, encryptStorage } from "../utils/utils";
import {LogFEvent} from '../firebase'
import { basepath } from "../constants";
import {
    tokenexpire, devicetype, Login_URL, LoginRes, Registration_URL, Registration_Verify_URL, RegistrationReq, RegistrationRes,
    OTPRegistrationRes, ForgotPass_URL, ForgotPassRes, ForgotPass_Verify_URL, OTPForgotPassRes, ResetPass_URL, ResetPassRes,
    BasicInfo_URL, BasicInfoRes, Post_URL, PostList_URL, PostRes, PostListRes, SearchPost_URL, SearchPostRes, NearPost_URL,
    NearPostRes, PostDetails_URL, PostDetailsRes, UPVote_URL, VoteRes, UPVoteList_URL, UPVoteListRes, DownVote_URL,
    DownVoteList_URL, DownVoteListRes, Comment_URL, CommentRes, CommentList_URL, CommentListRes, SavePost_URL,
    SavePostRes, RemoveFromSave_URL, RemoveFromSaveRes, SavePostList_URL, SavePostListRes, ProfilePic_URL, ProfilePicRes,
    GetProfile_URL, GetProfileRes, UpdateProfileRes, UpdateProfile_URL, NotificationList_URL, NotificationListRes,
    DeleteNotification_URL, DeleteNotificationRes, ReadNotification_URL, ReadNotificationRes, MyPost_URL, MyPostRes,
    SavedPost_URL, SavedPostRes, OtherProfile_URL, OtherProfileRes, OtherPost_URL, OtherPostRes, ChangePass_URL, ChangePassRes,
    GetSetting_URL, GetSettingRes, SetSetting_URL, SetSettingRes, ThreadList_URL, ThreadListRes, AddThread_URL,
    AddThreadRes, AcceptRejectRequest_URL, AcceptRejectRequestRes, AllUser_URL, AllUserRes, DeletePost_URL,
    DeletePostRes, ChatDetails_URL, ChatDetailsRes, SendMessage_URL, SendMessageRes, DeleteChat_URL, DeleteChatRes,
    Blockuser_URL, UnBlockuser_URL, UnBlockuserRes, BlockuserList_URL, BlockuserListRes, PostDetail_URL, PostDetailRes, Logout_URL,
    PageDetails_URL, PageDetailsRes, BasicCount_URL, ReportPost_URL, RemoveProfile_URL, UPVoteCmt_URL, DownVoteCmt_URL,
    UPVoteListcmnt_URL, AddFriend_URL, AddFriendRes, AcceptRejectFriend_URL, AcceptRejectFriendRes, FriendList_URL, 
    FriendListRes, FriendReqList_URL, FriendReqListRes,FriendSuggested_URL,FriendSuggestedRes,MutualFriend_URL,MutualFriendRes,
    SearchUsers_URL,SearchUsersRes,SaveFeedback_URL,FeedbackRes, DeleteAcURL
} from "../constants";
import swal from 'sweetalert';
import { toast } from 'react-toastify';
toast.configure()


export function LoginAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(Login_URL, data)
            .then((response) => {
                if (response.data.success) {
                    encryptStorage.setItem('cizzr_token', response.data.data.token_type + " " + response.data.data.access_token);
                    encryptStorage.setItem('username', response.data.data.username ? response.data.data.username : '');
                    encryptStorage.setItem('myname', response.data.data.name ? response.data.data.name : '');
                    encryptStorage.setItem('useremail', response.data.data.email ? response.data.data.email : '');
                    encryptStorage.setItem('userid', response.data.data._id ? response.data.data._id : '');
                    localStorage.setItem('guideflag', response.data.data.first_time_login);
                    
                    if(response.data.data.first_time_login){
                        localStorage.setItem('askfortourflag', true);
                        localStorage.setItem('guidehomeflag', true);
                    }
                    dispatch({ type: LoginRes, payload: response.data });
                    return;
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("loginprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("loginprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function RegistrationAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(Registration_URL, data)
            .then((response) => {
                
                dispatch({ type: RegistrationReq, payload: data });
                dispatch({ type: RegistrationRes, payload: response.data });
                // if(response.data.success){
                //     // dispatch({ type: RegistrationReq, payload: data });
                //     // dispatch({ type: RegistrationRes, payload: response.data });
                // }else{

                // }

                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("regprocess")
                localStorage.removeItem("Registrationprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("regprocess")
                localStorage.removeItem("Registrationprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function RegistrationVerifyAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(Registration_Verify_URL, data)
            .then((response) => {

                if (response.data.success) {
                    encryptStorage.setItem('cizzr_token', response.data.data.token_type + " " + response.data.data.access_token);
                    encryptStorage.setItem('username', response.data.data.username ? response.data.data.username : '');
                    encryptStorage.setItem('myname', response.data.data.name ? response.data.data.name : '');
                    encryptStorage.setItem('useremail', response.data.data.email ? response.data.data.email : '');
                    encryptStorage.setItem('userid', response.data.data._id);
                    localStorage.setItem('guideflag', response.data.data.first_time_login);
                    if(response.data.data.first_time_login){
                        localStorage.setItem('askfortourflag', true);
                        localStorage.setItem('guidehomeflag', true);
                    }
                    dispatch({ type: OTPRegistrationRes, payload: response.data });
                    LogFEvent("user_created_web")
                    swal({ text: response.data.message, icon: "success" }).then(function () {
                        window.location.href = window.location.origin + `/signupdetails`;
                    });
                    // _id
                    // photo
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("RegistrationVerifyprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("RegistrationVerifyprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ForgotPassAPI(data) {

    return function (dispatch, getState) {
        return getAsios().post(ForgotPass_URL, data)
            .then((response) => {

                if (response.data.success) {
                    encryptStorage.setItem("forgot_pass", true);
                    encryptStorage.setItem("Femail", data.email);
                    dispatch({ type: ForgotPassRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("forgotprocess")
                localStorage.removeItem("Forgotpassprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("forgotprocess")
                localStorage.removeItem("Forgotpassprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ForgotPassVerifyAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(ForgotPass_Verify_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: OTPForgotPassRes, payload: response.data });
                    encryptStorage.setItem('forgot_pass', false);
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("ForgotPassVerifyprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("ForgotPassVerifyprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ResetPassAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(ResetPass_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: ResetPassRes, payload: response.data });
                    encryptStorage.removeItem("forgot_pass")
                    encryptStorage.removeItem("Femail")
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("resetprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("resetprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function BasicInfoAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(BasicInfo_URL, data)
            .then((response) => {
                // debugger
                if (response.data.success) {
                    dispatch({ type: BasicInfoRes, payload: response.data });
                    encryptStorage.setItem('username', response.data.userData.username ? response.data.userData.username : '');
                    encryptStorage.setItem('myname', response.data.userData.name ? response.data.userData.name : '');
                    encryptStorage.setItem('useremail', response.data.userData.email ? response.data.userData.email : '');
                    encryptStorage.setItem('userpic', response.data.userData.photo ? response.data.userData.photo : '');
                    encryptStorage.setItem('userbio', response.data.userData.bio ? response.data.userData.bio : '');
                    // encryptStorage.setItem('notifycount', !window.location.href.includes("/notification")&&response.data.notificationCount?response.data.notificationCount:0);
                    // encryptStorage.setItem('chatcount', !window.location.href.includes("/chat")&&response.data.chatCount?response.data.chatCount:0);
                    encryptStorage.setItem('basicInfoRes', response.data);
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        localStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Post_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: PostRes, payload: response.data.data });
                    if (!data.post_id) {
                        toast.success(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                        // swal({ text: response.data.message, icon: "success" });
                    }

                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PostListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(PostList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: PostListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SearchPostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SearchPost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SearchPostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function NearPostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(NearPost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: NearPostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PostDetailsAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(PostDetails_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: PostDetailsRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UPVoteAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UPVote_URL, data)
            .then((response) => {

                if (response.data.success) {
                    // dispatch({ type: VoteRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UPVoteListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UPVoteList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: UPVoteListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DownVoteAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DownVote_URL, data)
            .then((response) => {

                if (response.data.success) {
                    // dispatch({ type: VoteRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DownVoteListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DownVoteList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: DownVoteListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function CommentAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Comment_URL, data)
            .then((response) => {

                if (response.data.success) {
                    // dispatch({ type: CommentRes, payload: response.data });
                    const Cdata = {
                        "post_id": data.post_id,
                        "device_type": devicetype
                    }
                    dispatch(CommentListAPI(Cdata));
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function CommentListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(CommentList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: CommentListRes, payload: response.data.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SavePostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SavePost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SavePostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function RemoveFromSaveAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(RemoveFromSave_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SavePostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SavePostListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SavePostList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SavePostListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ProfilePicAPI(data) {
    var C_token =  encryptStorage.getItem("cizzr_token")
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    return function (dispatch, getState) {
        return getAsios(C_token).post(ProfilePic_URL, data, config)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: ProfilePicRes, payload: response.data });
                    dispatch(GetProfileAPI())
                    // swal({ text: response.data.message, icon: "success" });
                    toast.success(response.data.message, {
                        style: { fontSize: 14, top: 40 },
                        autoClose: 3000
                    });
                    if(data.get('username') && window.location.href.includes("/signupdetails")){
                        window.location.href = window.location.origin + `/home`;
                    }
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function GetProfileAPI() {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(GetProfile_URL)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: GetProfileRes, payload: response.data });
                    encryptStorage.setItem('username', response.data.data.username ? response.data.data.username : '');
                    encryptStorage.setItem('myname', response.data.data.name ? response.data.data.name : '');
                    encryptStorage.setItem('useremail', response.data.data.email ? response.data.data.email : '');
                    encryptStorage.setItem('userpic', response.data.data.photo ? response.data.data.photo : '');
                    encryptStorage.setItem('userbio', response.data.data.bio ? response.data.data.bio : '');
                    encryptStorage.setItem('ProfileRes', response.data);
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }

                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UpdateProfileAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UpdateProfile_URL, data)
            .then((response) => {

                if (response.data.success) {

                    // dispatch({ type: GetProfileRes, payload: response.data });
                    // dispatch({ type: UpdateProfileRes, payload: response.data });
                    encryptStorage.setItem('username', response.data.data.username ? response.data.data.username : '');
                    encryptStorage.setItem('myname', response.data.data.name ? response.data.data.name : '');
                    encryptStorage.setItem('useremail', response.data.data.email ? response.data.data.email : '');
                    encryptStorage.setItem('userpic', response.data.data.photo ? response.data.data.photo : '');
                    encryptStorage.setItem('userbio', response.data.data.bio ? response.data.data.bio : '');
                    encryptStorage.setItem('ProfileRes', response.data);
                    swal({ text: response.data.message, icon: "success" }).then(function () {
                        window.location.href = window.location.origin + `/profile`;
                    });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("updateprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("updateprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function NotificationListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(NotificationList_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: NotificationListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                localStorage.setItem("Notificationprocess",false)
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
                localStorage.setItem("Notificationprocess",false)
            });
    }
}

export function DeleteNotificationAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DeleteNotification_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: DeleteNotificationRes, payload: response.data });
                    encryptStorage.removeItem('NotificationListRes');
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ReadNotificationAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(ReadNotification_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: ReadNotificationRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function MyPostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(MyPost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: MyPostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SavedPostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SavedPost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SavedPostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function GetOtherUserProfileAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(OtherProfile_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: OtherProfileRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function GetOtherUserPostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(OtherPost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: OtherPostRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ChangePassAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(ChangePass_URL, data)
            .then((response) => {

                if (response.data.success) {
                    encryptStorage.clear();
                    dispatch({ type: ChangePassRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                localStorage.removeItem("changepassprocess")
                return;
            })
            .catch(err => {
                localStorage.removeItem("changepassprocess")
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function GetSettingAPI() {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(GetSetting_URL)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: GetSettingRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SetSettingAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SetSetting_URL, data)
            .then((response) => {

                if (response.data.success) {
                    // dispatch({ type: SetSettingRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ThreadListAPI() {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(ThreadList_URL)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: ThreadListRes, payload: response.data.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }

                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function AddThreadAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(AddThread_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: AddThreadRes, payload: response.data });
                    dispatch(ThreadListAPI())
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function AcceptRejectRequestAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(AcceptRejectRequest_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch(ThreadListAPI())
                    if (data.status == 1) {
                        dispatch(ChatDetailsAPI({
                            "thread_id": data.thread_id,
                            "start": 0,
                            "limit": 10,
                            "last_id": "",
                            "device_type": devicetype
                        }));
                    }
                    dispatch({ type: AcceptRejectRequestRes, payload: data });
                } else {

                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        dispatch({ type: AcceptRejectRequestRes, payload: response.data });
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function AllUserAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(AllUser_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: AllUserRes, payload: response.data.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DeletePostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DeletePost_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: DeletePostRes, payload: response.data });
                    // swal({text: response.data.message, icon: "success"});
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ChatDetailsAPI(data) {
    localStorage.setItem('callchatapi', false)
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(ChatDetails_URL, data)
            .then((response) => {
                localStorage.setItem('callchatapi', true)
                if (response.data.success) {
                    dispatch({ type: ChatDetailsRes, payload: response.data.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                localStorage.setItem('callchatapi', false)
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SendMessageAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SendMessage_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SendMessageRes, payload: response.data });
                    // dispatch(ChatDetailsAPI({ "thread_id": data.thread_id }));
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DeleteChatAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DeleteChat_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch(ThreadListAPI())
                    dispatch({ type: DeleteChatRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function BlockuserAPI(data, isthreadcall) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Blockuser_URL, data)
            .then((response) => {

                if (response.data.success) {
                    if (isthreadcall == 'true') {
                        dispatch(ThreadListAPI())
                    }
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UnBlockuserAPI(data, isthreadcall) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UnBlockuser_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: UnBlockuserRes, payload: response.data });
                    if (isthreadcall == 'true') {
                        dispatch(ThreadListAPI())
                    }
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function BlockuserListAPI() {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(BlockuserList_URL)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: BlockuserListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PostDetailAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(PostDetail_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: PostDetailRes, payload: response.data.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function LogoutAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(Logout_URL, data)
            .then((response) => {
                if (response.data.success) {
                    encryptStorage.clear();
                    window.location.href = window.location.origin + `/signin`;
                } else {
                    // if(response.data.success_code==401){
                    //     swal({ text: response.data.message, icon: "error" }).then(function () {
                    //         encryptStorage.clear();
                    //         window.location.href = window.location.origin + `/signin`;
                    //     });
                    // }else{
                    toast.error(response.data.message, {
                        style: { fontSize: 14, top: 40 },
                        autoClose: 3000
                    });
                    // }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                // if (err.toString().includes("401")) {
                //     swal({ text: tokenexpire, icon: "error" }).then(function () {
                //         encryptStorage.clear();
                //         window.location.href = window.location.origin + `/signin`;
                //     });
                // }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function PageDetailsAPI(data) {

    // var C_token = encryptStorage.getItem("admintoken")
    return function (dispatch, getState) {
        return getAsios().post(PageDetails_URL, data)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: PageDetailsRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {

                if (err.toString().includes("401")) {
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function BasicCountAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(BasicCount_URL, data)
            .then((response) => {

                if (response.data.success) {
                    encryptStorage.setItem('notifycount', !window.location.href.includes("/notification") && response.data.notificationCount ? response.data.notificationCount : 0);
                    encryptStorage.setItem('chatcount', !window.location.href.includes("/chat") && response.data.chatCount ? response.data.chatCount : 0);

                    encryptStorage.setItem('username_required',  response.data.username_required ? response.data.username_required : false);

                    if(response.data.username_required == true) {
                        window.location.href = window.location.origin + `/signupdetails`;
                    }
                    
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                // if (err.toString().includes("401")) {
                //     swal({ text: tokenexpire, icon: "error" }).then(function () {
                //         encryptStorage.clear();
                //         localStorage.clear();
                //         window.location.href = window.location.origin + `/signin`;
                //     });
                // }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function ReportPostAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(ReportPost_URL, data)
            .then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message, {
                        style: { fontSize: 14, top: 40 },
                        autoClose: 3000
                    });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        localStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function RemoveProfileAPI() {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(RemoveProfile_URL)
            .then((response) => {
                if (response.data.success) {
                    dispatch(GetProfileAPI())
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UPVoteCmtAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UPVoteCmt_URL, data)
            .then((response) => {

                if (response.data.success) {
                    // dispatch({ type: VoteRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DownVoteCmtAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DownVoteCmt_URL, data)
            .then((response) => {

                if (response.data.success) {
                    // dispatch({ type: VoteRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function UPVoteListcmntAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(UPVoteListcmnt_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: UPVoteListRes, payload: response.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function AddFriendAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(AddFriend_URL, data)
            .then((response) => {
                if(response){
                dispatch({ type: AddFriendRes, payload: response.data.friend_data });
                if(response.data.success){
                    toast.success(response.data.message, {  
                        style:{fontSize:14,top:40},
                        autoClose: 3000
                        });
                }else {
                    if(response.data.success_code==401){
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    }else{
                        toast.error(response.data.message, {  
                            style:{fontSize:14,top:40},
                            autoClose: 3000
                            });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
            }
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function AcceptRejectFriendAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(AcceptRejectFriend_URL, data)
            .then((response) => {
                if(response){
                    dispatch({ type: AcceptRejectFriendRes, payload: response.data.friend_data });
                    if (response.data.success) {
                        
                    } else {
                        if(response.data.success_code==401){
                            swal({ text: response.data.message, icon: "error" }).then(function () {
                                encryptStorage.clear();
                                window.location.href = window.location.origin + `/signin`;
                            });
                        }else{
                            toast.error(response.data.message, {  
                                style:{fontSize:14,top:40},
                                autoClose: 3000
                                });
                        }
                        // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                    }
                }             
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function FriendListAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(FriendList_URL,data)
            .then((response) => {
                if (response.data.success) {
                    localStorage.setItem("Tfriends",response.data.count)
                    dispatch({ type: FriendListRes, payload: response.data.data });
                } else {
                    if(response.data.success_code==401){
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    }else{
                        toast.error(response.data.message, {  
                            style:{fontSize:14,top:40},
                            autoClose: 3000
                            });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }
                localStorage.setItem("callfriend",false)
                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                localStorage.setItem("callfriend",false)
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function FriendReqListAPI() {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(FriendReqList_URL)
            .then((response) => {
                
                if (response.data.success) {
                    dispatch({ type: FriendReqListRes, payload: response.data.data });
                } else {
                    if(response.data.success_code==401){
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    }else{
                        toast.error(response.data.message, {  
                            style:{fontSize:14,top:40},
                            autoClose: 3000
                            });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function FriendSuggestedAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(FriendSuggested_URL,data)
            .then((response) => {
                
                if (response.data.success) {
                    dispatch({ type: FriendSuggestedRes, payload: response.data.data });
                } else {
                    if(response.data.success_code==401){
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    }else{
                        toast.error(response.data.message, {  
                            style:{fontSize:14,top:40},
                            autoClose: 3000
                            });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function MutualFriendAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(MutualFriend_URL,data)
            .then((response) => {
                if (response.data.success) {
                    dispatch({ type: MutualFriendRes, payload: response.data });
                } else {
                    if(response.data.success_code==401){
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    }else{
                        toast.error(response.data.message, {  
                            style:{fontSize:14,top:40},
                            autoClose: 3000
                            });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SearchUsersAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(SearchUsers_URL, data)
            .then((response) => {

                if (response.data.success) {
                    dispatch({ type: SearchUsersRes, payload: response.data.data });
                } else {
                    if (response.data.success_code == 401) {
                        swal({ text: response.data.message, icon: "error" }).then(function () {
                            encryptStorage.clear();
                            window.location.href = window.location.origin + `/signin`;
                        });
                    } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function SaveFeedbackAPI(data) {
    return function (dispatch, getState) {
        return getAsios().post(SaveFeedback_URL, data)
            .then((response) => {
                if (response.data.success_code) {
                    dispatch({ type: FeedbackRes, payload: response.data });
                } else {
                    // if (response.data.success_code == 401) {
                    //     swal({ text: response.data.message, icon: "error" }).then(function () {
                    //         encryptStorage.clear();
                    //         window.location.href = window.location.origin + `/signin`;
                    //     });
                    // } else {
                        toast.error(response.data.message, {
                            style: { fontSize: 14, top: 40 },
                            autoClose: 3000
                        });
                    // }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                if (err.toString().includes("401")) {
                    swal({ text: tokenexpire, icon: "error" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}

export function DeleteAcAPI(data) {
    var C_token = encryptStorage.getItem("cizzr_token")
    return function (dispatch, getState) {
        return getAsios(C_token).post(DeleteAcURL)
            .then((response) => {
                if (response.data.success) {
                    swal({ text: response.data.message, icon: "success" }).then(function () {
                        encryptStorage.clear();
                        window.location.href = window.location.origin + `/signin`;
                    });
                    // encryptStorage.clear();
                    // window.location.href = window.location.origin + `/signin`;
                } else {
                    // if(response.data.success_code==401){
                    //     swal({ text: response.data.message, icon: "error" }).then(function () {
                    //         encryptStorage.clear();
                    //         window.location.href = window.location.origin + `/signin`;
                    //     });
                    // }else{
                    toast.error(response.data.message, {
                        style: { fontSize: 14, top: 40 },
                        autoClose: 3000
                    });
                    // }
                    // swal({ text: response.data.message, icon: "error", className: "error-modal" });
                }

                dispatch(dispatchLoadingStatus(false));
                return;
            })
            .catch(err => {
                // if (err.toString().includes("401")) {
                //     swal({ text: tokenexpire, icon: "error" }).then(function () {
                //         encryptStorage.clear();
                //         window.location.href = window.location.origin + `/signin`;
                //     });
                // }
                console.log("ERROR...", err)
                dispatch(dispatchLoadingStatus(false));
            });
    }
}