export const basepath = "cizzr";
export const devicetype = "web";

// --------// Local ----------
// export const baseURL = "https://dev-api.cizzr.com/api/"; 
// export const currentorigin= "http://localhost:3001";
// export const crossorigin = "http://localhost:3000";
// export const ReCAPTCHAkey = "6Ld7iiAfAAAAAF78AWm3SaCwr8wXdM2ZJZ7AyZm_"; 

// --------// QA ----------
// export const baseURL = "https://dev-api.cizzr.com/api/"; 
// export const currentorigin= "https://webqa.cizzr.com";
// export const crossorigin = "https://adminqa.cizzr.com";
// export const ReCAPTCHAkey = "6LcuwSYfAAAAAIWQPEuiCR11CEBr2mvSzySIabC5"; 

// --------// Live ----------
export const baseURL = "https://api.cizzr.com/api/";
export const currentorigin = "https://cizzr.com";
export const crossorigin = "https://admin.cizzr.com";
export const crossweborigin = "https://web.cizzr.com";
export const ReCAPTCHAkey = "6Lfk70gfAAAAAKpKUIvc6Eg8FJB8504kZ5qxAhhz";

export const TC_URL = "https://dev-api.cizzr.com/terms-and-condition";
export const Help_URL = "https://dev-api.cizzr.com/help";
export const Policy_URL = "https://dev-api.cizzr.com/privacy-policy";


export const IS_LOADING = "IS_LOADING";
export const tokenexpire = "Your token has expired! Please sign in again."
export const errormsg = "Something went wrong please try again."

export const Login_URL = "login";
export const LoginRes = "LoginRes";

export const Registration_URL = "register";
export const RegistrationReq = "RegistrationReq";
export const RegistrationRes = "RegistrationRes";

export const Registration_Verify_URL="register-verify-otp";
export const OTPRegistrationRes = "OTPRegistrationRes";

export const ForgotPass_URL = "forgot-password";
export const ForgotPassRes = "ForgotPassRes";

export const ForgotPass_Verify_URL = "forgot-password-verify-otp";
export const OTPForgotPassRes = "OTPForgotPassRes";

export const ResetPass_URL = "set-password";
export const ResetPassRes = "ResetPassRes";

export const BasicInfo_URL = "home/basicInfo";
export const BasicInfoRes = "BasicInfoRes";

export const Post_URL = "/post/submit";
export const PostRes = "PostRes";

export const PostList_URL = "/post/list";
export const PostListRes = "PostListRes";

export const SearchPost_URL = "/post/search-post-web";
export const SearchPostRes = "SearchPostRes";

export const NearPost_URL = "/post/list-nearby";
export const NearPostRes = "NearPostRes";

export const PostDetails_URL = "/post/detail";
export const PostDetailsRes = "PostDetailsRes";

export const UPVote_URL = "/post/add-up-vote";
export const VoteRes = "VoteRes";

export const UPVoteList_URL = "/post/up-vote-list";
export const UPVoteListRes = "UPVoteListRes";

export const DownVote_URL = "/post/add-down-vote";
// export const DownVoteRes = "DownVoteRes";

export const DownVoteList_URL = "/post/down-vote-list";
export const DownVoteListRes = "DownVoteListRes";

export const Comment_URL = "/post/add-comment";
export const CommentRes = "CommentRes";

export const CommentList_URL = "/post/comment-list";
export const CommentListRes = "CommentListRes";

export const SavePost_URL = "/post/add-save";
export const SavePostRes = "SavePostRes";

export const RemoveFromSave_URL = "/post/remove-save";
export const RemoveFromSaveRes = "RemoveFromSaveRes";

export const SavePostList_URL = "/post/save-list";
export const SavePostListRes = "SavePostListRes";

export const ProfilePic_URL = "/user/upload-profile-photo";
export const ProfilePicRes = "ProfilePicRes";

export const GetProfile_URL = "/user/get-profile";
export const GetProfileRes = "GetProfileRes";

export const UpdateProfile_URL = "/user/update-profile";
export const UpdateProfileRes = "UpdateProfileRes";

export const NotificationList_URL = "/user/get-notification-list";
export const NotificationListRes = "NotificationListRes";

export const DeleteNotification_URL = "/user/delete-notification";
export const DeleteNotificationRes = "DeleteNotificationRes";

export const ReadNotification_URL = "/user/notification-marked-as-read";
export const ReadNotificationRes = "ReadNotificationRes";

export const MyPost_URL = "/user/get-posts";
export const MyPostRes = "MyPostRes";

export const SavedPost_URL = "/post/save-list";
export const SavedPostRes = "SavedPostRes";

export const OtherProfile_URL = "/get-user-data";
export const OtherProfileRes = "OtherProfileRes";

export const OtherPost_URL = "/get-post-by-user-id";
export const OtherPostRes = "OtherPostRes";

export const ChangePass_URL = "/user/change-password";
export const ChangePassRes = "ChangePassRes";

export const GetSetting_URL ="/user/get-settings";
export const GetSettingRes = "GetSettingRes";

export const SetSetting_URL = "/user/set-settings";
export const SetSettingRes = "SetSettingRes";

export const ThreadList_URL = "/user/thread-list";
export const ThreadListRes = "ThreadListRes";

export const AddThread_URL = "/user/add-thread";
export const AddThreadRes = "AddThreadRes";

export const AcceptRejectRequest_URL = "/user/accept-reject-request";
export const AcceptRejectRequestRes = "AcceptRejectRequestRes";

export const AllUser_URL = "/user/all-users";
export const AllUserRes = "AllUserRes";

export const DeletePost_URL = "/post/delete-post";
export const DeletePostRes = "DeletePostRes";

export const ChatDetails_URL = "/user/get-chat-details";
export const ChatDetailsRes = "ChatDetailsRes";

export const SendMessage_URL = "/user/send-message";
export const SendMessageRes = "SendMessageRes";

export const DeleteChat_URL = "/user/delete-thread";
export const DeleteChatRes = "DeleteChatRes";

export const Blockuser_URL = "/user/block-user";
export const UnBlockuser_URL = "/user/unblock-user";
export const UnBlockuserRes = "UnBlockuserRes";

export const BlockuserList_URL = "/user/get-block-user-list";
export const BlockuserListRes = "BlockuserListRes";

export const PostDetail_URL = "/post/detail";
export const PostDetailRes = "PostDetailRes";

export const Logout_URL = "/user/logout";


export const PageDetails_URL = "/admin/page-details";
export const PageDetailsRes = "PageDetailsRes";

export const BasicCount_URL = "/home/basicCount";
export const ReportPost_URL = "/post/post-report";

export const RemoveProfile_URL = "/user/remove-profile-photo";
export const UPVoteCmt_URL = "/post/add-up-vote-comment";
export const DownVoteCmt_URL = "/post/add-down-vote-comment";
export const UPVoteListcmnt_URL = '/post/up-vote-list-comment';

export const AddFriend_URL = "/user/send-friend-request";
export const AddFriendRes = "AddFriendRes";
export const AcceptRejectFriend_URL = "/user/accept-reject-friend-request";
export const AcceptRejectFriendRes = "AcceptRejectFriendRes";
export const FriendList_URL = "/user/my-friend-list";
export const FriendListRes= "FriendListRes";
export const FriendReqList_URL = "/user/my-friend-requests";
export const FriendReqListRes = "FriendReqListRes";
export const FriendSuggested_URL = "/user/friend-suggestions-list";
export const FriendSuggestedRes = "FriendSuggestedRes";
export const MutualFriend_URL = "/user/mutual-friend-list";
export const MutualFriendRes = "MutualFriendRes";
export const SearchUsers_URL = "/user/search-users";
export const SearchUsersRes = "SearchUsersRes";
export const SaveFeedback_URL = "admin/feedback_insert";
export const FeedbackRes = "FeedbackRes";

export const DeleteAcURL="delete-account"