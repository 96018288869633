import React, { useState, useEffect } from "react";
import user09 from "../asset/images/user1.svg"
import { diff_minutes, encryptStorage ,numFormatter} from '../utils/utils';
import { basepath, devicetype } from "../constants";
import Share from "./Share";
import pageloadloader from "../asset/images/page-load-loader.gif";
import { useHistory } from 'react-router-dom';
import Modal from "react-responsive-modal";
import { UPVoteListAPI,UPVoteListcmntAPI, ReportPostAPI } from '../actions/globalactions';
import { useDispatch, useSelector } from "react-redux";
import { Img } from 'react-image';
import Linkify from 'react-linkify';
import ShowMoreText from "react-show-more-text";
import parse from "html-react-parser";
import TextareaAutosize from 'react-textarea-autosize';
import { Checkbox } from "@material-ui/core";
import Mentiontextarea from './Mentionuser'

const PostCard = (props) => {
    const history = useHistory();
    const [Comment, setComment] = useState("");
    const [isAnonymous, setAnonymous] = useState(false);
    const [is_openlike, setopenlike] = useState(false);
    const [loading, setloading] = useState(false);
    const [likelist, setlikelist] = useState();
    const [Lid, setlikeid] = useState();
    const [cmtid, setcmtid] = useState();
    const [isreport, setisreport] = useState(false);
    const [reportdata, setreportdata] = useState(false);
    const [reason, setreason] = useState("Annoying or not interesting");
    const [wreason, setwreason] = useState("");
    const [error, seterror] = useState("");
    const reasonlist = [
        { text: "Annoying or not interesting" },
        { text: "Rude, vulgar or uses bad language" },
        { text: "Sexually explicit" },
        { text: "Spam" },
        { text: "Harassment or hate speech" },
        { text: "Threatening, violent or suicidal" },
        { text: "Other" }
    ]
    const dispatch = useDispatch();
    // const [upvoteRes, setUpvoteRes] = useState(null);

    // const  upvoteRes = useSelector(state =>state.globalReducer.UPVoteListRes);
    const upvoteRes = useSelector(state => state.globalReducer.UPVoteListRes)

    const c = 0;
    const [count, setCount] = useState(0);

    function addcomment() {

        if (Comment && Comment.trim() != "") {            
            props.addcomment(Comment, isAnonymous);
            setComment("")
        }
    }

    function gotochat(data) {

        history.push({
            pathname: `/chat`,
            state: data
        });
    }

    function reportpostcall() {
        var req = {
            "post_id": reportdata._id,
            "report_cat_id": 1,
            "reason": reason == "Other" ? wreason : reason,
            "device_type": devicetype
        }
        // alert(req.reason)
        dispatch(ReportPostAPI(req));
        // props.hidepost();
        setisreport(false)
        setreason("Annoying or not interesting")
        setwreason("")
    }

    function reportpost() {
        debugger
        if (reportdata && reason) {
            if (reason == "Other") {
                if (wreason) {
                    reportpostcall();
                } else {
                    seterror("Please Enter Reason.")
                }
            } else {
                reportpostcall();
            }
        }
    }

    function EnterPressCall(e) {
        if (e.charCode == 13 || e.which == 13) {
            addcomment()
        }
    }

    function profilefunction(id, index) {
        const userid = encryptStorage.getItem("userid");
        if (id == userid) {
            history.push(`/profile`);
        } else {
            debugger
            history.push({
                pathname: `/otherprofile/`+id,
                state: id
            });
            if (window.location.href.includes('/otherprofile')) {
                window.location.href = window.location.origin + `/otherprofile/`+id;
            }
        }
        if (document.getElementById(index)) {
            var scrollpos = document.getElementById(index).offsetTop;
            encryptStorage.setItem('scrollposother', scrollpos);
            encryptStorage.setItem('othercount', index);
        }

    }
    useEffect(() => {
        // if (upvoteRes != null ) { // && UpvoteRes.success == true && UpvoteRes.data[0] && UpvoteRes.message == "Success.") {
        if (upvoteRes && is_openlike) {
            setlikelist(upvoteRes.data);
            setloading(false);
        }
        // response();

    }, [upvoteRes]);
    // function response(){
    // }

    useEffect(() => {
        if (props.data && props.cmtid && encryptStorage.getItem('cmtscroll')) {

            // console.log('comment id.....'+props.cmtid)
            setcmtid(props.cmtid)
            setTimeout(() => {
                setcmtid()
            }, 2500);
            var scrollcmt = document.getElementById('cmt-' + props.cmtid) && document.getElementById('cmt-' + props.cmtid).offsetTop;
            if (scrollcmt) { window.scrollTo(0, scrollcmt); encryptStorage.removeItem("cmtscroll") }

        }
    }, []);


    function Openlike(id,type) {

        
        setopenlike(true);
        setloading(true);
        setlikelist(null);
        if(type=='post'){
            setlikeid(id);
            const data = {
                "post_id": id,
                "device_type": devicetype
            }
            dispatch(UPVoteListAPI(data));
        }else if(type=='cmnt'){
            const data = {
                "comment_id": id,
                "device_type": devicetype
            }
            dispatch(UPVoteListcmntAPI(data));
        }
       
        // setCount(count+1);
    }
    function gotopost(post_id) {

        history.push({
            pathname: `/post/` + post_id,
            state: { 'postid': post_id }
        });
        //   encryptStorage.setItem('notificationdata', JSON.stringify(this.state.notificationdata));
    }
    function executeOnClick(isExpanded) {

        // console.log(isExpanded);
    }

    var i = props.data
    var comnt = i.commentlist ? i.commentlist.length > 1 ?
        i.commentlist.length + " Comments" :
        i.commentlist.length > 0 ? i.commentlist.length + " Comment" : "" :
        i.total_comments > 1 ?
        numFormatter(i.total_comments) + " Comments" :
            i.total_comments == 1 ?
                i.total_comments + " Comment" :
                ""

    var like = i.total_up_votes > 1 ?
    numFormatter(i.total_up_votes) + " Likes" :
        i.total_up_votes == 1 ?
            i.total_up_votes + " Like" :
            ""

    // console.log("Post Id: ",i._id)
    const UserID = encryptStorage.getItem('userid');

    return (
        <>
            {i && 
            // !i.is_hide &&
                <div className={props.cardview == "2post" ? "col-lg-12 col-md-12 col-sm-12 half-card" : "col-lg-12 col-md-12 col-sm-12"} id={props.index}>
                    <div className="mt-1 iq-card iq-card-block iq-card-stretch iq-card-height home-card">
                        <div className="iq-card-body " >
                            <div className="user-post-data">
                                <div className="d-flex flex-wrap align-items-center">
                                    <a className="media-support-user-img mr-2 cursor-pointer" onClick={() => i.is_anonymous != "1" && profilefunction(i.user_data._id, props.index)}>
                                        {/* <img className="rounded-circle img-fluid" src={user09} /> */}
                                        {i.user_data &&
                                            <Img
                                                className="rounded-circle img-fluid"
                                                src={i.is_anonymous == "1" ? user09 : i.user_data.photo}
                                                loader={<img src={user09} className="rounded-circle img-fluid" />}
                                                unloader={<img src={user09} className="rounded-circle img-fluid" />}
                                            />
                                            // <img className="rounded-circle img-fluid" src={i.user_data.photo != "" ? i.is_anonymous == "1" ? user09 : i.user_data.photo : user09}/>
                                        }

                                    </a>
                                    <div className="media-support-info mt-2 " >
                                        <div className="feeling-user">
                                            {i.user_data &&
                                                <h5 className="mb-0 d-inline-block cursor-pointer"><a onClick={() => i.is_anonymous != "1" && profilefunction(i.user_data._id, props.index)}>{i.is_anonymous == "1" ? "Anonymous" : i.user_data.real_name}</a></h5>

                                            }
                                            {i.emotion_name && <p className="feeling-name">is feeling {i.emotion_icon && <img src={i.emotion_icon} className="rounded-circle" />} <span className="post-emotion-name"> {i.emotion_name}</span> </p>}
                                        </div>


                                        <p className="mb-0 text-primary post-time"><span className="cursor-pointer" onClick={() => gotopost(i._id)}>{diff_minutes(i.created_at, true)}</span></p>
                                        {/* <a className="mb-0 text-primary post-time cursor-pointer" href={`/post/` + i._id}>{diff_minutes(i.created_at,true)}</a> */}

                                    </div>
                                    {/* <div className="iq-card-post-toolbar">
                                        <div className="dropdown">
                                            <a className="save-post" onClick={props.bookmark} >
                                                {i.is_save ?
                                                    <i className="fa fa-bookmark text-primary" aria-hidden="true"></i> :
                                                    <i className="fa fa-bookmark-o" aria-hidden="true"></i>}
                                            </a>
                                         

                                        </div>
                                    </div> */}
                                     <div className="iq-card-post-toolbar custom-toolbar">
                                       <div className="dropdown">
                                          <span className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                          <i className="ri-more-fill"></i>
                                          </span>
                                          <div className="dropdown-menu m-0 p-0">
                                             <a className="dropdown-item px-3 py-2" onClick={props.bookmark}>
                                                <div className="d-flex align-items-top">
                                                   <div className="icon font-size-20">
                                                   {i.is_save ?
                                                    <i className="fa fa-bookmark text-primary" aria-hidden="true"></i> :
                                                    <i className="fa fa-bookmark-o" aria-hidden="true"></i>}
                                                   </div>
                                                   <div className="data ml-2">
                                                      <h6>Save </h6>
                                                  
                                                   </div>
                                                </div>
                                             </a>
                                             {props.isEdit && 
                                             <a className="dropdown-item px-3 py-2" onClick={props.editpost}>
                                                <div className="d-flex align-items-top">
                                                   <div className="icon font-size-20"><i className="ri-edit-line"></i></div>
                                                   <div className="data ml-2">
                                                      <h6>Edit </h6>
                                                     
                                                   </div>
                                                </div>
                                             </a>}
                                             {encryptStorage.getItem("userid") == i.user_id &&  <a className="dropdown-item px-3 py-2" onClick={props.deletepost}>
                                                <div className="d-flex align-items-top">
                                                   <div className="icon font-size-20"><i className="ri-delete-bin-6-line"></i></div>
                                                   <div className="data ml-2">
                                                      <h6>Delete </h6>
                                                    
                                                   </div>
                                                </div>
                                             </a>}
                                             {encryptStorage.getItem("userid") != i.user_id && <a className="dropdown-item px-3 py-2"  onClick={() => { setisreport(true); setreportdata(i); }}>
                                                <div className="d-flex align-items-top">
                                                   <div className="icon font-size-20"><i className="ri-file-3-line"></i></div>
                                                   <div className="data ml-2">
                                                      <h6>Report </h6>
                                                      
                                                   </div>
                                                </div>
                                             </a>}
                                          </div>
                                       </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 home-card-desc" >
                         <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    <ShowMoreText
                                        /* Default options */
                                        lines={3}
                                        more="Read more"
                                        less="Read less"
                                        className="content-css"
                                        anchorclassName="my-anchor-css-class"
                                        onClick={executeOnClick}
                                        expanded={false}
                                        truncatedEndingComponent={"... "}
                                    >{
                                             i.content.split("\n").map(function (item, idx) {
                                                return (

                                                    <p key={idx}>
                                                       {parse(`${item}`)}
                                                        <br />
                                                    </p>
                                                )
                                            })
                                        }
                                    </ShowMoreText>  </Linkify>
                                {/* {props.FormPostDetails ? <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    {
                                        i.content.split("\n").map(function (item, idx) {
                                            return (

                                                <p key={idx}>
                                                    {item}
                                                    <br />
                                                </p>
                                            )
                                        })
                                    }
                                </Linkify> : i.content.length < 151 ? <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    {
                                        i.content.split("\n").map(function (item, idx) {
                                            return (

                                                <p key={idx}>
                                                    {item}
                                                    <br />
                                                </p>
                                            )
                                        })
                                    }
                                </Linkify> : <><Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    {
                                        i.content.substring(0, 150).split("\n").map(function (item, idx) {
                                            return (

                                                <p key={idx}>
                                                    {item}
                                                    <br />
                                                </p>
                                            )
                                        })
                                    }
                                </Linkify><a className="cursor-pointer" onClick={() => gotopost(i._id)}>Read more</a></>} */}

                            </div>

                            {comnt || like ?
                                <div className="comment-area ">


                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="like-block position-relative d-flex align-items-center">
                                            {comnt && <div className="d-flex align-items-center">

                                                <div className="total-comment-block ">
                                                    <div className="dropdown">
                                                        <div className="cursor-pointer">
                                                            <a onClick={props.getcomments}>
                                                                <span onClick={props.collapsecomment}>
                                                                    {comnt}
                                                                </span></a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {like && !is_openlike &&
                                                <div className="total-comment-block">
                                                    <div className="dropdown" >
                                                        {comnt && <span className="separator-icon">  <i className="fa fa-circle"></i>    </span>}
                                                        <a onClick={() => Openlike(i._id,'post')}>
                                                            <span> 
                                                            {/* className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button" */}
                                                                {like}
                                                            </span></a>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                : null}

                                {props.applycolor && i.emotion_color ?<hr className="post-card-hr" style={{height:1,backgroundColor:i.emotion_color,border:'none'}} />:<hr className="post-card-hr" />}

                                
                            <div className="sorting-sec">
                                <div className="row">
                                    <div className="col-lg-8 col-md-9 col-sm-7 col-10">
                                        <div className="like-comnt-share-sec">
                                            <div className="post-space-sec post-like-sec">
                                                <a onClick={props.likeunlike} className={i.is_up_vote ? "active" : ""}> <i className={"ri-arrow-up-line"} aria-hidden="true" ></i> {i.total_up_votes?i.total_up_votes:0}</a>
                                                {/* //cizzr 1.5 (NiraliBThummar 9Nov2021) */}
                                                <a onClick={props.downvote} className={i.is_down_vote ? "active" : ""}> <i className={"ri-arrow-down-line"} aria-hidden="true" ></i> {i.total_down_votes?i.total_down_votes:0}</a> 
                                            </div>
                                            <div className="post-space-sec" onClick={props.opencomment}>  <a onClick={props.getcomments} ><i className="ri-chat-2-line" aria-hidden="true"  ></i> <span className="hide-on-mobile"> Comment</span></a>
                                            </div>
                                            <div className="post-space-sec">
                                                <Share URL="https://web.cizzr.com" Message={i.share_content} />
                                                {/* <a><i className="ri-share-fill" aria-hidden="true"></i> Share</a> */}
                                            </div>
                                            {i.is_anonymous != "1" && encryptStorage.getItem("userid") != i.user_id && <div className="post-space-sec" onClick={() => gotochat(i.threadData)}>  <a><i className="ri-send-plane-line" aria-hidden="true"  ></i> <span className="hide-on-mobile"> Chat </span></a></div>}
                                            {/* {encryptStorage.getItem("userid") != i.user_id && <div className="post-space-sec" onClick={() => { setisreport(true); setreportdata(i); }}> <a><i className="ri-file-3-line" aria-hidden="true"  ></i> <span className="hide-on-mobile"> Report </span></a></div>} */}
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-md-3 col-sm-5 col-2">
                                      
                                            <div className="edit-delete-main float-right like-comnt-share-sec">
                                            {props.isEdit &&  <div className="edit-delete-post">
                                                    <a className="" onClick={props.editpost}><i className="ri-edit-line mr-2"></i><span className="hide-on-mobile">Edit </span></a>
                                                </div>}
                                                {encryptStorage.getItem("userid") == i.user_id &&  <div className="edit-delete-post">
                                                    <a className="" onClick={props.deletepost}><i className="ri-delete-bin-6-line mr-2"></i><span className="hide-on-mobile">Delete</span> </a>
                                                </div>}
                                            </div>
                                    </div> */}
                                </div>
                            </div>

                            {i.is_open && props.cardview != '2post' &&
                                <div className="comment-area mt-3 mb-2">
                                    <div className="comment-text d-flex align-items-center mt-3 mb-1" action="">
                                        <a className="user-img">  <Img
                                            className="avatar-50 rounded-circle img-fluid"
                                            src={isAnonymous ? user09 : encryptStorage.getItem('userpic')}
                                            loader={<img src={user09} className="avatar-50 rounded-circle img-fluid" />}
                                            unloader={<img src={user09} className="avatar-50 rounded-circle img-fluid" />}
                                        />    </a>

                                        <Mentiontextarea intialval={''} fromcmt="true" setvalue={(val) => setComment(val)} keyPress={(e) => EnterPressCall(e)} addcommentfrommention={()=>addcomment()}/>
                                        {/* <TextareaAutosize style={{ backgroundColor: "#ecf0f9" }} placeholder={"write a comment..."} type="text" rows="1" className="form-control rounded comment-custom-textarea" value={Comment} maxLength={500} onChange={(e) => { e.target.value.trim() == "" ? setComment("") : setComment(e.target.value) }} onKeyPress={(e) => EnterPressCall(e)}>
                                        </TextareaAutosize> */}
                                        {/* <div className="comment-attagement d-flex">
                                            <a onClick={() => addcomment()}><i className="ri-send-plane-2-line mr-3"></i></a>
                                        </div> */}
                                    </div>
                                    <div className="switch-div comment-switch" > <label htmlFor="checkbox">Comment as anonymous</label><input onClick={() => setAnonymous(!isAnonymous)} className="switch" type="checkbox"  onChange={()=>{}} checked={isAnonymous} />  </div>
                                    <ul className="post-comments p-0 m-0">
                                        {i.commentlist &&
                                            i.commentlist.length > 0 ?
                                            i.commentlist.map((j, index) =>
                                                index < i.Clistcount ?
                                                    <li className="mb-2" id={'cmt-' + j._id} key={j._id}>
                                                        <div className="d-flex flex-wrap">
                                                            <a className="user-img" onClick={() => { debugger; j.user_data && j.is_anonymous == "0" && profilefunction(j.user_data._id, props.index) }}>
                                                                {/* <img src={j.user_data.photo}  className="avatar-50 rounded-circle img-fluid" /> */}
                                                                <Img
                                                                    className="avatar-50 rounded-circle img-fluid"
                                                                    src={j.is_anonymous == "1" ? user09 : j.user_data.photo}
                                                                    loader={<img src={user09} className="avatar-50 rounded-circle img-fluid" />}
                                                                    unloader={<img src={user09} className="avatar-50 rounded-circle img-fluid" />}
                                                                />
                                                            </a>
                                                            {/* cmtid == j._id ? "comment-data-block cmt-active" : index==0&&j.user_data._id==encryptStorage.getItem("userid")?"comment-data-block cmt-active":"comment-data-block " */}
                                                            <div className={cmtid == j._id ? "comment-data-block cmt-active" :"comment-data-block "}>
                                                                <div className="time-profile-name">   <h6>{j.is_anonymous == "1" ? "Anonymous" : j.user_data.name}</h6>

                                                                    <span>{diff_minutes(j.created_at)}</span>

                                                                </div>
                                                                <div className=" comment-data-sec">
                                                                    <Linkify
                                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                            <a target="blank" href={decoratedHref} key={key}>
                                                                                {decoratedText}
                                                                            </a>
                                                                        )}
                                                                    >
                                                                        {
                                                                            j.message.split("\n").map(function (item, idx) {
                                                                                return (
                                                                                    <p className="mb-0" key={idx}>
                                                                                        {parse(`${item}`)}
                                                                                        <br />
                                                                                    </p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Linkify>                                                              
                                                                </div>  
                                                                {/* //cizzr 1.5 (NiraliBThummar 9Nov2021) */}
                                                                <div className="comment-like-div">
                                                                {j.total_up_votes ? <a  onClick={() => Openlike(j._id,'cmnt')}><span>{numFormatter(j.total_up_votes)}{j.total_up_votes>1?' Likes':' Like'}</span></a> : null}
                                                                <a  onClick={()=>props.cmntupvote(j._id)} className={j.is_up_vote ? "active updown-vote" : "updown-vote"}><i className={"ri-arrow-up-line"} aria-hidden="true" ></i>{j.total_up_votes?j.total_up_votes:0}</a>
                                                                <a  onClick={()=>props.cmntdownvote(j._id)} className={j.is_down_vote ? "active updown-vote" : "updown-vote "}><i className={"ri-arrow-down-line"} aria-hidden="true" ></i>{j.total_down_votes?j.total_down_votes:0}</a>
                                                                    </div>     
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                        </div>
                                                    </li>
                                                    : null) : null}</ul>
                                    {!props.FormPostDetails && i.commentlist && i.commentlist.length > i.Clistcount && <a className="load-new-comment cizzrlink" onClick={props.viewmore}>View more</a>}
                                </div>
                            }
                        </div>
                    </div>
                    <Modal
                        classNames={{
                            overlay: "customOverlay",
                            modal: "customModal"
                        }}
                        open={is_openlike}
                        center
                        onClose={() => { }}
                        showCloseIcon={false}
                    >
                        {is_openlike &&
                            <div className="modal fade show liked-by-user" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="post-modalLabel">Likes</h5>
                                            <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => { setopenlike(false); setlikeid(null); setlikelist(null) }}><i className="ri-close-fill"></i></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="like-lists-sec">
                                                {likelist && likelist.length > 0 ?
                                                    likelist.map((i, index) =>
                                                        <div className="who-has-liked" key={i._id}>
                                                            <div className="d-flex">
                                                            <Img
                                                                className="rounded-circle img-fluid user-like-img"
                                                                src={i.photo}
                                                                loader={<img src={user09} className="rounded-circle img-fluid user-like-img" />}
                                                                unloader={<img src={user09} className="rounded-circle img-fluid user-like-img" />}
                                                            />
                                                            {/* {i.photo!=""?<img className="rounded-circle img-fluid user-like-img" src={i.photo}  />:<img className="rounded-circle img-fluid user-like-img" src={user09}  />} */}
                                                           <div> <p className="user-like-name">{i.name}</p>
                                                            <p className="user-like-name  font-size-12">{i.username}</p>
                                                            </div>
                                                            </div></div>)
                                                    : null}
                                            </div>

                                            {loading && <div className="col-sm-12 text-center">
                                                <img src={pageloadloader} style={{ height: 50 }} />
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </Modal>

                    <Modal
                        classNames={{
                            overlay: "customOverlay",
                            modal: "customModal"
                        }}
                        open={isreport}
                        center
                        onClose={() => { }}
                        showCloseIcon={false}
                    >
                        {isreport &&
                            <div className="modal fade show" id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="post-modalLabel">Why are you reporting this post?</h5>
                                            <button type="button" className="btn  close-modal" data-dismiss="modal" onClick={() => { setisreport(false); setreportdata(null); setreason("Annoying or not interesting"); setwreason(""); seterror("")}}><i className="ri-close-fill"></i></button>
                                        </div>
                                        <div className="modal-body">
                                            <div>
                                                {reasonlist && reasonlist.length > 0 ?
                                                    reasonlist.map((i, index) =>
                                                        <div key={index} onClick={() => { setreason(i.text); seterror("") }} style={{marginLeft:5,alignItems:'center'}} className="cursor-pointer row">
                                                            <input type="radio" value={i.text} checked={reason == i.text ? true : false} onChange={()=>{}}/>
                                                            <span className="user-like-name"  style={{marginLeft:10}}>{i.text}</span>
                                                        </div>)
                                                    : null}
                                                {reason == "Other" && 
                                                 <TextareaAutosize  style={{ backgroundColor: "#ecf0f9" }}  type="text" rows="1" className="form-control rounded comment-custom-textarea report-textarea"  maxLength={125} value={wreason} placeholder="write a reason" onChange={(e) => { setwreason(e.target.value.trim() == "" ? "" : e.target.value); seterror("") }} >
                                                </TextareaAutosize>
                                                //<input className="form-control" type="text" value={wreason} placeholder="write a reason" onChange={(e) => { setwreason(e.target.value.trim() == "" ? "" : e.target.value); seterror("") }} maxLength="125"/>
                                                }
                                                {error && <div style={{ display:'table', width: '100%'}}><p style={{ color: 'red', fontSize: 14, float: 'left' }}>{error}</p></div>}
                                                <div style={{ textAlign: 'center' }}>
                                                    <button type="submit" className="btn  submit-btn btn-primary mt-2 mb-2" onClick={() => reportpost()}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </Modal>
                    {(is_openlike || isreport) && <div className="modal-backdrop fade show"></div>}
                </div>
            }
        </>
    )
}

export default PostCard;