import React from "react";
import { connect } from "react-redux";
import { LogFEvent } from '../firebase'
import Loader from "./Loader";
import img2 from "../asset/images/sign-bg.png";
import { devicetype, TC_URL } from "../constants";
import { dispatchLoadingStatus, isValidNumber, isValidName, Validatepass, encryptStorage } from '../utils/utils';
import { RegistrationAPI } from "../actions/globalactions";
import moment from "moment";
import flogo from "../asset/images/logo.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import swal from 'sweetalert';
import Slider from "./Slider"
import Footer from "./Footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

class SignUP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FullName: '',
      Email: '',
      PhoneNo: '',
      DOB: '',
      Password: '',
      confirmpass: '',
      checked: false,
      hidden: false,
      hidden1: false,
      year: moment().year(),
      month: moment().month(),
      Ccode: "",
    }
    this.textInput = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.togglePass = this.togglePass.bind(this);
    this.toggleCPass = this.toggleCPass.bind(this);
  }

  togglePass() {
    this.setState({ hidden: !this.state.hidden });
  }
  toggleCPass() {
    this.setState({ hidden1: !this.state.hidden1 });
  }

  componentDidMount() {
    debugger
    // console.log("Moment..",moment().year()-1900)
    LogFEvent("signup_pageopen")
    var token = encryptStorage.getItem("cizzr_token")
    if (!token) {
      if (this.props) {
        if (this.props.location.search) {
          if (this.props.location.search.includes("reference_id")) {
            this.setState({ reference_id: this.props.location.search.replace('?reference_id=', '') })
          }
        }
      }
      // swal({text: tokenexpire, icon: "error",className:"error-modal"});
      // this.props.history.push(`/signin`);
    } else {
      this.props.history.push(`/home`);
    }
  }

  EnterPressCall = (e) => {
    if (e.charCode == 13 || e.which == 13) {
      this.Validation()
    }
  }

  selectcountry = (e) => {

    var code = e.target.value.split(" ");
    this.setState({ Ccode: code[0] })
  }
  Validationnum = (phoneNumber) => {

    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      if (phoneNumber) {
        valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
      }
    } catch (e) {
      valid = false;
    }
    if (valid) {
      return true;
      // this.setState({
      //   message:'Phone number '+this.getValidNumber(phoneNumber)+' is valid',
      //   color:'green'
      // });
    } else {
      return false;
      // this.setState({
      //   message:'Phone number '+phoneNumber+' is not valid',
      //   color:'red'
      // });
    }
  }
  getValidNumber(phoneNumber) {

    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL)
  }
  handleChange(event) {
    if (localStorage.getItem("regprocess") == "true") {

    } else {
      if (event.target.id == 'FullName' && event.target.value) {
        this.setState({
          [event.target.id]: event.target.value.trim() == "" ? event.target.value.trim() : isValidName(event.target.value) ? event.target.value : this.state.FullName,
          FNError: '',
          EError: '',
          PNError: '',
          DError: '',
          PWError: '',
          CPWError: '',
          TCError: ''
        });
      } else
        if (event.target.id == 'PhoneNo' && event.target.value) {
          this.setState({
            [event.target.id]: isValidNumber(event.target.value) ? event.target.value : this.state.PhoneNo,
            FNError: '',
            EError: '',
            PNError: '',
            DError: '',
            PWError: '',
            CPWError: '',
            TCError: ''
          });
        } else if (event.target.id == 'DOB' && event.target.value) {
          this.setState({
            [event.target.id]: event.target.value.length < 11 ? event.target.value : this.state.DOB,
            FNError: '',
            EError: '',
            PNError: '',
            DError: '',
            PWError: '',
            CPWError: '',
            TCError: ''
          });
        }
        // else if (event.target.id == 'Password' && event.target.value != "") {
        //   this.setState({
        //     [event.target.id]: event.target.value.trim(),//isValidpass(event.target.value)?event.target.value:this.state.Password,
        //     FNError: '',
        //     EError: '',
        //     PNError: '',
        //     DError: '',
        //     PWError: '',
        //     CPWError: '',
        //     TCError: ''
        //   });
        // } 
        else if (event.target.id == 'confirmpass' && event.target.value != "") {
          this.setState({
            [event.target.id]: event.target.value.trim(),
            FNError: '',
            EError: '',
            PNError: '',
            DError: '',
            PWError: '',
            CPWError: '',
            TCError: ''
          });
        } else {
          this.setState({
            [event.target.id]: event.target.value,
            FNError: '',
            EError: '',
            PNError: '',
            DError: '',
            PWError: '',
            CPWError: '',
            TCError: ''
          });
        }

    }
  }
  handleChangedob(edate) {

    if (edate == "" || !edate) {
      this.setState({ DOB: "" })
    } else {
      this.setState({
        DOB: edate,//moment(edate).format("yyyy-MM-DD"), 
        FNError: '',
        EError: '',
        PNError: '',
        DError: '',
        PWError: '',
        CPWError: '',
        TCError: ''
      })
    }

  }
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  handleCheck() {

    this.setState({ checked: !this.state.checked, TCError: '' })
  }


  validatepass = (passw) => {
    if (localStorage.getItem("regprocess") == "true") {

    } else {
      // var minMaxLength = /^[\s\S]{8,15}$/,
      var upper = /[A-Z]/,
        lower = /[a-z]/,
        number = /[0-9]/,
        special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

      this.setState({
        PWError: "",
        PassError: true,
        Password: passw != "" ? passw.trim() : "",
        isminmax: passw.length > 7 ? true : false,
        isupper: upper.test(passw) ? true : false,
        islower: lower.test(passw) ? true : false,
        isnumber: number.test(passw) ? true : false,
        isspecial: special.test(passw) ? true : false
      })
    }
  }


  Checkusername = (e) => {
    debugger
    var uname = e.target.value.toString().toLowerCase();
    var userregex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9_.]+$/;
    var moredot = /^(?!.*?\.\.)/;
    if (uname) {
      if (uname.trim() == "") {
        this.setState({ Username: uname.trim() })
      } else {
        this.setState({ Username: uname })
        this.setState({ UNError: "" })
        if (!userregex.test(uname)) {
          if (uname.charAt(0) == ".") {
            this.setState({ UNError: "You can't start your username with a dot." })
          } else if (uname.charAt(uname.length - 1) == ".") {
            this.setState({ UNError: "You can't end your username with a dot." })
          } else if (!moredot.test(uname)) {
            this.setState({ UNError: "You can't use your username with consecutive dots." })
          } else {
            this.setState({ UNError: "Username can only use letters, numbers, underscores and dots." })
          }
        }
      }
    } else {
      this.setState({ Username: "", UNError: "" })
    }
  }

  Validation() {

    var dateOB = moment(this.state.DOB);
    var todayDate = moment().subtract(18, 'years');
    // var nameFormat = /^[a-zA-Z ]+$/;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,3})$/;
    var userregex = /^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-z0-9_.]+$/;
    var userregex2 = /^[0-9]+$/;
    // var passFormat = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;
    var isValidphone = this.state.PhoneNo ? this.Validationnum("+" + this.state.PhoneNo) : true;

    // if (this.state.PhoneNo && this.state.PhoneNo != '') {
    //   if (this.state.PhoneNo.includes("+")) {
    //     isvalidnum = this.Validationnum(this.state.PhoneNo)
    //   } else if (this.state.Ccode) {
    //     isvalidnum = this.Validationnum(this.state.Ccode + " " + this.state.PhoneNo)
    //   }
    // }

    if (!this.state.FullName) {
      this.setState({ FNError: "Please enter your Full Name." });
    } else if (this.state.FullName.trim() == "") { //|| !nameFormat.test(this.state.FullName)
      this.setState({ FNError: "Please provide a valid Name." });
    } 
    
     //cizzr 3 (NiraliBThummar 9May2022)


    //  else if (!this.state.Username) { 
    //   this.setState({ UNError: "Please enter your Username." });
    // }else if (!this.state.Username && this.state.UNError != "") {
    //   // this.setState({ UNError: "Please enter your Username." });
    // } else if (this.state.Username && (this.state.Username.length < 6 || this.state.Username.length > 30)) {
    //   this.setState({ UNError: "Username must be between 6 to 30 characters." });
    // } else if (this.state.Username && userregex2.test(this.state.Username)) {
    //   this.setState({ UNError: "Username cannot contain only numbers." });
    // } else if (this.state.Username && !userregex.test(this.state.Username)) {
    //   this.setState({ UNError: "Username can only use letters, numbers, underscores and dots." });
    // }  13/06/2022 changes

    
    else if (!this.state.Email) {
      this.setState({ EError: "Please enter your Email." });
    } else if (this.state.Email && !mailFormat.test(this.state.Email)) {
      this.setState({ EError: "Please provide a valid Email." });
      return false;
    }
    //  else if (!this.state.PhoneNo) {
    //   this.setState({ PNError: "Please enter your Phone number." });
    // } 
    else if (this.state.PhoneNo && this.state.PhoneNo != '' && !isValidphone) {
      this.setState({ PNError: "Please provide a valid Phone number." });
    }
    // else if (this.state.PhoneNo != '' && this.state.PhoneNo.length != 10) {
    //   this.setState({ PNError: "Please provide 10 digit Phone number." });
    // } 
    else if (!this.state.DOB) {
      this.setState({ DError: "Please enter your Date of Birth." });
    } else if (!todayDate.isAfter(dateOB)) {
      this.setState({ DError: "Please enter a valid Date of Birth." });
    }
    else if (!this.state.Password) {
      this.setState({ PWError: "Please enter Password." });
    }
    else if (!Validatepass(this.state.Password)) {
      this.setState({ PassError: true });
    }
    // else if (!passFormat.test(this.state.Password)) {
    //   this.setState({ PWError: "Password must be between 8 to 15 characters and it contains at least 1 letter in Uppercase, 1 letter in Lowercase, 1 Special Character (!@#$&*) and 1 Number." });
    // }
    else if (!this.state.confirmpass) {
      this.setState({ CPWError: "Please enter Confirm Password." });
    } else if (this.state.Password !== this.state.confirmpass) {
      this.setState({ CPWError: "Password and Confirm Password does not match." });
    }

   
    else {
      this.APICall()
    }
  }

  APICall() {
    // var phone="";
    // var country_code="";
    // var isValidphonenum = this.state.PhoneNo&&this.Validationnum("+"+this.state.PhoneNo)
    // if(isValidphonenum){
    //   phone = this.state.PhoneNo.length>5?this.getValidNumber("+"+this.state.PhoneNo):"";
    //   var code = phone?phone.split(" "):null;
    //   country_code = code?code[0]:"";
    // }

    if (!this.state.checked) {
      this.setState({ TCError: "Please Accept Terms and Conditions." })
    } else {
      // var x;
      // if (this.state.PhoneNo) {
      //   x = this.state.PhoneNo.includes("+") ? this.getValidNumber(this.state.PhoneNo) : this.getValidNumber(this.state.Ccode + this.state.PhoneNo.replaceAll(" ", ""))
      // }
      // var phone = x && x.substring(this.state.Ccode.length + 1, x.length)
      // this.setState({ PhoneNo: phone })
      if (localStorage.getItem("regprocess") == "true") {

      } else {
        var Token = encryptStorage.getItem('FCM_Token');
        this.props.isLoading(true);
        const postdata = {
          "name": this.state.FullName,
          "email": this.state.Email,
          // "username":this.state.Username, 13/06/2022 changes
          // "country_code": phone ? this.state.Ccode ? this.state.Ccode : "" : "",
          // "phone": phone ? phone : this.state.PhoneNo,
          "country_code": this.state.PhoneNo ? this.state.Ccode ? this.state.Ccode : "+1" : "",
          "phone": this.state.PhoneNo ? this.state.PhoneNo.substring(this.state.Ccode.length, this.state.PhoneNo.length) : "",
          "dob": this.state.DOB ? moment(this.state.DOB).format("yyyy-MM-DD") : "",
          "password": this.state.Password,
          "fcm_token": Token ? Token : "",
          "device_type": devicetype,
          "reference_id": this.state.reference_id ? this.state.reference_id : ""
        }
        // alert("Call register api")
        // console.log(postdata)
        this.props.RegistrationAPI(postdata)
        localStorage.setItem("regprocess", true)
      }
    }
  }


  componentWillReceiveProps(nextprops) {

    if (nextprops.regRes != null) {
      if (nextprops.regRes !== this.props.regRes) {

        if (nextprops.regRes.success) {
          this.props.history.push(`/otp`);
          encryptStorage.setItem("ISOTP", true);
          // window.location.href = window.location.origin+`/otp`;
        } else {

          if (nextprops.regRes.success_code == 401) {
            swal({ text: nextprops.regRes.message, icon: "error" }).then(function () {
              encryptStorage.clear();
              window.location.href = window.location.origin + `/signin`;
            });
          } else {
            toast.error(nextprops.regRes.message, {
              style: { fontSize: 14 },
              autoClose: 3000
            });
          }
          // toast.error(nextprops.regRes.message, {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: true,
          //   closeOnClick: true
          //   });
          // swal({ text: nextprops.regRes.message, icon: "error", className: "error-modal" });
          if (this.state.PhoneNo) {
            if (!this.state.PhoneNo.includes("+") && this.state.Ccode) {
              this.setState({ PhoneNo: this.state.Ccode + this.state.PhoneNo })
            }
          }
        }
      }
    }
  }

  render() {
    return (
      <>
        <Loader />
        <section className="sign-in-page new-sign-in  overlay-bg">

          <div className="container-fluid p-0">
            <div className="row no-gutters align-items-center">
              <div className="col-lg-7  col-md-12 sm-displaynone">
                <div className="image-signin signup-image overlay-bg">
                  <div className="overlay-homeslider"></div>
                  <div className="slider-text">
                    <h3>A new twist on social media.<br /> Cutting the stigma around mental health. </h3>
                  </div>

                  <img src={img2} className="img-fluid mb-2" />
                </div>
              </div>
              <div className="col-lg-5 col-md-12  align-self-center ">

                <div className="sign-in-main">

                  <div className="sign-in-from signup-width">
                    <div className="sigin-logo-div"><img src={flogo}></img>
                      {/* <h1>Let's Cut The Stigma</h1> */}
                    </div>
                    <h1 className="signin-h1 mb-0">Sign up</h1>
                    <div className="mt-4 row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputnm"> Full Name</label>
                          <input type="text" className="form-control mb-0" placeholder="enter full name" id="FullName" value={this.state.FullName} onChange={this.handleChange} maxLength={30} onKeyPress={(e) => this.EnterPressCall(e)} />
                          {this.state.FNError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.FNError}</p>}

                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputun">User Name </label>
                          <input type="text"  className="form-control mb-0" placeholder="enter username " id="exampleInputun" value={this.state.Username} onChange={this.Checkusername} maxLength={30} onKeyPress={(e) => this.EnterPressCall(e)} />
                          {this.state.UNError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.UNError}</p>}
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail">Email </label>
                          <input type="text" className="form-control mb-0" placeholder="enter email " id="Email" value={this.state.Email} onChange={this.handleChange} maxLength={80} onKeyPress={(e) => this.EnterPressCall(e)} />
                          {this.state.EError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.EError}</p>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputnum">Phone Number (Optional)</label>
                          <PhoneInput
                            countryCodeEditable={false}
                            country={'us'}
                            value={this.state.phone}
                            onChange={(phone, country) => { this.setState({ phone: phone, PNError: "", PhoneNo: country.dialCode != phone ? phone : "", Ccode: country.dialCode }) }}
                          />
                          {/* <PhoneInput
                          id="phone"
                          className="form-control mb-0 country-dropdown" placeholder="enter phone number "
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="US"
                          onSelect={(e) => this.selectcountry(e)}
                          value={this.state.PhoneNo} onChange={(e) => this.setState({ PhoneNo: e, PNError: "" })} maxLength={20} /> */}

                          {/* <input type="text" className="form-control mb-0" placeholder="enter phone number " id="PhoneNo" value={this.state.PhoneNo} onChange={this.handleChange} maxLength={10}/> */}
                          {this.state.PNError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PNError}</p>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group addon-input">
                          <label htmlFor="exampleInputdob">Date of Birth</label>
                          <DatePicker
                            className=" form-control"
                            placeholderText={"mm/dd/yyyy"}
                            dateFormat="MM/dd/yyyy"
                            onChangeRaw={this.handleDateChangeRaw}
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={moment().year() - 1900}
                            selected={this.state.DOB ? this.state.DOB : null}
                            onChange={(date) => this.handleChangedob(date)}
                            maxDate={moment().subtract(18, 'years').toDate()}
                            minDate={moment("01/01/1900").toDate()}
                            ref={this.textInput}
                          />
                          <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={() => { this.textInput.current.input && this.textInput.current.input.focus() }}> <i className="fa fa-calendar-o"></i> </span>

                          {/* <input placeholder="enter dob" type="text" name="checkIn" id="DOB"  className="form-control datepicker" value={this.state.DOB} onChange={this.handleChange} />
                                    <span className="input-icon-addon input-icon-rights"> <i className="fa fa-calendar-o" aria-hidden="true"></i> </span>
                                    <input type="Date" className="form-control mb-0" placeholder="enter dob"id="DOB" value={this.state.DOB} onChange={this.handleChange}  max={moment().format("YYYY-MM-DD")}/> */}
                          {this.state.DError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.DError}</p>}
                        </div>
                      </div>
                  
                      <div className="col-md-6">
                        <div className="form-group addon-input">
                          <label htmlFor="exampleInputPassword">Password</label>
                          {/* <input type='text' autoComplete="off" className={!this.state.hidden && this.state.Password ? "form-control mb-0 passwordtext" : "form-control mb-0"} placeholder="enter password" id="Password" value={this.state.Password} onChange={(e) => this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} /> */}
                          <input type={!this.state.hidden ? 'password' : 'text'} autoComplete="off" className={"form-control mb-0"} placeholder="enter password" id="Password" value={this.state.Password} onChange={(e) => this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} />

                          {this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.togglePass}> <i className="fa fa-eye"></i> </span>}
                          {!this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.togglePass}> <i className="fa fa-eye-slash"></i> </span>}
                          {/* <span className="input-icon-addon input-icon-rights"> <i className="fa fa-eye"></i> </span> */}

                          {this.state.PWError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PWError}</p>}
                          {this.state.PassError && this.state.Password ?
                            (!this.state.isminmax || !this.state.isupper || !this.state.islower || !this.state.isspecial || !this.state.isnumber)
                              ?
                              <><p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left' }}>Your password needs to:</p>
                                <p className="error-msg-p" style={{ color: this.state.isupper && this.state.islower ? 'green' : 'red', fontSize: 12, float: 'left' }}>include both uppercase and lowercase characters.</p>
                                <p className="error-msg-p" style={{ color: this.state.isnumber ? 'green' : 'red', fontSize: 12, float: 'left' }}>include at least one number.</p>
                                <p className="error-msg-p" style={{ color: this.state.isspecial ? 'green' : 'red', fontSize: 12, float: 'left' }}>include at least one special character.</p>
                                <p className="error-msg-p" style={{ color: this.state.isminmax ? 'green' : 'red', fontSize: 12, float: 'left' }}>be at least 8 characters long.</p>
                                {/*<p style={{ color: this.state.isupper?'green':'red', fontSize: 14, float: 'left'}}>One or more uppercase letters.</p>
                            <p style={{ color: this.state.islower?'green':'red', fontSize: 14, float: 'left'}}>One or more lowercase letters.</p>
                            <p style={{ color: this.state.isnumber?'green':'red', fontSize: 14, float: 'left'}}>One or more numbers.</p>
                            <p style={{ color: this.state.isspecial?'green':'red', fontSize: 14, float: 'left'}}>One or more special characters.</p> */}
                              </> : null : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group addon-input">
                          <label htmlFor="exampleInputPassword1">Confirm Password</label>
                          {/* <input type='text' autoComplete="off" className={!this.state.hidden1 && this.state.confirmpass? "form-control mb-0 passwordtext" : "form-control mb-0"} placeholder="confirm password" id="confirmpass" value={this.state.confirmpass} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} /> */}
                          <input type={!this.state.hidden1 ? 'password' : 'text'} autoComplete="off" className="form-control mb-0" placeholder="confirm password" id="confirmpass" value={this.state.confirmpass} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} />

                          {this.state.hidden1 && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleCPass}> <i className="fa fa-eye"></i> </span>}
                          {!this.state.hidden1 && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleCPass}> <i className="fa fa-eye-slash"></i> </span>}

                          {this.state.CPWError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.CPWError}</p>}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="d-inline-block w-100">
                          <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                            <input type="checkbox" className="cursor-pointer custom-control-input" id="customCheck1" onClick={() => this.handleCheck()} defaultChecked={this.state.checked} />
                            <label className=" custom-control-label" htmlFor="customCheck1"> I agree to the Cizzr <a target="_blank" href={"/terms-condition"}>T&C</a></label>
                            {/* Terms and Conditions */}
                            {this.state.TCError && <div ><p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.TCError}</p> </div>}

                          </div>
                          <button type="submit" className="btn btn-primary float-right" onClick={() => this.Validation()}>Sign up</button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="sign-info border-0">
                          <span className="dark-color d-inline-block">Already have an account ? <a href={`/signin`}>Sign in</a></span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <Footer show={true} />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
    },
    RegistrationAPI: (postdata) => { dispatch(RegistrationAPI(postdata)) }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading,
    regRes: state.globalReducer.RegistrationRes
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUP);