import React from "react";
import { basepath, devicetype } from "../constants";
import {encryptStorage} from '../utils/utils'


const Sidebar = (props) => {
   // console.log('chatcount..',encryptStorage.getItem("chatcount"))
    return (
<div className="iq-sidebar">
<div id="sidebar-scrollbar">
   <nav className="iq-sidebar-menu">
      {/* <Share URL="https://web.cizzr.com/" Message="Hello..."/> */}
      <ul id="iq-sidebar-toggle" className="iq-menu">
         {/* <li>
            <p className="menu-sidebar">MENU</p>
         </li> */}
         <li className={props.active=="home"?"active":""}>
            <a className="iq-waves-effect " href={`/home`}><i className="ri-checkbox-multiple-blank-fill"></i><span>New Post</span></a>
         </li>
         <li className={props.active=="chat"?"active":""}>
            <a className="iq-waves-effect" href={`/chat`}><i className="ri-message-3-fill"></i><span>Chat</span>{props.active!="chat"&&encryptStorage.getItem("chatcount")!=undefined&&encryptStorage.getItem("chatcount")!=0&&<p className="noti-count">{encryptStorage.getItem("chatcount")>9?"9+":encryptStorage.getItem("chatcount")}</p>}</a>
         </li>
         <li className={props.active=="notification"?"active":""}>
            <a className="iq-waves-effect" href={`/notification`}><i className="ri-notification-2-fill"></i><span>Notification</span>{props.active!="notification"&&encryptStorage.getItem("notifycount")!=undefined&&encryptStorage.getItem("notifycount")!=0&&<p className="noti-count">{encryptStorage.getItem("notifycount")>9?"9+":encryptStorage.getItem("notifycount")}</p>}</a>
         </li>
         <li className={props.active=="profile"?"active":""}>
            <a className="iq-waves-effect" href={`/profile`}><i className="ri-user-fill"></i><span>Profile</span></a>
         </li>


      </ul>
   </nav>
   <div className="p-3"></div>
</div>
</div>
  )
}

export default Sidebar