import React from "react";
// import {basepath} from "./constants";
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";
import { getFCMToken } from './firebase';
import Home from './Component/Home';
import SignUP from './Component/signup';
import SignIN from './Component/signin';
import ForgotPW from './Component/forgotpassword';
import OTP from './Component/otp';
import ResetPW from './Component/resetpassword';
import Profile from './Component/Profile';
import Notification from './Component/Notification';
import Chat from './Component/ChatScreen';
import EditProfile from './Component/editprofile';
import Otherprofile from './Component/OtherUserProfile';
import setting from './Component/setting';
import PostDetail from './Component/PostDetails';
import Help from './Component/Help';
import Contact from './Component/Contactus';
import PrivacyPolicy from './Component/privacypolicy';
import TermsCondition from './Component/termscondition';
import AboutUs from './Component/Aboutus';
import SignupDetails from './Component/SignupDetails';
import { encryptStorage } from './utils/utils';
import Friends from './Component/Friends'
import emoji from './Component/emoji'

class App extends React.Component {

  componentDidMount() {
 
    var Token = encryptStorage.getItem('FCM_Token');
    if (!Token) {
      getFCMToken();
    }
  }
  render() {
    return (
      <Router>
        <Route exact path="/" render={() => { return (<Redirect to={`/home`} />) }} />
        <Route exact path={`/home`} component={Home} />
        <Route exact path={`/signup`} component={SignUP} />
        <Route exact path={`/signin`} component={SignIN} />
        <Route exact path={`/forgotpassword`} component={ForgotPW} />
        <Route exact path={`/otp`} component={OTP} />
        <Route exact path={`/resetpassword`} component={ResetPW} />
        <Route exact path={`/profile`} component={Profile} />
        <Route exact path={`/notification`} component={Notification} />
        <Route exact path={`/chat`} component={Chat} />
        <Route exact path={`/editprofile`} component={EditProfile} />
        <Route exact path={`/otherprofile/:id`} component={Otherprofile} />
        <Route exact path={`/setting`} component={setting} />
        <Route exact path={`/post/:id`} component={PostDetail} />
        <Route exact path={`/help`} component={Help} />
        <Route exact path={`/contact`} component={Contact} />
        <Route exact path={`/privacy-policy`} component={PrivacyPolicy} />
        <Route exact path={`/terms-condition`} component={TermsCondition} />
        <Route exact path={`/about-us`} component={AboutUs} />
        <Route exact path={`/friend`} component={Friends} />
        <Route exact path={`/signupdetails`} component={SignupDetails} />
     
        {/* <Route exact path={`/emoji`} component={emoji} /> */}
      </Router>
    )
  }
}

export default App;
