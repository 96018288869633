import React from "react";
import { connect } from "react-redux";
import img1 from "../asset/images/app-store.png";
import img2 from "../asset/images/sign-bg.png";
import flogo from "../asset/images/logo.svg";
import { LoginAPI } from "../actions/globalactions";
import { dispatchLoadingStatus, encryptStorage } from '../utils/utils';
import { devicetype } from "../constants";
import Loader from "./Loader";

import Slider from "./Slider"
import Footer from "./Footer";

class SignIN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: '',
      Password: '',
      hidden: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleChange(event) {
    if(localStorage.getItem("loginprocess")=="true"){
    }else{
      if (event.target.id == 'Password' && event.target.value != "") {

        this.setState({
          [event.target.id]: event.target.value.trim(),
          EError: "",
          PError: "",
        });
      } else {
        this.setState({
          [event.target.id]: event.target.value,
          EError: "",
          PError: "",
        });
      }
    } 

  }

  componentDidMount() {
    var token = encryptStorage.getItem("cizzr_token")
    // debugger
    if (!token) {
      localStorage.clear();
      encryptStorage.clear();
    } else {
      this.props.history.push(`/home`);
    }
  }


  EnterPressCall = (e) => {
    if (e.charCode == 13 || e.which == 13) {
      this.login()
    }
  }


  login = () => {

    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,3})$/;
    if (this.state.Email.trim() == "" || !this.state.Email) {
      this.setState({ EError: "Please enter Email." });
    } else if (this.state.Email && !mailFormat.test(this.state.Email)) {
      this.setState({ EError: "Please provide a valid Email." });
      return false;
    } else if (this.state.Password.trim() == "" || !this.state.Password) {
      this.setState({ PError: "Please enter Password." });
    }
    // else if (this.state.Password.length<8) {
    //   this.setState({ PError: "Password must be between 8 and 15." });
    // } 
    else {
      this.props.isLoading(true);
      this.APICall()
      localStorage.setItem("loginprocess",true)
    }
  }

  APICall = () => {
    if(localStorage.getItem("loginprocess")=="true"){

    }else{
      var Token = encryptStorage.getItem('FCM_Token');
    const postdata = {
      "email": this.state.Email,
      "password": this.state.Password,
      "fcm_token": Token ? Token : "",
      "device_type": devicetype
    }
    this.props.LoginAPI(postdata);  //status of check
    }    
  }

  componentWillReceiveProps(nextprops) {

    if (nextprops.loginRes !== null) {
      if (nextprops.loginRes !== this.props.loginRes) {
        this.props.history.push(`/home`);
      }
    }
  }

  render() {
    return (
      <>
        <Loader />
        {/* 
          <section className=" new-sign-in  overlay-bg"  >
	<div className="overlay-homeslider"></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 align-self-center">
                      <div className="sign-in-main">
                        <div className="sign-in-from">
                        
                            <h2 className="mb-0 text-center">Login</h2>
                            
                            <form className="mt-3 default-form">
                                <div className="form-group">
                                    <label className="label" for="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control mb-0" id="exampleInputEmail1" placeholder="Enter email" value="myemmail@gmail.com" />
                                </div>
                                <div className="form-group">
                                    <label className="label" for="exampleInputPassword1">Password</label>
              
                                    <input type="password" className="form-control mb-0" id="exampleInputPassword1" placeholder="Password" value="123456sd" />
                                </div>
                                <div className="d-inline-block w-100  mb-3">
                                    <div className="custom-control custom-checkbox d-inline-block ">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label cursur-pointer" for="customCheck1">Remember Me</label>
                                    </div>
                                  
                                                         <a href="#" className="float-right link-btn cursur-pointer">Forgot password?</a>
                                </div>
                                <div className="form-group text-center">
                                	  <a href="index.html"  className="btn default-btn">Sign In</a>
                                </div>
                                <div className="sign-info text-center">
                                    <span className="">Don't have an account? <a href="index.html" className="link-btn cursur-pointer">Sign up</a></span>
                                    
                                </div>
                            </form>
                        </div>
                      </div>
                    </div>
                    
                </div>
            </div>
        </section> */}

        {<section className="sign-in-page new-sign-in  overlay-bg">

          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-7  col-md-12 sm-displaynone">
                <div className="image-signin overlay-bg">
                <div className="overlay-homeslider"></div>
                  <div className="slider-text">
        <h3>A new twist on social media.<br /> Cutting the stigma around mental health. </h3>
                  </div>
              
                  <img src={img2} className="img-fluid mb-2" />
                </div>
              </div>
              <div className="col-lg-5 col-md-12   align-self-center ">
                <div className="sign-in-main">
                  <div className="sign-in-from">
                    <div className="sigin-logo-div">
                   
                      <img src={flogo}></img>
                      {/* <h1>Let's Cut The Stigma</h1> */}
                    </div>
                    <h1 className="signin-h1 mb-0">Sign in</h1>

                    <div className="mt-2 row ">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Email </label>
                          <input type="text" className="form-control mb-0" id="Email" placeholder="enter email " value={this.state.Email} onChange={this.handleChange} maxLength={80} onKeyPress={(e) => this.EnterPressCall(e)} />
                          {this.state.EError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.EError}</p>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group addon-input">
                          <label htmlFor="exampleInputPassword1">Password</label>
                          
                          {/* <input type='text' autoComplete="off" className={!this.state.hidden && this.state.Password ? "form-control mb-0 passwordtext" : "form-control mb-0"} id="Password" placeholder="enter password" value={this.state.Password} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} /> */}
                          <input type={!this.state.hidden?'password':'text'} autoComplete="off" className={"form-control mb-0"} id="Password" placeholder="enter password" value={this.state.Password} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} />
                          {this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleShow}> <i className="fa fa-eye"></i> </span>}
                          {!this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleShow}> <i className="fa fa-eye-slash"></i> </span>}

                          {this.state.PError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PError}</p>}
                        </div>
                      </div>


                      <div className="col-md-12">
                        <div className="d-inline-block w-100">
                          <div className=" d-inline-block pt-1">

                            <a href={`/forgotpassword`} className="float-right">Forgot password?</a>
                          </div>
                          <button type="submit" className="btn btn-primary float-right" onClick={() => this.login()}>Sign in</button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="sign-info">
                          <span className="dark-color d-inline-block">Don't have an account? <a href={`/signup`}>Sign up</a></span>

                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="download-app">
                          <h3>Download The App Now!</h3>
                          <a href="https://apps.apple.com/in/app/cizzr/id1573235257" target="_blank"> <img src={img1} className="img-fluid mb-2" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        }
      <Footer show={true}/>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
    },
    LoginAPI: (postdata) => { dispatch(LoginAPI(postdata)) }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading,
    loginRes: state.globalReducer.LoginRes
  }
}
export default connect(mapStateToProps, mapDispatchToProps)((SignIN))
