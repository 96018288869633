import React from 'react'
import { connect } from "react-redux";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import pageloadloader from "../asset/images/page-load-loader.gif";
import {
   GetOtherUserProfileAPI, GetOtherUserPostAPI, CommentListAPI, SavePostAPI, RemoveFromSaveAPI,
   UPVoteAPI, DownVoteAPI, CommentAPI, BasicInfoAPI, BasicCountAPI, BlockuserAPI, UnBlockuserAPI,
   UPVoteCmtAPI, DownVoteCmtAPI, AddFriendAPI, AcceptRejectFriendAPI
} from "../actions/globalactions";
import swal from 'sweetalert';
import { devicetype } from '../constants';
import Loader from './Loader';
import { basepath } from "../constants";
import user1 from "../asset/images/user1.svg";
import bgimg from "../asset/images/profile-bg1.jpg";
import PostCard from './Postcard';
import { dispatchLoadingStatus, encryptStorage } from '../utils/utils';
import { Img } from 'react-image';
import ShowMoreText from "react-show-more-text";

class Otherprofile extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         Pdata: [],
         userinfo: null,
         start: 0, loading: true,
         process:false
      }
      this.handleScroll = this.handleScroll.bind(this);
   }
   executeOnClick = (isExpanded) => {

      // console.log(isExpanded);
   }
   componentDidMount() {
      debugger
      var token = encryptStorage.getItem("cizzr_token")
      if (!token) {
         this.props.history.push(`/signin`);
      } else {
         
         
         var userid=this.props.match.params.id?this.props.match.params.id:this.props.location.state
         if(userid==encryptStorage.getItem('userid')){
            this.props.history.push(`/profile`);
         }else{
            window.addEventListener("scroll", this.handleScroll);
            const data = {
               "user_id": userid
            }
   
            const Postdata = {
               "user_id": userid,
               "device_type": devicetype,
               "start": this.state.start,
               "limit": 5//encryptStorage.getItem('othercount') ? encryptStorage.getItem('othercount') > 5 ? parseInt(encryptStorage.getItem('othercount')) + 2 : 5 : 5
            }
            this.props.isLoading(true);
            this.props.GetOtherUserProfileAPI(data);
            this.props.GetOtherUserPostAPI(Postdata);
            var Token = encryptStorage.getItem('FCM_Token');
            const pdata = {
               "fcm_token": Token ? Token : "",
               "device_type": devicetype
            }
            this.props.BasicCountAPI(pdata);
            if (!encryptStorage.getItem('basicInfoRes')) {
               this.props.BasicInfoAPI(pdata);
            }
            window.scrollTo(0, 0)
         }
        
      }
   }

   componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
   }
   componentWillReceiveProps(nextprops) {

      if (nextprops.userinfo) {
         if (nextprops.userinfo != this.props.userinfo) {
            this.setState({ userinfo: nextprops.userinfo })
            window.scrollTo(0,0);
         }
      }
    
      
      if (nextprops.otherpostres) {
         if (nextprops.otherpostres != this.props.otherpostres) {

            this.setState({ loading: false })
            if (this.state.Pdata && (this.state.Pdata.length == 0 || this.state.start == 0)) {
               this.setState({ Pdata: nextprops.otherpostres.data, Total_COUNT: nextprops.otherpostres.count, isnodata: nextprops.otherpostres.count > 0 ? '' : "This user don't have any post!" })
               window.scrollTo(0,0);
            }
            else {
               var new_data = this.state.Pdata && this.state.Pdata.concat(nextprops.otherpostres.data)
               this.setState({ Pdata: new_data, Total_COUNT: nextprops.otherpostres.count, isnodata: '' })
            }
         }
      }


      if (nextprops.commentlist) {
         if (nextprops.commentlist != this.props.commentlist) {

            if (this.state.CID != undefined) {
               var list = this.state.Pdata
               list[this.state.CID]["commentlist"] = nextprops.commentlist
               // list[this.state.CID]["is_open"] = true
               this.setState({ Pdata: list })
            }
         }
      }

      if (nextprops.savePost) {
         if (nextprops.savePost != this.props.savePost) {

            if (this.state.BID != undefined) {
               // var list = this.state.Pdata
               // list[this.state.BID]["is_save"] = !list[this.state.BID]["is_save"]
               // this.setState({ Pdata: list })
            }
         }
      }

      // if (nextprops.votePost) {
      //    if (nextprops.votePost != this.props.votePost) {

      //       if (this.state.LID != undefined) {
      //          var list = this.state.Pdata
      //          list[this.state.LID]["total_up_votes"] = nextprops.votePost.data
      //          list[this.state.LID]["is_up_vote"] = !list[this.state.LID]["is_up_vote"]
      //          this.setState({ Pdata: list })
      //       }
      //    }
      // }

      if (nextprops.unblockuser) {
         if (nextprops.unblockuser != this.props.unblockuser) {
            const Postdata = {
               "user_id": this.props.location.state,
               "device_type": devicetype,
               "start": this.state.start,
               "limit": 5
            }
            this.props.isLoading(true);
            this.props.GetOtherUserPostAPI(Postdata);
         }
      }

      if (nextprops.addFriendRes) {
         if (nextprops.addFriendRes != this.props.addFriendRes) {
            debugger
            var xuserinfo = this.state.userinfo
            if(xuserinfo){
               xuserinfo.data["friend_status"]=nextprops.addFriendRes.friend_status
               xuserinfo.data["friend_request_sent_by"]=nextprops.addFriendRes.friend_request_sent_by
               xuserinfo.data["friend_id"]=nextprops.addFriendRes.friend_id
               this.setState({userinfo:xuserinfo,process:false})
            }
           
            debugger
            var Friendlist = encryptStorage.getItem('friendtitle') == "Suggested Friends"?encryptStorage.getItem('SuggestedFriendsList'):encryptStorage.getItem('FriendsList') 
                  if(Friendlist && this.state.userinfo){
                     if(Friendlist.length>0){
                        var index = Friendlist.findIndex(i=>i._id?i._id==this.state.userinfo.data._id:i.user_id==this.state.userinfo.data._id)
                        var chngobj = Friendlist[index]
                        if(chngobj){
                           chngobj["friend_status"]=nextprops.addFriendRes.friend_status
                           chngobj["friend_request_sent_by"]=nextprops.addFriendRes.friend_request_sent_by
                           chngobj["friend_id"]=nextprops.addFriendRes.friend_id
                           Friendlist[index]=chngobj
                           if(encryptStorage.getItem('friendtitle') == "Suggested Friends"){
                              encryptStorage.setItem('SuggestedFriendsList',Friendlist)
                           }else{
                           encryptStorage.setItem('FriendsList',Friendlist) 
                           }
                        }      
                     }                              
                  }
         }
      }

      if (nextprops.accrejFriendRes) {
         if (nextprops.accrejFriendRes != this.props.accrejFriendRes) {
            debugger
            var xuserinfo = this.state.userinfo
            // if(nextprops.accrejFriendRes.status==1){
            //    xuserinfo.data["friend_status"]=1
            //    xuserinfo.data["friend_request_sent_by"]=1
            // }else if(nextprops.accrejFriendRes.status==2){
            //    xuserinfo.data["friend_status"]=2
            //    xuserinfo.data["friend_request_sent_by"]=2
            // }            
            // xuserinfo.data["friend_id"]=nextprops.addFriendRes
            if(xuserinfo){
               xuserinfo.data["friend_status"]=nextprops.accrejFriendRes.friend_status
               xuserinfo.data["friend_request_sent_by"]= nextprops.accrejFriendRes.friend_request_sent_by
               xuserinfo.data["friend_id"]=nextprops.accrejFriendRes.friend_id
               
               this.setState({userinfo:xuserinfo,process:false})
            }           
            debugger
            var isfrndreq = encryptStorage.getItem('friendtitle')
            if(isfrndreq){
               if(isfrndreq== "Friend Requests"){
                  var Friendlist = encryptStorage.getItem('FriendsReqList') 
                  if(Friendlist && this.state.userinfo){
                     if(Friendlist.length>0){
                        var index = Friendlist.findIndex(i=>i._id?i._id==this.state.userinfo.data._id:i.user_id==this.state.userinfo.data._id)
                        if (index > -1) {
                           Friendlist.splice(index, 1);
                       }
                       encryptStorage.setItem('FriendsReqList',Friendlist) 
                     }                    
                  }
               }else{
                  var Friendlist = isfrndreq == "Suggested Friends"?encryptStorage.getItem('SuggestedFriendsList'):encryptStorage.getItem('FriendsList') 
                  if(Friendlist && this.state.userinfo){
                     if(Friendlist.length>0){
                     var index = Friendlist.findIndex(i=>i._id?i._id==this.state.userinfo.data._id:i.user_id==this.state.userinfo.data._id)
                     var chngobj = Friendlist[index]
                     if(chngobj){
                        // if(nextprops.accrejFriendRes.status==1){
                        //    chngobj["friend_status"]=1
                        //    chngobj["friend_request_sent_by"]=1
                        // }else if(nextprops.accrejFriendRes.status==2){
                        //    chngobj["friend_status"]=2
                        //    chngobj["friend_request_sent_by"]=2
                        // }            
                        // chngobj["friend_id"]=nextprops.addFriendRes
                        chngobj["friend_status"]=nextprops.accrejFriendRes.friend_status
                        chngobj["friend_request_sent_by"]=nextprops.accrejFriendRes.friend_request_sent_by
                        chngobj["friend_id"]=nextprops.accrejFriendRes.friend_id
                        Friendlist[index]=chngobj
                        if(isfrndreq == "Suggested Friends"){
                           encryptStorage.setItem('SuggestedFriendsList',Friendlist)
                        }else{
                        encryptStorage.setItem('FriendsList',Friendlist) 
                        }
                     }  
                  }             
                  }
               }
            }
           
          
            
            debugger
         }
      }

   }


   handleScroll = () => {


      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
         if (this.state.Pdata) {
            if (this.state.Pdata.length > 4) {
               this.searching()
            }
         }
      }
   }

   searching = () => {

      if (this.state.Total_COUNT > this.state.start + 5) {
         const Postdata = {
            "user_id": this.props.location.state,
            "device_type": devicetype,
            "start": this.state.start+5,
            "limit": 5
         }
         this.setState({ loading: true })
         this.props.GetOtherUserPostAPI(Postdata);
         this.setState({ start: this.state.start + 5 })
      }
   }

   comments = (index, i) => {

      this.setState({ CID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.CommentListAPI(data)

   }

   bookmarkcall = (index, i) => {


      this.setState({ BID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      if (i.is_save) {
         this.props.RemoveFromSaveAPI(data)
      } else {
         this.props.SavePostAPI(data)
      }
      // var list = this.state.Pdata
      // list[index]["is_save"] = !list[index]["is_save"]
      // this.setState({ Pdata: list })

      var list = this.state.Pdata
      list[index]["is_save"] = !list[index]["is_save"]
      this.setState({ Pdata: list })
   }

   likeunlikecall = (index, i) => {

      var list = this.state.Pdata
      let count = list[index]["total_up_votes"]
      list[index]["total_up_votes"] = list[index]["is_up_vote"] == true ? count - 1 : count + 1
      list[index]["is_up_vote"] = !list[index]["is_up_vote"]
      if (list[index]["is_down_vote"] == true) {
         list[index]["total_down_votes"] = list[index]["total_down_votes"] - 1
         list[index]["is_down_vote"] = !list[index]["is_down_vote"]
      }
      this.setState({ Pdata: list })
      this.setState({ LID: index })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.UPVoteAPI(data)
      //   if(i.is_up_vote){
      //    this.props.DownVoteAPI(data)
      //   }else{

      //   }
      // var list = this.state.Pdata
      // list[index]["is_save"] = !list[index]["is_save"]
      // this.setState({ Pdata: list })

   }

   //cizzr 1.5 (NiraliBThummar 10Nov2021)
   downvotecall = (index, i) => {

      var list = this.state.Pdata
      let count = list[index]["total_down_votes"]
      list[index]["total_down_votes"] = list[index]["is_down_vote"] == true ? count - 1 : count + 1
      list[index]["is_down_vote"] = !list[index]["is_down_vote"]
      if (list[index]["is_up_vote"] == true) {
         list[index]["total_up_votes"] = list[index]["total_up_votes"] - 1
         list[index]["is_up_vote"] = !list[index]["is_up_vote"]
      }
      this.setState({ Pdata: list })
      const data = {
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.DownVoteAPI(data)

   }

   // cizzr 1.5 (NiraliBThummar 10Nov2021)
   cmntupvotecall = (cmtid, index, i) => {
      debugger
      var data = this.state.Pdata
      let postobj = data[index]
      var list = postobj.commentlist
      var cindex = list.map(item => item._id).indexOf(cmtid);
      let count = list[cindex]["total_up_votes"] ? list[cindex]["total_up_votes"] : 0
      list[cindex]["total_up_votes"] = list[cindex]["is_up_vote"] == true ? count - 1 : count + 1
      list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
      if (list[cindex]["is_down_vote"] == true) {
         list[cindex]["total_down_votes"] = list[cindex]["total_down_votes"] - 1
         list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
      }
      data[index]["commentlist"] = list
      this.setState({ Pdata: data })
      const req = {
         "comment_id": cmtid,
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.UPVoteCmtAPI(req)

   }

   //cizzr 1.5 (NiraliBThummar 10Nov2021)
   cmntdownvotecall = (cmtid, index, i) => {

      var data = this.state.Pdata
      let postobj = data[index]
      var list = postobj.commentlist
      var cindex = list.map(item => item._id).indexOf(cmtid);
      let count = list[cindex]["total_down_votes"] ? list[cindex]["total_down_votes"] : 0
      list[cindex]["total_down_votes"] = list[cindex]["is_down_vote"] == true ? count - 1 : count + 1
      list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
      if (list[cindex]["is_up_vote"] == true) {
         list[cindex]["total_up_votes"] = list[cindex]["total_up_votes"] - 1
         list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
      }
      data[index]["commentlist"] = list
      this.setState({ Pdata: data })
      const req = {
         "comment_id": cmtid,
         "post_id": i._id,
         "device_type": devicetype
      }
      this.props.DownVoteCmtAPI(req)

   }

   Addcomment = (comnt, isAnonymous, index, i) => {

      this.setState({ CID: index })
      const data = {
         "post_id": i._id,
         "message": comnt,
         "device_type": devicetype,
         "is_anonymous": isAnonymous ? "1" : "0"
      }
      this.props.CommentAPI(data)
   }

   Opencomment = (index) => {

      var list = this.state.Pdata
      list[index]["is_open"] = true
      list[index]["Clistcount"] = 3
      this.setState({ Pdata: list })

   }

   Collapsecomment = (index) => {

      var list = this.state.Pdata
      list[index]["is_open"] = list[index]["is_open"] ? !list[index]["is_open"] : true
      list[index]["Clistcount"] = 3
      this.setState({ Pdata: list })

   }


   Viewmore = (index) => {

      var list = this.state.Pdata
      list[index]["Clistcount"] = list[index]["Clistcount"] + 3
      this.setState({ Pdata: list })

   }

   gotochat = (data) => {

      this.props.history.push({
         pathname: `/chat`,
         state: data.threadData
      });
   }


   blockunblock = (data) => {
      swal({
         title: "",
         text: data.is_block == 0 ? "Are you sure you want to block " + data.name + "?" : "Are you sure you want to unblock " + data.name + "?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               var req = {
                  "to_user_id": data._id
               }
               if (data.is_block == 0) {
                  this.props.BlockuserAPI(req)
               } else {
                  this.setState({ isnodata: "", loading: true })
                  this.props.UnBlockuserAPI(req)
               }
               data.is_block = data.is_block == 0 ? 1 : 0
               var userdata = this.state.userinfo
               userdata.data = data
               this.setState({ userinfo: userdata })
            } else {
            }
         })

   }


   sendfriendrequest=(id)=>{
      debugger
      if(id){
         var sendreq = {    
            to_user_id :id,
            device_type: devicetype
        }
        this.props.AddFriendAPI(sendreq)
        this.setState({process:true})
      }
      
   }

   acceptrejectfriendrequest=(freqid,statusreq,status)=>{
      debugger
      if(freqid){
         var sendreq = {
            request_id : freqid,
            status : statusreq,
            cancel_request:statusreq==2 ? status==1 ? "0":"1":"0"
        }
        this.props.AcceptRejectFriendAPI(sendreq)
        this.setState({process:true})
      }
    
   }
   render() {
      // var scrollpos = encryptStorage.getItem('scrollposother');

      // if (scrollpos) {
      //    window.scrollTo(0, scrollpos);
      //    setTimeout(() => {
      //       encryptStorage.removeItem('scrollposother')
      //    }, 3000);

      // }
      return (
         <>
            <Loader />
            <div className="wrapper">
               <Sidebar />
               <Navbar />
               {this.state.userinfo ?
                  <div id="content-page" className="content-page">
                     <div className="container-fluid">
                        <div className="row">
                           <div className="col-sm-12">
                              <div className="iq-card profile-card">
                                 <div className="iq-card-body profile-page p-0">
                                    <div className="profile-header">
                                       <div className="cover-container">
                                          <img src={bgimg} className=" img-fluid" />

                                       </div>
                                       <div className="profile-data">
                                          <div className="row">
                                             <div className="col-md-6">
                                                <div className="user-detail">
                                                   <div className="profile-img">
                                                      {this.state.userinfo && this.state.userinfo.data &&
                                                         // <img src={this.state.userinfo.data.photo!=""?this.state.userinfo.data.photo:user1}  className="avatar-130 img-fluid" />
                                                         <Img className="avatar-100 img-fluid" src={this.state.userinfo.data.photo}
                                                            loader={<img src={user1} className="avatar-100 img-fluid" />} unloader={<img src={user1} className="avatar-100 img-fluid" />} />
                                                      }
                                                   </div>
                                                   <div className="profile-detail">
                                                      {this.state.userinfo && this.state.userinfo.data && this.state.userinfo.data.name && <h4 className="">{this.state.userinfo.data.name}</h4>}
                                                      {this.state.userinfo && this.state.userinfo.data && this.state.userinfo.data.bio && <div className="bio-details">
                                                         <ShowMoreText lines={3} more="Read more" less="Read less" className="content-css" anchorclassName="my-anchor-css-class" onClick={(isExpanded) => this.executeOnClick(isExpanded)} expanded={false} truncatedEndingComponent={"... "}>
                                                            {this.state.userinfo.data.bio.split("\n").map(function (item, idx) { return (<p key={idx}>{item} <br /></p>) })}
                                                         </ShowMoreText>
                                                      </div>}
                                                      {this.state.userinfo && this.state.userinfo.data && this.state.userinfo.data.website && <p className="website-details"><a target='_blank' href={this.state.userinfo.data.website.includes('http') ? this.state.userinfo.data.website : '//' + this.state.userinfo.data.website}>{this.state.userinfo.data.website}</a></p>}
                                                   </div>
                                                </div>
                                             </div>
                                             {this.state.userinfo &&
                                                <div className="col-md-6">
                                                   <div className="profile-info p-4 ">
                                                      <div className="social-info profile-btns">
                                                         {this.state.process ?null:
                                                         this.state.userinfo.data.friend_status == 2 ?
                                                            <button type="reset" className="btn submit-btn btn-primary mr-2" onClick={() => this.sendfriendrequest(this.state.userinfo.data._id)}>Add Friend</button>
                                                            : this.state.userinfo.data.friend_status == 1 || (this.state.userinfo.data.friend_status == 0 && this.state.userinfo.data.friend_request_sent_by == 0) ?
                                                               <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(this.state.userinfo.data.friend_id,2,this.state.userinfo.data.friend_status)}>{this.state.userinfo.data.friend_status == 1?'Remove':'Cancel Request'}</button>
                                                               : this.state.userinfo.data.friend_status == 0 && this.state.userinfo.data.friend_request_sent_by == 1 ?
                                                                  <><button type="reset" className="btn submit-btn btn-primary mr-2" onClick={() => this.acceptrejectfriendrequest(this.state.userinfo.data.friend_id,1,this.state.userinfo.data.friend_status)}>Accept</button>
                                                                     <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.acceptrejectfriendrequest(this.state.userinfo.data.friend_id,2,this.state.userinfo.data.friend_status)}>Reject</button></>
                                                                  : null}
                                                         {this.state.userinfo.data.is_block == 0 && <button type="reset" className="btn cancel-btn mr-2" onClick={() => this.gotochat(this.state.userinfo.data)}>Message</button>}
                                                         <button type="submit" className="btn submit-btn btn-primary " onClick={() => this.blockunblock(this.state.userinfo.data)}>{this.state.userinfo.data.is_block == 0 ? "Block" : "Unblock"}</button>
                                                      </div>
                                                   </div>
                                                </div>
                                             }
                                          </div>
                                       </div>
                                       {/* <div className="user-tabing">
                              <ul className="nav nav-pills profile-tabs  d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                                 <li className="col-sm-2 col-6 p-0 ">
                                    <a className="nav-link  active" data-toggle="pill" href="#timeline">{this.state.userinfo&&this.state.userinfo.data&&this.state.userinfo.data.name?this.state.userinfo.data.name+"'s Posts":null}</a>
                                 </li>
                              </ul>
                           </div> */}


                                    </div>
                                 </div>
                              </div>

                           </div>
                           {this.state.userinfo.data.is_block == 0 &&
                              <div className="col-sm-12">
                                 <div className="tab-content">
                                    <div className="tab-pane fade active show" id="timeline" role="tabpanel">
                                       {this.state.Pdata && this.state.Pdata &&
                                          this.state.Pdata.length > 0 ?
                                          this.state.Pdata.map((i, index) =>
                                             <PostCard data={i} key={i._id} index={index}
                                                bookmark={() => this.bookmarkcall(index, i)}
                                                getcomments={() => this.comments(index, i)}
                                                likeunlike={() => this.likeunlikecall(index, i)}
                                                addcomment={(val, isAnonymous) => this.Addcomment(val, isAnonymous, index, i)}
                                                opencomment={() => this.Opencomment(index)}
                                                viewmore={() => this.Viewmore(index)}
                                                collapsecomment={() => this.Collapsecomment(index)}
                                                downvote={() => this.downvotecall(index, i)} //cizzr 1.5 (NiraliBThummar 10Nov2021)
                                                cmntupvote={(cmtid) => this.cmntupvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                                cmntdownvote={(cmtid) => this.cmntdownvotecall(cmtid, index, i)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                             />
                                          ) : null}
                                    </div>
                                    {this.state.loading && <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                       <img src={pageloadloader} style={{ height: 80 }} />
                                    </div>}
                                    {this.state.isnodata && <p className="no-data-found">{this.state.isnodata}</p>}
                                 </div>
                              </div>
                           }
                        </div>
                     </div>
                  </div>
                  : null}

            </div>
         </>)
   }
}
const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => {
         dispatch(dispatchLoadingStatus(loading));
      },
      GetOtherUserProfileAPI: (postdata) => { dispatch(GetOtherUserProfileAPI(postdata)) },
      GetOtherUserPostAPI: (postdata) => { dispatch(GetOtherUserPostAPI(postdata)) },
      CommentListAPI: (postdata) => { dispatch(CommentListAPI(postdata)) },
      SavePostAPI: (postdata) => { dispatch(SavePostAPI(postdata)) },
      RemoveFromSaveAPI: (postdata) => { dispatch(RemoveFromSaveAPI(postdata)) },
      UPVoteAPI: (postdata) => { dispatch(UPVoteAPI(postdata)) },
      DownVoteAPI: (postdata) => { dispatch(DownVoteAPI(postdata)) },
      CommentAPI: (postdata) => { dispatch(CommentAPI(postdata)) },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
      BlockuserAPI: (data) => { dispatch(BlockuserAPI(data)) },
      UnBlockuserAPI: (data) => { dispatch(UnBlockuserAPI(data)) },
      UPVoteCmtAPI: (postdata) => { dispatch(UPVoteCmtAPI(postdata)) },
      DownVoteCmtAPI: (postdata) => { dispatch(DownVoteCmtAPI(postdata)) },
      AddFriendAPI: (postdata) => { dispatch(AddFriendAPI(postdata)) },
      AcceptRejectFriendAPI: (postdata) => { dispatch(AcceptRejectFriendAPI(postdata)) },
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      userinfo: state.globalReducer.OtherProfileRes,
      otherpostres: state.globalReducer.OtherPostRes,
      commentlist: state.globalReducer.CommentListRes,
      savePost: state.globalReducer.SavePostRes,
      // votePost: state.globalReducer.VoteRes,
      unblockuser: state.globalReducer.UnBlockuserRes,
      addFriendRes: state.globalReducer.AddFriendRes,
      accrejFriendRes: state.globalReducer.AcceptRejectFriendRes
   }
}
export default connect(mapStateToProps, mapDispatchToProps)((Otherprofile))
