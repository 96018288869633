import { currentorigin, crossorigin } from '../constants';
import { encryptStorage } from './utils'
var createGuest = require("cross-domain-storage/guest");
var createHost = require("cross-domain-storage/host");

export const getCizzrdatafromcrossdomain = () => {
  // var token,myname;
  
  var storageHost = createHost([
    {
      origin: currentorigin,
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: crossorigin,
      allowedMethods: ["get", "set", "remove"],
    },
  ]);
  
  var myStorage = createGuest(
    window.location.origin === currentorigin
      ? crossorigin
      : currentorigin
  );

  if (myStorage) {
    debugger
    myStorage.get("admin_web_data", function (error, value) {
      // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
      if (error) {
        debugger
        console.log(error)
      } else {
        debugger
        var admin_web_data = JSON.parse(value);
        encryptStorage.setItem('cizzr_token', admin_web_data.token_type + " " + admin_web_data.access_token);
        encryptStorage.setItem('username', admin_web_data.username ? admin_web_data.username : '');
        encryptStorage.setItem('myname', admin_web_data.name ? admin_web_data.name : '');
        encryptStorage.setItem('useremail', admin_web_data.email ? admin_web_data.email : '');
        encryptStorage.setItem('userid', admin_web_data._id);
        encryptStorage.setItem('userpic', admin_web_data.photo ? admin_web_data.photo : '');
        encryptStorage.setItem('userbio', admin_web_data.basic_info.userData.bio ? admin_web_data.basic_info.userData.bio : '');
        encryptStorage.setItem('basicInfoRes', admin_web_data.basic_info);
        debugger
        storageHost.close();
        window.location.reload();
      }
    });
  }
}

