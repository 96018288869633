import React from "react";
import { connect } from "react-redux";
import Header from './Header';
import Footer from './Footer';
import parse from "html-react-parser";
import {PageDetailsAPI} from "../actions/globalactions";
import pageloadloader from "../asset/images/page-load-loader.gif";

class TermsCondition extends React.Component {
  componentDidMount(){
    this.props.PageDetailsAPI({
      "slug": "terms-and-condition"
    });
  }
    render() {
        return (
       <>
       <Header/>
         <section className="main-section privacy-policy-sec">
           
         <div className="container">
           <div className="row">
             <div className="col-lg-12">
               <h1 className="privacy-policy-title"> Terms and Conditions</h1>
             </div>
             <div className="col-lg-12"> 
             {this.props.pageres?this.props.pageres.data&&
             this.props.pageres.data.body?parse(this.props.pageres.data.body):
             <p className="no-data-found">No Data Found</p> :
             <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                              <img src={pageloadloader} style={{ height: 80 }} />
                           </div>}
             </div>
           </div>
         </div>  
      
     </section>
     <Footer/>   
     </>
        )
    }
}
    const mapDispatchToProps = (dispatch, ownProps) => {
        return {
          PageDetailsAPI: (data) => { dispatch(PageDetailsAPI(data)) }
        }
     }
     const mapStateToProps = (state, ownProps) => {
        return {
          pageres: state.globalReducer.PageDetailsRes
        }
     }
     export default connect(mapStateToProps, mapDispatchToProps)((TermsCondition))