import React, { useEffect, useState } from "react";
import { devicetype } from "../constants";
import { dispatchLoadingStatus, encryptStorage } from '../utils/utils';
import Loader from "../Component/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ForgotPassAPI } from "../actions/globalactions";
import img2 from "../asset/images/sign-bg.png";
import flogo from "../asset/images/logo.svg";
import Slider from "./Slider"
import Footer from "./Footer";
const ForgotPW = () => {
    const [Email, setEmail] = useState("");
    const [EError, setEError] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();

    const loading = useSelector(state => state.LoadingStatusReducer.loading);
    const fpRes = useSelector(state => state.globalReducer.ForgotPassRes);

    useEffect(() => {

        if (fpRes != null) {
            history.push(`/otp`);
            encryptStorage.setItem("ISOTP", true);
        }
    }, [fpRes]);


    function getOtp() {
        var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,3})$/;
        if (!Email) {
            setEError("Please enter your Email.");
        } else if (Email && !mailFormat.test(Email)) {
            setEError("Please provide a valid Email.");
            return false;
        } else {
            APICall();
            localStorage.setItem("forgotprocess",true)
        }
    }

    function APICall() {
        if(localStorage.getItem("forgotprocess")=="true"){

        }else{
        dispatch(dispatchLoadingStatus(true));
        const postdata = {
            "email": Email,
            "device_type": devicetype
        }
        // alert("ForgotPassAPI success");
        dispatch(ForgotPassAPI(postdata));
    }
    }

    function EnterPressCall(e) {
        if (e.charCode == 13 || e.which == 13) {
            getOtp()
        }
    }

    return (
        <>
            <Loader />
            <section className="sign-in-page new-sign-in  overlay-bg">

                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                    <div className="col-lg-7  col-md-12 sm-displaynone">
                <div className="image-signin overlay-bg">
                <div className="overlay-homeslider"></div>
                  <div className="slider-text">
        <h3>A new twist on social media.<br /> Cutting the stigma around mental health. </h3>
                  </div>
              
                  <img src={img2} className="img-fluid mb-2" />
                </div>
            </div>
                        <div className="col-lg-5 col-md-12   align-self-center ">
                            <div className="sign-in-main">
                                <div className="sign-in-from">
                                    <div className="sigin-logo-div"><img src={flogo}></img>
                                        {/* <h1>Let's Cut The Stigma</h1> */}
                                    </div>
                                    <h1 className="signin-h1 mb-0">Forgot Password</h1>

                                    <div className="mt-4 row ">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Email  </label>
                                                <input type="text" className="form-control mb-0" id="exampleInputEmail1" placeholder="enter email" onChange={(e) => { setEmail(localStorage.getItem("forgotprocess")=="true"?Email:e.target.value); setEError("") }} maxLength={80} onKeyPress={(e) => EnterPressCall(e)} />
                                                {EError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{EError}</p>}
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="d-inline-block w-100">

                                                <button type="submit" className="btn btn-primary float-right" onClick={getOtp}>Get OTP</button>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="sign-info border-0">
                                                <span className="dark-color  d-inline-block line-height-2">Don't have an account? <a href={`/signup`}>Sign up</a></span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer show={true}/>
        </>
    )
}

export default ForgotPW

