import React from "react";
import { connect } from "react-redux";
import Header from './Header';
import Footer from './Footer';
import parse from "html-react-parser";
import { SaveFeedbackAPI } from "../actions/globalactions";
import pageloadloader from "../asset/images/page-load-loader.gif";
import Loader from "./Loader";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {isValidName,dispatchLoadingStatus} from '../utils/utils'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import ReCAPTCHA from "react-google-recaptcha";
import {ReCAPTCHAkey} from "../constants"
toast.configure()

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      Name: '',
      Email: '',
      Phone: '',
      Message: '',
      PhoneNo:'',
      Ccode:'',
      Captchaverify:false,
      apicall:false
    }
  }

  onChange(value) {
    this.setState({Captchaverify:value?true:false}) 
  }

  Validationnum = (phoneNumber) => {

    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      if (phoneNumber) {
        valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
      }
    } catch (e) {
      valid = false;
    }
    if (valid) {
      return true;
      // this.setState({
      //   message:'Phone number '+this.getValidNumber(phoneNumber)+' is valid',
      //   color:'green'
      // });
    } else {
      return false;
      // this.setState({
      //   message:'Phone number '+phoneNumber+' is not valid',
      //   color:'red'
      // });
    }
  }

  feedbackcall() {
    debugger
    var isValidphone = this.state.PhoneNo && this.state.PhoneNo!="1" ? this.Validationnum("+" + this.state.PhoneNo) : true;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,3})$/;
    var phoneFormat = /^[0-9]*$/;
    if (!this.state.Name) {
      this.setState({ NError: "Please enter your full name." });
    } else if (!this.state.Email && !this.state.PhoneNo) {
      this.setState({ Error: "Please enter your email or phone number." });
    } else if (this.state.Email && !mailFormat.test(this.state.Email)) {
      this.setState({ EError: "Please provide a valid email." });
    } else if (this.state.PhoneNo && this.state.PhoneNo != '' && !isValidphone) {
      this.setState({ PNError: "Please provide a valid phone number." });
    } else if (!this.state.Message) {
      this.setState({ MError: "Please enter message." });
    } else {
      this.props.isLoading(true);
      this.setState({apicall:true})
      this.props.SaveFeedbackAPI({
        "name":this.state.Name,
        "email":this.state.Email,
        "phone_number":this.state.PhoneNo?parseInt(this.state.PhoneNo):null,
        "feedback":this.state.Message,
        "country_code":this.state.PhoneNo?this.state.Ccode?this.state.Ccode:"1":null
    })
      
      }
  }

  componentWillReceiveProps(nextprops){
    
    if (nextprops.feedbackRes) {
      if (nextprops.feedbackRes != this.props.feedbackRes) {
      console.log("dxzczxc  ",nextprops.feedbackRes)
      toast.success(nextprops.feedbackRes.message, {
        style: { fontSize: 14 },
        autoClose: 3000
      });
      this.captcha.reset()
      this.setState({apicall:false,
        Name: '', NError:"",
        Email: '', EError:"",
        Phone: '1',PhoneNo:'', Ccode:'1',PNError:"", 
        Message: '',  MError:"",
        Captchaverify:false ,Error:"",})
    
      }
   }
  }
  render() {
    return (
      <>
            <Loader />
        <Header />
        <section className="main-section privacy-policy-sec">

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4 style={{cursor:'pointer'}} onClick={()=>this.props.history.push(`/help`)}> Help</h4>
                <span style={{marginLeft:20}}></span>
                <h4 className="privacy-policy-title" style={{cursor:'pointer'}} > Contact us</h4>
              </div>
              <div className="container">
                <div className="iq-card">
                  <div className="iq-card-body">
                  <div className="row">
                 <div className="form-group  col-md-6">
              <label htmlFor="fname"> Name :</label>
              <input type="text" className="form-control" id="fname" placeholder="enter your full name" value={this.state.Name} onChange={(e) => this.setState({ Name: e.target.value.trim() == '' ? '' : isValidName(e.target.value)?e.target.value:this.state.Name, NError: '' })} maxLength={30} />
              {this.state.NError && <p style={{ margin:0, color: 'red', fontSize: 14, textAlign: 'left', display: 'block' , width: '100%'}}>{this.state.NError}</p>}
            </div>
            <div className="form-group  col-md-6">
              <label htmlFor="email"> Email :</label>
              <input type="text" className="form-control" id="email" placeholder="enter your email" value={this.state.Email} onChange={(e) =>{debugger; this.setState({ Email: e.target.value.trim() == '' ? '' : e.target.value, Error: '',EError:"" })}} maxLength={320} />
              {this.state.EError && <p style={{ margin:0, color: 'red', fontSize: 14, textAlign: 'left', display: 'block' , width: '100%'}}>{this.state.EError}</p>}
            </div>
            </div> 
            <div className="row ">
            <div className="form-group  col-md-6">
              <label htmlFor="phone"> Phone :</label>
              <PhoneInput
              placeholder={'enter your phone'}
                            countryCodeEditable={false}
                            country={'us'}
                            value={this.state.Phone}
                            onChange={(phone, country) => {debugger; this.setState({ Phone: phone, PNError: "", PhoneNo: country.dialCode != phone ? phone : "", Ccode: country.dialCode ,Error: '',PNError:""}) }}
                          />
                          {this.state.PNError && <p style={{  margin:0, color: 'red', fontSize: 14, textAlign: 'left', display: 'block' , width: '100%'}}>{this.state.PNError}</p>}
            </div>
            <div className="form-group  col-md-6">
              <label htmlFor="msg"> Feedback :</label>
              <TextareaAutosize style={{height:110, lineHeight:1.6  }} className="form-control bio-textarea" id='msg' placeholder="enter your message" value={this.state.Message} onChange={(e) => this.setState({ Message: e.target.value.trim() == '' ? '' : e.target.value, MError: '' })} maxLength={500} />
              {this.state.MError && <p style={{  margin:0,color: 'red', fontSize: 14, textAlign: 'left', display: 'block' , width: '100%'}}>{this.state.MError}</p>}
            </div>
            </div> 
            <div className="row ">
            <div className="col-md-12 text-center">
           <div className="captcha-code">
            <ReCAPTCHA
            ref={(r) => this.captcha = r}
    sitekey={ReCAPTCHAkey}
    onChange={(val)=>this.onChange(val)}
  />
   {this.state.Error && <p style={{ color: 'red', fontSize: 14, textAlign: 'center', display: 'block' , width: '100%'}}>{this.state.Error}</p>}
           </div>
            
            </div>
            <div className="col-md-12 text-center">
            <button type="submit" disabled={this.state.Captchaverify && !this.state.apicall?false:true} className="mt-2 btn btn-primary" onClick={() => this.feedbackcall()}>Submit</button>
            </div>
            </div> 
                
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
   },
    SaveFeedbackAPI: (data) => { dispatch(SaveFeedbackAPI(data)) }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading,
    feedbackRes: state.globalReducer.FeedbackRes
  }
}
export default connect(mapStateToProps, mapDispatchToProps)((Contact))