import {
  LoginRes, RegistrationRes, RegistrationReq, OTPRegistrationRes, ForgotPassRes, OTPForgotPassRes,
  ResetPassRes, BasicInfoRes, PostRes, PostListRes, SearchPostRes, NearPostRes, PostDetailsRes, VoteRes,
  UPVoteListRes, DownVoteListRes, CommentRes, CommentListRes, SavePostRes, RemoveFromSaveRes, SavePostListRes,
  ProfilePicRes, GetProfileRes, UpdateProfileRes, NotificationListRes, DeleteNotificationRes, ReadNotificationRes,
  MyPostRes, SavedPostRes, OtherProfileRes, OtherPostRes, ChangePassRes, GetSettingRes, SetSettingRes,
  ThreadListRes, AddThreadRes, AcceptRejectRequestRes, AllUserRes, DeletePostRes, ChatDetailsRes, SendMessageRes
  , DeleteChatRes, BlockuserRes, UnBlockuserRes, BlockuserListRes, PostDetailRes, PageDetailsRes, AddFriendRes,
  AcceptRejectFriendRes, FriendListRes, FriendReqListRes, FriendSuggestedRes, MutualFriendRes,SearchUsersRes,FeedbackRes
} from "../constants";

let defaultState = {
  LoginRes: null,
  RegistrationReq: null,
  RegistrationRes: null,
  OTPRegistrationRes: null,
  ForgotPassRes: null,
  OTPForgotPassRes: null,
  ResetPassRes: null,
  BasicInfoRes: null,
  PostRes: null,
  PostListRes: null,
  SearchPostRes: null,
  NearPostRes: null,
  PostDetailsRes: null,
  VoteRes: null,
  UPVoteListRes: null,
  DownVoteListRes: null,
  CommentRes: null,
  CommentListRes: null,
  SavePostRes: null,
  RemoveFromSaveRes: null,
  SavePostListRes: null,
  ProfilePicRes: null,
  UpdateProfileRes: null,
  NotificationListRes: null,
  DeleteNotificationRes: null,
  ReadNotificationRes: null,
  MyPostRes: null,
  SavedPostRes: null,
  OtherProfileRes: null,
  OtherPostRes: null,
  ChangePassRes: null,
  GetSettingRes: null,
  SetSettingRes: null,
  ThreadListRes: null,
  AddThreadRes: null,
  AcceptRejectRequestRes: null,
  AllUserRes: null,
  DeletePostRes: null,
  ChatDetailsRes: null,
  SendMessageRes: null,
  DeleteChatRes: null,
  BlockuserRes: null,
  UnBlockuserRes: null,
  BlockuserListRes: null,
  PostDetailRes: null,
  PageDetailsRes: null,
  AddFriendRes: null,
  AcceptRejectFriendRes: null,
  FriendListRes: null,
  FriendReqListRes: null,
  FriendSuggestedRes: null,
  MutualFriendRes: null,
  SearchUsersRes:null,
  FeedbackRes:null
};

const globalReducer = (state = defaultState, action) => {

  switch (action.type) {
    case LoginRes:
      return Object.assign({}, state, {
        LoginRes: action.payload
      });
    case RegistrationReq:
      return Object.assign({}, state, {
        RegistrationReq: action.payload
      });
    case RegistrationRes:
      return Object.assign({}, state, {
        RegistrationRes: action.payload
      });
    case OTPRegistrationRes:
      return Object.assign({}, state, {
        OTPRegistrationRes: action.payload
      });
    case ForgotPassRes:
      return Object.assign({}, state, {
        ForgotPassRes: action.payload
      });
    case OTPForgotPassRes:
      return Object.assign({}, state, {
        OTPForgotPassRes: action.payload
      });
    case ResetPassRes:
      return Object.assign({}, state, {
        ResetPassRes: action.payload
      });
    case BasicInfoRes:
      return Object.assign({}, state, {
        BasicInfoRes: action.payload
      });
    case PostRes:
      return Object.assign({}, state, {
        PostRes: action.payload
      });
    case PostListRes:
      return Object.assign({}, state, {
        PostListRes: action.payload
      });
    case SearchPostRes:
      return Object.assign({}, state, {
        SearchPostRes: action.payload
      });
    case NearPostRes:
      return Object.assign({}, state, {
        NearPostRes: action.payload
      });
    case PostDetailsRes:
      return Object.assign({}, state, {
        PostDetailsRes: action.payload
      });
    case VoteRes:
      return Object.assign({}, state, {
        VoteRes: action.payload
      });
    case UPVoteListRes:
      return Object.assign({}, state, {
        UPVoteListRes: action.payload
      });
    case DownVoteListRes:
      return Object.assign({}, state, {
        DownVoteListRes: action.payload
      });
    case CommentRes:
      return Object.assign({}, state, {
        CommentRes: action.payload
      });
    case CommentListRes:
      return Object.assign({}, state, {
        CommentListRes: action.payload
      });
    case SavePostRes:
      return Object.assign({}, state, {
        SavePostRes: action.payload
      });
    case RemoveFromSaveRes:
      return Object.assign({}, state, {
        RemoveFromSaveRes: action.payload
      });
    case SavePostListRes:
      return Object.assign({}, state, {
        SavePostListRes: action.payload
      });
    case ProfilePicRes:
      return Object.assign({}, state, {
        ProfilePicRes: action.payload
      })
    case GetProfileRes:
      return Object.assign({}, state, {
        GetProfileRes: action.payload
      })
    case UpdateProfileRes:
      return Object.assign({}, state, {
        UpdateProfileRes: action.payload
      })
    case NotificationListRes:
      return Object.assign({}, state, {
        NotificationListRes: action.payload
      })
    case DeleteNotificationRes:
      return Object.assign({}, state, {
        DeleteNotificationRes: action.payload
      })
    case ReadNotificationRes:
      return Object.assign({}, state, {
        ReadNotificationRes: action.payload
      })
    case MyPostRes:
      return Object.assign({}, state, {
        MyPostRes: action.payload
      })
    case SavedPostRes:
      return Object.assign({}, state, {
        SavedPostRes: action.payload
      })
    case OtherProfileRes:
      return Object.assign({}, state, {
        OtherProfileRes: action.payload
      })
    case OtherPostRes:
      return Object.assign({}, state, {
        OtherPostRes: action.payload
      })
    case ChangePassRes:
      return Object.assign({}, state, {
        ChangePassRes: action.payload
      })
    case GetSettingRes:
      return Object.assign({}, state, {
        GetSettingRes: action.payload
      })
    case SetSettingRes:
      return Object.assign({}, state, {
        SetSettingRes: action.payload
      })
    case ThreadListRes:
      return Object.assign({}, state, {
        ThreadListRes: action.payload
      })
    case AddThreadRes:
      return Object.assign({}, state, {
        AddThreadRes: action.payload
      })
    case AcceptRejectRequestRes:
      return Object.assign({}, state, {
        AcceptRejectRequestRes: action.payload
      })
    case AllUserRes:
      return Object.assign({}, state, {
        AllUserRes: action.payload
      })
    case DeletePostRes:
      return Object.assign({}, state, {
        DeletePostRes: action.payload
      });
    case ChatDetailsRes:
      return Object.assign({}, state, {
        ChatDetailsRes: action.payload
      });
    case SendMessageRes:
      return Object.assign({}, state, {
        SendMessageRes: action.payload
      });
    case DeleteChatRes:
      return Object.assign({}, state, {
        DeleteChatRes: action.payload
      });
    case BlockuserListRes:
      return Object.assign({}, state, {
        BlockuserListRes: action.payload
      });
    case PostDetailRes:
      return Object.assign({}, state, {
        PostDetailRes: action.payload
      });
    case PageDetailsRes:
      return Object.assign({}, state, {
        PageDetailsRes: action.payload
      });
    case UnBlockuserRes:
      return Object.assign({}, state, {
        UnBlockuserRes: action.payload
      });
    case AddFriendRes:
      return Object.assign({}, state, {
        AddFriendRes: action.payload
      });
    case AcceptRejectFriendRes:
      return Object.assign({}, state, {
        AcceptRejectFriendRes: action.payload
      });
    case FriendListRes:
      return Object.assign({}, state, {
        FriendListRes: action.payload
      });
    case FriendReqListRes:
      return Object.assign({}, state, {
        FriendReqListRes: action.payload
      });
    case FriendSuggestedRes:
      return Object.assign({}, state, {
        FriendSuggestedRes: action.payload
      });
    case MutualFriendRes:
      return Object.assign({}, state, {
        MutualFriendRes: action.payload
      });
      case SearchUsersRes:
      return Object.assign({}, state, {
        SearchUsersRes: action.payload
      });
      case FeedbackRes:
      return Object.assign({}, state, {
        FeedbackRes: action.payload
      });
      
    default:
      return state;
  }
};
export default globalReducer;
