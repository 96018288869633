import React from "react";
import logo from "../asset/images/logo.svg";

const Header = () => {
   return (

      <div className="iq-top-navbar">

   <div className="iq-navbar-custom">
   <div className="container">
<div className="row">
   <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
               <div className="iq-navbar-custom-logo d-flex justify-content-between">
                  <a href={`/home`}>
                     <img src={logo} className="img-fluid" />
                  </a>
               </div>
            </nav>
         </div>
   </div>
</div>
         </div>
         </div>
   )
}

export default Header