import React from "react";
import moment from 'moment';
const Footer = (props) => {
    return (  
    <footer className=" iq-footer pt-2 pb-2">
    <div className="container-fluid">
       <div className="row align-items-center">


         {props.show && <div className="col-lg-9">
             <ul className="list-inline mb-0 privacy-poicy">
                <li className="list-inline-item"><a target="_blank" href={"/privacy-policy"}>Privacy Policy</a></li>
                <li className="list-inline-item">|<i className=""></i></li>
                <li className="list-inline-item"><a target="_blank" href={"/help"}>Help</a></li>
                <li className="list-inline-item">|<i className=""></i></li>
                <li className="list-inline-item"><a target="_blank" href={"/about-us"}>About Us</a></li>

             </ul>
          </div>}

          <div className={props.show?"col-lg-3 text-right":"col-lg-12 text-center"}>
             <p className="copyright-sec">  Copyright © {moment().year()} <a href={`/home`} className="cizzrlink">Cizzr.    </a> All Rights Reserved.</p>
          </div>
       </div>
    </div>
 </footer>)
}

export default Footer