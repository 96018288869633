import React from "react";
import { connect } from "react-redux";
import { ChangePassAPI, GetSettingAPI, SetSettingAPI, BlockuserListAPI, UnBlockuserAPI ,BasicInfoAPI,BasicCountAPI,DeleteAcAPI} from "../actions/globalactions";
import pageloadloader from "../asset/images/page-load-loader.gif";
import { dispatchLoadingStatus, Validatepass,encryptStorage } from '../utils/utils';
import { basepath, devicetype } from "../constants";
import Loader from "./Loader";
import Sidebar from './Sidebar';
import swal from 'sweetalert';
import Navbar from './Navbar';
import { Img } from 'react-image';
import user1 from "../asset/images/user1.svg";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
toast.configure()
class Settings extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         privacy: false,
         termuse: false,
         changepass: true,
         pushnotify: false,
         blocklist: false,
         oeye: false,
         peye: false,
         cpeye: false,
      }
      this.handleChange = this.handleChange.bind(this);
      this.toggleOeye = this.toggleOeye.bind(this);
      this.togglePeye = this.togglePeye.bind(this);
      this.toggleCPeye = this.toggleCPeye.bind(this);
   }

   componentDidMount() {
      var token = encryptStorage.getItem("cizzr_token")
      if (!token) {
         this.props.history.push(`/signin`);
      } else {
         this.props.GetSettingAPI()
         var Token = encryptStorage.getItem('FCM_Token');
         const postdata = {
            "fcm_token": Token ? Token : "",
            "device_type": devicetype
         }
         this.props.BasicCountAPI(postdata);
         if(!encryptStorage.getItem('basicInfoRes')){
            this.props.BasicInfoAPI(postdata);
         }
      }
   }

   handleChange(event) {
      if(localStorage.getItem("changepassprocess")=="true"){}else{
      if (event.target.value != "") {
         this.setState({
            [event.target.id]: event.target.value.trim(),
            OError: '',
            PError: '',
            CPError: ''
         });
      } else {
         this.setState({
            [event.target.id]: event.target.value,
            OError: '',
            PError: '',
            CPError: ''
         });
      }
   }
   }


   toggleOeye() {
      this.setState({ oeye: !this.state.oeye });
   }
   togglePeye() {
      this.setState({ peye: !this.state.peye });
   }
   toggleCPeye() {
      this.setState({ cpeye: !this.state.cpeye });
   }

   cancelcall() {
      this.setState({ oldpass: "", newpass: "", confirmpass: "" });
   }


   validatepass = (passw) => {
      if(localStorage.getItem("changepassprocess")=="true"){}else{
      // var minMaxLength = /^[\s\S]{8,15}$/,
      var upper = /[A-Z]/,
         lower = /[a-z]/,
         number = /[0-9]/,
         special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

      this.setState({
         PError: "",
         PassError: true,
         newpass: passw != "" ? passw.trim() : "",
         isminmax: passw.length > 7 ? true : false,
         isupper: upper.test(passw) ? true : false,
         islower: lower.test(passw) ? true : false,
         isnumber: number.test(passw) ? true : false,
         isspecial: special.test(passw) ? true : false
      })
   }
   }

   EnterPressCall = (e) => {
      if(e.charCode==13 || e.which==13){
        this.Validation()
      }
    }

   Validation() {
      
      // var passFormat = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;
      if (!this.state.oldpass) {
         this.setState({ OError: "Please enter Old Password." });
      } else if (!this.state.newpass) {
         this.setState({ PError: "Please enter New Password." });
      }
      // else if (this.state.newpass.length < 8) {
      //     this.setState({ PError: "Password must be between 8 to 15." });
      // } 
      else if (this.state.oldpass == this.state.newpass) {
         this.setState({ PError: "New Password should not be same as Old Password." });
      }
      else if (!Validatepass(this.state.newpass)) {
         this.setState({ PassError: true });
      }
      //   else if (!passFormat.test(this.state.newpass)) {
      //    this.setState({ PError: "Password must be between 8 to 15 characters and it contains at least 1 letter in Uppercase, 1 letter in Lowercase, 1 Special Character (!@#$&*) and 1 Number." });
      //  }
      else if (!this.state.confirmpass) {
         this.setState({ CPError: "Please enter Confirm Password." });
      } else if (this.state.newpass !== this.state.confirmpass) {
         this.setState({ CPError: "Password and Confirm Password does not match." });
      } else {
         this.props.isLoading(true);
         const postdata = {
            "current_password": this.state.oldpass,
            "new_password": this.state.newpass,
            "confirm_password": this.state.confirmpass
         }
         if(localStorage.getItem("changepassprocess")=="true"){}else{
         this.props.ChangePassAPI(postdata)
         localStorage.setItem("changepassprocess",true)
         }
      this.setState({oldpass:"",newpass:"",confirmpass:""})
      }


   }

   componentWillReceiveProps(nextprops) {
      
      if (nextprops.changepwRes != null) {
         if (nextprops.changepwRes !== this.props.changepwRes) {
            debugger
            toast.success(nextprops.changepwRes.message, {  
               style:{fontSize:14},
               autoClose: 3000
               });          
           
            // swal({ text: nextprops.changepwRes && nextprops.changepwRes.message, icon: "success" });
            this.props.history.push(`/signin`)
         }
      }
      if (nextprops.gsettres != null) {
         if (nextprops.gsettres !== this.props.gsettres) {
            this.setState({ notifyflag: nextprops.gsettres.data && nextprops.gsettres.data.notification_setting })
         }
      }

      if (nextprops.blocklistRes != null) {
         if (nextprops.blocklistRes !== this.props.blocklistRes) {
            this.setState({ blocklistRes: nextprops.blocklistRes.data })
         }
      }


   }


   SetSetting(flag) {
      this.props.isLoading(true);
      this.setState({ notifyflag: flag })
      this.props.SetSettingAPI({
         "setting": flag
      })
   }

   unblockuser = (id, index) => {
      let list = this.state.blocklistRes
      if (index > -1 && list) {
         list.splice(index, 1);
      }
      this.setState({ blocklistRes: list })
      this.props.isLoading(true);
      this.props.UnBlockuserAPI(
         {
            "to_user_id": id
         });
   }

   deleteconfirm=()=>{
         swal({
            title: "",
            text: "Are you sure you want to delete your account?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
         })
            .then((willDelete) => {
               if (willDelete) {
                  this.props.isLoading(true);
                  this.props.DeleteAcAPI()
               } else {
               }
            });
   }

   render() {
      var Token = encryptStorage.getItem('FCM_Token');
      return (
         <>
            <Loader />
            <div className="wrapper">
               <Sidebar />
               <Navbar />
               <div id="content-page" className="content-page">
                  <div className="row">
                     <div className="col-md-3">
                        <div className="iq-card settings-card-left settings-card">
                           <div className="iq-card-header d-flex justify-content-between">
                              <div className="iq-header-title d-flex justify-content-between w-100">
                                 <h4 className="card-title">Settings</h4>
                                 <div onClick={() => {
                                    localStorage.setItem('guideflag', true);
                                    localStorage.setItem('guidehomeflag', true);
                                    localStorage.setItem('guideprofileflag', true);
                                 this.props.history.push('/home')}}>
                           <span tooltip="Guidance" > <h5><i  className="fa fa-question-circle" style={{color:'#0099ff'}}></i></h5></span>
                                 </div>
                              </div>
                           </div>
                           <div className="iq-card-body">
                              <div className="iq-seeting-tabs">

                                 <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    {/* <a className={this.state.privacy?"nav-link active":"nav-link"} id="v-pills-home-tab" data-toggle="pill" href="#v-pills-policy" role="tab" aria-controls="v-pills-home" aria-selected="true" onClick={()=>this.setState({privacy:true,termuse:false,changepass:false,pushnotify:false})}>Privacy policy</a>
                                 <a className={this.state.termuse?"nav-link active":"nav-link"} id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true" onClick={()=>this.setState({privacy:false,termuse:true,changepass:false,pushnotify:false})}>Terms of use</a> */}
                                    <a className={this.state.changepass ? "nav-link active" : "nav-link"} id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="true" onClick={() => this.setState({ privacy: false, termuse: false, changepass: true, pushnotify: false, blocklist: false })}>Change Password</a>
                                    <a className={this.state.pushnotify ? "nav-link active" : "nav-link"} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="true" onClick={() => this.setState({ privacy: false, termuse: false, changepass: false, pushnotify: true, blocklist: false })}>Push Notifications</a>
                                    <a className={this.state.blocklist ? "nav-link active" : "nav-link"} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="true" onClick={() => { this.props.BlockuserListAPI(); this.setState({ privacy: false, termuse: false, changepass: false, pushnotify: false, blocklist: true }); }}>Block List</a>
                                    <a className={"nav-link"} id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="true" onClick={()=>this.deleteconfirm()}>Delete Account</a>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="col-sm-9">
                        <div className="iq-card settings-card-right settings-card">

                           <div className="iq-card-body">
                              <div className="iq-seeting-tabs-content">

                                 <div className="tab-content mt-0" id="v-pills-tabContent">
                                    {/* {this.state.privacy &&
                                       <div className="tab-pane fade show active" id="v-pills-policy" role="tabpanel" aria-labelledby="v-pills-home-tab">

                                          <div className="privacy-policy-content">
                                             <div className="setting-tab-title">
                                                <h4 className="card-title">Privacy Policy</h4>
                                             </div>

                                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

                                          </div>
                                       </div>
                                    }
                                    {this.state.termuse &&
                                       <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                          <div className="terms-of-use">
                                             <div className="setting-tab-title">
                                                <h4 className="card-title">Terms Of Use</h4>
                                             </div>
                                             <div className="term-content">
                                                <h5>
                                                   Lorem ipsum dolor sit
                                                </h5>
                                                <p>
                                                   Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                </p>
                                             </div>
                                             <div className="term-content">
                                                <h5>
                                                   Lorem ipsum dolor sit
                                                </h5>
                                                <p>
                                                   Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                </p>
                                             </div>

                                             <div className="term-content">
                                                <h5>
                                                   Lorem ipsum dolor sit
                                                </h5>
                                                <p>
                                                   Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                </p>
                                             </div>

                                             <div className="term-content">
                                                <h5>
                                                   Lorem ipsum dolor sit
                                                </h5>
                                                <p>
                                                   Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                </p>
                                             </div>



                                          </div>
                                       </div>
                                    } */}
                                    {this.state.changepass &&
                                       <div className="tab-pane fade show active" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

                                          <div className="change-pw-sec">
                                             <div className="row mt-4">
                                                <div className="col-md-12">
                                                   <div className="form-group addon-input">
                                                      <label htmlFor="oldpass">Old Password :</label>
                                                      {/* <input type='text' autoComplete="off" className={!this.state.oeye && this.state.oldpass ? "form-control passwordtext" : "form-control"} id="oldpass" value={this.state.oldpass} placeholder={"enter old password"} onChange={this.handleChange} maxLength={16} onKeyPress={(e)=>this.EnterPressCall(e)}/> */}
                                                      <input type={!this.state.oeye ?'password':'text'} autoComplete="off" className="form-control" id="oldpass" value={this.state.oldpass} placeholder={"enter old password"} onChange={this.handleChange} maxLength={16} onKeyPress={(e)=>this.EnterPressCall(e)}/>
                                                      
                                                      {this.state.oeye && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleOeye}> <i className="fa fa-eye"></i> </span>}
                                                      {!this.state.oeye && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleOeye}> <i className="fa fa-eye-slash"></i> </span>}

                                                      {this.state.OError && <p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.OError}</p>}
                                                   </div>
                                                </div>
                                                <div className="col-md-12 ">
                                                   <div className="form-group addon-input">
                                                      <label htmlFor="newpass">New Password :</label>
                                                      {/* <input type='text' autoComplete="off" className={!this.state.peye && this.state.newpass? "form-control passwordtext" : "form-control"} id="newpass" value={this.state.newpass} placeholder={"enter new password"} onChange={(e) => this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e)=>this.EnterPressCall(e)}/> */}
                                                      <input type={!this.state.peye?'password':'text'} autoComplete="off" className="form-control" id="newpass" value={this.state.newpass} placeholder={"enter new password"} onChange={(e) => this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e)=>this.EnterPressCall(e)}/>
                                                      {this.state.peye && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.togglePeye}> <i className="fa fa-eye"></i> </span>}
                                                      {!this.state.peye && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.togglePeye}> <i className="fa fa-eye-slash"></i> </span>}
                                                      {this.state.PError && <p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PError}</p>}
                                                      {this.state.PassError && this.state.newpass ?
                                                         (!this.state.isminmax || !this.state.isupper || !this.state.islower || !this.state.isspecial || !this.state.isnumber)
                                                            ?
                                                            <><p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left' }}>Your password needs to:</p>
                                                               <p className="error-msg-p" style={{ color: this.state.isupper && this.state.islower ? 'green' : 'red', fontSize: 12, float: 'left' }}>include both uppercase and lowercase characters.</p>
                                                               <p className="error-msg-p" style={{ color: this.state.isnumber ? 'green' : 'red', fontSize: 12, float: 'left' }}>include at least one number.</p>
                                                               <p className="error-msg-p" style={{ color: this.state.isspecial ? 'green' : 'red', fontSize: 12, float: 'left' }}>include at least one special character.</p>
                                                               <p className="error-msg-p" style={{ color: this.state.isminmax ? 'green' : 'red', fontSize: 12, float: 'left' }}>be at least 8 characters long.</p>
                                                            </> : null : null}
                                                   </div>
                                                </div>
                                                <div className="col-md-12 ">
                                                   <div className="form-group addon-input">
                                                      <label htmlFor="confirmpass">Confirm Password :</label>
                                                      {/* <input type='text' autoComplete="off" className={!this.state.cpeye && this.state.confirmpass? "form-control passwordtext" : "form-control"} id="confirmpass" value={this.state.confirmpass} placeholder={"confirm password"} onChange={this.handleChange} maxLength={16} onKeyPress={(e)=>this.EnterPressCall(e)}/> */}
                                                      <input type={!this.state.cpeye ?'password':'text'} autoComplete="off" className="form-control" id="confirmpass" value={this.state.confirmpass} placeholder={"confirm password"} onChange={this.handleChange} maxLength={16} onKeyPress={(e)=>this.EnterPressCall(e)}/>
                                                      {this.state.cpeye && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleCPeye}> <i className="fa fa-eye"></i> </span>}
                                                      {!this.state.cpeye && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleCPeye}> <i className="fa fa-eye-slash"></i> </span>}

                                                      {this.state.CPError && <p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.CPError}</p>}
                                                   </div>
                                                </div>
                                                <div className="col-md-12 ">
                                                   <div className="change-pw-btns">
                                                      {/* <button type="reset" className="btn   cancel-btn mr-2"onClick={() => this.cancelcall()}>Cancel</button> */}
                                                      <button type="submit" className="btn   submit-btn btn-primary " onClick={() => this.Validation()}>Update</button>
                                                   </div>
                                                </div>
                                             </div>

                                          </div>
                                       </div>
                                    }
                                    {this.state.pushnotify &&
                                       <div className="tab-pane fade show active" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                          <div className="data-privacy">
                                             <h4 className="mb-2"> Push Notifications </h4>
                                             <div className="custom-control custom-radio">
                                                <input type="radio" id="public" name="customRadio1" checked={this.state.notifyflag} className="custom-control-input" disabled={Token ? false : true} onClick={() => this.SetSetting(true)} />
                                                <label className="custom-control-label" htmlFor="public"> ON </label>
                                             </div>
                                             <div className="custom-control custom-radio">
                                                <input type="radio" id="friend" name="customRadio1" checked={!this.state.notifyflag} className="custom-control-input" disabled={Token ? false : true} onClick={() => this.SetSetting(false)} />
                                                <label className="custom-control-label" htmlFor="friend"> OFF </label>
                                             </div>
                                             {!Token && <p>Please provide notification permission.</p>}
                                          </div>
                                       </div>
                                    }
                                    {this.state.blocklist ?
                                       <div className="tab-pane fade show active" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                          <div className="setting-inner-title"><h4 className="card-title">Block List</h4></div>
                                          {this.state.blocklistRes ? this.state.blocklistRes.length > 0 ?
                                             this.state.blocklistRes.map((i, index) =>
                                                <div className="block-list-page">

                                                   <div className="row align-items-center ">
                                                      <div className="col-md-6">
                                                         <div className="list-block-user">
                                                            {/* <img src={i.photo} style={{ height: 50 ,width:50}} /> */}
                                                            <Img src={i.photo} style={{ height: 50, width: 50 }}
                                                               loader={<img src={user1} />} unloader={<img src={user1} />} />
                                                            <p>{i.name}</p></div>
                                                      </div>
                                                      <div className="col-md-6"><button className="btn  btn-primary float-right" onClick={() => this.unblockuser(i._id, index)}>Unblock</button></div>
                                                   </div>
                                                </div>)
                                             : <p className="no-data-found">No results found!</p> :
                                             <div className="col-sm-12 text-center">
                                                <img src={pageloadloader} style={{ height: 50 }} />
                                             </div>}
                                       </div>
                                       : null}
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>

            </div>
         </>)
   }
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => {
         dispatch(dispatchLoadingStatus(loading));
      },
      ChangePassAPI: (postdata) => { dispatch(ChangePassAPI(postdata)) },
      GetSettingAPI: () => { dispatch(GetSettingAPI()) },
      SetSettingAPI: (postdata) => { dispatch(SetSettingAPI(postdata)) },
      BlockuserListAPI: () => { dispatch(BlockuserListAPI()) },
      UnBlockuserAPI: (postdata) => { dispatch(UnBlockuserAPI(postdata)) },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
      DeleteAcAPI:()=>{dispatch(DeleteAcAPI())}
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      changepwRes: state.globalReducer.ChangePassRes,
      gsettres: state.globalReducer.GetSettingRes,
      ssettres: state.globalReducer.SetSettingRes,
      blocklistRes: state.globalReducer.BlockuserListRes
   }
}
export default connect(mapStateToProps, mapDispatchToProps)((Settings))