import React from "react";
import { connect } from "react-redux";
import { ResetPassAPI } from "../actions/globalactions";
import { dispatchLoadingStatus, Validatepass, encryptStorage } from '../utils/utils';
import { devicetype } from "../constants";
import Loader from "./Loader";
import flogo from "../asset/images/logo.svg";
import img2 from "../asset/images/sign-bg.png";
import swal from 'sweetalert';
import Slider from "./Slider"
import Footer from "./Footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
class ResetPW extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Password: '',
      confirmPassword: '',
      hidden: false,
      hidden1: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.togglePass = this.togglePass.bind(this);
    this.toggleCpass = this.toggleCpass.bind(this);
  }

  togglePass() {
    this.setState({ hidden: !this.state.hidden });
  }
  toggleCpass() {
    this.setState({ hidden1: !this.state.hidden1 });
  }
  componentDidMount() {
    var token = encryptStorage.getItem("cizzr_token")
    var femail = encryptStorage.getItem("Femail")

    if (femail && !token) {
      // swal({text: tokenexpire, icon: "error",className:"error-modal"});
      // this.props.history.push(`/signin`);
    } else {
      this.props.history.push(`/home`);
    }
  }


  EnterPressCall = (e) => {
    if (e.charCode == 13 || e.which == 13) {
      this.resetpass()
    }
  }

  handleChange(event) {
    if(localStorage.getItem("resetprocess")=="true"){

    }else{
    this.setState({
      [event.target.id]: event.target.value.trim(),
      PError: "",
      CPError: "",
    });
  }
  }


  validatepass = (passw) => {
    if(localStorage.getItem("resetprocess")=="true"){

    }else{
    // var minMaxLength = /^[\s\S]{8,15}$/,
    var upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    this.setState({
      PError: "",
      PassError: true,
      Password: passw != "" ? passw.trim() : "",
      isminmax: passw.length > 7 ? true : false,
      isupper: upper.test(passw) ? true : false,
      islower: lower.test(passw) ? true : false,
      isnumber: number.test(passw) ? true : false,
      isspecial: special.test(passw) ? true : false
    })
  }
  }


  resetpass() {
    // var passFormat = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;
    if (this.state.Password.trim() == "" || !this.state.Password) {
      this.setState({ PError: "Please enter Password." });
    }
    // else if (this.state.Password.length<8) {
    //     this.setState({ PError: "Password must be between 8 to 15." });
    //   }  
    // else if (!passFormat.test(this.state.Password)) {
    //   this.setState({ PError: "Password must be between 8 to 15 characters and it contains at least 1 letter in Uppercase, 1 letter in Lowercase, 1 Special Character (!@#$&*) and 1 Number." });
    // }
    else if (!Validatepass(this.state.Password)) {
      this.setState({ PassError: true });
    }
    else if (this.state.confirmPassword.trim() == "" || !this.state.confirmPassword) {
      this.setState({ CPError: "Please enter Confirm Password." });
    } else if (this.state.Password !== this.state.confirmPassword) {
      this.setState({ CPError: "Password and Confirm Password does not match." });
    } else {
      //   alert("call api..")
      this.APICall()
      localStorage.setItem("resetprocess",true)
    }
  }

  APICall() {
    if(localStorage.getItem("resetprocess")=="true"){

    }else{
    const postdata = {
      "email": encryptStorage.getItem("Femail"),
      "password": this.state.Password,
      "device_type": devicetype
    }
    this.props.isLoading(true);
    this.props.ResetPassAPI(postdata);  //status of check
  }
  }

  componentWillReceiveProps(nextprops) {

    if (nextprops.resetres !== null) {
      if (nextprops.resetres !== this.props.resetres) {
        toast.success(nextprops.resetres.message, {
          style: { fontSize: 14 },
          autoClose: 3000
        });

        // swal({text: nextprops.resetres.message, icon: "success",className:"success-modal"});
        this.props.history.push(`/signin`);
      }
    }
  }

  render() {
    return (
      <>
        <Loader />
        <section className="sign-in-page new-sign-in  overlay-bg">

          <div className="container-fluid p-0">
            <div className="row no-gutters">
            <div className="col-lg-7  col-md-12 sm-displaynone">
                <div className="image-signin overlay-bg">
                <div className="overlay-homeslider"></div>
                  <div className="slider-text">
        <h3>A new twist on social media.<br /> Cutting the stigma around mental health. </h3>
                  </div>
              
                  <img src={img2} className="img-fluid mb-2" />
                </div>
            </div>
              <div className="col-lg-5 col-md-8 order-lg-2  order-1  align-self-center ">
                <div className="sign-in-main">
                  <div className="sign-in-from">
                    <div className="sigin-logo-div"><img src={flogo}></img>
                      {/* <h1>Let's Cut The Stigma</h1> */}
                    </div>
                    <h1 className="mb-0">Reset Password</h1>
                    <div className="mt-4 row ">
                      <div className="col-md-12">
                        <div className="form-group addon-input">
                          <label htmlFor="exampleInputPassword1">Password</label>

                          {/* <input type='text' autoComplete="off" className={!this.state.hidden && this.state.Password ? "form-control mb-0 passwordtext" : "form-control mb-0"} id="Password" placeholder="enter password" value={this.state.Password} onChange={(e) => this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} /> */}
                          <input type={!this.state.hidden?'password':'text'} autoComplete="off" className={"form-control mb-0"} id="Password" placeholder="enter password" value={this.state.Password} onChange={(e) => this.validatepass(e.target.value)} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} />

                          {this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.togglePass}> <i className="fa fa-eye"></i> </span>}
                          {!this.state.hidden && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.togglePass}> <i className="fa fa-eye-slash"></i> </span>}
                          {this.state.PError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.PError}</p>}
                          {this.state.PassError && this.state.Password ?
                            (!this.state.isminmax || !this.state.isupper || !this.state.islower || !this.state.isspecial || !this.state.isnumber)
                              ?
                              <><p className="error-msg-p" style={{ color: 'red', fontSize: 14, float: 'left' }}>Your password needs to:</p>
                                <p className="error-msg-p" style={{ color: this.state.isupper && this.state.islower ? 'green' : 'red', fontSize: 12, float: 'left' }}>include both uppercase and lowercase characters.</p>
                                <p className="error-msg-p" style={{ color: this.state.isnumber ? 'green' : 'red', fontSize: 12, float: 'left' }}>include at least one number.</p>
                                <p className="error-msg-p" style={{ color: this.state.isspecial ? 'green' : 'red', fontSize: 12, float: 'left' }}>include at least one special character.</p>
                                <p className="error-msg-p" style={{ color: this.state.isminmax ? 'green' : 'red', fontSize: 12, float: 'left' }}>be at least 8 characters long.</p>
                              </> : null : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group addon-input">
                          <label htmlFor="exampleInputPassword1">Confirm Password</label>
                         {/* <input type='text' autoComplete="off" className={!this.state.hidden1 && this.state.confirmPassword ? "form-control mb-0 passwordtext" : "form-control mb-0"} id="confirmPassword" placeholder="confirm password" value={this.state.confirmPassword} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} /> */}
                         <input type={!this.state.hidden1?'password':'text'} autoComplete="off" className="form-control mb-0" id="confirmPassword" placeholder="confirm password" value={this.state.confirmPassword} onChange={this.handleChange} maxLength={16} onKeyPress={(e) => this.EnterPressCall(e)} />
                          {this.state.hidden1 && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleCpass}> <i className="fa fa-eye"></i> </span>}
                          {!this.state.hidden1 && <span className="input-icon-addon input-icon-rights cursor-pointer" onClick={this.toggleCpass}> <i className="fa fa-eye-slash"></i> </span>}
                          {this.state.CPError && <p style={{ color: 'red', fontSize: 14, float: 'left' }}>{this.state.CPError}</p>}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="d-inline-block w-100">
                          <button type="submit" className="btn btn-primary float-right" onClick={() => this.resetpass()}>Reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer show={true}/>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    isLoading: loading => {
      dispatch(dispatchLoadingStatus(loading));
    },
    ResetPassAPI: (postdata) => { dispatch(ResetPassAPI(postdata)) }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.LoadingStatusReducer.loading,
    resetres: state.globalReducer.ResetPassRes
  }
}
export default connect(mapStateToProps, mapDispatchToProps)((ResetPW))
