import React from "react";
import { connect } from "react-redux";
import { basepath,devicetype } from "../constants";
import { dispatchLoadingStatus,encryptStorage } from '../utils/utils';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Loader from "./Loader";
import PostCard from './Postcard';
import {CommentListAPI, SavePostAPI, RemoveFromSaveAPI,
    UPVoteAPI, DownVoteAPI, CommentAPI,PostDetailAPI,BasicInfoAPI,BasicCountAPI,
     UPVoteCmtAPI , DownVoteCmtAPI,DeletePostAPI
 } from "../actions/globalactions";
 import pageloadloader from "../asset/images/page-load-loader.gif";
 import MetaTags from 'react-meta-tags';
 import swal from 'sweetalert';
import {getCizzrdatafromcrossdomain} from '../utils/cross-domain-access';
 
class PostDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }  
    componentDidMount(){
      var token = encryptStorage.getItem("cizzr_token")
      
      if (!token) {
         // getCizzrdatafromcrossdomain();
         this.props.history.push(`/signin`);
         } else if(this.props){
            debugger
            var Token = encryptStorage.getItem('FCM_Token');
            const postdata = {
               "fcm_token": Token ? Token : "",
               "device_type": devicetype
            }
           this.props.BasicCountAPI(postdata);
            if(!encryptStorage.getItem('basicInfoRes')){
               this.props.BasicInfoAPI(postdata);
            }
            this.props.PostDetailAPI({
               "post_id": this.props.match.params.id?this.props.match.params.id:this.props.location.state.postid,
               "device_type": devicetype
           });
      }
    }


   componentWillReceiveProps(nextprops) {

if(nextprops.postdetail){
    if (nextprops.postdetail != this.props.postdetail) {
        var data = nextprops.postdetail;
        data.is_open=true;
        data.commentlist=nextprops.postdetail&&nextprops.postdetail.comment;
        data.Clistcount=data.commentlist&&data.commentlist.length;
        this.setState({i:data})
    }
}

      if (nextprops.commentlist) {
         
        if (nextprops.commentlist != this.props.commentlist) {
           
           var data = this.state.i
           data.commentlist = nextprops.commentlist
           data.Clistcount= nextprops.commentlist&&nextprops.commentlist.length;
           data.is_open=true
           this.setState({i: data })
        }
     }

     if (nextprops.savePost) {
        if (nextprops.savePost != this.props.savePost) {
           
         //   var data = this.state.i
         //   data.is_save = !data.is_save
         //   this.setState({i: data })
           }
     }

   //   if (nextprops.votePost) {
   //      if (nextprops.votePost != this.props.votePost) {
           
   //         var data = this.state.i
   //         data.total_up_votes = nextprops.votePost.data
   //         data.is_up_vote = !data.is_up_vote
   //         this.setState({i: data })
   //      }
   //   }

   if (nextprops.deletePostRes) {
      if (nextprops.deletePostRes != this.props.deletePostRes) {
         this.props.history.goBack()
      }
   }
   }

   comments = () => {
    const data = {
       "post_id": this.state.i&&this.state.i._id,
       "device_type": devicetype
    }
    this.props.CommentListAPI(data)
    
 }

 bookmarkcall = () => {
    
let i = this.state.i

    const data = {
       "post_id": this.state.i&&this.state.i._id,
       "device_type": devicetype
    }
    if (i.is_save) {
       this.props.RemoveFromSaveAPI(data)
    } else {
       this.props.SavePostAPI(data)
    }
    
    i.is_save = !i.is_save
    this.setState({i: i })
 }

 likeunlikecall = () => {
    
    var data = this.state.i
    let count = data.total_up_votes
           data.total_up_votes = data.is_up_vote==true?count-1:count+1
           data.is_up_vote = !data.is_up_vote
           if(data.is_down_vote==true){
            data.total_down_votes = data.total_down_votes-1
            data.is_down_vote = !data.is_down_vote
          }
           this.setState({i: data })
    const pdata = {
       "post_id": this.state.i&&this.state.i._id,
       "device_type": devicetype
    }
    this.props.UPVoteAPI(pdata)
 }

 //cizzr 1.5 (NiraliBThummar 10Nov2021)
 downvotecall= () => {
    
   var data = this.state.i
   let count = data.total_down_votes
          data.total_down_votes = data.is_down_vote==true?count-1:count+1
          data.is_down_vote = !data.is_down_vote
          if(data.is_up_vote==true){
            data.total_up_votes = data.total_up_votes-1
            data.is_up_vote = !data.is_up_vote
          }
          this.setState({i: data })
   const pdata = {
      "post_id": this.state.i&&this.state.i._id,
      "device_type": devicetype
   }
   this.props.DownVoteAPI(pdata)
}

// cizzr 1.5 (NiraliBThummar 10Nov2021)
cmntupvotecall = (cmtid, index, i) => {
   debugger
   var data = this.state.i
   var list = data.commentlist
   var cindex = list.map(item => item._id).indexOf(cmtid);
   let count = list[cindex]["total_up_votes"]?list[cindex]["total_up_votes"]:0
   list[cindex]["total_up_votes"] = list[cindex]["is_up_vote"] == true ? count - 1 : count + 1
   list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
   if(list[cindex]["is_down_vote"] == true){
      list[cindex]["total_down_votes"] = list[cindex]["total_down_votes"] - 1 
      list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
   }
   data["commentlist"] = list
   this.setState({ i: data })
   const req = {
      "comment_id": cmtid,
      "post_id": this.state.i&&this.state.i._id,
      "device_type": devicetype
   }
   this.props.UPVoteCmtAPI(req)  

}

//cizzr 1.5 (NiraliBThummar 10Nov2021)
cmntdownvotecall = (cmtid, index, i) => {

   var data = this.state.i
   var list = data.commentlist
   var cindex = list.map(item => item._id).indexOf(cmtid);
   let count = list[cindex]["total_down_votes"]?list[cindex]["total_down_votes"]:0
   list[cindex]["total_down_votes"] = list[cindex]["is_down_vote"] == true ? count - 1 : count + 1
   list[cindex]["is_down_vote"] = !list[cindex]["is_down_vote"]
   if(list[cindex]["is_up_vote"] == true){
      list[cindex]["total_up_votes"] = list[cindex]["total_up_votes"] - 1
      list[cindex]["is_up_vote"] = !list[cindex]["is_up_vote"]
   }
   data["commentlist"] = list
   this.setState({ i: data })
   const req = {
      "comment_id": cmtid,
      "post_id": this.state.i&&this.state.i._id,
      "device_type": devicetype
   }
   this.props.DownVoteCmtAPI(req)  

}

    Addcomment=(comnt,isAnonymous)=>{
        const data = {
            "post_id": this.state.i&&this.state.i._id,
            "message": comnt,
            "device_type": devicetype,
            "is_anonymous":isAnonymous?"1":"0"
         }
         this.props.CommentAPI(data)
    }
    Opencomment = () => {
     }
     Collapsecomment = ()=>{

     }
    Viewmore = () => {
      //   
      //   var data = this.state.i
      //   data.Clistcount = data&&data.Clistcount + 3
      //   this.setState({i: data })
      //   
     }
  

//cizzr 3 : NiraliBThummar : 12May2022
Deletepost = (i) => {

   swal({
      title: "",
      text: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
   })
      .then((willDelete) => {
         if (willDelete) {
            this.props.isLoading(true);
            const postdata = {
               "post_id": i._id
            }
            this.props.DeletePostAPI(postdata);
         } else {
         }
      });
}




    render(){
       var Url = window.location.origin+'/post/'+this.props.match.params.id
       
        return(
            <div>
             <MetaTags>
             {/* <title>post detail</title> */}
             <meta property="al:ios:url" content={Url} />
            </MetaTags>
            <Loader />
           <div className="wrapper">
               <Sidebar/>
               <Navbar/>
               <div id="content-page" className="content-page">
               {this.state.i?<PostCard cardview={'list'} data={this.state.i} key={this.state.i._id} FormPostDetails={true}
                                       bookmark={() => this.bookmarkcall()}
                                       getcomments={() => this.comments()}
                                       likeunlike={() => this.likeunlikecall()}
                                       addcomment={(val,isAnonymous) => this.Addcomment(val,isAnonymous)}
                                       opencomment={() => this.Opencomment()}
                                       viewmore={() => this.Viewmore()}
                                       collapsecomment={() => this.Collapsecomment()}
                                       cmtid={this.props.location.state&&this.props.location.state.cmtid}
                                       downvote={() => this.downvotecall()} //cizzr 1.5 (NiraliBThummar 10Nov2021)
                                       cmntupvote={(cmtid) => this.cmntupvotecall(cmtid)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                       cmntdownvote={(cmtid) => this.cmntdownvotecall(cmtid)} //cizzr 1.5 (NiraliBThummar 16Nov2021)
                                       deletepost={() => this.Deletepost(this.state.i)}//cizzr 3 (NiraliBThummar 11May2022)
                                    />:<div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                    <img src={pageloadloader} style={{ height: 80 }} />
                                 </div>}
     </div>
     </div>
     </div>   
        )
    }

}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => {
         dispatch(dispatchLoadingStatus(loading));
      },
      CommentListAPI: (postdata) => { dispatch(CommentListAPI(postdata)) },
      SavePostAPI: (postdata) => { dispatch(SavePostAPI(postdata)) },
      RemoveFromSaveAPI: (postdata) => { dispatch(RemoveFromSaveAPI(postdata)) },
      UPVoteAPI: (postdata) => { dispatch(UPVoteAPI(postdata)) },
      DownVoteAPI: (postdata) => { dispatch(DownVoteAPI(postdata)) },
      CommentAPI: (postdata) => { dispatch(CommentAPI(postdata)) },
      PostDetailAPI: (postdata) => { dispatch(PostDetailAPI(postdata)) },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
      UPVoteCmtAPI: (postdata) => { dispatch(UPVoteCmtAPI(postdata)) },
      DownVoteCmtAPI: (postdata) => { dispatch(DownVoteCmtAPI(postdata)) },      
      DeletePostAPI: (postdata) => { dispatch(DeletePostAPI(postdata)) },
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      basicinfo: state.globalReducer.BasicInfoRes,
      postres: state.globalReducer.PostRes,
      postlist: state.globalReducer.PostListRes,
      commentlist: state.globalReducer.CommentListRes,
      searchlist: state.globalReducer.SearchPostRes,
      savePost: state.globalReducer.SavePostRes,
      // votePost: state.globalReducer.VoteRes,
      postdetail: state.globalReducer.PostDetailRes,
      deletePostRes: state.globalReducer.DeletePostRes,
   }
}
export default connect(mapStateToProps, mapDispatchToProps)((PostDetail))
     