import React, { Component } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { connect } from "react-redux";
import { SearchUsersAPI } from "../actions/globalactions";
import { mentionUser } from '../utils/utils';
const defaultStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
    fontWeight: "normal"
  },
  highlighter: {
    overflow: "hidden"
  },
  input: {
    // margin: 0,
    // overflow: "auto",
    // height: 30
  },
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      border: "1px solid silver",
      borderRadius:10,
      minHeight:80,
      maxHeight:200,
      overflow:"auto"
    },
    highlighter: {
      padding: 9
    },
    input: {
      padding: 9,
      minHeight: 3,
      outline: 0,
      border: 0,
      borderRadius:10,
      minHeight:80,
      maxHeight:200,
      overflow:"auto"
    }
  },
  suggestions: {
    top: "0",
    width:"100%",
    top: "unset",
    
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
      boxShadow:"0px 0px 20px 0px rgb(44 101 144 / 30%)",
      height: "100%",
      maxHeight: "300px",
      overflowX:"auto",
    },
    item: {
      padding: "10px 20px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5"
      }
    }
  }
};
// const defaultMentionStyle = {
//   // backgroundColor: "#cee4e5"
// };
class Mentiontextarea extends Component {
    state = {
      value:this.props.intialval,
      mentionUserlist:[]
    };
    keypress=(event)=>{
      debugger
      if(!event.shiftKey){
        if (event.charCode == 13 || event.which == 13) {
          this.props.keyPress(event)
          this.setState({value:""})
        }
      }      
    }
    handleChange = (event, newValue, newPlainTextValue, mentions) => {
      debugger
      if(this.props.fromcmt=="true"?event.target.value.length<=800:event.target.value.length<=1000){
        var res = mentionUser(event.target.value)
        if (res) {
          if (res.status) {
            debugger
            if (res.searchtext) {
              if(!res.searchtext.includes(']'))
              this.props.SearchUsersAPI({ "search_query": res.searchtext })
            }
          }
        }
        this.setState({ value: event.target.value });
        this.props.setvalue(event.target.value);
      }      
    };


    

    componentWillReceiveProps(nextprops) {
      if (nextprops.searchUser) {
                 if (nextprops.searchUser != this.props.searchUser) {
                   var list = []
                   nextprops.searchUser.map(i=>
                    list.push({id:i.user_id,display:'@'+i.username,name:i.name,photo:i.photo}))
                   this.setState({mentionUserlist:list})
                   debugger
                  //  document.getElementById("mentioncon").focus();
                 }
              }
        
           }
    render() {
      return (
        <div 
          className={this.props.fromcmt=="true"?"comment-box rounded text-area":" rounded text-area"}
          style={{
            border: "none"
          }}
        >
          {/* <p>{this.state.value}</p> */}
          <MentionsInput
            value={this.state.value.replace(/\r?\n|\r/g, "")==""?'':this.state.value}
            onChange={this.handleChange}
            style={defaultStyle}
            autoFocus={true}
            id="mentioncon"
            placeholder={this.props.fromcmt=="true"?"write a comment...":"tell us how you feel..."}
            onKeyPress={this.keypress}
          >
            <Mention
              markup="[__display__](__id__)"
              data={this.state.mentionUserlist}
              // onAdd={this.onAdd}
              // style={defaultMentionStyle}
              renderSuggestion={(i) =>
                <div class="d-flex align-items-center ">
                  <img className="avatar-40 mr-3 rounded-circle user-like-img img-fluid" src={i.photo} />
            <div style={{wordBreak:"break-all"}}>  <h6>{i.name}</h6><p className="mb-0 font-weight-bold">{i.display}</p></div>
             </div>}
            />
          </MentionsInput>
          {this.props.fromcmt=="true"&& <div className="comment-attagement d-flex">
                                            <a onClick={() => {this.props.addcommentfrommention();this.setState({value:""})}}><i className="ri-send-plane-2-line mr-3"></i></a> 
                                        </div> }
        </div>
      );
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      SearchUsersAPI: (postdata) => { dispatch(SearchUsersAPI(postdata)) }
    }
  }
  const mapStateToProps = (state, ownProps) => {
    return {
      searchUser: state.globalReducer.SearchUsersRes
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)((Mentiontextarea))