import React from "react";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Loader from "./Loader";
import { basepath, devicetype } from "../constants";
import swal from 'sweetalert';
import pageloadloader from "../asset/images/page-load-loader.gif";
import { connect } from "react-redux";
import user1 from "../asset/images/user1.svg";
import { NotificationListAPI, DeleteNotificationAPI, ReadNotificationAPI, BasicInfoAPI, BasicCountAPI, FriendReqListAPI } from "../actions/globalactions";
import { dispatchLoadingStatus, diff_minutes, encryptStorage } from '../utils/utils';
import { Img } from 'react-image';
import parse from "html-react-parser";


class Notification extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         firstcall: true
      }
      this.handleScrollnotification = this.handleScrollnotification.bind(this);
   }

   componentDidMount() {
      // window.onpopstate = e=>{ 
      //    // var list = JSON.parse(encryptStorage.getItem('notificationdata'));
      //    // var index = encryptStorage.getItem('notificationdid');
      //    // list[index]["is_read"] = 1;
      //    // this.setState({ notificationdata: list }, () => {encryptStorage.removeItem('notificationdata'encryptStorage.age.removeItem('notificationdid');})
      // }
      window.addEventListener("scroll", this.handleScrollnotification);
      encryptStorage.removeItem('notifycount');
      localStorage.setItem("Notificationprocess",false)
      var token = encryptStorage.getItem("cizzr_token")

      if (!token) {
         this.props.history.push(`/signin`);
      } else {

         var Token = encryptStorage.getItem('FCM_Token');
         const postdata = {
            "fcm_token": Token ? Token : "",
            "device_type": devicetype
         }
         this.props.BasicCountAPI(postdata);
         if (!encryptStorage.getItem('basicInfoRes')) {
            this.props.BasicInfoAPI(postdata);
         }
         debugger
         this.setState({ notificationdata: encryptStorage.getItem('NotificationListRes') })
         this.callnotificationAPI()
         this.props.FriendReqListAPI()
         this.setState({ FriendsReqList: encryptStorage.getItem('FriendsReqList') && encryptStorage.getItem('FriendsReqList') != 'undefined' ? encryptStorage.getItem('FriendsReqList') : [] })
      }
   }

   componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScrollnotification);
   }

   callnotificationAPI = () => {
      debugger
      // var scrollpos = encryptStorage.getItem('scrollpos');
      // if (scrollpos) {
      // } else {
         if(localStorage.getItem("Notificationprocess")=="true"){
         }else{
         this.setState({ loading: true })
         var postdata = {
            "start": this.state.firstcall ? 0 : this.state.notificationdata ? this.state.notificationdata.length : 0,
            "limit": 10,
            "device_type": devicetype
         }
         this.props.NotificationListAPI(postdata)
         localStorage.setItem("Notificationprocess",true)
      }
   // }
   }

   handleScrollnotification = () => {
      const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
         if (this.state.notificationdata) {
            debugger
            if (this.state.totalcount > this.state.notificationdata.length) {
               this.callnotificationAPI()
            }
         }
      }
   }

   gotopost = (i, index) => {

      if (i.action == "chat_request" || i.action == "accept_request") {

         this.props.history.push({
            pathname: `/chat`,
            state: i.threadData
         });
      } else if (i.action == "up_vote" || i.action == "post_mention") {

         this.props.history.push({
            pathname: `/post/` + i.post_id,
            state: { 'postid': i.post_id }
         });
      } else if (i.action == "comment" || i.action == "up_vote_comment" || i.action == "comment_mention") {

         encryptStorage.setItem('cmtscroll', true)
         this.props.history.push({
            pathname: `/post/` + i.post_id,
            state: { 'postid': i.post_id, 'cmtid': i.comment_id }
         });
      } 
      // else if (i.action == "friend_request") {
      //    if (i.from_user && i.is_anonymous == "1") {
      //    } else {
      //       this.gotoprofile(i.from_user._id)
      //    }
      // }

      this.readcall(index, i, true);



   }

   gotoprofile = (id) => {

      this.props.history.push({
         pathname: `/otherprofile/`+id,
         state: id
      });
   }

   componentWillReceiveProps(nextprops) {

      if (nextprops.notificationlist) {
         debugger
         if (nextprops.notificationlist != this.props.notificationlist) {
            if (this.state.firstcall) {
               this.setState({ notificationdata: nextprops.notificationlist.data, totalcount: nextprops.notificationlist.count, loading: false, firstcall: false });
               encryptStorage.setItem('NotificationListRes', nextprops.notificationlist.data);
            } else {
               var ndata = this.state.notificationdata.concat(nextprops.notificationlist.data)
               this.setState({ notificationdata: ndata, totalcount: nextprops.notificationlist.count, loading: false });
               encryptStorage.setItem('NotificationListRes', ndata);
            }
         }
      }

      if (nextprops.deletenotification) {
         if (nextprops.deletenotification != this.props.deletenotification) {

            if (this.state.DID != undefined) {
               var list = this.state.notificationdata
               if (this.state.DID > -1) {
                  list.splice(this.state.DID, 1);
               }
               this.setState({ notificationdata: list })
               encryptStorage.setItem('NotificationListRes', list);
            }
         }
      }

      if (nextprops.readnotification) {
         if (nextprops.readnotification != this.props.readnotification) {

            if (this.state.Allread) {
               var list = this.state.notificationdata
               list && list.map(i => i.is_read = 1)
               this.setState({ notificationdata: list })
               encryptStorage.setItem('NotificationListRes', list);
            } else if (this.state.RID != undefined) {
               var list = this.state.notificationdata
               list[this.state.RID]["is_read"] = 1;
               this.setState({ notificationdata: list })
               encryptStorage.setItem('NotificationListRes', list);
            }
         }
      }

      if (nextprops.friendReqListRes) {
         if (nextprops.friendReqListRes != this.props.friendReqListRes) {
            debugger
            this.setState({ FriendsReqList: nextprops.friendReqListRes })
            encryptStorage.setItem('FriendsReqList', nextprops.friendReqListRes)
         }
      }
   }

   deletecall = (index, i) => {

      this.setState({ DID: index })
      const data = {
         "notification_id": i._id
      }
      this.props.DeleteNotificationAPI(data)
   }

   readcall = (index, i, isscroll) => {

      this.setState({ RID: index })
      const data = {
         "notification_id": [i._id]
      }
      this.props.ReadNotificationAPI(data)
      var ndata = this.state.notificationdata
      debugger
      ndata[index].is_read = 1;
      this.setState({ notificationdata: ndata })
      encryptStorage.setItem('notificationdata', JSON.stringify(ndata));

      if (isscroll && document.getElementById(index)) {
         var scrollpos = document.getElementById(index).offsetTop;
         encryptStorage.setItem('scrollpos', scrollpos);
      }
   }
   deleteall = () => {
      swal({
         title: "",
         text: "Are you sure you want to delete?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               var ids = this.state.notificationdata && this.state.notificationdata.map(i => i._id)
               const ndata = {
                  "notification_id": "-1"//ids.toString()
               }
               this.props.DeleteNotificationAPI(ndata)
               this.setState({ notificationdata: [] })
            } else {
            }
         });

   }

   readall = () => {
      debugger
      var ids = []
      this.state.notificationdata && this.state.notificationdata.map(i => i.is_read != 1 && ids.push(i._id))
      if (ids) {
         const data = {
            "notification_id": ["-1"]//ids
         }
         if (ids.length > 0) {
            this.setState({ Allread: true })
            this.props.ReadNotificationAPI(data)
         }
      }
   }

   gotofriend = (data, title) => {
      this.props.history.push(`/friend`);
      // this.props.history.push({
      //    pathname: `/friend`,
      //    state: { 'frnddata': data, 'title': title }
      // });
      encryptStorage.setItem('friendtitle', title)
   }

   render() {
      var scrollpos = encryptStorage.getItem('scrollpos');

      if (scrollpos && this.state.notificationdata) {
         // console.log('scrollpos..',scrollpos)
         // window.scrollTo(0, scrollpos);
         setTimeout(() => {
            encryptStorage.removeItem('scrollpos')
         }, 3000);

      }

      return (
         <>
            <Loader />
            <div className="wrapper">
               <Sidebar active="notification" />
               <Navbar />
               <div id="content-page" className="content-page">
                  <div className="container-fluid">
                     <div className="row">

                        <div className="col-sm-12 pt-3">
                           {/* <h5 className="noti-day">Today</h5> */}
                           {this.state.FriendsReqList ?
                              this.state.FriendsReqList.length > 0 ?
                                 <div className="iq-card-body ">
                                    <div className="iq-card noti-card shadow-none border" id="1">
                                       <div className="iq-card-body ">
                                          <ul className="notification-list friend-req-list m-0 p-0">
                                             <li className="d-flex align-items-center friend-req-card justify-content-between" >
                                                <div className="d-flex">
                                                   <a className=" noti-icon img-fluid friend-reqs" onClick={() => this.gotofriend(this.state.FriendsReqList, "Friend Requests")}>

                                                      <Img className="rounded-circle avatar-45" src={encryptStorage.getItem("userpic")}
                                                         loader={<img src={user1} className="rounded-circle avatar-45" />} unloader={<img src={user1} className="rounded-circle avatar-45" />} />

                                                      <span className="req-count">{this.state.FriendsReqList.length}</span>
                                                   </a>
                                                   <a className="media-support-info ml-3" onClick={() => this.gotofriend(this.state.FriendsReqList, "Friend Requests")}><h4>Friend Requests</h4>
                                                      <p className="mb-0 noti-time">Accept Or Reject The Requests</p></a>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                   <div className="mr-3 "><a className="total-reqs" onClick={() => this.gotofriend(this.state.FriendsReqList, "Friend Requests")}>Total Requests ({this.state.FriendsReqList.length})<i className="ri-arrow-right-s-line"></i>
                                                   </a>
                                                   </div></div></li></ul></div>
                                    </div>
                                 </div>
                                 : null : null}
                           {this.state.notificationdata ?
                              this.state.notificationdata.length > 0
                                 ?
                                 <div>
                                    <div className="row ">
                                       <div className="col-sm-12 ">
                                          <div className="delete-noti ml-2">
                                             <button type="submit" className="btn submit-btn btn-primary float-right" onClick={() => this.deleteall()}>Delete All</button>
                                          </div>
                                          {/* <div className="delete-noti">
                                             <button type="submit" className="btn submit-btn btn-primary float-right" onClick={() => this.readall()}>Read All</button>
                                          </div> */}
                                       </div>
                                    </div>
                                    {this.state.notificationdata.map((i, index) =>
                                       <div className="iq-card noti-card" id={index} key={index}>
                                          <div className={i.is_read == 1 ? "iq-card-body " : "iq-card-body unread-noti"}>
                                             <ul className="notification-list m-0 p-0">
                                                <li className="d-flex align-items-center">
                                                   <a className="user-img noti-icon img-fluid" onClick={() => i.from_user && i.is_anonymous == "1" ? {} : this.gotoprofile(i.from_user._id)}>
                                                      {i.from_user && i.from_user.photo != "" ?
                                                         <img src={i.is_anonymous == "1" ? user1 : i.from_user.photo} className="rounded-circle avatar-40" /> :
                                                         <img src={user1} className="rounded-circle avatar-40" />
                                                      }

                                                      {/* <Img className="rounded-circle avatar-40" src={i.is_anonymous == "1"?user1:i.from_user.photo}
                                                         loader={<img src={user1} className="rounded-circle avatar-40" />} unloader={<img src={user1} className="rounded-circle avatar-40" />} /> */}
                                                   </a>
                                                   <a onClick={() => this.gotopost(i, index)} className="media-support-info ml-3">
                                                      <h6>{i.title}</h6>
                                                      <p className="mb-0 noti-time">{diff_minutes(i.created_at)}
                                                         {/* {i.action == "comment" && i.comment_data && i.comment_data.message && <span className="pl-3 comment-msg">{i.comment_data.message.length > 100 ? '"' + i.comment_data.message.slice(0, 100) + '..."' : '"' + i.comment_data.message + '"'}</span>}</p> */}
                                                         {i.action == "comment" && i.comment_data && i.comment_data.message && <span className="pl-3 comment-msg">{parse(`${i.comment_data.message}`)}</span>}</p> 
                                                   </a>
                                                   <div className="d-flex align-items-center">
                                                      <div className="mr-3 iq-notify"> {i.action == "comment" ?
                                                         <i className="comment-icon ri-chat-4-fill"></i>
                                                         : (i.action == "up_vote" || i.action == "up_vote_comment") ?
                                                            <i className="like-icon ri-heart-fill"></i>
                                                            : (i.action == "chat_request" || i.action == "accept_request") ?
                                                               <i style={{ color: "#3ba58b" }} className="fa fa-comments"></i>
                                                               :(i.action == "accept_friend_request" || i.action == "friend_request") ?
                                                               <i className="group-icon ri-group-fill"></i>
                                                               : null}</div>
                                                      <div className="iq-card-header-toolbar d-flex align-items-center drop-down-index">
                                                         <div className="dropdown">
                                                            <a className="dropdown-toggle" data-toggle="dropdown">
                                                               <i className="ri-more-fill"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right ">
                                                               <a className="dropdown-item" onClick={() => this.deletecall(index, i)}><i className="ri-delete-bin-6-fill mr-2"></i>Delete</a>

                                                               {i.is_read == 1 ?
                                                                  // <><a className="dropdown-item"><i className="fa fa-envelope mr-2"></i>Mark as unread</a></>
                                                                  null :
                                                                  <><a className="dropdown-item" onClick={() => this.readcall(index, i)}><i className="fa fa-envelope-open mr-2"></i>Mark as read</a></>}

                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                    )
                                    }</div> : !this.state.loading && <p className="no-data-found">No Notifications Yet</p> : null}
                           {this.state.loading && <div className="col-sm-12 text-center">
                              <img src={pageloadloader} style={{ height: 60 }} />
                           </div>}
                           {/* <div className="iq-card noti-card">
                        <div className="iq-card-body">
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center">
                                 <div className="user-img noti-icon img-fluid"><i className="like-icon ri-heart-fill"></i></div>
                                 <div className="media-support-info ml-3">
                                    <h6>Cliff Hanger <span> Commented on your post</span></h6>
                                    <p className="mb-0 noti-time">16 h  ago</p>
                                 </div>
                                 <div className="d-flex align-items-center">
                                    
                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                       <div className="dropdown">
                                          <span className="dropdown-toggle" data-toggle="dropdown">
                                          <i className="ri-more-fill"></i>
                                          </span>
                                          <div className="dropdown-menu dropdown-menu-right">
                                             <a className="dropdown-item" href="#"><i className="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                             <a className="dropdown-item" href="#"><i className="ri-pencil-fill mr-2"></i>Mark as read</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                      */}

                           {/* <h5 className="noti-day">Yesterday</h5>
                     <div className="iq-card noti-card">
                        <div className="iq-card-body  ">
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center">
                                 <div className="user-img noti-icon img-fluid"><i className="comment-icon ri-chat-4-fill"></i></div>
                                 <div className="media-support-info ml-3">
                                    <h6>Anne Fibbiyon <span>Liked your post</span></h6>
                                    <p className="mb-0 noti-time">2 days ago</p>
                                 </div>
                                 <div className="d-flex align-items-center">
                                    
                                    <div className="iq-card-header-toolbar d-flex align-items-center">
                                       <div className="dropdown">
                                          <span className="dropdown-toggle" data-toggle="dropdown">
                                          <i className="ri-more-fill"></i>
                                          </span>
                                          <div className="dropdown-menu dropdown-menu-right">
                                             <a className="dropdown-item" href="#"><i className="ri-delete-bin-6-fill mr-2"></i>Delete</a>
                                             <a className="dropdown-item" href="#"><i className="ri-pencil-fill mr-2"></i>Report</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                     
                   */}




                        </div>
                     </div>
                  </div>
               </div>

               {/* <Footer/> */}
            </div>

         </>)
   }
}


const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => {
         dispatch(dispatchLoadingStatus(loading));
      },
      NotificationListAPI: (postdata) => { dispatch(NotificationListAPI(postdata)) },
      DeleteNotificationAPI: (postdata) => { dispatch(DeleteNotificationAPI(postdata)) },
      ReadNotificationAPI: (postdata) => { dispatch(ReadNotificationAPI(postdata)) },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
      FriendReqListAPI: (postdata) => { dispatch(FriendReqListAPI(postdata)) },

   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      notificationlist: state.globalReducer.NotificationListRes,
      deletenotification: state.globalReducer.DeleteNotificationRes,
      readnotification: state.globalReducer.ReadNotificationRes,
      friendReqListRes: state.globalReducer.FriendReqListRes,
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification)
