import React from "react";
import { connect } from "react-redux";
import {LogFEvent} from '../firebase'
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Loader from './Loader';
import user1 from "../asset/images/user1.svg";
import user02 from "../asset/images/user1.svg";
import { Img } from 'react-image';
import pageloadloader from "../asset/images/page-load-loader.gif";
import Linkify from 'react-linkify';
import {
   ThreadListAPI, AllUserAPI, AddThreadAPI, AcceptRejectRequestAPI, ChatDetailsAPI, SendMessageAPI,
   DeleteChatAPI, BlockuserAPI, UnBlockuserAPI, BasicInfoAPI, BasicCountAPI
} from "../actions/globalactions";
import { dispatchLoadingStatus, diff_minutes_chat, encryptStorage } from '../utils/utils';
import Modal from "react-responsive-modal";
import swal from 'sweetalert';
import { basepath, devicetype } from "../constants";
import InputEmoji from 'react-input-emoji';
// import ShowMoreText from "react-show-more-text";
// let notupdate = false;

const userid = encryptStorage.getItem('userid');
class Chat extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         default: true,
         chatscrolldown: true,
         textmsg: '',
         search: '',
         searchtext: ''
      }
      this.myRef = React.createRef()
   }



   setreadflag = (index, read_flag) => {

      var list = this.state.chatlist
      if (list) {
         list[index].readless = read_flag == "more" ? true : false;
      }
      this.setState({ chatlist: list, notupdate: true })
   }



   executeOnClick = (isExpanded) => {

      // console.log(isExpanded);
   }
   componentDidMount() {
debugger
LogFEvent("chat_pageopen")
      encryptStorage.removeItem('chatcount');
      var token = encryptStorage.getItem("cizzr_token")

      if (!token) {
         this.props.history.push(`/signin`);
      } else {
         localStorage.setItem('callchatapi', true)
         if(localStorage.getItem("Fromprofiletochat")=="true"){
            var chatobj = JSON.parse(localStorage.getItem("chatobj"))
            if(chatobj){
               this.openScreen(chatobj)
            }
            localStorage.removeItem("Fromprofiletochat")
         }else if (this.props.location && this.props.location.state) {

            this.openScreen(this.props.location.state)
            
         }
         // this.props.isLoading(true);
         var Token = encryptStorage.getItem('FCM_Token');
         const postdata = {
            "fcm_token": Token ? Token : "",
            "device_type": devicetype
         }
         this.props.BasicCountAPI(postdata);
         if (!encryptStorage.getItem('basicInfoRes')) {
            this.props.BasicInfoAPI(postdata);
         }

         this.props.ThreadListAPI()
         var oldthread = encryptStorage.getItem('threadRes') && encryptStorage.getItem('threadRes') != 'undefined' ? encryptStorage.getItem('threadRes') : []
         this.setState({ filterthread: oldthread, threadRes: oldthread })
      }

   }

   componentWillUnmount() {
      localStorage.removeItem('callchatapi')
   }

   handleScrollchat = (e) => {
      if (e.target.scrollTop == 0) {
         if (this.state.chatlist) {
            if (this.state.total_chat_history > this.state.chatlist.length) {
               let msgID = this.state.chatlist[this.state.chatlist.length - 1]._id
               this.setState({ scrollmsgid: msgID }, () => {
                  this.callchatapi(this.state.currentmainID, false)
               })
            }
         } else {
            // this.callchatapi(this.state.currentmainID,false)
         }
      }
   }

   callalluser = (val) => {
      setTimeout(
         () => {
            if (val.length > 2) {
               this.props.AllUserAPI({
                  "text": val
               })
            }
         },
         1500
      );
   }

   searchuser = (e) => {
      this.setState({ searchtext: e.target.value.trim() == "" ? "" : e.target.value })
      if (e.target.value.trim() != "" && e.target.value.length > 2) {
         this.callalluser(e.target.value)
      } else if (e.target.value.trim() == "" || e.target.value.length < 3) {
         this.setState({ alluserRes: null })
      }

   }
   searchthread = (e) => {

      this.setState({ search: e.target.value.trim() == "" ? "" : e.target.value })
      if (e.target.value.trim() != "" && this.state.threadRes) {
         let result = []
         if (this.state.threadRes.length > 0) {
            this.state.threadRes.map(i =>
               i.name.toLowerCase().includes(e.target.value.toLowerCase()) &&
               result.push(i)
            )
            this.setState({ filterthread: result })
         }
      } else if (e.target.value.trim() == "") {
         this.setState({ filterthread: this.state.threadRes })
      }

   }

   componentWillReceiveProps(nextprops) {

      if (nextprops.alluserRes) {
         if (nextprops.alluserRes != this.props.alluserRes) {
            this.setState({ alluserRes: this.state.searchtext && this.state.newuser ? nextprops.alluserRes : null })
         }
      }
      if (nextprops.threadRes) {
         if (nextprops.threadRes != this.props.threadRes) {
            if (nextprops.threadRes.length > 0) {

               setTimeout(
                  () => {
                     // console.log('thread call 30 sec..')
                     this.props.ThreadListAPI();
                  },
                  25000
               );
            }
            if (this.state.current_threadid) {
               debugger
               var tdata = nextprops.threadRes.filter(i => i.thread_id == this.state.current_threadid)
               if (tdata) {
                  if (tdata.length > 0) {
                     this.setState({
                        IsBlock: tdata[0].is_block == 1 ? true : false, otheruserid: tdata[0].user_id, current_photo: tdata[0].photo,current_uname:tdata[0].username,
                        current_name: tdata[0].name, created_at: tdata[0].created_at, current_msg: tdata[0].message, current_threadid: tdata[0].thread_id
                     })
                  }
               }
            }
            if (this.state.search) {
               let result = []
               if (nextprops.threadRes.length > 0) {
                  nextprops.threadRes.map(i =>
                     i.name.toLowerCase().includes(this.state.search.toLowerCase()) &&
                     result.push(i)
                  )
                  this.setState({ filterthread: result })
               }
            } else {
               this.setState({ filterthread: nextprops.threadRes, search: "", threadRes: nextprops.threadRes })
            }
            encryptStorage.setItem('threadRes', nextprops.threadRes)
         }
      }
      if (nextprops.addthreadRes) {
         if (nextprops.addthreadRes != this.props.addthreadRes) {
            this.setState({ sendreq: false, chat: false, chatreq: false, default: true })
         }
      }

      if (nextprops.chatlistRes) {
         if (nextprops.chatlistRes != this.props.chatlistRes) {
            debugger
            if (this.state.currentmainID == nextprops.chatlistRes._id) {
               if (this.state.sendlastid) {
                  setTimeout(
                     () => {
                        if (this.state.currentmainID) {
                           // console.log('repeat 30 sec..')
                           this.callchatapi(this.state.currentmainID, true)
                        }
                     },
                     25000
                  );
                  this.setState({ sendlastid: false, total_chat_history: nextprops.chatlistRes.total_chat_history, IsBlock: nextprops.chatlistRes.is_block == 1 ? true : false, notupdate: this.state.chatscrolldown ? false : true, chatscrolldown: false })
                  if (nextprops.chatlistRes.chat_history.length > 0) {
                     this.setState({ chatlist: nextprops.chatlistRes.chat_history.concat(this.state.chatlist) }, () => {
                        this.scrollToBottom()
                     })
                  }
               }
               else if (this.state.chatlist && this.state.chatlist.length > 0) {
                  this.setState({ chatlist: this.state.chatlist.concat(nextprops.chatlistRes.chat_history), total_chat_history: nextprops.chatlistRes.total_chat_history, IsBlock: nextprops.chatlistRes.is_block == 1 ? true : false, notupdate: this.state.chatscrolldown ? false : true, chatscrolldown: false }, () => {
                     if (this.state.scrollmsgid && document.getElementById('chatcontent')) {
                        var divpos = document.getElementById('chatcontent')
                        var scrollcmt = document.getElementById(this.state.scrollmsgid) && document.getElementById(this.state.scrollmsgid).offsetTop;
                        if (scrollcmt) { divpos.scrollTo(0, scrollcmt) }
                     }
                  })
               } else {
                  this.setState({
                     chatlist: nextprops.chatlistRes.chat_history, total_chat_history: nextprops.chatlistRes.total_chat_history,
                     IsBlock: nextprops.chatlistRes.is_block == 1 ? true : false, notupdate: this.state.chatscrolldown ? false : true,
                     chatscrolldown: false, currentmainID: nextprops.chatlistRes._id
                  }, () => {
                     this.scrollToBottom();
                     if (this.state.currentmainID) {
                        setTimeout(
                           () => {
                              if (this.state.currentmainID) {
                                 // console.log('first after first res..')
                                 this.callchatapi(this.state.currentmainID, true)
                              }
                           },
                           25000
                        );
                     }
                  })
               }
            }
         }
      }

      if (nextprops.sendmsgRes) {
         if (nextprops.sendmsgRes != this.props.sendmsgRes) {
            this.setState({ textmsg: "", sendloader: false })
            if (nextprops.sendmsgRes.data) {
               if (this.state.currentmainID == nextprops.sendmsgRes.data.thread_id) {
                  var sendmsgRes = []
                  sendmsgRes.push(nextprops.sendmsgRes.data)
                  this.setState({
                     chatlist: sendmsgRes.concat(this.state.chatlist),
                     total_chat_history: nextprops.sendmsgRes.count ? nextprops.sendmsgRes.count : this.state.total_chat_history
                  }, () => {
                     this.scrollToBottom()
                  })
               }
            }
         }
      }
      if (nextprops.deletechatRes) {
         if (nextprops.deletechatRes != this.props.deletechatRes) {

            this.setState({ sendreq: false, chat: false, chatreq: false, default: true, currentmainID: '' })
         }
      }
      if (nextprops.acptrejRes) {
         if (nextprops.acptrejRes != this.props.acptrejRes) {
            if (nextprops.acptrejRes.status == 1) {
               this.setState({ sendreq: false, chat: true, chatreq: false, default: false, currentmainID: this.state.current_threadid }, () => {
                  this.callchatapi(this.state.current_threadid, false, true)
               })
            } else {
               this.setState({ sendreq: false, chat: false, chatreq: false, default: true,reqpen:false, currentmainID: '' })
            }
         }
      }

   }

   callchatapi = (thread_id, sendid, fromnew) => {
      this.setState({ sendlastid: sendid ? true : false })
      if (localStorage.getItem('callchatapi') == 'true') {
         this.props.ChatDetailsAPI({
            "thread_id": thread_id,
            "start": fromnew ? 0 : !sendid ? this.state.chatlist ? this.state.chatlist.length : 0 : 0,
            "limit": 30,
            "last_id": sendid ? this.state.chatlist ? this.state.chatlist.length > 0 ? this.state.chatlist[0]._id : "" : "" : "",
            "device_type": devicetype
         });
      }
   }


   openScreen = (i, index) => {
      debugger
      this.setState({
         IsBlock: i.is_block == 1 ? true : false, otheruserid: i.user_id, textmsg: "",current_uname:i.username,
         sendloader: false, reqpen: false, sendreq: false, chat: false, chatreq: false, default: false
         , current_photo: i.photo, current_name: i.name, created_at: i.created_at, current_msg: i.message
         , current_threadid: i.thread_id, newuser: false, searchtext: '', alluserRes: null, chatlist: null
      })

      if (i.status == 0) {
         this.setState({ sendreq: true, currentmainID: null })
      } else if (i.status == 1) {
         if (index) {
            let list = this.state.filterthread
            list[index]['unread_count'] = 0
            this.setState({ filterthread: list })
         }
         this.callchatapi(i.thread_id, false, true)
         this.setState({ currentmainID: i.thread_id })
         this.setState({ chat: true, notupdate: false, chatscrolldown: true })
      } else if (i.status == 3) {
         this.setState({ reqpen: true, currentmainID: null })
      } else if (i.status == 4) {
         this.setState({ chatreq: true, currentmainID: null })
      }
   }

   // requestcall = (i) => {
   //    
   //    this.setState({ IsBlock: i.is_block == 1 ? true : false, otheruserid: i.user_id ? i.user_id : i._id, textmsg: "", sendloader: false })
   //    if (i.status == 1) {
   //       this.props.ChatDetailsAPI({ "thread_id": i.thread_id });
   //       this.setState({ currentmainID: i.thread_id }, () => {
   //          this.IntervalCall(i.thread_id, this.state.reqcall_id)
   //       })
   //       this.setState({ notupdate: false, sendreq: false, chat: true, chatreq: false, default: false, newuser: false, searchtext: '', alluserRes: null })
   //       this.setState({ reqpen: false, current_touserid: i.user_id ? i.user_id : i._id, current_threadid: i.thread_id, current_userid: i.user_id ? i.user_id : i._id, current_photo: i.photo, current_name: i.name })
   //    } else if (i.status == 0) {
   //       this.setState({ currentmainID: null, current_threadid: i.thread_id, current_userid: i.user_id ? i.user_id : i._id, current_photo: i.photo, current_name: i.name })
   //       this.setState({ reqpen: false, sendreq: true, chat: false, chatreq: false, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    } else if (i.status == 3) {
   //       this.setState({ reqpen: true, sendreq: false, chat: false, chatreq: false, default: false, newuser: false, current_photo: i.photo, current_name: i.name })
   //    } else if (i.status == 4) {
   //       this.setState({ created_at: i.created_at, current_msg: i.message, currentmainID: null, sendreq: false, chat: false, chatreq: true, default: false, newuser: false, searchtext: '', alluserRes: null })
   //       this.setState({ reqpen: false, current_touserid: i.user_id ? i.user_id : i._id, current_threadid: i.thread_id, current_userid: i.user_id ? i.user_id : i._id, current_photo: i.photo, current_name: i.name })
   //    }
   //    // if (i.thread_id == "") {
   //    //    this.setState({ currentmainID: null, current_threadid: i.thread_id, current_userid: i.user_id ? i.user_id : i._id, current_photo: i.photo, current_name: i.name })
   //    //    this.setState({ reqpen:false,sendreq: true, chat: false, chatreq: false, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    // } 
   //    // else if (i.status != 0 || i.user_id != userid || i._id != userid) {
   //    //    if (i.status == 1) {
   //    //       // this.props.isLoading(true);
   //    //       this.props.ChatDetailsAPI({ "thread_id": i.thread_id });
   //    //       this.setState({ currentmainID: i.thread_id }, () => {
   //    //          this.IntervalCall(i.thread_id, this.state.reqcall_id)
   //    //       })
   //    //       this.setState({ notupdate: false, sendreq: false, chat: true, chatreq: false, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    //    } else {
   //    //       this.setState({ created_at: i.created_at,current_msg: i.message,currentmainID: null, sendreq: false, chat: false, chatreq: true, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    //    }

   //    //    this.setState({ reqpen:false,current_touserid: i.user_id ? i.user_id : i._id, current_threadid: i.thread_id, current_userid: i.user_id ? i.user_id : i._id, current_photo: i.photo, current_name: i.name })
   //    // } else {
   //    //    this.setState({reqpen:true, sendreq: false, chat: false, chatreq: false, default: false,newuser: false, current_photo: i.photo, current_name: i.name})
   //    //    // swal({ text: "Request Pending..", icon: "warning" });
   //    // }

   // }

   // threaduser = (i, index) => {
   //    
   //    this.setState({ IsBlock: i.is_block == 1 ? true : false, otheruserid: i.other_user_id, textmsg: "", sendloader: false })
   //    if (i.status == 1) {
   //       let list = this.state.filterthread
   //       list[index]['unread_count'] = 0
   //       this.setState({ filterthread: list })
   //       this.props.ChatDetailsAPI({ "thread_id": i._id });
   //       this.setState({ currentmainID: i._id }, () => {
   //          this.IntervalCall(i._id, this.state.threadcall_id)
   //       })
   //       this.setState({ notupdate: false, sendreq: false, chat: true, chatreq: false, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    } else if (i.status == 3) {
   //       this.setState({ reqpen: true, sendreq: false, chat: false, chatreq: false, default: false, current_photo: i.display_profile, current_name: i.display_user_name })
   //    } else if (i.status == 4) {
   //       let list = this.state.filterthread
   //       list[index]['unread_count'] = 0
   //       this.setState({ filterthread: list })
   //       this.setState({ currentmainID: null, sendreq: false, chat: false, chatreq: true, default: false, newuser: false, searchtext: '', alluserRes: null })
   //       this.setState({ reqpen: false, current_threadid: i._id, current_touserid: i.other_user_id, current_userid: i.user_id, created_at: i.created_at, current_msg: i.message, current_photo: i.display_profile, current_name: i.display_user_name })
   //    }

   //    // if (i.status != 0 || i.to_user_id == userid) {
   //    //    let list = this.state.filterthread
   //    //    list[index]['unread_count'] = 0
   //    //    this.setState({ filterthread: list })
   //    //    if (i.status == 1) {
   //    //       // this.props.isLoading(true);
   //    //       this.props.ChatDetailsAPI({ "thread_id": i._id });
   //    //       this.setState({ currentmainID: i._id }, () => {
   //    //          this.IntervalCall(i._id, this.state.threadcall_id)
   //    //       })
   //    //       // if(i._id||this.state.threadcall_id){
   //    //       //    this.IntervalCall(i._id,this.state.threadcall_id)
   //    //       // }
   //    //       this.setState({ notupdate: false, sendreq: false, chat: true, chatreq: false, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    //    } else {
   //    //       this.setState({ currentmainID: null, sendreq: false, chat: false, chatreq: true, default: false, newuser: false, searchtext: '', alluserRes: null })
   //    //    }
   //    //    this.setState({ reqpen: false, current_threadid: i._id, current_touserid: i.other_user_id, current_userid: i.user_id, created_at: i.created_at, current_msg: i.message, current_photo: i.display_profile, current_name: i.display_user_name })
   //    // } else {
   //    //    this.setState({ reqpen: true, sendreq: false, chat: false, chatreq: false, default: false, current_photo: i.display_profile, current_name: i.display_user_name })
   //    //    // swal({ text: "Request Pending..", icon: "warning" });
   //    // }
   // }

   sendrequest = () => {

      let userid = encryptStorage.getItem('userid')
      // this.props.isLoading(true);
      let data = {
         "from_user_id": userid,
         "to_user_id": this.state.otheruserid
      }
      this.props.AddThreadAPI(data)
   }
   AcptRej = (status) => {
      // this.props.isLoading(true);
      let data = {
         "thread_id": this.state.current_threadid,
         "status": status
      }
      this.props.AcceptRejectRequestAPI(data)
   }

   sendmsg = () => {

      if (!this.state.sendloader) {
         if (this.state.textmsg && this.state.textmsg.trim() != "") {
            // this.props.isLoading(true);
            this.setState({ sendloader: true })
            let data = {
               "thread_id": this.state.current_threadid,
               "to_user_id": this.state.otheruserid,
               "message": this.state.textmsg
            }
            this.props.SendMessageAPI(data);
            this.setState({textmsg:''})
         }
      }

   }

   blockuser = () => {
      swal({
         title: "",
         text: "Are you sure you want to block this account?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               this.setState({ IsBlock: true })
               // this.props.isLoading(true);
               this.props.BlockuserAPI(
                  {
                     "to_user_id": this.state.otheruserid
                  }, 'true');
            } else {
            }
         });
   }


   unblockuser = () => {
      this.setState({ IsBlock: false })
      // this.props.isLoading(true);
      this.props.UnBlockuserAPI(
         {
            "to_user_id": this.state.otheruserid
         }, 'true');
   }

   deletechat = () => {
      swal({
         title: "",
         text: "Are you sure you want to delete this chat?",
         icon: "warning",
         buttons: true,
         dangerMode: true,
      })
         .then((willDelete) => {
            if (willDelete) {
               // this.props.isLoading(true);
               this.props.DeleteChatAPI(
                  {
                     "thread_id": this.state.current_threadid
                  });

            } else {
            }
         });
   }

   gotoprofile = () => {

      this.props.history.push({
         pathname: `/otherprofile/`+this.state.otheruserid,
         state: this.state.otheruserid
      });
   }



   scrollToBottom = () => {
      var objDiv = document.getElementById("chatcontent");
      if (objDiv) { objDiv.scrollTop = objDiv.scrollHeight; }
   }

   // componentDidUpdate() {
   //    if (!this.state.notupdate) {
   //       this.scrollToBottom();
   //    }
   // }

   render() {
      return (
         <>
            <Loader />
            <div className="wrapper">
               <Sidebar active="chat" />
               <Navbar />
               <div id="content-page" className="content-page chat-content-page">
                  <div className="container-fluid">
                     <div className="row">
                        <div className="col-sm-12">
                           <div className="iq-card">
                              <div className="iq-card-body chat-page p-0">
                                 <div className="chat-data-block">
                                    <div className="row">

                                       <div className="col-lg-4 chat-data-left scroller show">
                                          <div className="chat-search pt-3 pl-2">
                                             <div className="find-new-user-for-chat">

                                                <div className="d-flex align-items-center imgcontent-block">
                                                   <div className="chat-profile mr-2">
                                                      <Img className="avatar-40 " src={encryptStorage.getItem('userpic')} loader={<img src={user1} className="avatar-50 " />} unloader={<img src={user1} className="avatar-50 " />} />
                                                      {/* <img src={encryptStorage.getItem('userpic') ? encryptStorage.getItem('userpic') : user1}  className="avatar-50 " /> */}
                                                   </div>
                                                   <div className="chat-caption">
                                                      <h5 className="mb-0">{encryptStorage.getItem('myname')}</h5>

                                                   </div>
                                                   <div className="find-new-chat-user">
                                                      <a onClick={() => { this.setState({ newuser: true }) }}>
                                                         <div className="addnew-chat"><i className="fa fa-plus"></i></div>
                                                      </a>
                                                   </div>
                                                </div>


                                             </div>

                                             <div className="chat-searchbar  pt-3 ">

                                                <div className="form-group chat-search-data m-0">
                                                   <input
                                                      maxLength={30}
                                                      type="text"
                                                      className="form-control round"
                                                      id="chat-search"
                                                      value={this.state.search}
                                                      onChange={(e) => this.searchthread(e)}
                                                      //disabled={this.state.threadRes&&this.state.threadRes.length>0?false:true} 
                                                      placeholder="Search " />
                                                   <i className="ri-search-line"></i>
                                                </div>





                                             </div>
                                          </div>
                                          <div className="chat-sidebar-channel scroller mt-4 pl-3">
                                             {this.state.filterthread ?
                                                this.state.filterthread.length > 0 ?
                                                   <ul className="iq-chat-ui nav flex-column nav-pills">
                                                      {this.state.filterthread.map((i, index) =>
                                                         <li key={i._id} className="thread">
                                                            <a data-toggle="pill" onClick={() => this.openScreen(i, index)}>
                                                               <div className="d-flex align-items-center">
                                                                  <div className="avatar mr-2">
                                                                     {/* <img src={i.display_profile ? i.display_profile : user02}  className="avatar-40 " /> */}
                                                                     <Img className="avatar-50 " src={i.photo} loader={<img src={user02} className="avatar-50 " />} unloader={<img src={user02} className="avatar-50 " />} />
                                                                  </div>
                                                                  <div className="chat-sidebar-name">
                                                                     <h6 className="mb-0">{i.name && i.name.slice(0, 30)}{i.name && i.name.length > 30 && '...'}</h6>
                                                                     {/* <span>{i.created_at && diff_minutes_chat(i.created_at)}</span> */}

                                                                     <span>{i.message && i.message.slice(0, 20)}{i.message.length > 20 && '...'}</span>

                                                                  </div>
                                                                  <div className=" chat-meta float-right text-center mt-2 mr-1">
                                                                     {i.unread_count > 0 && this.state.currentmainID != i._id && <span className="chat-msg-counter chat-unreadcount bg-primary text-white">{i.unread_count > 9 ? '9+' : i.unread_count}</span>}
                                                                     <span className="text-nowrap">{i.last_message_date && diff_minutes_chat(i.last_message_date)}</span>
                                                                  </div>

                                                               </div>
                                                            </a>
                                                         </li>
                                                      )}
                                                   </ul>
                                                   // : <p style={{ textAlign: 'center' }}>No results found!</p>
                                                   : this.state.search && this.state.threadRes && this.state.threadRes.length > 0 ? <p style={{ textAlign: 'center' }}>No results found!</p>
                                                      : <p style={{ textAlign: 'center' }}>No conversations. <br />Tap the + button to start a conversations!</p>
                                                : <div className="col-sm-12 text-center" style={{ marginBottom: 50 }}>
                                                   <img src={pageloadloader} style={{ height: 80 }} />
                                                </div>}
                                          </div>
                                       </div>

                                       <div className="col-lg-8 chat-data p-0 chat-data-right">
                                          <div className="tab-content">
                                             {this.state.default &&
                                                <div className="tab-pane fade active show" id="default-block" role="tabpanel">
                                                   <div className="chat-start" >
                                                      <span className="iq-start-icon text-primary" onClick={() => { this.setState({ newuser: true }) }}><i className="ri-message-3-line"></i></span>
                                                      <button id="chat-start" className="btn bg-white mt-3" onClick={() => { this.setState({ newuser: true }) }}>Start
                                                         Conversation!</button>
                                                   </div>
                                                </div>
                                             }
                                             {this.state.chat &&
                                                <div className="tab-pane fade active show" id="chatbox1" role="tabpanel">
                                                   <div className="chat-head">
                                                      <header className="d-flex justify-content-between align-items-center  pt-3 pr-3 pb-3">
                                                         <div className="d-flex align-items-center">
                                                            <div className="sidebar-toggle new-side-arrow">
                                                               <i className="las la-arrow-left"></i>
                                                            </div>
                                                            <a className="avatar chat-user-profile m-0 mr-3" onClick={() => this.gotoprofile()}>
                                                               {/* <img src={this.state.current_photo ? this.state.current_photo : user1}  className="avatar-50 " /> */}
                                                               <Img className="avatar-50 " src={this.state.current_photo} loader={<img src={user1} className="avatar-50 " />} unloader={<img src={user1} className="avatar-50 " />} />
                                                            </a>
                                                            <div>
                                                            <h5 className="mb-0" onClick={() => this.gotoprofile()}>{this.state.current_name}</h5>
                                                            <p className=" font-size-12 mb-0" onClick={() => this.gotoprofile()}>{this.state.current_uname}</p>
                                                            </div>
                                                         </div>
                                                         <div className="chat-header-icons d-flex">


                                                            <span className="dropdown ">
                                                               <i className="ri-more-2-line cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer pr-0" id="dropdownMenuButton02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu"></i>
                                                               <span className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton02">

                                                                  <a className="dropdown-item" onClick={() => this.deletechat()}><i className="fa fa-trash-o" aria-hidden="true"></i> Delete chat</a>
                                                                  {!this.state.IsBlock && <a className="dropdown-item" onClick={() => this.blockuser()}><i className="fa fa-ban" aria-hidden="true"></i> Block</a>}
                                                               </span>
                                                            </span>
                                                         </div>
                                                      </header>
                                                   </div>

                                                   <div className="chat-content scroller" id="chatcontent" onScroll={(e) => this.handleScrollchat(e)}>
                                                      {this.state.chatlist ?
                                                         this.state.chatlist.length > 0 ?
                                                            this.state.chatlist.map((i, index) =>
                                                               i && userid == i.from_user_id ?
                                                                  <div className="chat" key={index} id={i._id}>
                                                                     <div className="chat-user" >
                                                                        <a className="avatar m-0">
                                                                           {/* <img src={encryptStorage.getItem('userpic') ? encryptStorage.getItem('userpic') : user1}  className="avatar-35 " /> */}
                                                                           <Img className="avatar-35 " src={encryptStorage.getItem('userpic')} loader={<img src={user1} className="avatar-35 " />} unloader={<img src={user1} className="avatar-35 " />} />
                                                                        </a>

                                                                     </div>
                                                                     <div className="chat-detail">
                                                                        <div className="chat-message">
                                                                           <Linkify
                                                                              componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                 <a target="blank" href={decoratedHref} key={key}>
                                                                                    {decoratedText}
                                                                                 </a>
                                                                              )}>
                                                                              {/* <ShowMoreText lines={5}
                                                                           more="Read more"
                                                                           less="Read less"
                                                                           className="content-css chat-msg"
                                                                           anchorclassName="my-anchor-css-class"
                                                                           onClick={(isExpanded) => this.executeOnClick(isExpanded)}
                                                                           expanded={false}
                                                                           truncatedEndingComponent={"... "}
                                                                        > */}
                                                                              <div className="content-css chat-msg">
                                                                                 {i.message && i.message.length > 600 && !i.readless ?
                                                                                    i.message.substring(0, 600).split("\n").map(function (item, idx) { return (<p key={idx}>{item} <br /></p>) })
                                                                                    : i.message.split("\n").map(function (item, idx) { return (<p key={idx}>{item} <br /></p>) })
                                                                                 }
                                                                                 {i.message && i.message.length > 600 ? i.readless ? <a style={{ textDecoration: "underline" }} onClick={() => this.setreadflag(index, "less")}>Read less</a> : <a style={{ textDecoration: "underline" }} onClick={() => this.setreadflag(index, "more")}>Read more</a> : null}
                                                                              </div>
                                                                              {/* </ShowMoreText> */}
                                                                           </Linkify>

                                                                        </div>
                                                                        <span className="chat-time mt-1">{i.created_at && diff_minutes_chat(i.created_at)}</span>
                                                                     </div>
                                                                  </div>
                                                                  :
                                                                  i && <div className="chat chat-left" key={index} id={i._id}>
                                                                     <div className="chat-user">
                                                                        <a className="avatar m-0">
                                                                           {/* <img src={this.state.current_photo ? this.state.current_photo : user1}  className="avatar-35 " /> */}
                                                                           <Img className="avatar-35 " src={this.state.current_photo} loader={<img src={user1} className="avatar-35 " />} unloader={<img src={user1} className="avatar-35 " />} />
                                                                        </a>

                                                                     </div>
                                                                     <div className="chat-detail">
                                                                        <div className="chat-message">
                                                                           <Linkify
                                                                              componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                 <a target="blank" href={decoratedHref} key={key}>
                                                                                    {decoratedText}
                                                                                 </a>
                                                                              )}>
                                                                              {/* <ShowMoreText lines={5}
                                                                           more="Read more"
                                                                           less="Read less"
                                                                           className="content-css"
                                                                           anchorclassName="my-anchor-css-class"
                                                                           onClick={(isExpanded) => this.executeOnClick(isExpanded)}
                                                                           expanded={false}
                                                                           truncatedEndingComponent={"... "}
                                                                        > */}
                                                                              <div className="content-css chat-msg">
                                                                                 {i.message && i.message.length > 600 && !i.readless ?
                                                                                    i.message.substring(0, 600).split("\n").map(function (item, idx) { return (<p key={idx}>{item} <br /></p>) })
                                                                                    : i.message.split("\n").map(function (item, idx) { return (<p key={idx}>{item} <br /></p>) })
                                                                                 }
                                                                                 {i.message && i.message.length > 600 ? i.readless ? <a style={{ textDecoration: "underline" }} onClick={() => this.setreadflag(index, "less")}>Read less</a> : <a style={{ textDecoration: "underline" }} onClick={() => this.setreadflag(index, "more")}>Read more</a> : null}
                                                                              </div>
                                                                              {/* </ShowMoreText> */}
                                                                           </Linkify>
                                                                        </div>
                                                                        <span className="chat-time mt-1">{i.created_at && diff_minutes_chat(i.created_at)}</span>
                                                                     </div>
                                                                  </div>
                                                            ).reverse()
                                                            : null : null}

                                                   </div>
                                                   {this.state.IsBlock ?
                                                      <div className="blocked-account-sec">
                                                         <p>You Blocked This Account</p>
                                                         <button className="btn  btn-primary " onClick={() => this.unblockuser()}>Unblock</button></div> :
                                                      <div className="chat-footer p-3 bg-white">
                                                         <div className="d-flex align-items-center"  >
                                                            <div className="chat-input-div-main">
                                                               <div className="main-inpt-1">
                                                                  <InputEmoji
                                                                     className=""
                                                                     value={this.state.textmsg}
                                                                     onChange={(val) => { this.setState({ textmsg: val.length > 2000 ? val.substring(0, 2000) : val }) }}
                                                                     placeholder={this.state.textmsg ? "" : "Type a message"}
                                                                     maxLength={2000}
                                                                     onEnter={() => this.sendmsg()}
                                                                  />
                                                               </div>
                                                               <div className="main-inpt-2">
                                                                  {this.state.sendloader ? <img src={pageloadloader} style={{ height: 50 }} /> :
                                                                     <button type="submit" className="btn btn-primary d-flex align-items-center p-2" onClick={() => this.sendmsg()}>
                                                                        <i className="fa fa-paper-plane-o" aria-hidden="true"></i><span className="d-none d-lg-block ml-1">Send</span></button>
                                                                  }</div>

                                                            </div>

                                                         </div>
                                                      </div>}
                                                </div>
                                             }
                                             {this.state.sendreq &&
                                                <div className="tab-pane fade active show" id="send-request" role="tabpanel">
                                                   <div className="chat-head">
                                                      <header className="d-flex justify-content-between align-items-center  pt-3  pr-3 pb-3">
                                                         <a className="d-flex align-items-center" >
                                                            <div className="sidebar-toggle new-side-arrow">
                                                               <i className="las la-arrow-left"></i>
                                                            </div>
                                                            <div className="avatar chat-user-profile m-0 mr-3" onClick={() => this.gotoprofile()}>
                                                               {/* <img src={this.state.current_photo ? this.state.current_photo : user1}  className="avatar-50 " /> */}
                                                               <Img className="avatar-50 " src={this.state.current_photo} loader={<img src={user1} className="avatar-50 " />} unloader={<img src={user1} className="avatar-50 " />} />
                                                            </div>
                                                            <div>
                                                            <h5 className="mb-0" onClick={() => this.gotoprofile()}>{this.state.current_name}</h5>
                                                            <p className=" font-size-12 mb-0" onClick={() => this.gotoprofile()}>{this.state.current_uname}</p>
                                                            </div>
                                                         </a>
                                                         {/* <div className="chat-header-icons d-flex">
                                                         <span className="dropdown">
                                                            <i className="ri-more-2-line cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu"></i>
                                                            <span className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                                                            {!this.state.IsBlock && <a className="dropdown-item" onClick={() => this.blockuser()}><i className="fa fa-ban" aria-hidden="true"></i> Block</a>}
                                                            </span>
                                                         </span>
                                                      </div> */}
                                                      </header>
                                                   </div>
                                                   <div className="chat-start send-req">
                                                      <h6 className="font-weight-bold"> Start a conversation with {this.state.current_name}!</h6>

                                                      <input className="btn bg-white mt-3  wants-tochat" disabled value="Wants to chat with you" />
                                                      {!this.state.IsBlock && <button id="chat-start" className="btn send-req-btn btn-primary" onClick={() => this.sendrequest()}>Send Request</button>}

                                                   </div>
                                                   {this.state.IsBlock ? <div className="blocked-account-sec">
                                                      <p>You Blocked This Account</p>
                                                      <button className="btn  btn-primary " onClick={() => this.unblockuser()}>Unblock</button></div> :
                                                      null}
                                                </div>
                                             }
                                             {this.state.reqpen &&
                                                <div className="tab-pane fade active show" id="send-request" role="tabpanel">
                                                   <div className="chat-head">
                                                      <header className="d-flex justify-content-between align-items-center  pt-3 pr-3 pb-3">
                                                         <a className="d-flex align-items-center">
                                                            <div className="sidebar-toggle new-side-arrow">
                                                               <i className="las la-arrow-left"></i>
                                                            </div>
                                                            <div className="avatar chat-user-profile m-0 mr-3" onClick={() => this.gotoprofile()}>
                                                               {/* <img src={this.state.current_photo ? this.state.current_photo : user1}  className="avatar-50 " /> */}
                                                               <Img className="avatar-50 " src={this.state.current_photo}
                                                                  loader={<img src={user1} className="avatar-50 " />}
                                                                  unloader={<img src={user1} className="avatar-50 " />} />
                                                            </div>
                                                            <div>
                                                            <h5 className="mb-0" onClick={() => this.gotoprofile()}>{this.state.current_name}</h5>
                                                            <p className=" font-size-12 mb-0" onClick={() => this.gotoprofile()}>{this.state.current_uname}</p>
                                                            </div>
                                                         </a>
                                                         {/* <div className="chat-header-icons d-flex">
                                                         <span className="dropdown">
                                                            <i className="ri-more-2-line cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu"></i>
                                                            <span className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                                                            {!this.state.IsBlock && <a className="dropdown-item" onClick={() => this.blockuser()}><i className="fa fa-ban" aria-hidden="true"></i> Block</a>}
                                                            </span>
                                                         </span>
                                                      </div> */}
                                                      </header>
                                                   </div>
                                                   <div className="chat-start"> 
                                                      <span className="iq-start-icon text-primary"><i className="ri-information-line"></i></span>
                                                      <h6 className="font-weight-bold mt-2"> Request Pending... </h6>
                                                      <p className="pending-req">Chat request is sent to {this.state.current_name}.<br /> You have to wait till request gets accepted.</p>

                                                      {this.state.IsBlock ? null : <div className="request-btns">
                                                         <button className="decline-btn btn" onClick={() => this.AcptRej(2)}>Remove Request</button>
                                                      </div>}

                                                      
                                                   </div>
                                                   {this.state.IsBlock ? <div className="blocked-account-sec"><p>You Blocked This Account</p><button className="btn  btn-primary "  onClick={() => this.unblockuser()}>Unblock</button></div> :
                                                         null}

                                                      {/* <input className="btn bg-white mt-3  wants-tochat" disabled placeholder="Wants to chat with you" /> */}
                                                      {/* <button id="chat-start" className="btn send-req-btn btn-primary" onClick={() => this.sendrequest()}>Send Request</button> */}
                                                </div>
                                             }
                                             {this.state.chatreq &&
                                                <div className="tab-pane fade active show" id="chat-withyou" role="tabpanel">
                                                   <div className="chat-head">
                                                      <header className="d-flex justify-content-between align-items-center  pt-3  pr-3 pb-3">
                                                         <a className="d-flex align-items-center">
                                                            <div className="sidebar-toggle new-side-arrow">
                                                               <i className="las la-arrow-left"></i>
                                                            </div>
                                                            <div className="avatar chat-user-profile m-0 mr-3" onClick={() => this.gotoprofile()}>
                                                               {/* <img src={this.state.current_photo ? this.state.current_photo : user1}  className="avatar-50 " /> */}
                                                               <Img className="avatar-50 " src={this.state.current_photo} loader={<img src={user1} className="avatar-50 " />} unloader={<img src={user1} className="avatar-50 " />} />
                                                            </div>
                                                            <div >
                                                            <h5 className="mb-0" onClick={() => this.gotoprofile()}>{this.state.current_name}</h5>
                                                            <p className=" font-size-12 mb-0" onClick={() => this.gotoprofile()}>{this.state.current_uname}</p>
                                                            </div>
                                                         </a>
                                                         {!this.state.IsBlock && <div className="chat-header-icons d-flex">

                                                            <span className="dropdown">
                                                               <i className="ri-more-2-line cursor-pointer dropdown-toggle nav-hide-arrow cursor-pointer" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="menu"></i>
                                                               <span className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                                                                  {!this.state.IsBlock && <a className="dropdown-item" onClick={() => this.blockuser()}><i className="fa fa-ban" aria-hidden="true"></i> Block</a>}
                                                               </span>
                                                            </span>
                                                         </div>}
                                                      </header>
                                                   </div>
                                                   <div className="chat-content scroller">

                                                      <div className="chat chat-left">
                                                         <div className="chat-user">
                                                            <a className="avatar m-0">
                                                               {/* <img src={this.state.current_photo ? this.state.current_photo : user1}  className="avatar-35 " /> */}
                                                               <Img className="avatar-35 " src={this.state.current_photo} loader={<img src={user1} className="avatar-35 " />} unloader={<img src={user1} className="avatar-35 " />} />
                                                            </a>

                                                         </div>
                                                         <div className="chat-detail">
                                                            <div className="chat-message">
                                                               <Linkify
                                                                  componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                     <a target="blank" href={decoratedHref} key={key}>
                                                                        {decoratedText}
                                                                     </a>
                                                                  )}> <div className="content-css chat-msg">{this.state.current_msg ? this.state.current_msg : this.state.current_name + " wants to chat with you."}</div></Linkify>

                                                            </div>
                                                            <span className="chat-time mt-1">{this.state.created_at && diff_minutes_chat(this.state.created_at)}</span>
                                                         </div>
                                                      </div>
                                                      {this.state.IsBlock ? null : <div className="request-btns">
                                                         <button className="decline-btn btn" onClick={() => this.AcptRej(2)}>Decline</button>
                                                         <button className="accpt-btn btn bg-success" onClick={() => this.AcptRej(1)}>Accept</button>
                                                      </div>}

                                                   </div>
                                                   {this.state.IsBlock ? <div className="blocked-account-sec">
                                                      <p>You Blocked This Account</p>
                                                      <button className="btn  btn-primary " onClick={() => this.unblockuser()}>Unblock</button></div> :
                                                      null}
                                                </div>
                                             }
                                          </div>
                                       </div>



                                    </div>
                                 </div>
                              </div>
                           </div>
                           <Modal
                              classNames={{
                                 overlay: "customOverlay",
                                 modal: "customModal"
                              }}
                              open={this.state.newuser}
                              center
                              onClose={() => { }}
                              showCloseIcon={false}
                           >
                              {this.state.newuser &&
                                 <div className="modal fade show new-search-modal " id="post-modal" tabIndex="-1" role="dialog" aria-labelledby="post-modalLabel" aria-modal="true" style={{ paddingRight: '8', display: 'block' }}>
                                    <div className="modal-dialog addnew-chat-modal" role="document">
                                       <div className="modal-content">
                                          <div className="modal-header">
                                             <div className="d-flex modal-search-img">
                                                {/* <img src={encryptStorage.getItem('userpic') ? encryptStorage.getItem('userpic') : user1}  className="avatar-50 rounded-circle" /> */}
                                                <Img className="avatar-50 rounded-circle" src={encryptStorage.getItem('userpic')} loader={<img src={user1} className="avatar-50 rounded-circle" />} unloader={<img src={user1} className="avatar-50 rounded-circle" />} />

                                                <h5 className="ml-2" style={{ alignSelf: 'center' }}>{ encryptStorage.getItem("username")?encryptStorage.getItem("username"):encryptStorage.getItem("myname")}</h5></div>

                                             <a className="close-modal" style={{ alignSelf: 'center' }} onClick={() => this.setState({ alluserRes: null, newuser: false, searchtext: '', alluserRes: null })}><i className="ri-close-fill"></i></a></div>
                                          <div className="modal-body">
                                             {/* <div className="row">
                                               
                                                <button type="button" className="btn  close-modal" data-dismiss="modal"onClick={() => this.setState({ newuser: false, searchtext: '', alluserRes: null })}><i className="ri-close-fill"></i></button> 
                                                <a style={{ alignSelf: 'center' }} onClick={() => this.setState({ newuser: false, searchtext: '', alluserRes: null })}><i className="ri-close-fill"></i></a>
                                             </div> */}

                                             <div id="user-detail-popup" className="scroller">
                                                <div className="user-profile">
                                                   <button type="submit" className="close-popup p-3"><i className="ri-close-fill"></i></button>
                                                   <div className="user text-center mb-4">
                                                      <a className="avatar m-0">
                                                         {/* <img src={encryptStorage.getItem('userpic') ? encryptStorage.getItem('userpic') : user1}  /> */}
                                                         <Img src={encryptStorage.getItem('userpic')} loader={<img src={user1} />} unloader={<img src={user1} />} />
                                                      </a>
                                                      <div className="user-name mt-4">
                                                         <h4>{ encryptStorage.getItem("username")?encryptStorage.getItem("username"):encryptStorage.getItem("myname")}</h4>
                                                      </div>
                                                   </div>
                                                   <hr />
                                                   <div className="user-detail text-left mt-4 pl-4 pr-4">
                                                      <h5 className="mt-4 mb-4">About</h5>
                                                      <p>It is long established fact that a reader will be distracted bt the reddable.</p>
                                                      <h5 className="mt-3 mb-3">Status</h5>
                                                      <ul className="user-status p-0">
                                                         <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-success pr-1"></i><span>Online</span></li>
                                                         <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-warning pr-1"></i><span>Away</span></li>
                                                         <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-danger pr-1"></i><span>Do Not Disturb</span></li>
                                                         <li className="mb-1"><i className="ri-checkbox-blank-circle-fill text-light pr-1"></i><span>Offline</span></li>
                                                      </ul>
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="chat-searchbar pt-3">
                                                <div className="form-group chat-search-data m-0">
                                                   <input
                                                      maxLength={30}
                                                      type="text"
                                                      className="form-control round"
                                                      id="chat-search"
                                                      value={this.state.searchtext}
                                                      onChange={(e) => this.searchuser(e)}
                                                      placeholder="Search "
                                                   />
                                                   <i className="ri-search-line"></i>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="chat-sidebar-channel scroller mt-4 pl-3">
                                             {this.state.searchtext ? this.state.searchtext.length > 2 && this.state.alluserRes ? this.state.alluserRes.length > 0 ?
                                                <ul className="iq-chat-ui nav flex-column nav-pills">
                                                   {this.state.alluserRes.map((i, index) =>
                                                      <li key={index}>
                                                         <a data-toggle="pill" onClick={() => this.openScreen(i)}>
                                                            <div className="d-flex align-items-center">
                                                               <div className="avatar mr-2">
                                                                  {/* <img src={i.photo ? i.photo : user02}  className="avatar-40 rounded-circle" /> */}
                                                                  <Img className="avatar-50 rounded-circle" src={i.photo} loader={<img src={user02} className="avatar-50 rounded-circle" />} unloader={<img src={user02} className="avatar-50 rounded-circle" />} />
                                                               </div>
                                                               <div className="chat-sidebar-name">
                                                                  <h6 className="mb-0 mr-2"> {i.name}</h6>
                                                                  <h5 className="mb-0 mr-2 user-name">{i.username}</h5>
                                                                  <span>{i.message}</span>
                                                               </div>


                                                            </div>
                                                         </a>
                                                      </li>
                                                   )}
                                                </ul> :
                                                <p style={{ textAlign: 'center' }}>No results found!</p> : null : null}
                                          </div>
                                       </div>

                                    </div>

                                 </div>
                              }
                           </Modal>
                           {this.state.newuser && <div className="modal-backdrop fade show"></div>}


                        </div>
                     </div>
                  </div>
               </div>

            </div>
         </>)
   }
}
const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      isLoading: loading => { dispatch(dispatchLoadingStatus(loading)); },
      ThreadListAPI: () => { dispatch(ThreadListAPI()) },
      AllUserAPI: (data) => { dispatch(AllUserAPI(data)) },
      AddThreadAPI: (data) => { dispatch(AddThreadAPI(data)) },
      AcceptRejectRequestAPI: (data) => { dispatch(AcceptRejectRequestAPI(data)) },
      ChatDetailsAPI: (data) => { dispatch(ChatDetailsAPI(data)) },
      SendMessageAPI: (data) => { dispatch(SendMessageAPI(data)) },
      DeleteChatAPI: (data) => { dispatch(DeleteChatAPI(data)) },
      BlockuserAPI: (data, isthreadcall) => { dispatch(BlockuserAPI(data, isthreadcall)) },
      UnBlockuserAPI: (data, isthreadcall) => { dispatch(UnBlockuserAPI(data, isthreadcall)) },
      BasicCountAPI: (postdata) => { dispatch(BasicCountAPI(postdata)) },
      BasicInfoAPI: (postdata) => { dispatch(BasicInfoAPI(postdata)) },
   }
}
const mapStateToProps = (state, ownProps) => {
   return {
      loading: state.LoadingStatusReducer.loading,
      threadRes: state.globalReducer.ThreadListRes,
      alluserRes: state.globalReducer.AllUserRes,
      addthreadRes: state.globalReducer.AddThreadRes,
      acptrejRes: state.globalReducer.AcceptRejectRequestRes,
      chatlistRes: state.globalReducer.ChatDetailsRes,
      sendmsgRes: state.globalReducer.SendMessageRes,
      deletechatRes: state.globalReducer.DeleteChatRes

   }
}
export default connect(mapStateToProps, mapDispatchToProps)((Chat))