import React from "react";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import TextareaAutosize from 'react-textarea-autosize';

export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isopen:false,
            msg:''
        }
    }
    addEmoji=(emoji, event)=>{
        debugger
        if(this.state.msg.length<2000){
            this.setState({msg:this.state.msg!='\n'?this.state.msg + emoji.native:emoji.native})
            this.refs.msg.focus()
        }            
    }

    handlechange=(e)=>{
        debugger
            this.setState({msg:e.target.value})
            this.refs.msg.focus()
    }

    onenter=(evt)=>{
        debugger
        if (evt.charCode == 13 && !evt.shiftKey) {
            if (this.state.msg && this.state.msg.trim() != ""){
                alert('Enter Event')
                // this.props.sendmsg(this.state.msg);
                this.setState({isopen:false,msg:''})
            }            
        }
    }

    render(){
        return(
            <div>
             <TextareaAutosize style={{width:'70%'}} ref='msg' placeholder={"Type a message.."} type="text" value={this.state.msg!='\n'?this.state.msg:""} maxLength={2000} onChange={(e)=>this.handlechange(e)} onKeyPress={(e)=>this.onenter(e)}></TextareaAutosize>
            <p style={{cursor:'pointer'}} onClick={()=>this.setState({isopen:!this.state.isopen})}>🙂 </p>
            {this.state.isopen&&<Picker onClick={(emoji, event) => this.addEmoji(emoji, event)}/>}
            </div>
        )
    }
}

