import { IS_LOADING, baseURL, basepath } from "../constants";
import axios from "axios";
import moment from "moment";
import { EncryptStorage } from 'encrypt-storage';

export const encryptStorage = EncryptStorage('secret_key', {});

export function getAsios(token) {
  if (token) {
    return axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: token
      }
    });
  } else {
    return axios.create({
      baseURL: baseURL,
      // headers: {
      //   Authorization: "bearer " + token
      //   }
    });
  }

}

export function dispatchLoadingStatus(loading) {
  return function (dispatch, getState) {
    dispatch({ type: IS_LOADING, payload: { loading: loading } });
  };
}



// export function logout() {

//   encryptStorage.removeItem("cizzrtoken");
//   this.props.history.push(`/home`);
// }

export function isValidName(name) {

  let isValidName = /^[a-zA-Z ]+$/.test(name);
  return isValidName;
}

export function isValidNumber(num) {

  let isValidNumber = /^[0-9]+$/.test(num);
  return isValidNumber;
}


export function SecureEmail(email) {

  // var regex = /(?<!^).(?!$)/g;
  if (email) {
    let arr = email.split("@")
    if (arr.length > 1) {
      var x = arr[0].split('')
      var y = x.map((i, index) => index != 0 && index != x.length - 1 ? "*" : i)
      var securetext = y.toString().replaceAll(",", "") + "@" + arr[1]
      // var securetext = arr[0].replace(regex, '*') + "@" + arr[1]
      return securetext;
    }
    return email;
  }
}

export function isValidpass(pass) {

  let isValidpw = /^\S/.test(pass);
  return isValidpw;
}

export function todaydate() {

  let newDate = new Date()
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let date = year + "-" + month + "-" + day
  return date
}


export function diff_minutes(prevDate, datedisplay) {
  var d1 = moment(prevDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
  var d2 = moment().format('YYYY-MM-DD');
  var diff = moment(d2).diff(d1, 'days')
  if (diff > 30) {
    return moment(prevDate, 'YYYY-MM-DD').format('MMMM DD, YYYY');
  } else {
    var d = new Date(prevDate);
    const diff = Number(new Date()) - d;

    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000);
        return seconds > 1 ? `${seconds} seconds ago` : 'few seconds ago';
      case diff < hour:
        return Math.round(diff / minute) == 1 ? Math.round(diff / minute) + ' minute ago' : Math.round(diff / minute) + ' minutes ago';
      case diff < day:
        return Math.round(diff / hour) == 1 ? Math.round(diff / hour) + ' hour ago' : Math.round(diff / hour) + ' hours ago';
      case diff < month:
        return Math.round(diff / day) == 1 ? Math.round(diff / day) + ' day ago' : Math.round(diff / day) + ' days ago';
      case diff < year:
        return Math.round(diff / month) == 1 ? Math.round(diff / month) + ' month ago' : Math.round(diff / month) + ' months ago';
      case diff > year:
        return Math.round(diff / year) == 1 ? Math.round(diff / year) + ' year ago' : Math.round(diff / year) + ' years ago';
      default:
        return "";
    }
  }
}

export function diff_minutes_chat(prevDate) {
  var d1 = moment(prevDate, "YYYY-MM-DD").format("YYYY-MM-DD");
  var d2 = moment().format("YYYY-MM-DD");
  var todaydiff = moment(d2).diff(d1, "days");

  var d = new Date(prevDate);
  const diff = Number(new Date()) - d;

  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  // const month = day * 30;
  const year = day * 365;
  switch (true) {
    case diff < minute:
      return "Now";
    case diff < hour:
      return Math.round(diff / minute) + " min";
    case todaydiff < 1:
      return moment(prevDate).format("h:mm A");
    case todaydiff >= 1 && diff < year:
      return moment(prevDate).format("MMM D, h:mm A");
    case diff > year:
      return moment(prevDate).format("MMM D YYYY, h:mm A");
    default:
      return "";
  }
}



export function Validatepass(password) {
  var upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

  if (password.length > 7 &&
    upper.test(password) &&
    lower.test(password) &&
    number.test(password) &&
    special.test(password)
  ) {
    return true;
  }

  return false;
}

// Created by NiraliBThummar 
export function mentionUser(text) {
  if (text) {
    var defaultflag = text.toString().charAt(0) == '@' || text.toString().charAt(text.length - 1) == '@'
    var result = [defaultflag];
    var searchtext = text;
    if (text.toString().includes(' ')) {
      result = text.split(' ').map(i => i.toString().charAt(0) == '@' || i.toString().charAt(i.length - 1) == '@')
      searchtext = text.split(' ')[result.length - 1]
      if (result[result.length - 1] == true) {
        return { status: true, searchtext: searchtext.toString().charAt(searchtext.length - 1) == '@' ? '' : searchtext.toString().substring(1, searchtext.length) }
      }
    }else if(text.toString().includes('@')){
      result = text.split('@')
      return { status: true, searchtext: result[result.length - 1]}
    }   
    return { status: false, searchtext: '' }
  } else {
    return { status: false, searchtext: '' }
  }
}


// Created by NiraliBThummar 
export function numFormatter(num) {
  if(num > 999 && num < 1000000){
      return (num/1000).toFixed(1) + 'K'; // thousand
  }else if(num >= 1000000 && num < 1000000000){
      return (num/1000000).toFixed(1) + 'M'; // million
  }else if(num >= 1000000000 && num < 1000000000000){
      return (num/1000000000).toFixed(1) + 'B'; // billion
  }else if(num >= 1000000000000){
      return (num/1000000000000).toFixed(1) + 'T'; // trillion
  }else if(num < 900){
      return num; // if value < 1000, nothing to do
  }
}