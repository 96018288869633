import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

export default class Share extends React.Component {
  render() {
    var url = this.props.URL //"https://web.cizzr.com/"
    var message = this.props.Message // "Hello.."
    return (
      <>
        <div className="dropdown">
          <ul className="share-drop">
            <li className="post-share">
              <a className="share-btn">
                <i className="ri-share-line"></i><span className="hide-on-mobile">Share </span>
              </a>
              <ul className="share-list">
                <EmailShareButton url={message} subject="" body={""}>
                  <li><a className="color-messenger"><i className="fa fa-envelope-o"></i></a></li>
                </EmailShareButton>
                <FacebookShareButton url={message} >
                {/* quote={message} */}
                  <li><a className="color-fb"><i className="fa fa-facebook"></i></a></li>
                </FacebookShareButton>
                <WhatsappShareButton url={message} >
                {/* title={message} */}
                  <li><a className="color-whatsapp"><i className="fa fa-whatsapp"></i></a></li>
                </WhatsappShareButton>
                <TwitterShareButton url={message} >
                {/* title={message} */}
                  <li><a className="color-twitter"><i className="fa fa-twitter"></i></a></li>
                </TwitterShareButton>
                <TelegramShareButton url={message} >
                {/* title={message} */}
                  <li><a className="color-telegram"><i className="fa fa-telegram"></i></a></li>
                </TelegramShareButton>
              </ul>
            </li>
          </ul>
        </div>
      </>
    )
  }
}